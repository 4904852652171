import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { useAppSelector } from "../../../redux/hooks";

import Typography from "@material-ui/core/Typography";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Button from "@material-ui/core/Button";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";

import { TrashIcon, CrossIcon } from "../../../assets/icons";

import useStyles from "./css";
import { AppointmentCancelModalProps } from "./props";


const AppointmentCancelModal = (props: AppointmentCancelModalProps) => {
  
  const { open, onClose, onCancelAppointment, startDate, scheduleTime, duration, appointmentId } = props;
  
  const theme = useTheme();
  const isMdMedia = useMediaQuery(theme.breakpoints.up("md"));
  const classes = useStyles(isMdMedia ? undefined : 368)();
  const { t } = useTranslation(["general"]);
  
  const [disableButton, setDisableButton] = useState<boolean>(false);
  const localeApp = useAppSelector(state => state.scheduler.locale);

  const date = (startDate: Date, scheduleTime: string): string => {
    const start = new Date(startDate);
      
    let weekDay = start.toLocaleString(localeApp, {
      weekday: "long",
    });

    weekDay = `${weekDay.charAt(0).toUpperCase()}${weekDay.slice(1)}`;

    const monthDay = start.toLocaleString(localeApp, {
      day: "2-digit",
      month: "long",
    });

    return `${weekDay}, ${monthDay} \u00B7 ${getScheduleTime(scheduleTime)}`;
  };

  const getDuration = (duration: number): string => {
    const seconds = duration * 60;
    const hour = Math.floor(seconds / 3600);
    const minute = Math.floor((seconds % 3600) / 60);
    const hourDisplay = hour > 0 ? `${hour} ${hour === 1 ? "hr" : "hrs"} ` : "";
    const minuteDisplay = minute > 0 ? `${minute} min` : "";
    const format = hourDisplay + minuteDisplay;
    return format.trim();
  };
  
  const getScheduleTime = (scheduleTime: string): string => {
    const items = scheduleTime.split(' ');
    items[0] = items[0] + "hrs";
    items[items.length-1] = items[items.length-1] + "hrs";
    return items.join(' ');
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={classes.modalPaper}>
          <div className={classes.modalHeader}>
            <div className={classes.modalClose}>
              <CrossIcon
                className={classes.closeButton}
                onClick={onClose}
                viewBox="0 0 14 14"
                style={{ width: 14, height: 14 }}
              />
            </div>
            <div className={classes.modalHeaderContent}>
              <div className={classes.modalCircle}>
                <TrashIcon />
              </div>
            </div>
          </div>
          <div className={classes.modalContent}>
            <div
              className={`${classes.modalCenter} ${classes.modalTitleSpace}`}
            >
              <Typography className={classes.modalTitleText}>
                {t("Are you sure you want to cancel your appointment?")}
              </Typography>
            </div>
            <div className={classes.modalCenter}>
              <div className={classes.separator} style={{ width: 217 }}></div>
            </div>
            <div className={classes.modalCenter}>
              <Typography className={classes.modalContentText}>
                {t("Confirm that you want to cancel your appointment")}
              </Typography>
            </div>
            <div className={classes.modalCenter}>
              <Typography className={classes.modalDateText}>
                {`${date(
                  startDate,
                  scheduleTime
                )} (${getDuration(duration)})`}
              </Typography>
            </div>
            <div className={classes.modalActions}>
              <Button
                className={`${classes.button} ${classes.goBack}`}
                onClick={onClose}
              >
                {t("Go back")}
              </Button>
              <Button
                disabled={disableButton}
                className={`${classes.button} ${classes.deleteAppointment}`}
                onClick={() => {
                  setDisableButton(true);
                  onCancelAppointment(appointmentId);
                }}
              >
                {t("Cancel Appointment")}
              </Button>
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default AppointmentCancelModal;
