import React, { useEffect } from 'react';

import { useTranslation } from "react-i18next";

import { useParams } from "react-router-dom";

import { schedulerActions } from "../../../redux/scheduler-slice";
import { useAppDispatch } from "../../../redux/hooks";

import { AccountInfoModel } from "../../../models/account";
import { Url } from "../../../constants";
import { AppointmentService } from "../../../api";


export default function AccountInfoProvider() {
  
  const { accountBusinessName } = useParams<any>();
  
  const dispatch = useAppDispatch();
  const { i18n } = useTranslation("general");
  const { setAccount, setLocale, setShowLoader } = schedulerActions;
  
  const _setAccount = (value: AccountInfoModel) => {
    const language = value.languageCode ?? process.env.REACT_APP_DEFAULT_LANGUAGE ?? 'es';
    i18n.changeLanguage(language).finally(() => {
      dispatch(setLocale(language));
      dispatch(setAccount(value));
    });
  };
  
  
  useEffect(() => {
    const fetchData = async () => {
      await loadData();
    };
    
    fetchData();
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  const redirect = () => {
    window.location.href = Url.NotFound;
  };
  
  const loadData = async () => {
    if (accountBusinessName) {
      try {
        dispatch(setShowLoader(true));
        const accountByBusinessName =
          await AppointmentService.getByBusinessName(accountBusinessName);
        dispatch(setShowLoader(false));
        if (accountByBusinessName && accountByBusinessName.id) {
          _setAccount(accountByBusinessName);
        } else {
          redirect();
        }
      } catch (error) {
        redirect();
      }
    } else {
      redirect();
    }
  };
  
  return null;
}