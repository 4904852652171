import { makeStyles } from "@material-ui/core/styles";

const useStyles = (isMobile: boolean) => makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    
    height: isMobile ? 84 : 159,
    
    paddingTop: isMobile ? 19 : 36,
    paddingLeft: isMobile ? 24 : 44,
    
    backgroundColor: theme.colors.white
  },
  content: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    
    height: isMobile ? 43 : 60,
  },
  headers: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-start",
    
    marginLeft: isMobile ? 14 : 36,
  },
  brandingCircle: {
    height: isMobile ? 43 : 60,
    width: isMobile ? 43 : 60,
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  imageCircle: {
    height: isMobile ? 43 : 60,
    width: isMobile ? 43 : 60,
    borderRadius: "50%",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    boxShadow: "0px 3px 20px rgba(229, 231, 234, 0.56)",
  },
  brandingTitle: {
    fontFamily: theme.typography.header2.fontFamily,
    fontStyle: theme.typography.header2.fontStyle,
    fontSize: isMobile ? theme.typography.header4.fontSize : theme.typography.header2.fontSize,
    lineHeight: isMobile ? theme.typography.header4.lineHeight : theme.typography.header2.lineHeight,
    
    fontWeight: 700,
    color: theme.colors.grey5,
  },
  patientFormTitle: {
    fontFamily: isMobile ? theme.typography.txtBody2.fontFamily : theme.typography.header4.fontFamily,
    fontStyle: isMobile ? theme.typography.txtBody2.fontStyle : theme.typography.header4.fontStyle,
    fontSize: isMobile ? theme.typography.txtBody2.fontSize : theme.typography.header4.fontSize,
    lineHeight: isMobile ? theme.typography.txtBody2.lineHeight : theme.typography.header4.lineHeight,
    
    fontWeight: 400,
    color: theme.colors.grey5,
  }
}));

export default useStyles;
