import { makeStyles } from "@material-ui/core/styles";

const useStyles = (width?: number) => makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalPaper: {
    backgroundColor: theme.palette.background.paper,
    border: "1px solid red",
    borderRadius: "10px",
    boxShadow: theme.shadows[5],
    padding: "16px 24px 16px 24px",
    width: width,
    "&:focus:not(:focus-visible)": {
      outline: "none",
    },
    "&:focus-visible": {
      outline: "none",
      border: "1px solid red",
    },
  },
  modalHeader: {
    // display: "flex",
    alignItems: "center",
  },
  modalHeaderContent: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  modalClose: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
  },
  closeButton: {
    cursor: "pointer",
    color: "#919CA5",
    height: 14,
  },
  modalTitle: {
    width: "100%",
  },
  modalTitleText: {
    fontSize: "18px",
    lineHeight: "22px",
    textAlign: "center",
    fontStyle: "normal",
    fontWeight: "bold",
    color: "#26292B",
  },
  modalContent: {},
  modalCircle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    border: "1px solid #F15857",
    width: "50px",
    height: "50px",
  },
  modalCenter: {
    display: "flex",
    justifyContent: "center",
  },
  modalTitleSpace: {
    marginTop: 24,
  },
  modalContentText: {
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "17px",
    textAlign: "center",
    color: "#4A4D51",
  },
  modalDateText: {
    ...theme.typography.txtBody2,
    paddingTop: 7,
  },
  modalActions: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: 32,
  },
  button: {
    width: 200,
    height: 32,
    borderRadius: 51,
    padding: "11px 16px",
    alignItems: "center",
    justifyContent: "center",
    fontFamily: "Inter, sans-serif",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 12,
  },
  goBack: {
    marginRight: 16,
    background: "#F6F6F6",
    color: "#ACB7C0",
  },
  deleteAppointment: {
    background: "#F15857",
    color: "#F6F6F6",
    "&:hover": {
      background: "#F15857",
    },
  },
  separator: {
    height: 0,
    borderTop: "1px solid rgba(201, 205, 211, 0.38)",
    margin: "16px 0px 16px 0px",
  },
}));

export default useStyles;
