import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const BankIcon = (props: any) => {
  const { style, viewBox, ...otherProps } = props;
  return (
    <SvgIcon
      {...otherProps}
      style={{ width: 14, height: 14, color: "#919CA5", ...style }}
      viewBox={viewBox || "0 0 14 14"}
    >
      <path d="M13.6992 2.54297L7.30078 0.0820312C7.21875 0.0273438 7.08203 0 7 0C6.89062 0 6.75391 0.0273438 6.67188 0.0820312L0.273438 2.54297C0.109375 2.59766 0 2.76172 0 2.95312V3.9375C0 4.18359 0.191406 4.375 0.4375 4.375H13.5625C13.7812 4.375 14 4.18359 14 3.9375V2.95312C14 2.76172 13.8633 2.59766 13.6992 2.54297ZM1.75 5.25V9.625H1.3125C1.06641 9.625 0.875 9.84375 0.875 10.0625V11.375H13.125V10.0625C13.125 9.84375 12.9062 9.625 12.6875 9.625H12.25V5.25H10.5V9.625H7.875V5.25H6.125V9.625H3.5V5.25H1.75ZM13.5625 12.25H0.4375C0.191406 12.25 0 12.4688 0 12.6875V13.5625C0 13.8086 0.191406 14 0.4375 14H13.5625C13.7812 14 14 13.8086 14 13.5625V12.6875C14 12.4688 13.7812 12.25 13.5625 12.25Z" />
    </SvgIcon>
  );
};

export default BankIcon;
