import React from "react";

import InputBase from "@material-ui/core/InputBase";

import useStyles from "./css";
import { InputProps } from "./props";

const Input = (props: InputProps) => {

  const { id, value, name, placeholder, isValid, width, inputClass, onChange, onKeyPress, disabled, readOnly, onClick, onBlur, maxLength, autoFocus, inputType } = props;

  const classes = useStyles(width)();

  const isError = (): boolean => {
    if (!isValid) {
      return false;
    }

    if (isValid() === undefined) {
      return false;
    }

    return !isValid();
  };

  const handleOnChange = (event: any) => {
    if (onChange) {
      onChange(event);
    }
  };

  const handleOnKeyPress = (event: any) => {
    if (onKeyPress) {
      onKeyPress(event);
    }
  };

    const handleOnClick = (event: any) => {
        if (onClick) {
            onClick(event);
        }
    };

    const handleOnBlur = (event: any) => {
        if (onBlur) {
            onBlur(event);
        }
    };

  return (
    <InputBase
      id={id}
      className={`${inputClass} ${classes.input} ${isError() ? classes.inputError : ""}`}
      classes={{
        focused: classes.inputFocused,
        disabled: classes.inputDisabled,
        input: classes.inputText,
      }}
      value={value}
      name={name}
      placeholder={placeholder}
      onChange={handleOnChange}
      onKeyPress={handleOnKeyPress}
      disabled={disabled}
      readOnly={readOnly}
      onClick={handleOnClick}
      onBlur={handleOnBlur}
      autoFocus={autoFocus}
      inputProps={{
        maxLength: maxLength
      }}
      type={inputType ?? "text"}

    />
  );
};

export default Input;
