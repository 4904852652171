import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const InfoCircleIcon = (props: any) => {
  const { style, viewBox, ...otherProps } = props;
  return (
    <SvgIcon
      {...otherProps}
      style={{ width: 8, height: 8, ...style }}
      viewBox={viewBox || "0 0 8 8"}
    >
      <path d="M4 0C1.79032 0 0 1.80645 0 4C0 6.20968 1.79032 8 4 8C6.19355 8 8 6.20968 8 4C8 1.80645 6.19355 0 4 0ZM4 1.77419C4.37097 1.77419 4.67742 2.08065 4.67742 2.45161C4.67742 2.83871 4.37097 3.12903 4 3.12903C3.6129 3.12903 3.32258 2.83871 3.32258 2.45161C3.32258 2.08065 3.6129 1.77419 4 1.77419ZM4.90323 5.87097C4.90323 5.98387 4.80645 6.06452 4.70968 6.06452H3.29032C3.17742 6.06452 3.09677 5.98387 3.09677 5.87097V5.48387C3.09677 5.3871 3.17742 5.29032 3.29032 5.29032H3.48387V4.25806H3.29032C3.17742 4.25806 3.09677 4.17742 3.09677 4.06452V3.67742C3.09677 3.58065 3.17742 3.48387 3.29032 3.48387H4.32258C4.41935 3.48387 4.51613 3.58065 4.51613 3.67742V5.29032H4.70968C4.80645 5.29032 4.90323 5.3871 4.90323 5.48387V5.87097Z" />
    </SvgIcon>
  );
};

export default InfoCircleIcon;
