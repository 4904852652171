import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {},
  text: {
    ...theme.typography.txtBody2,
    color: theme.colors.grey6,
  },
  textButton: {
    ...theme.typography.txtBody2,
    color: theme.colors.clearMain,
    fontWeight: 700,

    "&:hover": {
      textDecoration: "underline",
      cursor: "pointer",
    },
  },
  mobileContainer: {
    maxHeight: 500,

    overflowY: "scroll",
  },
}));

export default useStyles;
