import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export default function NoAvailableTimeIcon (props: any) {
  const { viewBox, ...otherProps } = props;
  return (
    <SvgIcon
      {...otherProps}
      style={{ width: 120, height: 89}}
      viewBox={viewBox || "0 0 120 89"}
    >
      <path opacity="0.1" d="M79.8233 6.36124C50.0127 -5.934 14.2582 15.2041 5.98251 32.6399C-13.8896 74.507 20.8376 93.8288 36.1147 86.7616C42.4924 83.8109 47.7019 78.7725 52.7368 73.8704C60.2966 66.5729 70.313 62.3675 80.8165 62.081C88.9058 61.8951 96.9563 64.0657 105.022 63.4283C113.089 62.791 120.214 51.6665 119.995 43.5781C119.661 31.2328 111.79 19.5454 79.8233 6.36124Z" fill="#9FA9E6" />
      <path d="M100.35 5.26489H19.6507C16.9747 5.26489 14.8053 7.43425 14.8053 10.1103V73.6158C14.8053 76.2918 16.9747 78.4612 19.6507 78.4612H100.35C103.026 78.4612 105.195 76.2918 105.195 73.6158V10.1103C105.195 7.43425 103.026 5.26489 100.35 5.26489Z" fill="white" />
      <path d="M100.35 78.6139H19.6505C18.3255 78.6124 17.0553 78.0854 16.1184 77.1485C15.1816 76.2116 14.6546 74.9414 14.6531 73.6164V10.1109C14.6546 8.78598 15.1816 7.51572 16.1184 6.57882C17.0553 5.64193 18.3255 5.11492 19.6505 5.1134H100.35C101.675 5.11492 102.945 5.64193 103.882 6.57882C104.819 7.51572 105.346 8.78598 105.347 10.1109V73.6164C105.346 74.9414 104.819 76.2116 103.882 77.1485C102.945 78.0854 101.675 78.6124 100.35 78.6139ZM19.6505 5.41748C18.4062 5.4189 17.2132 5.91385 16.3334 6.79374C15.4535 7.67363 14.9586 8.8666 14.9572 10.1109V73.6164C14.9586 74.8607 15.4535 76.0537 16.3334 76.9336C17.2132 77.8135 18.4062 78.3084 19.6505 78.3099H100.35C101.594 78.3084 102.787 77.8135 103.667 76.9336C104.547 76.0537 105.042 74.8607 105.043 73.6164V10.1109C105.042 8.8666 104.547 7.67363 103.667 6.79374C102.787 5.91385 101.594 5.4189 100.35 5.41748H19.6505Z" fill="#292A2E" />
      <path d="M105.195 10.1103V19.2984H14.8055V10.1103C14.8053 9.47385 14.9305 8.84361 15.174 8.25558C15.4175 7.66754 15.7744 7.13323 16.2244 6.68318C16.6744 6.23312 17.2087 5.87614 17.7967 5.63262C18.3847 5.38909 19.0149 5.26381 19.6514 5.26392H100.35C101.635 5.26413 102.868 5.77484 103.776 6.68371C104.685 7.59257 105.195 8.82514 105.195 10.1103Z" fill="#6462F3" />
      <path d="M105.358 19.4614H14.6426V10.1101C14.6438 8.78193 15.1719 7.50855 16.111 6.56936C17.0501 5.63016 18.3234 5.10189 19.6515 5.10046H100.35C101.678 5.10211 102.951 5.63047 103.89 6.56963C104.829 7.5088 105.357 8.78206 105.358 10.1101V19.4614ZM14.9693 19.1349H105.032V10.1101C105.031 8.86862 104.537 7.67836 103.659 6.80041C102.782 5.92245 101.592 5.42849 100.35 5.4269H19.6515C18.41 5.42828 17.2196 5.92215 16.3418 6.80013C15.4639 7.67811 14.9702 8.86849 14.969 10.1101L14.9693 19.1349Z" fill="#367CFF" />
      <path d="M38.2834 10.0263V3.24354C38.2834 1.99489 37.2712 0.982666 36.0226 0.982666C34.7739 0.982666 33.7617 1.99489 33.7617 3.24354V10.0263C33.7617 11.275 34.7739 12.2872 36.0226 12.2872C37.2712 12.2872 38.2834 11.275 38.2834 10.0263Z" fill="white" />
      <path d="M36.0222 12.438C35.3825 12.4371 34.7693 12.1826 34.3171 11.7303C33.8649 11.2779 33.6105 10.6647 33.6098 10.025V3.2424C33.6072 2.92387 33.6677 2.60796 33.7878 2.31292C33.9079 2.01787 34.0852 1.74952 34.3095 1.52335C34.5338 1.29718 34.8007 1.11766 35.0947 0.995151C35.3888 0.872644 35.7042 0.80957 36.0227 0.80957C36.3413 0.80957 36.6567 0.872644 36.9507 0.995151C37.2448 1.11766 37.5117 1.29718 37.736 1.52335C37.9603 1.74952 38.1376 2.01787 38.2577 2.31292C38.3778 2.60796 38.4383 2.92387 38.4356 3.2424V10.025C38.4349 10.6649 38.1804 11.2783 37.728 11.7307C37.2755 12.1831 36.662 12.4374 36.0222 12.438ZM36.0222 1.13349C35.463 1.13409 34.927 1.35648 34.5316 1.75184C34.1362 2.14721 33.9138 2.68327 33.9132 3.2424V10.025C33.9108 10.3035 33.9635 10.5797 34.0684 10.8377C34.1733 11.0958 34.3282 11.3304 34.5243 11.5282C34.7204 11.726 34.9537 11.883 35.2107 11.9902C35.4678 12.0973 35.7436 12.1525 36.0221 12.1525C36.3006 12.1525 36.5763 12.0973 36.8334 11.9902C37.0905 11.883 37.3238 11.726 37.5199 11.5282C37.7159 11.3304 37.8709 11.0958 37.9757 10.8377C38.0806 10.5797 38.1334 10.3035 38.1309 10.025V3.2424C38.1303 2.6833 37.9079 2.14726 37.5126 1.7519C37.1173 1.35654 36.5813 1.13414 36.0222 1.13349Z" fill="#262626" />
      <path d="M86.2385 10.0217V3.2389C86.2385 1.99026 85.2263 0.978027 83.9777 0.978027C82.729 0.978027 81.7168 1.99026 81.7168 3.2389V10.0217C81.7168 11.2703 82.729 12.2825 83.9777 12.2825C85.2263 12.2825 86.2385 11.2703 86.2385 10.0217Z" fill="white" />
      <path d="M83.9773 12.4351C83.3376 12.4344 82.7242 12.18 82.2718 11.7276C81.8195 11.2752 81.565 10.6619 81.5643 10.0221V3.23953C81.5643 2.59956 81.8186 1.98581 82.2711 1.53329C82.7236 1.08076 83.3374 0.826538 83.9773 0.826538C84.6173 0.826538 85.231 1.08076 85.6836 1.53329C86.1361 1.98581 86.3903 2.59956 86.3903 3.23953V10.0221C86.3896 10.6619 86.1352 11.2752 85.6828 11.7276C85.2304 12.18 84.6171 12.4344 83.9773 12.4351ZM83.9773 1.13061C83.4183 1.13139 82.8824 1.35385 82.4872 1.7492C82.092 2.14454 81.8697 2.68051 81.8691 3.23953V10.0221C81.8691 10.5815 82.0912 11.1179 82.4867 11.5134C82.8822 11.9089 83.4187 12.1311 83.978 12.1311C84.5373 12.1311 85.0737 11.9089 85.4692 11.5134C85.8647 11.1179 86.0869 10.5815 86.0869 10.0221V3.23953C86.0863 2.68028 85.8638 2.14412 85.4683 1.74874C85.0728 1.35335 84.5366 1.13104 83.9773 1.13061Z" fill="#262626" />
      <path d="M49.0896 49.0442C50.2948 49.0442 51.2718 48.53 51.2718 47.8956C51.2718 47.2613 50.2948 46.7471 49.0896 46.7471C47.8844 46.7471 46.9074 47.2613 46.9074 47.8956C46.9074 48.53 47.8844 49.0442 49.0896 49.0442Z" fill="white" />
      <path d="M70.9113 49.0442C72.1165 49.0442 73.0935 48.53 73.0935 47.8956C73.0935 47.2613 72.1165 46.7471 70.9113 46.7471C69.7061 46.7471 68.7291 47.2613 68.7291 47.8956C68.7291 48.53 69.7061 49.0442 70.9113 49.0442Z" fill="white" />
      <path opacity="0.3" d="M32.2354 25.8024H19.2223V38.5928H32.2354V25.8024Z" fill="#BDC7F8" />
      <path opacity="0.3" d="M32.2354 42.1486H19.2223V54.939H32.2354V42.1486Z" fill="#BDC7F8" />
      <path opacity="0.3" d="M32.2354 58.4946H19.2223V71.2851H32.2354V58.4946Z" fill="#BDC7F8" />
      <path opacity="0.3" d="M49.3701 25.8024H36.3571V38.5928H49.3701V25.8024Z" fill="#BDC7F8" />
      <path opacity="0.3" d="M49.3701 42.1486H36.3571V54.939H49.3701V42.1486Z" fill="#BDC7F8" />
      <path opacity="0.3" d="M66.3318 25.8024H53.3187V38.5928H66.3318V25.8024Z" fill="#BDC7F8" />
      <path opacity="0.3" d="M66.3318 42.1486H53.3187V54.939H66.3318V42.1486Z" fill="#BDC7F8" />
      <path opacity="0.3" d="M83.7744 25.8024H70.7614V38.5928H83.7744V25.8024Z" fill="#BDC7F8" />
      <path opacity="0.3" d="M83.7744 42.1486H70.7614V54.939H83.7744V42.1486Z" fill="#BDC7F8" />
      <path opacity="0.3" d="M49.3701 58.4946H36.3571V71.2851H49.3701V58.4946Z" fill="#BDC7F8" />
      <path opacity="0.3" d="M66.3318 58.4946H53.3187V71.2851H66.3318V58.4946Z" fill="#BDC7F8" />
      <path opacity="0.3" d="M100.778 25.8024H87.7653V38.5928H100.778V25.8024Z" fill="#BDC7F8" />
      <path opacity="0.3" d="M100.778 42.1486H87.7653V54.939H100.778V42.1486Z" fill="#BDC7F8" />
      <path opacity="0.5" d="M46.3689 45.1865C46.2106 45.0282 45.9959 44.9393 45.7721 44.9393C45.5482 44.9393 45.3335 45.0282 45.1752 45.1865L42.8878 47.4739L40.6003 45.1865C40.442 45.0282 40.2273 44.9393 40.0034 44.9393C39.7796 44.9393 39.5649 45.0282 39.4066 45.1865C39.2483 45.3448 39.1594 45.5594 39.1594 45.7833C39.1594 46.0071 39.2483 46.2218 39.4066 46.3801L41.6941 48.6676L39.4066 50.9551C39.2483 51.1134 39.1594 51.3281 39.1594 51.5519C39.1594 51.7758 39.2483 51.9905 39.4066 52.1487C39.5649 52.307 39.7796 52.396 40.0034 52.396C40.2273 52.396 40.442 52.307 40.6003 52.1487L42.8856 49.8634L45.1731 52.1509C45.3314 52.3092 45.546 52.3981 45.7699 52.3981C45.9938 52.3981 46.2084 52.3092 46.3667 52.1509C46.525 51.9926 46.6139 51.7779 46.6139 51.5541C46.6139 51.3302 46.525 51.1155 46.3667 50.9573L44.0814 48.6719L46.3711 46.3823C46.4496 46.3037 46.5119 46.2104 46.5543 46.1078C46.5967 46.0051 46.6185 45.895 46.6183 45.7839C46.6181 45.6728 46.5959 45.5629 46.5532 45.4603C46.5104 45.3578 46.4477 45.2647 46.3689 45.1865Z" fill="#F15857" />
      <path opacity="0.5" d="M63.4103 45.3279C63.252 45.1697 63.0374 45.0807 62.8135 45.0807C62.5897 45.0807 62.375 45.1697 62.2167 45.3279L59.9292 47.6154L57.6417 45.3279C57.4834 45.1697 57.2687 45.0807 57.0449 45.0807C56.821 45.0807 56.6063 45.1697 56.4481 45.3279C56.2898 45.4862 56.2008 45.7009 56.2008 45.9248C56.2008 46.1486 56.2898 46.3633 56.4481 46.5216L58.7355 48.8091L56.4481 51.0966C56.2898 51.2549 56.2008 51.4695 56.2008 51.6934C56.2008 51.9172 56.2898 52.1319 56.4481 52.2902C56.6063 52.4485 56.821 52.5374 57.0449 52.5374C57.2687 52.5374 57.4834 52.4485 57.6417 52.2902L59.927 50.0049L62.2145 52.2924C62.3728 52.4507 62.5875 52.5396 62.8113 52.5396C63.0352 52.5396 63.2499 52.4507 63.4082 52.2924C63.5665 52.1341 63.6554 51.9194 63.6554 51.6956C63.6554 51.4717 63.5665 51.257 63.4082 51.0987L61.1229 48.8134L63.4125 46.5238C63.4911 46.4452 63.5533 46.3519 63.5958 46.2492C63.6382 46.1466 63.6599 46.0365 63.6597 45.9254C63.6595 45.8143 63.6374 45.7043 63.5946 45.6018C63.5518 45.4993 63.4892 45.4062 63.4103 45.3279Z" fill="#F15857" />
      <path opacity="0.5" d="M81.1615 28.7137C81.0032 28.5554 80.7885 28.4665 80.5647 28.4665C80.3408 28.4665 80.1261 28.5554 79.9678 28.7137L77.6804 31.0012L75.3929 28.7137C75.2346 28.5554 75.0199 28.4665 74.796 28.4665C74.5722 28.4665 74.3575 28.5554 74.1992 28.7137C74.0409 28.872 73.952 29.0867 73.952 29.3105C73.952 29.5344 74.0409 29.7491 74.1992 29.9073L76.4867 32.1948L74.1992 34.4823C74.0409 34.6406 73.952 34.8553 73.952 35.0791C73.952 35.303 74.0409 35.5177 74.1992 35.676C74.3575 35.8343 74.5722 35.9232 74.796 35.9232C75.0199 35.9232 75.2346 35.8343 75.3929 35.676L77.6782 33.3907L79.9657 35.6781C80.124 35.8364 80.3386 35.9254 80.5625 35.9254C80.7864 35.9254 81.001 35.8364 81.1593 35.6781C81.3176 35.5198 81.4065 35.3052 81.4065 35.0813C81.4065 34.8575 81.3176 34.6428 81.1593 34.4845L78.874 32.1992L81.1637 29.9095C81.2422 29.831 81.3045 29.7377 81.3469 29.635C81.3894 29.5323 81.4111 29.4223 81.4109 29.3112C81.4107 29.2001 81.3885 29.0901 81.3458 28.9876C81.303 28.885 81.2403 28.792 81.1615 28.7137Z" fill="#F15857" />
    </SvgIcon>
  );
};
