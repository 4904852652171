import React, { useState } from 'react';

import { useTranslation } from "react-i18next";

import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { CancellationPoliciesBlockProps } from "./props";
import InterweaveComponent from "../../common/InterweaveComponent";
import useStyles from "./css";


export default function CancellationPoliciesBlock(props: CancellationPoliciesBlockProps) {

  const { text } = props;

  const { t } = useTranslation("general");
  const classes = useStyles();

  const theme = useTheme();
  const isMdMedia = useMediaQuery(theme.breakpoints.up("md"));

  const shortModeLength = isMdMedia ? 650 : 250;
  const [shortMode, setShortMode] = useState<boolean>(true);

  const forbiddenLastSymbols = ['<', '>', '/', '\\'];

  if (text.length < shortModeLength) {
    return <InterweaveComponent text={text} />
  }

  function handleSeeMoreClick() {
    setShortMode(false)
  }

  function handleSeeLessClick() {
    setShortMode(true)
  }

  if (shortMode) {
    const lastLetter = text.charAt(shortModeLength - 1);
    const cutLength = forbiddenLastSymbols.includes(lastLetter) ? shortModeLength -1 : shortModeLength;
    let cutText = text.substring(0, cutLength) + "...";

    return (
      <div>
        <InterweaveComponent text={cutText} />
        <span onClick={handleSeeMoreClick} className={classes.textButton}>{t("See more")}</span>
      </div>
    );
  }

  return (
    <div className={isMdMedia ? "" : classes.mobileContainer}>
      <InterweaveComponent text={text} />
      <span onClick={handleSeeLessClick} className={classes.textButton}>{t("See less")}</span>
    </div>
  );
}