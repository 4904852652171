import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const CountryCHLIcon = (props: any) => {
    return (
        <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props} >
            <path d="M0.75 12C0.75 18.225 5.775 23.25 12 23.25C18.225 23.25 23.25 18.225 23.25 12H0.75Z" fill="#ED4C5C"/>
            <path d="M12 0.75C18.225 0.75 23.25 5.775 23.25 12H12V0.75Z" fill="#F9F9F9"/>
            <path d="M12 0.75C5.775 0.75 0.75 5.775 0.75 12H12V0.75Z" fill="#2A5F9E"/>
            <path d="M7.5 8.1375L9.3375 9.375L8.6625 7.3875L10.5 6.075H8.2125L7.5 4.125L6.825 6.075H4.5L6.3375 7.3875L5.6625 9.375L7.5 8.1375Z" fill="#F9F9F9"/>
        </SvgIcon>
    )
};

export default CountryCHLIcon;
