import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const SearchIcon = (props: any) => {

  const { style, viewBox, ...otherProps } = props;
  
  return (
  <SvgIcon
    {...otherProps}
    style={style || {width: 16, height: 16}}
    viewBox={viewBox || "0 0 16 16"}
  >
    <path
        d="M5.83786 11.3471C6.91927 11.3485 7.977 11.0297 8.87814 10.4306L11.8094 13.3527C12.014 13.5543 12.2896 13.6671 12.5766 13.6666C12.8636 13.6661 13.1389 13.5524 13.3428 13.35C13.5467 13.1477 13.6629 12.873 13.6663 12.5854C13.6696 12.2978 13.5598 12.0205 13.3606 11.8135L10.4141 8.87617C11.0167 7.9754 11.3353 6.91399 11.3286 5.82958C11.3261 4.7402 11.0013 3.67604 10.3953 2.7716C9.78938 1.86716 8.92943 1.16304 7.9242 0.748248C6.91897 0.333455 5.81359 0.22661 4.74778 0.441215C3.68196 0.65582 2.70356 1.18224 1.93625 1.95394C1.16894 2.72564 0.647154 3.70797 0.436857 4.77677C0.226561 5.84557 0.337191 6.95285 0.754763 7.95866C1.17234 8.96447 1.8781 9.82364 2.78286 10.4276C3.68762 11.0315 4.75074 11.3531 5.83786 11.3518V11.3471ZM5.83786 2.48807C6.49692 2.489 7.14093 2.68562 7.68856 3.05309C8.23618 3.42057 8.66285 3.94242 8.91469 4.55274C9.16653 5.16306 9.23224 5.83447 9.10352 6.48219C8.9748 7.12991 8.65742 7.72488 8.19148 8.19196C7.72553 8.65904 7.1319 8.97728 6.48557 9.1065C5.83924 9.23571 5.1692 9.1701 4.56006 8.91795C3.95091 8.66581 3.43 8.23843 3.06309 7.6898C2.69619 7.14117 2.49975 6.49588 2.49859 5.83545C2.49859 4.94798 2.85041 4.09686 3.47664 3.46933C4.10287 2.84179 4.95223 2.48925 5.83786 2.48925V2.48807Z"
    />
  </SvgIcon>
  );
}

export default SearchIcon;
