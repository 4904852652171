import React from 'react';

import { useLocation } from "react-router-dom";


export default function useQueryParameter(parameterName: string) {
  
  const { search } = useLocation();
  
  const urlSearchParams = new URLSearchParams(search)
  
  return urlSearchParams.get(parameterName);
}