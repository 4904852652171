import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const CountryURYIcon = (props: any) => {
    return (
        <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props} >
            <path d="M23.1375 10.5H22.6125V8.25C22.2375 7.1625 21.675 6.15 21 5.25H18.75V3C16.875 1.575 14.55 0.75 12 0.75C5.775 0.75 0.75 5.775 0.75 12C0.75 12.525 0.7875 13.0125 0.8625 13.5H1.3875V15.75C1.7625 16.8375 2.325 17.85 3 18.75H5.25V21C7.125 22.425 9.45 23.25 12 23.25C14.55 23.25 16.875 22.425 18.75 21V18.75H21C21.675 17.85 22.2375 16.8375 22.6125 15.75V13.5H23.1375C23.2125 13.0125 23.25 12.525 23.25 12C23.25 11.475 23.2125 10.9875 23.1375 10.5Z" fill="#F9F9F9"/>
            <path d="M1.38755 15.75H22.6126C22.8751 15.0375 23.0626 14.2875 23.1376 13.5H0.862549C0.937549 14.2875 1.12505 15.0375 1.38755 15.75Z" fill="#428BC1"/>
            <path d="M3 18.75C3.6375 19.6125 4.3875 20.3625 5.25 21H18.75C19.6125 20.3625 20.3625 19.6125 21 18.75H3Z" fill="#428BC1"/>
            <path d="M12 5.25H21C20.3625 4.3875 19.6125 3.6375 18.75 3H12V5.25Z" fill="#428BC1"/>
            <path d="M12 10.5H23.1375C23.025 9.7125 22.8375 8.9625 22.6125 8.25H12V10.5Z" fill="#428BC1"/>
            <path d="M6.26245 9.2998C6.26245 9.2623 6.26245 9.2998 6.26245 9.2998L5.73745 9.6748C5.43745 10.3873 5.54995 10.6873 5.58745 10.9498L5.32495 11.5873C6.33745 11.2498 5.47495 10.6498 6.86245 9.63731L6.52495 9.1123L6.26245 9.2998Z" fill="#FCD116"/>
            <path d="M5.9625 8.7374L5.6625 8.6999L5.0625 8.5874C4.35 8.8874 4.2 9.1874 4.05 9.3749L3.375 9.5999C4.3125 10.0874 4.125 9.0374 5.85 9.2999L5.9625 8.7374Z" fill="#FCD116"/>
            <path d="M5.325 8.36269L5.85 8.02519L5.6625 7.7627H5.7C5.7 7.7627 5.6625 7.76269 5.6625 7.72519L5.325 7.2002C4.6125 6.9002 4.3125 7.01269 4.05 7.05019L3.375 6.8252C3.7125 7.80019 4.3125 6.93769 5.325 8.36269Z" fill="#FCD116"/>
            <path d="M5.66239 7.2749L6.26239 7.3874L6.29989 7.0874L6.41239 6.4874C6.11239 5.7749 5.81239 5.6249 5.62489 5.4749L5.36239 4.8374C4.87489 5.7749 5.92489 5.5874 5.66239 7.2749Z" fill="#FCD116"/>
            <path d="M8.17495 4.875C7.16245 5.2125 8.02495 5.8125 6.63745 6.825L6.97495 7.35L7.76245 6.825C8.28745 5.55 7.57495 5.475 8.17495 4.875Z" fill="#FCD116"/>
            <path d="M7.61255 7.76239L7.91255 7.79989H7.87505H7.91255L8.51255 7.91239C9.22505 7.61239 9.37505 7.31239 9.52505 7.12489L10.1625 6.86239C9.22505 6.37489 9.41255 7.42489 7.68755 7.16239L7.61255 7.76239Z" fill="#FCD116"/>
            <path d="M8.2125 8.1377L7.6875 8.4752L7.875 8.73769H7.8375C7.8375 8.73769 7.875 8.73769 7.875 8.77519L8.2125 9.30019C8.925 9.60019 9.225 9.48769 9.4875 9.45019L10.125 9.71269C9.825 8.70019 9.225 9.56269 8.2125 8.1377Z" fill="#FCD116"/>
            <path d="M7.79995 9.1877L7.19995 9.0752L7.12495 9.37519V9.33769V9.37519L7.01245 9.9752C7.31245 10.6877 7.61245 10.8377 7.79995 10.9877L8.06245 11.6252C8.54995 10.6877 7.49995 10.8752 7.79995 9.1877Z" fill="#FCD116"/>
            <path d="M7.875 8.7375L10.5 8.25L7.875 7.7625L9.4125 5.5875L7.2375 7.125L6.75 4.5L6.2625 7.125L4.0875 5.5875L5.5875 7.7625L3 8.25L5.5875 8.7375L4.0875 10.9125L6.2625 9.4125L6.75 12L7.2375 9.4125L9.4125 10.9125L7.875 8.7375Z" fill="#FCD116"/>
            <path d="M6.75007 9.1123C6.67507 9.1123 6.56257 9.1498 6.75007 9.1123V9.1123Z" fill="black"/>
        </SvgIcon>
    )
};

export default CountryURYIcon;
