import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const BookMarkIcon = (props: any) => {
  const { style, viewBox, ...otherProps } = props;
  return (
    <SvgIcon
      {...otherProps}
      style={{ width: 12, height: 16, color: "#919CA5", ...style }}
      viewBox={viewBox || "0 0 12 16"}
    >
      <path
        d="M0 16L6 12.5L12 16V1.5C12 0.6875 11.3125 0 10.5 0H1.5C0.65625 0 0 0.6875 0 1.5V16Z"
      />
    </SvgIcon>
  );
};

export default BookMarkIcon;
