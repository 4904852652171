import { makeStyles } from "@material-ui/core/styles";

const useStyles = (isMobile: boolean) => makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    
    backgroundColor: theme.colors.backgroundGrey1
  },
  stepper: {
    height: 85,
    width: isMobile ? 320 : 360,
    
    padding: 8,
    paddingTop: 12,
    
    backgroundColor: theme.colors.backgroundGrey1
  },
  labelAlternativeLabel: {
    
    ...theme.typography.txtBody2,
    color: `${theme.colors.grey3} !important`,
    "& .MuiStepLabel-active": {
      color: `${theme.colors.clearMain} !important`,
      fontWeight: 700,
    },
    "& .MuiStepLabel-label": {
      marginTop: 8,
    }
  },
  labelCompleted: {
    color: `${theme.colors.clearGreen} !important`
  },
}));

export const useStepIconStyles = makeStyles((theme) => ({
  root: {
    color: theme.colors.grey2,
    display: 'flex',
    height: 22,
    alignItems: 'center',
  },
  circle: {
    width: 14,
    height: 14,
    borderRadius: '50%',
    border: `1px solid ${theme.colors.grey2}`
  },
  activeCircle: {
    width: 14,
    height: 14,
    borderRadius: '50%',
    border: `1px solid ${theme.colors.clearMain}`,
    backgroundColor: theme.colors.clearMain
  },
  completed: {
    color: theme.colors.clearGreen,
    zIndex: 1,
    fontSize: 22,
    fontWeight: "bold"
  }})
);

export default useStyles;
