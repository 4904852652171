import CustomerInfo from "../customer/customer-info";
import PatientFormModel from "./patient-form-model";
import { PatientFormValue } from "./patient-form-value";

export default class PatientFormSaveDataRequest {
  accountId: string;
  customerId: string;
  customerInfo: CustomerInfo;
  values: PatientFormValue[];
  hasAcceptedPrivacyNotice: boolean;
  hasReadConsentLetter: boolean;
  signature: string;
  
  constructor(accountId: string, customerId: string, formData: PatientFormModel) {
    this.accountId = accountId;
    this.customerId = customerId;
    this.customerInfo = formData.customerInfo;
    
    this.hasAcceptedPrivacyNotice = formData.acceptance.hasAcceptedPrivacyNotice;
    this.hasReadConsentLetter = formData.acceptance.hasReadConsentLetter;
    this.signature = formData.acceptance.signature ?? "";
  
    const generalInfoValues: PatientFormValue[] = formData.generalInfo
      .flatMap(x => x.controls
        .map(c => { 
          return { 
            fieldId: c.fieldId, 
            type: c.type, 
            value: c.value ? typeof c.value === "string" ? c.value : JSON.stringify(c.value) : null
          } 
        }))
    const clinicHistoryValues: PatientFormValue[] = formData.clinicHistory
      .flatMap(x => x.controls
        .map(c => {
          return {
            fieldId: c.fieldId,
            type: c.type,
            value: c.value ? typeof c.value === "string" ? c.value : JSON.stringify(c.value) : null
          }
        }))
    
    this.values = [...generalInfoValues, ...clinicHistoryValues];
  }
}