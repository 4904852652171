import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container:{
      "@media (min-width: 960px)":{
        maxWidth:"542px"
      }
    },
    rightTitle: {
      ...theme.typography.txtBody1,
      fontWeight: "bold",
      color: theme.colors.clearMain,
      marginBottom: 20,
    },
    rowCenter: {
      display: "flex",
      width: "100%",
      justifyContent: "center",
    },
    brandingCircle: {
      height: 80,
      width: 80,
      borderRadius: "50%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    imageCircle: {
      height: 80,
      width: 80,
      borderRadius: "50%",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      boxShadow: "0px 3px 20px rgba(229, 231, 234, 0.56)",
    },
    brandingTitle: {
      ...theme.typography.header4,
      fontWeight: "bold",
      color: theme.colors.grey5,
    },
    greetingsTxt: {
      ...theme.typography.txtBody1,
      fontWeight: "bold",
      color: theme.colors.clearMain,
    },
    confirmationTxt: {
      ...theme.typography.header4,
      color: theme.colors.grey4,
      textAlign: "center"
    },
    divider: {
      borderTop: "1px solid rgba(201, 205, 211, 0.38)",
      width: "100%",
      marginTop: 60,
      [theme.breakpoints.down("sm")]: {
        marginTop: 20
      },
    },
    instructionTxt: {
      ...theme.typography.header5,
      color: theme.colors.grey4,
      fontWeight: "bold",
      fontSize: "15px"
    },
    instructionTxtContainer: {
      marginTop: 60,
      [theme.breakpoints.down("sm")]: {
        marginTop: 20
      },
    },
    
    label: {
      ...theme.typography.txtBody1,
      color: theme.colors.grey4,
    },
    caption: {
      ...theme.typography.txtBody1,
      fontWeight: "bold",
      color: theme.colors.grey4,
    },
    button: {
      ...theme.typography.buttons,
      fontWeight: "bold",
      borderRadius: 5,
      width: "100%",
      height: 37,
    },
    buttonCreate: {
      color: theme.colors.clearMain,
      border: `1px solid ${theme.colors.clearMain}`,
      "&:hover": {
        background: theme.colors.clearMain,
        color: theme.colors.grey1,
      },
      maxWidth: 400
    },
  })
);

export default useStyles;
