import React from "react";

import AppointmentScheduler from "./../../../components/appointment/AppointmentScheduler";


const AppointmentNew = () => {
  return (
    <div>
      <AppointmentScheduler />
    </div>
  );
};

export default AppointmentNew;
