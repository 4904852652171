import React from "react";
import { useTranslation } from "react-i18next";
import Typography from "@material-ui/core/Typography";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Button from "@material-ui/core/Button";

import { ExclamationIcon, CrossIcon } from "../../../assets/icons";

import useStyles from "./css";
import { PersonalizedAttentionModalProps } from "./props";

const PersonalizedAttentionModal = (props: PersonalizedAttentionModalProps) => {
  const { open, businessNameUrl, name, phone, onClose } = props;
  const classes = useStyles();
  const { t } = useTranslation(["general"]);

  const format = (text: string, ...replacements: string[]) => {
    for (let index = 0; index < replacements.length; index++) {
      text = text.replace(`{${index}}`, replacements[index]);
    }
    return text;
  };

  const onContinue = () => {
    onClose();
    let text = t("require_attention_command_template");
    text = format(text, `${name} [${businessNameUrl}]`);
    const url = `https://wa.me/${phone}?text=${encodeURIComponent(text)}`;
    const win = window.open(url, '_blank');
    if (win != null) {
      win.focus();
    } else {
      document.location.href = url;
    }
  }

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modalYellow}
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={classes.modalPaperYellow}>
          <div className={classes.modalHeader}>
            <div className={classes.modalClose}>
              <CrossIcon
                className={classes.closeButton}
                onClick={onClose}
                viewBox="0 0 14 14"
                style={{ width: 14, height: 14 }}
              />
            </div>
            <div className={classes.modalHeaderContent}>
              <div className={classes.modalCircle}>
                <ExclamationIcon viewBox="0 0 5 16" />
              </div>
            </div>
          </div>
          <div className={classes.modalContent}>
            <div className={`${classes.modalCenter} ${classes.modalTitleSpace}`}>
              <Typography className={classes.modalTitleText}>
                {t("You will be redirected to the WhatsApp conversation with")}&nbsp;
                <b>{name}</b>
              </Typography>
            </div>
            <div className={classes.modalCenter}>
              <div className={classes.separator} style={{ width: 217 }}></div>
            </div>
            <div className={classes.modalCenter}>
              <Typography className={classes.modalContentText}>
                {t("It is not necessary to modify the message displayed, just send it and we will give you personalized attention.")}
              </Typography>
            </div>
            <div className={classes.modalActions}>
              <Button
                className={`${classes.button} ${classes.btnOk}`}
                onClick={onContinue}>
                {t("Continue")}
              </Button>
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default PersonalizedAttentionModal;
