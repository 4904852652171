import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    accountTitle: {
      ...theme.typography.txtBody1,
      fontWeight: "bold",
      color: theme.colors.grey6,
    },
    accountLabel: {
      ...theme.typography.txtBody2,
      color: theme.colors.grey6,
      
      width: "85%",
      
      [theme.breakpoints.down("sm")]: {
        width: "90%",
      },
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    marginLeft23: {
      marginLeft: 23
    },
    paper: {
      flexBasis: "50%",
      "&:focus:not(:focus-visible)": {
        outline: "none",
      },
      "&:focus-visible": {
        outline: "none",
      },
      
      [theme.breakpoints.down("sm")]: {
        flexBasis: "98%",
      },
    },
    modalImage: {
      borderRadius: "20px 20px 0px 0px",
    },
    stepper: {
      justifyContent: "center",
      height: 48,
      borderRadius: "0px 0px 20px 20px",
    },
    dot: {
      marginLeft: 22,
      "&:first-child": {
        marginLeft: 0,
      },
    },
    dotNone: {
      display: "none !important"
    },
    divFlag: {
      display: "inline-block",
      position: "relative",
      height: 20,
      width: 20,
      "& svg": {
        width: "20px !important",
        height: "20px !important"
      }
    },
    divCode: {
      ...theme.typography.txtBody2,
      color: theme.colors.grey6,
      position: "relative",
      display: "inline-block",
      width: 35,
      textAlign: "center"
    },
    divMobile: {
      ...theme.typography.txtBody2,
      color: theme.colors.grey6,
      display: "inline-block",
    },
    divider: {
      borderTop: "1px solid #C9CDD3",
      width: "100%",
      marginTop: 32,
      marginBottom: 32,
      
      [theme.breakpoints.down("sm")]: {
        marginTop: 24,
        marginBottom: 24,
      },
    },
    shortDesktopDivider: {
      [theme.breakpoints.up("md")]: {
        width: "90%!important",
      },
    },
    whatsAppIconSmall: {
      width: "18px !important",
      height: "18px !important",
      "& path": {
        fill: theme.colors.grey5,
      }
    },
    imagesContainer: {
      display: "flex",
      flexWrap: "nowrap",
      gap: 15,
      
      [theme.breakpoints.down("sm")]: {
        gap: 7
      },
    },
    paymentContainerItem: {
      display: "flex",
      marginTop: 10,
      marginLeft: 16,
      [theme.breakpoints.down("sm")]: {
        marginLeft: 22
      },
    },
    imageContainer: {
      borderRadius: 5,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      cursor: "pointer",
      minHeight: 80,
      width: "33.33%",
      boxSizing: "border-box",
      padding: "0.5em",
      
      [theme.breakpoints.down("sm")]: {
        minHeight: 50
      },
    },
    whatsAppNumberContainer: {
      paddingTop: 5,
      paddingLeft: 20
    },
    titleContainer: {
      display: "flex",
      marginTop: 25
    },
    titleContainerMargin: {
      [theme.breakpoints.down("sm")]: {
        marginLeft: 22
      },
    },
    imageModalContainer: {
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      minHeight: "65vh",
        
      [theme.breakpoints.down("sm")]: {
        minHeight: "40vh",
      },
    },
    workHoursItemContainer: {
      display: "flex",
      marginTop: 10,
      
      [theme.breakpoints.down("sm")]: {
        justifyContent: "space-between"
      },
    },
    hoursItemContainer: {
      flexBasis: "50%"
    },
    dayOfWeekItemContainer: {
      flexBasis: "20%",
      marginLeft: 16,
      
      [theme.breakpoints.down("sm")]: {
        marginLeft: 22
      },
    },
    accountInfoRoot: {
      marginTop: 25,
      alignSelf: "center",
      display: "flex", 
      justifyContent: "center",
      flexDirection: "column"
    },
    accountInfoLeftSideContainer: {
      flexBasis: "43%"
    },
    noMargin: {
      margin: "0!Important"
    },
    accountInfoRightSideContainer: {
      flexBasis: "56%"
    },
    itemWithoutImageMargin: {
      marginTop: 8,
      marginLeft: 23
    },
    
    accountInfoContainer: {
      display: "flex",
      flexBasis: "50%",
      boxShadow: "2px 2px 12px 5px rgba(235, 235, 235, 0.46)",
      padding: "0 26px 100px 26px",
      alignSelf: "center",
      width: "50%",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        flexBasis: "80%",
        width: "80%",
        padding: "0 24px 50px 24px",
        marginBottom: 30
      },
    },
    whatsAppContainer: {
      display: "flex",
      marginTop: 25,
      alignItems: "center",
      gap: 4
    },
  })
);

export default useStyles;
