import React from 'react';

import { EstablishmentLogoProps } from "./props";
import useStyles from "./css";


export default function EstablishmentLogo(props: EstablishmentLogoProps) {
  const { logo, onClick} = props;
  
  const classes = useStyles();
  
  return (
    <>
      {logo ? (
        <img
          src={logo}
          onClick={onClick}
          className={classes.selectEstablishmentPhoto}
        />
      ) : (
        <div className={classes.selectEstablishmentPhotoEmpty}></div>
      )}
    </>
  );
}