import React from 'react';

import { useTranslation } from "react-i18next";

import { PatientFormRadioGroupProps } from "./props";
import useStyles from "./css";
import PatientFormRadio from "../PatientFormRadio";
import RadioValue from "../../../models/common/radio-value";
import RadioItem from "../../../models/common/radio-item";

import useControlLabel from "../../../hooks/useControlLabel";
import PatientFormFreeTextInput from "../PatientFormFreeTextInput";


export default function PatientFormRadioGroup(props: PatientFormRadioGroupProps) {
  
  const { values, value, setValue, label, required, hasFreeText, isMobile } = props;
  
  const classes = useStyles();
  
  const { t } = useTranslation("patient-form-sections");
  
  const ControlLabel = useControlLabel({ label, required });
  
  function setCheckedValue(item: RadioItem) {
    let newValue;
    if (value === null) {
      newValue = new RadioValue({ item, additionalFreeTextValue: "" })
    } else {
      newValue = new RadioValue({ ...value, item })
    }
    setValue(newValue)
  }
  
  function setFreeTextValue(additionalFreeTextValue: string) {
    let newValue;
    if (value === null) {
      newValue = new RadioValue({ item: values[0], additionalFreeTextValue })
    } else {
      newValue = new RadioValue({ ...value, additionalFreeTextValue })
    }
    setValue(newValue)
  }
  
  function valueAsRenderItem(item: RadioItem) {
    return (
      <div className={classes.radioContainer}>
        <PatientFormRadio
          key={item.fieldItemId}
          value={t(item.value)}
          checked={value?.item?.fieldItemId === item.fieldItemId}
          onCheck={() => setCheckedValue(item)}
        />
      </div>
    );
  }
  
  return (
    <div className={classes.root}>
      <ControlLabel />
      <div className={classes.radios}>
        {
          values.map(valueAsRenderItem)
        }
      </div>
      {
        hasFreeText
          ?
          <PatientFormFreeTextInput
            value={value?.additionalFreeTextValue ?? ""}
            setValue={setFreeTextValue}
            placeholder={t(label)}
            isMobile={isMobile}
          />
          :
          null
      }
    </div>
  );
}