import React from 'react';

import { Redirect } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import { useAppSelector } from "../../../redux/hooks";

import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Fade from "@material-ui/core/Fade";

import PatientForm from "../PatientForm";
import AccountInfoProvider from "../../account/AccountInfoProvider/account-info-provider";
import useQueryParameter from "../../../hooks/useQueryParameter";
import { Url } from "../../../constants";
import LoaderLine from "../../common/LoaderLine";


export default function PatientFormPage() {
  
  const previewStepParameterName = 'previewStep';
  const customerIdParameterName = 'customerId';
  
  const previewStep = useQueryParameter(previewStepParameterName);
  const customerId = useQueryParameter(customerIdParameterName);
  
  const theme = useTheme();
  const isMdMedia = useMediaQuery(theme.breakpoints.up("md"));
  const isMobile = true; //!isMdMedia TODO bring back when desktop layout is ready
  const showLoader = useAppSelector((state) => state.scheduler.showLoader);
  
  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      {
        (previewStep !== null && previewStep !== "")  || (customerId !== null && customerId !== "") ?
          <>
            <Fade
              in={showLoader ?? false}
              style={{
                transitionDelay: '800ms',
                zIndex: 9999,
                position: "absolute",
                width: "100%",
                top: 0
              }}
            
            >
              <span>
                <LoaderLine/>
              </span>
            </Fade>
            <AccountInfoProvider/>
            <PatientForm
              previewStep={previewStep}
              customerId={customerId}
              isMobile={isMobile}
            />
          </>
          :
          <Redirect to={Url.NotFound}/>
      }
    </>
  );
}