import { AppointmentStatus } from "./../enum";

export class AppointmentDto {
  id: string | null;
  status: AppointmentStatus | null;
  start: Date | null;
  duration: number | null;

  constructor(x?: any) {
    if (typeof x === "object") {
      this.id = x.id;
      this.status = x.status;
      this.start = x.start;
      this.duration = x.duration;
    } else {
      this.id = null;
      this.status = null;
      this.start = null;
      this.duration = null;
    }
  }
}
