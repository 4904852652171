import { AppointmentStatus } from "./../enum/appointment-status.enum";
import { AppointmentServiceDtoModel } from "./appointment-service-dto.model";

export class AppointmentDetailDtoModel {
  id: string | null;
  number: number | null;
  userId: string | null;
  user: string | null;
  customerId: string | null;
  firstName: string | null;
  lastName: string | null;
  mobile: string | null;
  mobileDigits: number | null;
  mobileCodeIso3: string | null;
  email: string | null;
  startDate: Date | null;
  endDate: Date | null;
  status: AppointmentStatus | null;
  services: AppointmentServiceDtoModel[] | null;
  notes: string | null;
  isEditable: boolean | null;
  scheduledSimultaneousServices: boolean;

  constructor(x?: any) {
    if (typeof x === "object") {
      this.id = x.id;
      this.number = x.number;
      this.userId = x.userId;
      this.user = x.user;
      this.customerId = x.customerId;
      this.firstName = x.firstName;
      this.lastName = x.lastName;
      this.mobile = x.mobile;
      this.mobileDigits = x.mobileDigits;
      this.mobileCodeIso3 = x.mobileCodeIso3;
      this.email = x.email;
      this.startDate = x.startDate;
      this.endDate = x.endDate;
      this.status = x.status;
      this.services = x.services;
      this.notes = x.notes;
      this.isEditable = x.isEditable;
      this.scheduledSimultaneousServices = x.scheduledSimultaneousServices;
    } else {
      this.id = null;
      this.number = null;
      this.userId = null;
      this.user = null;
      this.customerId = null;
      this.firstName = null;
      this.lastName = null;
      this.mobile = null;
      this.mobileDigits = null;
      this.mobileCodeIso3 = null;
      this.email = null;
      this.startDate = null;
      this.endDate = null;
      this.status = null;
      this.services = null;
      this.notes = null;
      this.isEditable = null;
      this.scheduledSimultaneousServices = false;
    }
  }
}
