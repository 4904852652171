import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) =>  ({
  modalCenter: {
    display: "block",
    justifyContent: "center",
    textAlign: "center"
  },
  modalTitleSpace: {
    marginTop: 24,
    textAlign: "center"
  },
  modalContentText: {
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "17px",
    textAlign: "center",
    color: "#4A4D51",
  },
  modalDateText: {
    ...theme.typography.txtBody2,
    paddingTop: 7,
    textAlign: "center",
    marginBottom: 5
  },
  normalText: {
    ...theme.typography.txtBody1,
    fontFamily: 'Inter',
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: 14,
    textAlign: "center",
    color: "#5C6477"
  },
  scheduleText: {
    ...theme.typography.txtBody1,
    fontFamily: 'Roboto',
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: 12,
    textAlign: "center",
    color: "#5C6477"
  },
}));

export default useStyles;
