import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

function GoBackIcon(props: any) {
  const { style, viewBox, ...otherProps } = props;
  return (
    <SvgIcon
      {...otherProps}
      style={{ width: 24, fill: "none", height: 24, color: "#919CA5", ...style }}
      viewBox={viewBox || "0 0 24 24"}
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M9.18987 12.4318L13.8622 16.8199C13.9885 16.9353 14.1581 17 14.3348 17C14.5114 17 14.681 16.9353 14.8073 16.8199C14.9306 16.7018 14.9995 16.5434 14.9995 16.3785C14.9995 16.2135 14.9306 16.0551 14.8073 15.9371L10.5981 11.9998L14.8073 8.06338C14.9308 7.94529 15 7.78669 15 7.62153C15 7.45637 14.9308 7.29778 14.8073 7.17969C14.6811 7.06449 14.5117 7 14.3352 7C14.1588 7 13.9894 7.06449 13.8631 7.17969L9.18987 11.5535C9.06808 11.6713 9 11.8288 9 11.9926C9 12.1565 9.06808 12.314 9.18987 12.4318Z" fill="#6462F3"/>
    </SvgIcon>
  );
};

export default GoBackIcon;

