import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    selectEstablishmentRoot: {
      display: 'flex',
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center",
      
      [theme.breakpoints.down("sm")]: {
        backgroundColor: "#FAFAFA"
      },
    },
    selectEstablishmentContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: 16,
    },
    selectEstablishmentFlexContainer: {
      width: 1072,
      
      [theme.breakpoints.down("sm")]: {
        width: 272
      },
    },
    
    scheduleButtonContainer: {
      display: "flex",
      alignItems: "center",
      gap: 16,
      
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        width: 272,
        gap: 8
      },
    },
    selectEstablishmentText: {
      ...theme.typography.header4,
      fontWeight: 700,
      color: theme.colors.grey6
    },
   
    
    selectEstablishmentWindow: {
      width: 256,
      
      border: '1px solid #ccc',
      borderRadius: 10,
      
      [theme.breakpoints.down("sm")]: {
        width: "100%"
      },
    },
    selectEstablishmentPrice: {
      fontSize: 14,
      fontFamily: "Roboto",
    },
    
    buttonContainer: {
      display: "flex",
      textAlign: "right"
    },
    selectEstablishmentDescription: {
      overflow: "hidden",
      wordWrap: "break-word",
      ...theme.typography.txtBody2,
      display: "-webkit-box",
      WebkitBoxOrient: "vertical",
      WebkitLineClamp: 3,
      height: 39
      
    },
    descriptionContainer: {
      padding: 16,
      display: "flex",
      flexDirection: "column",
    },
    selectEstablishmentName: {
      overflow: "hidden",
      fontSize: 14,
      fontWeight: 700,
      fontFamily: "Inter",
      display: "-webkit-box",
      WebkitBoxOrient: "vertical",
      WebkitLineClamp: 1
    },
    whatsAppNumberContainer: {
      paddingTop: 5,
      marginBottom: 15
    },
    divFlag: {
      display: "inline-block",
      position: "relative",
      height: 20,
      width: 20,
      "& svg": {
        width: "20px !important",
        height: "20px !important"
      }
    },
    divCode: {
      ...theme.typography.txtBody2,
      color: theme.colors.grey6,
      position: "relative",
      display: "inline-block",
      width: 35,
      textAlign: "center"
    },
    divMobile: {
      ...theme.typography.txtBody2,
      color: theme.colors.grey6,
      display: "inline-block",
    },
    selectEstablishmentTitle: {
      ...theme.typography.header3,
      color: theme.colors.clear2,
      fontWeight: 700,
      alignSelf: "flex-start",
      marginTop: 35,
      marginBottom: 35
    },
    addButton: {
      ...theme.typography.buttons,
      fontWeight: "bold",
      color: theme.colors.clearMain,
      width: 168,
      height: 32,
      borderRadius: 5,
      padding: "11px 16px",
      border: `1px solid ${theme.colors.clearMain}`,
      justifyContent: "space-around",
      "&.Mui-disabled": {
        color: theme.colors.clearMain,
      },
      '& .MuiTouchRipple-root': {
        display: 'none',
      },
    },
    selectEstablishmentButton: {
      ...theme.typography.buttons,
      fontWeight: "bold",
      color: theme.colors.clearMain,
      width: 168,
      height: 32,
      borderRadius: 5,
      padding: "11px 16px",
      justifyContent: "space-around",
      "&.Mui-disabled": {
        color: theme.colors.clearMain,
      },
      [theme.breakpoints.down("sm")]: {
        width: 272
      },
    },
    
    scheduleButton: {
      background: "#7175FA",
      color: "#F6F6F6",
      "&:hover": {
        background: "#6462F3",
      },
    },
    
  })
);

export default useStyles;
