import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

export class ApiClient {
  static baseUrl = process.env.REACT_APP_SERVER_URL;

  static async post(url: string, formData?: any, config?: AxiosRequestConfig) {
    return axios
      .post(this.baseUrl + url, formData, config)
      .then((resp) => {
        if (!resp.data) {
          return Promise.reject(resp);
        }

        return resp.data;
      })
      .catch((resp) => {
        return Promise.reject(resp);
      });
  }

  static async put(url: string, formData?: any, config?: AxiosRequestConfig) {
    return axios
      .put(this.baseUrl + url, formData, config)
      .then((resp) => {
        if (!resp.data) {
          return Promise.reject(resp);
        }

        return resp.data;
      })
      .catch((resp) => {
        return Promise.reject(resp);
      });
  }

  static async remove(url: string, config?: AxiosRequestConfig) {
    return axios
      .delete(this.baseUrl + url, config)
      .then((resp) => {
        if (!resp.data) {
          return Promise.reject(resp);
        }

        return resp.data;
      })
      .catch((resp) => {
        return Promise.reject(resp);
      });
  }

  static async get(url: string, config?: AxiosRequestConfig) {
    return axios
      .get(this.baseUrl + url, config)
      .then((resp) => {
        if (!resp.data) {
          return Promise.reject(resp);
        }

        return resp.data;
      })
      .catch((resp) => {
        return Promise.reject(resp);
      });
  }

  static async all(values: (AxiosResponse<any> | Promise<AxiosResponse<any>> | Promise<any>)[]): Promise<any[]> {
      return axios.all(values)
          .then((resp) => {
              return resp;
          })
          .catch((resp) => {
              return Promise.reject(resp);
          });
  }
}
