export class EstablishmentInfo {
  name: string;
  phoneNumber: string;
  address: string;
  businessNameUrl: string;
  logo?: string;
  mobileCountry: MobileCountry; 
  constructor(x?: EstablishmentInfo) {
    if (typeof x === "object" && x !== null) {
      this.name = x.name;
      this.phoneNumber = x.phoneNumber ;
      this.address = x.address;
      this.logo = x.logo;
      this.businessNameUrl = x.businessNameUrl;
      this.mobileCountry = x.mobileCountry;
    }
    else {
      this.name = "";
      this.phoneNumber = "";
      this.address = "";
      this.logo = "";
      this.businessNameUrl = "";
      this.mobileCountry = {};
    }
  }
}

export class MobileCountry {
  iso3?: string;
  dialCode?: number;
  dialTemplate?: string;
  
  constructor(x: MobileCountry) {
    if (typeof x === "object" && x !== null) {
      this.iso3 = x.iso3;
      this.dialCode = x.dialCode;
      this.dialTemplate = x.dialTemplate;
    } else {
      this.iso3 = "MEX";
      this.dialCode = 52;
      this.dialTemplate = "000-000-0000";
    }
  }
}
