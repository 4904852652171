import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import backgroundTop from "../../../assets/images/background-top.png";
import backgroundBottom from "../../../assets/images/background-bottom.png";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rightTitle: {
      ...theme.typography.txtBody1,
      fontWeight: "bold",
      color: theme.colors.clear2,
      marginBottom: 20,
    },
    container: {
      display: "flex",
      flexDirection: "row",
      
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column"
      },
    },
    root: {
      position: "absolute",
      left: 0,
      width: "100vw",
      
      [theme.breakpoints.down("sm")]: {
        position: "static",
        width: "80vw"
      },
    },
    rowCenter: {
      display: "flex",
      width: "100%",
      justifyContent: "center",
    },

    brandingTitle: {
      ...theme.typography.header4,
      fontWeight: "bold",
      color: theme.colors.clearMain,
      letterSpacing: 1.44,
      
    },
    text: {
      color: theme.colors.grey4,
      fontFamily: "Inter",
      fontSize: 14
    },
    dividerTop: {
      borderTop: "1px solid rgba(201, 205, 211, 0.38)",
      width: "100%",
      marginTop: 34,
      [theme.breakpoints.down("sm")]: {
        marginTop: 24
      },
    },
    appntInfoContainer: {
      marginLeft: "auto",
      marginRight: "auto",
      paddingTop: 90,
      textAlign: "center",
      
      [theme.breakpoints.down("sm")]: {
        paddingTop: 20
      },
    },
    leftSideImage: {
      top: 0,
      left: 0,
      backgroundImage: `url(${backgroundTop}), url(${backgroundBottom})`,
      backgroundPosition: "left top, left bottom",
      backgroundRepeat: "no-repeat, no-repeat",
      flexBasis: "10%",
      height: "90vh",
      paddingTop: 107,
      display: "flex",
      justifyContent: "center",
      
      [theme.breakpoints.down("sm")]: {
        display: "none"
      },
    },
  })
);

export default useStyles;
