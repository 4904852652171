import React from "react";

import SvgIcon from "@material-ui/core/SvgIcon";

export default function CopyLinkIcon(props: any) {
  
  const { viewBox, style, color, ...otherProps } = props;
  
  return (
    <SvgIcon
      {...otherProps}
      style={style ?? { width: 24, height: 24, zIndex: 1 }}
      viewBox={viewBox || "0 0 24 24"}
    >
      <path
        fill={color || "#1D2027"}
        d="M11 17H7C5.61667 17 4.43767 16.5123 3.463 15.537C2.48767 14.5623 2 13.3833 2 12C2 10.6167 2.48767 9.43733 3.463 8.462C4.43767 7.48733 5.61667 7 7 7H11V9H7C6.16667 9 5.45833 9.29167 4.875 9.875C4.29167 10.4583 4 11.1667 4 12C4 12.8333 4.29167 13.5417 4.875 14.125C5.45833 14.7083 6.16667 15 7 15H11V17ZM8 13V11H16V13H8ZM13 17V15H17C17.8333 15 18.5417 14.7083 19.125 14.125C19.7083 13.5417 20 12.8333 20 12C20 11.1667 19.7083 10.4583 19.125 9.875C18.5417 9.29167 17.8333 9 17 9H13V7H17C18.3833 7 19.5627 7.48733 20.538 8.462C21.5127 9.43733 22 10.6167 22 12C22 13.3833 21.5127 14.5623 20.538 15.537C19.5627 16.5123 18.3833 17 17 17H13Z"/>
    </SvgIcon>
  );
}