import { ApiClient } from "./api-client";
import { EstablishmentInfo } from "../models/establishment/establishmentInfo";


export class EnterpriseService {
  private static baseUrl: string = "/CustomerSite";
  
  static async getListEstablishments(accountId: string): Promise<EstablishmentInfo[]> {
    const data = await ApiClient.get(
      `${this.baseUrl}/getListEstablishments?accountId=${accountId}`
    );

    return data.enterpriseInfoDto.map((establishment: any) => new EstablishmentInfo(establishment));
  }
  
}
