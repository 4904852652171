import React from "react";
import { Button, Snackbar } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import { useStyles } from "./css";
import { SnackbarNotificationProps } from "./props";
import { SnackbarNotificationKind } from "./snackbar-notification-kind";

const  SnackbarNotification = (props: SnackbarNotificationProps) => {
    const classes = useStyles();
    const { t } = useTranslation(["general"]);

    const {
        isOpen,
        onClose,
        message,
        notificationKind,
        anchorOriginHorizontal,
        anchorOriginVertical
    } = props;

    const classToUse = notificationKind === SnackbarNotificationKind.alert
        ? classes.alert
        : "";

    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === "clickaway") {
            return;
        }
        if (onClose) {
            onClose();
        }
    };

    const closeAction = (
        <Button
            color={"primary"}
            size={"small"}
            onClick={handleClose}
        >
            {t("Close").toUpperCase()}
        </Button>
    );

    return (
        <Snackbar
            open={isOpen}
            autoHideDuration={5000}
            onClose={handleClose}
            anchorOrigin={{horizontal: anchorOriginHorizontal || "center", vertical: anchorOriginVertical || "bottom"}}
            key={message}
            className={classToUse}
            action={closeAction}
            message={message}
        />
    );
};

export default SnackbarNotification;
