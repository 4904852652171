export enum PatientFormControlType {
  //Copy of FieldType enum in General
  
  PhoneNumber = 11,
  Email = 12,

  Radio = 21,
  Checkbox = 22,
  Dropdown = 23,

  Text = 31,
  Numeric = 32,
  Date = 33
}