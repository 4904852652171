import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const CountryPRTIcon = (props: any) => {
    return (
        <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props} >
            <path d="M6.375 21.75V2.25C3 4.2 0.75 7.8375 0.75 12C0.75 16.1625 3 19.8 6.375 21.75Z" fill="#699635"/>
            <path d="M12 0.75C9.9375 0.75 8.025 1.3125 6.375 2.25V21.75C8.025 22.725 9.9375 23.25 12 23.25C18.225 23.25 23.25 18.225 23.25 12C23.25 5.775 18.225 0.75 12 0.75Z" fill="#ED4C5C"/>
            <path d="M6.375 7.5C3.9 7.5 1.875 9.525 1.875 12C1.875 14.475 3.9 16.5 6.375 16.5C8.85 16.5 10.875 14.475 10.875 12C10.875 9.525 8.85 7.5 6.375 7.5ZM6.375 15.9375C4.2 15.9375 2.4375 14.175 2.4375 12C2.4375 9.825 4.2 8.0625 6.375 8.0625C8.55 8.0625 10.3125 9.825 10.3125 12C10.3125 14.175 8.55 15.9375 6.375 15.9375Z" fill="#FFE62E"/>
            <path d="M7.91255 16.3877C7.83755 16.4627 7.72505 16.4627 7.65005 16.3877L2.51255 9.6002C2.43755 9.5252 2.43755 9.4127 2.51255 9.3377L2.62505 9.2252C2.70005 9.1502 2.81255 9.1502 2.88755 9.2252L8.02505 16.0127C8.10005 16.0877 8.10005 16.2002 8.02505 16.2752L7.91255 16.3877Z" fill="#FFE62E"/>
            <path d="M6.4502 7.3125C6.5627 7.3125 6.6377 7.3875 6.6377 7.5V16.5C6.6377 16.6125 6.5627 16.6875 6.4502 16.6875H6.2627C6.1502 16.6875 6.0752 16.6125 6.0752 16.5V7.5C6.0752 7.3875 6.1502 7.3125 6.2627 7.3125H6.4502Z" fill="#FFE62E"/>
            <path d="M10.8375 12.4877L6.375 11.7002V12.2627L10.7625 13.0502C10.875 13.0502 10.95 13.0127 10.9875 12.9002L11.025 12.7127C11.025 12.6377 10.95 12.5252 10.8375 12.4877Z" fill="#FFE62E"/>
            <path d="M6.37521 11.6624L2.85021 9.18737C2.77521 9.11237 2.66271 9.14987 2.58771 9.22487L2.47521 9.37487C2.40021 9.44987 2.43771 9.56237 2.51271 9.63737L6.37521 12.3374V11.6624Z" fill="#FFE62E"/>
            <path d="M6.22505 11.25L2.70005 14.775C2.62505 14.85 2.62505 14.9625 2.70005 15.0375L2.85005 15.1875C2.92505 15.2625 3.03755 15.2625 3.11255 15.1875L6.22505 12.075V11.25Z" fill="#FFE62E"/>
            <path d="M6.37511 9.5625L1.68761 11.775C1.61261 11.8125 1.53761 11.925 1.61261 12.0375L1.68761 12.225C1.72511 12.3 1.83761 12.375 1.95011 12.3L6.37511 10.2V9.5625Z" fill="#FFE62E"/>
            <path d="M10.5377 10.2001L6.0752 9.4126V9.9751L10.4627 10.7626C10.5752 10.7626 10.6502 10.7251 10.6877 10.6126L10.7252 10.4251C10.6877 10.3126 10.6127 10.2001 10.5377 10.2001Z" fill="#FFE62E"/>
            <path d="M6.33751 14.5873C6.26251 14.6248 6.18751 14.7373 6.26251 14.8498L6.33751 15.0373C6.37501 15.1123 6.48751 15.1873 6.60001 15.1123L10.35 13.3873L10.5375 12.6748L6.33751 14.5873Z" fill="#FFE62E"/>
            <path d="M6.3377 13.3124L2.1377 11.7749V12.3749L6.3377 13.9124V13.3124Z" fill="#FFE62E"/>
            <path d="M3.4126 14.5498V15.1123L8.9626 15.4873C9.1876 15.3373 9.3751 15.1498 9.5626 14.9623L3.4126 14.5498Z" fill="#FFE62E"/>
            <path d="M4.5 10.125V12.75C4.5 13.8 5.325 14.625 6.375 14.625C7.425 14.625 8.25 13.8 8.25 12.75V10.125H4.5Z" fill="white"/>
            <path d="M6.375 15.375C4.9125 15.375 3.75 14.2125 3.75 12.75V9.375H9V12.75C9 14.2125 7.8375 15.375 6.375 15.375ZM5.25 10.875V12.75C5.25 13.3875 5.7375 13.875 6.375 13.875C7.0125 13.875 7.5 13.3875 7.5 12.75V10.875H5.25Z" fill="#ED4C5C"/>
            <path d="M6.0376 11.0248V11.6248C6.0376 11.7373 6.1126 11.8123 6.2251 11.8123H6.5251C6.6376 11.8123 6.7126 11.7373 6.7126 11.6248V10.9873H6.0376V11.0248Z" fill="#0071BC"/>
            <path d="M6.0376 11.9624V12.5999C6.0376 12.7124 6.1126 12.7874 6.2251 12.7874H6.5251C6.6376 12.7874 6.7126 12.7124 6.7126 12.5999V11.9624H6.0376Z" fill="#0071BC"/>
            <path d="M5.2876 11.9624V12.5999C5.2876 12.7124 5.3626 12.7874 5.4751 12.7874H5.7751C5.8876 12.7874 5.9626 12.7124 5.9626 12.5999V11.9624H5.2876Z" fill="#0071BC"/>
            <path d="M6.7876 11.9624V12.5999C6.7876 12.7124 6.8626 12.7874 6.9751 12.7874H7.2751C7.3876 12.7874 7.4626 12.7124 7.4626 12.5999V11.9624H6.7876Z" fill="#0071BC"/>
            <path d="M6.0376 12.8623V13.4998C6.0376 13.6123 6.1126 13.6873 6.2251 13.6873H6.5251C6.6376 13.6873 6.7126 13.6123 6.7126 13.4998V12.8623H6.0376Z" fill="#0071BC"/>
            <path d="M6.3748 12.4127C6.41623 12.4127 6.44981 12.3791 6.44981 12.3377C6.44981 12.2963 6.41623 12.2627 6.3748 12.2627C6.33338 12.2627 6.2998 12.2963 6.2998 12.3377C6.2998 12.3791 6.33338 12.4127 6.3748 12.4127Z" fill="white"/>
            <path d="M6.2249 12.6002C6.26632 12.6002 6.2999 12.5666 6.2999 12.5252C6.2999 12.4838 6.26632 12.4502 6.2249 12.4502C6.18348 12.4502 6.1499 12.4838 6.1499 12.5252C6.1499 12.5666 6.18348 12.6002 6.2249 12.6002Z" fill="white"/>
            <path d="M6.52495 12.6002C6.56637 12.6002 6.59995 12.5666 6.59995 12.5252C6.59995 12.4838 6.56637 12.4502 6.52495 12.4502C6.48353 12.4502 6.44995 12.4838 6.44995 12.5252C6.44995 12.5666 6.48353 12.6002 6.52495 12.6002Z" fill="white"/>
            <path d="M6.2249 12.2252C6.26632 12.2252 6.2999 12.1916 6.2999 12.1502C6.2999 12.1088 6.26632 12.0752 6.2249 12.0752C6.18348 12.0752 6.1499 12.1088 6.1499 12.1502C6.1499 12.1916 6.18348 12.2252 6.2249 12.2252Z" fill="white"/>
            <path d="M6.52495 12.2252C6.56637 12.2252 6.59995 12.1916 6.59995 12.1502C6.59995 12.1088 6.56637 12.0752 6.52495 12.0752C6.48353 12.0752 6.44995 12.1088 6.44995 12.1502C6.44995 12.1916 6.48353 12.2252 6.52495 12.2252Z" fill="white"/>
            <path d="M6.3748 11.4752C6.41623 11.4752 6.44981 11.4416 6.44981 11.4002C6.44981 11.3588 6.41623 11.3252 6.3748 11.3252C6.33338 11.3252 6.2998 11.3588 6.2998 11.4002C6.2998 11.4416 6.33338 11.4752 6.3748 11.4752Z" fill="white"/>
            <path d="M6.2249 11.6627C6.26632 11.6627 6.2999 11.6291 6.2999 11.5877C6.2999 11.5463 6.26632 11.5127 6.2249 11.5127C6.18348 11.5127 6.1499 11.5463 6.1499 11.5877C6.1499 11.6291 6.18348 11.6627 6.2249 11.6627Z" fill="white"/>
            <path d="M6.52495 11.6627C6.56637 11.6627 6.59995 11.6291 6.59995 11.5877C6.59995 11.5463 6.56637 11.5127 6.52495 11.5127C6.48353 11.5127 6.44995 11.5463 6.44995 11.5877C6.44995 11.6291 6.48353 11.6627 6.52495 11.6627Z" fill="white"/>
            <path d="M6.2249 11.2877C6.26632 11.2877 6.2999 11.2541 6.2999 11.2127C6.2999 11.1713 6.26632 11.1377 6.2249 11.1377C6.18348 11.1377 6.1499 11.1713 6.1499 11.2127C6.1499 11.2541 6.18348 11.2877 6.2249 11.2877Z" fill="white"/>
            <path d="M6.52495 11.2877C6.56637 11.2877 6.59995 11.2541 6.59995 11.2127C6.59995 11.1713 6.56637 11.1377 6.52495 11.1377C6.48353 11.1377 6.44995 11.1713 6.44995 11.2127C6.44995 11.2541 6.48353 11.2877 6.52495 11.2877Z" fill="white"/>
            <path d="M7.1248 12.4127C7.16623 12.4127 7.19981 12.3791 7.19981 12.3377C7.19981 12.2963 7.16623 12.2627 7.1248 12.2627C7.08338 12.2627 7.0498 12.2963 7.0498 12.3377C7.0498 12.3791 7.08338 12.4127 7.1248 12.4127Z" fill="white"/>
            <path d="M6.9749 12.6002C7.01632 12.6002 7.0499 12.5666 7.0499 12.5252C7.0499 12.4838 7.01632 12.4502 6.9749 12.4502C6.93348 12.4502 6.8999 12.4838 6.8999 12.5252C6.8999 12.5666 6.93348 12.6002 6.9749 12.6002Z" fill="white"/>
            <path d="M7.27495 12.6002C7.31637 12.6002 7.34995 12.5666 7.34995 12.5252C7.34995 12.4838 7.31637 12.4502 7.27495 12.4502C7.23353 12.4502 7.19995 12.4838 7.19995 12.5252C7.19995 12.5666 7.23353 12.6002 7.27495 12.6002Z" fill="white"/>
            <path d="M6.9749 12.2252C7.01632 12.2252 7.0499 12.1916 7.0499 12.1502C7.0499 12.1088 7.01632 12.0752 6.9749 12.0752C6.93348 12.0752 6.8999 12.1088 6.8999 12.1502C6.8999 12.1916 6.93348 12.2252 6.9749 12.2252Z" fill="white"/>
            <path d="M7.27495 12.2252C7.31637 12.2252 7.34995 12.1916 7.34995 12.1502C7.34995 12.1088 7.31637 12.0752 7.27495 12.0752C7.23353 12.0752 7.19995 12.1088 7.19995 12.1502C7.19995 12.1916 7.23353 12.2252 7.27495 12.2252Z" fill="white"/>
            <path d="M5.6248 12.4127C5.66623 12.4127 5.6998 12.3791 5.6998 12.3377C5.6998 12.2963 5.66623 12.2627 5.6248 12.2627C5.58338 12.2627 5.5498 12.2963 5.5498 12.3377C5.5498 12.3791 5.58338 12.4127 5.6248 12.4127Z" fill="white"/>
            <path d="M5.4749 12.6002C5.51632 12.6002 5.5499 12.5666 5.5499 12.5252C5.5499 12.4838 5.51632 12.4502 5.4749 12.4502C5.43348 12.4502 5.3999 12.4838 5.3999 12.5252C5.3999 12.5666 5.43348 12.6002 5.4749 12.6002Z" fill="white"/>
            <path d="M5.77495 12.6002C5.81637 12.6002 5.84995 12.5666 5.84995 12.5252C5.84995 12.4838 5.81637 12.4502 5.77495 12.4502C5.73353 12.4502 5.69995 12.4838 5.69995 12.5252C5.69995 12.5666 5.73353 12.6002 5.77495 12.6002Z" fill="white"/>
            <path d="M5.4749 12.2252C5.51632 12.2252 5.5499 12.1916 5.5499 12.1502C5.5499 12.1088 5.51632 12.0752 5.4749 12.0752C5.43348 12.0752 5.3999 12.1088 5.3999 12.1502C5.3999 12.1916 5.43348 12.2252 5.4749 12.2252Z" fill="white"/>
            <path d="M5.77495 12.2252C5.81637 12.2252 5.84995 12.1916 5.84995 12.1502C5.84995 12.1088 5.81637 12.0752 5.77495 12.0752C5.73353 12.0752 5.69995 12.1088 5.69995 12.1502C5.69995 12.1916 5.73353 12.2252 5.77495 12.2252Z" fill="white"/>
            <path d="M6.3748 13.3502C6.41623 13.3502 6.44981 13.3166 6.44981 13.2752C6.44981 13.2338 6.41623 13.2002 6.3748 13.2002C6.33338 13.2002 6.2998 13.2338 6.2998 13.2752C6.2998 13.3166 6.33338 13.3502 6.3748 13.3502Z" fill="white"/>
            <path d="M6.2249 13.5377C6.26632 13.5377 6.2999 13.5041 6.2999 13.4627C6.2999 13.4213 6.26632 13.3877 6.2249 13.3877C6.18348 13.3877 6.1499 13.4213 6.1499 13.4627C6.1499 13.5041 6.18348 13.5377 6.2249 13.5377Z" fill="white"/>
            <path d="M6.52495 13.5377C6.56637 13.5377 6.59995 13.5041 6.59995 13.4627C6.59995 13.4213 6.56637 13.3877 6.52495 13.3877C6.48353 13.3877 6.44995 13.4213 6.44995 13.4627C6.44995 13.5041 6.48353 13.5377 6.52495 13.5377Z" fill="white"/>
            <path d="M6.2249 13.1627C6.26632 13.1627 6.2999 13.1291 6.2999 13.0877C6.2999 13.0463 6.26632 13.0127 6.2249 13.0127C6.18348 13.0127 6.1499 13.0463 6.1499 13.0877C6.1499 13.1291 6.18348 13.1627 6.2249 13.1627Z" fill="white"/>
            <path d="M6.52495 13.1627C6.56637 13.1627 6.59995 13.1291 6.59995 13.0877C6.59995 13.0463 6.56637 13.0127 6.52495 13.0127C6.48353 13.0127 6.44995 13.0463 6.44995 13.0877C6.44995 13.1291 6.48353 13.1627 6.52495 13.1627Z" fill="white"/>
            <path d="M6 10.5H6.75L6.675 10.125V9.75H6.75V9.5625H6.6375V9.675H6.6V9.5625H6.4875V9.675H6.4125V9.5625H6.3375V9.675H6.2625V9.5625H6.15V9.675H6.075V9.5625H6V9.75H6.075V10.125L6 10.5Z" fill="#FFE62E"/>
            <path d="M6.48745 10.5003H6.44995V10.3503C6.44995 10.3128 6.41245 10.2753 6.37495 10.2753C6.33745 10.2753 6.29995 10.3128 6.29995 10.3503V10.5003H6.26245V10.3503C6.26245 10.2753 6.29995 10.2378 6.37495 10.2378C6.44995 10.2378 6.48745 10.2753 6.48745 10.3503V10.5003Z" fill="#3E4347"/>
            <path d="M6.3749 10.0876H6.1499V9.9001C6.1499 9.8251 6.1874 9.7876 6.2624 9.7876C6.3374 9.7876 6.3749 9.8251 6.3749 9.9001V10.0876ZM6.1874 10.0501H6.3374V9.9001C6.3374 9.8626 6.2999 9.8251 6.2624 9.8251C6.2249 9.8251 6.1874 9.8626 6.1874 9.9001V10.0501Z" fill="#3E4347"/>
            <path d="M6.6 10.0876H6.375V9.9001C6.375 9.8251 6.4125 9.7876 6.4875 9.7876C6.5625 9.7876 6.6 9.8251 6.6 9.9001V10.0876ZM6.4125 10.0501H6.5625V9.9001C6.5625 9.8626 6.525 9.8251 6.4875 9.8251C6.45 9.8251 6.4125 9.8626 6.4125 9.9001V10.0501Z" fill="#3E4347"/>
            <path d="M4.125 10.5H4.875L4.8 10.125V9.75H4.875V9.5625H4.7625V9.675H4.725V9.5625H4.6125V9.675H4.5375V9.5625H4.4625V9.675H4.3875V9.5625H4.275V9.675H4.2V9.5625H4.125V9.75H4.2V10.125L4.125 10.5Z" fill="#FFE62E"/>
            <path d="M4.61245 10.5003H4.57495V10.3503C4.57495 10.3128 4.53745 10.2753 4.49995 10.2753C4.46245 10.2753 4.42495 10.3128 4.42495 10.3503V10.5003H4.38745V10.3503C4.38745 10.2753 4.42495 10.2378 4.49995 10.2378C4.57495 10.2378 4.61245 10.2753 4.61245 10.3503V10.5003Z" fill="#3E4347"/>
            <path d="M4.4999 10.0876H4.2749V9.9001C4.2749 9.8251 4.3124 9.7876 4.3874 9.7876C4.4624 9.7876 4.4999 9.8251 4.4999 9.9001V10.0876ZM4.3124 10.0501H4.4624V9.9001C4.4624 9.8626 4.4249 9.8251 4.3874 9.8251C4.3499 9.8251 4.3124 9.8626 4.3124 9.9001V10.0501Z" fill="#3E4347"/>
            <path d="M4.725 10.0876H4.5V9.9001C4.5 9.8251 4.5375 9.7876 4.6125 9.7876C4.6875 9.7876 4.725 9.8251 4.725 9.9001V10.0876ZM4.5375 10.0501H4.6875V9.9001C4.6875 9.8626 4.65 9.8251 4.6125 9.8251C4.575 9.8251 4.5375 9.8626 4.5375 9.9001V10.0501Z" fill="#3E4347"/>
            <path d="M4.125 12.7124H4.875L4.8 12.3374V11.9624H4.875V11.7749H4.8V11.8874H4.725V11.7749H4.65V11.8874H4.5375V11.7749H4.4625V11.8874H4.3875V11.7749H4.3125V11.8874H4.2375V11.7749H4.125V11.9624H4.2375V12.3374L4.125 12.7124Z" fill="#FFE62E"/>
            <path d="M4.61245 12.7127H4.57495V12.5627C4.57495 12.5252 4.53745 12.4877 4.49995 12.4877C4.46245 12.4877 4.42495 12.5252 4.42495 12.5627V12.7127H4.38745V12.5627C4.38745 12.4877 4.42495 12.4502 4.49995 12.4502C4.57495 12.4502 4.61245 12.4877 4.61245 12.5627V12.7127Z" fill="#3E4347"/>
            <path d="M4.4999 12.3H4.2749V12.1125C4.2749 12.0375 4.3124 12 4.3874 12C4.4624 12 4.4999 12.0375 4.4999 12.1125V12.3ZM4.3124 12.2625H4.4624V12.1125C4.4624 12.075 4.4249 12.0375 4.3874 12.0375C4.3499 12.0375 4.3124 12.075 4.3124 12.1125V12.2625Z" fill="#3E4347"/>
            <path d="M4.76235 12.3H4.53735V12.1125C4.53735 12.0375 4.57485 12 4.64985 12C4.72485 12 4.76235 12.0375 4.76235 12.1125V12.3ZM4.57485 12.2625H4.72485V12.1125C4.72485 12.075 4.68735 12.0375 4.64985 12.0375C4.61235 12.0375 4.57485 12.075 4.57485 12.1125V12.2625Z" fill="#3E4347"/>
            <path d="M7.875 12.7501H8.625L8.55 12.3751V12.0001H8.625V11.8501H8.5125V11.9251H8.475V11.8501H8.3625V11.9251H8.2875V11.8501H8.2125V11.9251H8.1375V11.8501H8.025V11.9251H7.95V11.8501H7.875V12.0001H7.95V12.3751L7.875 12.7501Z" fill="#FFE62E"/>
            <path d="M8.36245 12.7503H8.32495V12.6003C8.32495 12.5628 8.28745 12.5253 8.24995 12.5253C8.21245 12.5253 8.17495 12.5628 8.17495 12.6003V12.7503H8.13745V12.6003C8.13745 12.5253 8.17495 12.4878 8.24995 12.4878C8.32495 12.4878 8.36245 12.5253 8.36245 12.6003V12.7503Z" fill="#3E4347"/>
            <path d="M8.2499 12.3376H8.0249V12.1501C8.0249 12.0751 8.0624 12.0376 8.1374 12.0376C8.2124 12.0376 8.2499 12.0751 8.2499 12.1501V12.3376ZM8.0624 12.3001H8.2124V12.1501C8.2124 12.1126 8.1749 12.0751 8.1374 12.0751C8.0999 12.0751 8.0624 12.1126 8.0624 12.1501V12.3001Z" fill="#3E4347"/>
            <path d="M8.475 12.3376H8.25V12.1501C8.25 12.0751 8.2875 12.0376 8.3625 12.0376C8.4375 12.0376 8.475 12.0751 8.475 12.1501V12.3376ZM8.2875 12.3001H8.4375V12.1501C8.4375 12.1126 8.4 12.0751 8.3625 12.0751C8.325 12.0751 8.2875 12.1126 8.2875 12.1501V12.3001Z" fill="#3E4347"/>
            <path d="M7.875 10.5H8.625L8.55 10.125V9.75H8.625V9.5625H8.5125V9.675H8.475V9.5625H8.3625V9.675H8.2875V9.5625H8.2125V9.675H8.1375V9.5625H8.025V9.675H7.95V9.5625H7.875V9.75H7.95V10.125L7.875 10.5Z" fill="#FFE62E"/>
            <path d="M8.36245 10.5003H8.32495V10.3503C8.32495 10.3128 8.28745 10.2753 8.24995 10.2753C8.21245 10.2753 8.17495 10.3128 8.17495 10.3503V10.5003H8.13745V10.3503C8.13745 10.2753 8.17495 10.2378 8.24995 10.2378C8.32495 10.2378 8.36245 10.2753 8.36245 10.3503V10.5003Z" fill="#3E4347"/>
            <path d="M8.2499 10.0876H8.0249V9.9001C8.0249 9.8251 8.0624 9.7876 8.1374 9.7876C8.2124 9.7876 8.2499 9.8251 8.2499 9.9001V10.0876ZM8.0624 10.0501H8.2124V9.9001C8.2124 9.8626 8.1749 9.8251 8.1374 9.8251C8.0999 9.8251 8.0624 9.8626 8.0624 9.9001V10.0501Z" fill="#3E4347"/>
            <path d="M8.475 10.0876H8.25V9.9001C8.25 9.8251 8.2875 9.7876 8.3625 9.7876C8.4375 9.7876 8.475 9.8251 8.475 9.9001V10.0876ZM8.2875 10.0501H8.4375V9.9001C8.4375 9.8626 8.4 9.8251 8.3625 9.8251C8.325 9.8251 8.2875 9.8626 8.2875 9.9001V10.0501Z" fill="#3E4347"/>
            <path d="M5.325 14.8499L5.85 14.2874L5.5125 14.0999L5.25 13.8374L5.325 13.7624L5.2125 13.6499L5.1375 13.7249L5.2125 13.7999L5.175 13.8374L5.1 13.7624L5.025 13.8374L5.1 13.8749L5.025 13.9499L4.9875 13.8749L4.9125 13.9499L4.9875 14.0249L4.9125 14.0624L4.875 13.9874L4.8 14.0624L4.8375 14.1374L4.8 14.1749L4.7625 14.0999L4.6875 14.1749L4.8 14.2874H4.8375L4.875 14.2499L5.1375 14.5124L5.325 14.8499Z" fill="#FFE62E"/>
            <path d="M5.66235 14.4752L5.51235 14.4002C5.47485 14.3627 5.43735 14.3627 5.39985 14.4002C5.36235 14.4377 5.36235 14.4752 5.39985 14.5127L5.51235 14.6252L5.47485 14.6627L5.36235 14.5502C5.32485 14.5127 5.32485 14.4377 5.36235 14.4002C5.39985 14.3627 5.47485 14.3627 5.51235 14.4002L5.66235 14.4752Z" fill="#3E4347"/>
            <path d="M5.28745 14.2876L5.13745 14.4376L5.02495 14.3251C4.98745 14.2876 4.98745 14.2126 5.02495 14.1751C5.06245 14.1376 5.13745 14.1376 5.17495 14.1751L5.28745 14.2876ZM5.13745 14.4001L5.24995 14.2876L5.13745 14.1751C5.09995 14.1376 5.06245 14.1376 5.02495 14.1751C4.98745 14.2126 4.98745 14.2501 5.02495 14.2876L5.13745 14.4001Z" fill="#3E4347"/>
            <path d="M5.47495 14.1001L5.32495 14.2501L5.21245 14.1376C5.17495 14.1001 5.17495 14.0251 5.21245 13.9876C5.24995 13.9501 5.32495 13.9501 5.36245 13.9876L5.47495 14.1001ZM5.32495 14.2126L5.43745 14.1001L5.32495 13.9876C5.28745 13.9501 5.24995 13.9501 5.21245 13.9876C5.17495 14.0251 5.17495 14.0626 5.21245 14.1001L5.32495 14.2126Z" fill="#3E4347"/>
            <path d="M7.4249 14.8499L6.8999 14.2874L7.1999 14.0999L7.4624 13.8374L7.4249 13.7624L7.5374 13.6499L7.6124 13.7249L7.5374 13.7999L7.5749 13.8374L7.6499 13.7624L7.7249 13.8374L7.6499 13.8749L7.7249 13.9499L7.7624 13.8749L7.8374 13.9499L7.7624 14.0249L7.8374 14.0624L7.8749 13.9874L7.9499 14.0624L7.8749 14.1374L7.9499 14.1749L7.9874 14.0999L8.0624 14.1749L7.9499 14.2874H7.9124L7.8749 14.2499L7.6124 14.5124L7.4249 14.8499Z" fill="#FFE62E"/>
            <path d="M7.0874 14.4752L7.2374 14.4002C7.2749 14.3627 7.3124 14.3627 7.3499 14.4002C7.3874 14.4377 7.3874 14.4752 7.3499 14.5127L7.1999 14.6252L7.2374 14.6627L7.3499 14.5502C7.3874 14.5127 7.3874 14.4377 7.3499 14.4002C7.3124 14.3627 7.2374 14.3627 7.1999 14.4002L7.0874 14.4752Z" fill="#3E4347"/>
            <path d="M7.5749 14.1751C7.6124 14.1376 7.6874 14.1376 7.7249 14.1751C7.7624 14.2126 7.7624 14.2876 7.7249 14.3251L7.6124 14.4376L7.4624 14.2876L7.5749 14.1751ZM7.6874 14.2876C7.7249 14.2501 7.7249 14.2126 7.6874 14.1751C7.6499 14.1376 7.6124 14.1376 7.5749 14.1751L7.4999 14.2876L7.6124 14.4001L7.6874 14.2876Z" fill="#3E4347"/>
            <path d="M7.3874 13.9876C7.4249 13.9501 7.4999 13.9501 7.5374 13.9876C7.5749 14.0251 7.5749 14.1001 7.5374 14.1376L7.4249 14.2501L7.2749 14.1001L7.3874 13.9876ZM7.5374 14.1001C7.5749 14.0626 7.5749 14.0251 7.5374 13.9876C7.4999 13.9501 7.4624 13.9501 7.4249 13.9876L7.3124 14.1001L7.4249 14.2126L7.5374 14.1001Z" fill="#3E4347"/>
        </SvgIcon>
    )
};

export default CountryPRTIcon;
