import React from 'react';

import { useTranslation } from "react-i18next";

import Typography from "@material-ui/core/Typography";

import { ClearBrandIcon } from "../../../assets/icons";
import { PatientFormHeaderProps } from "./props";
import useStyles from "./css";


export default function PatientFormHeader(props: PatientFormHeaderProps) {
  
  const { account, isMobile } = props;
  
  const { t } = useTranslation("patient-form");
  
  const classes = useStyles(isMobile)();
  
  return (
    <div className={classes.root}>
      <div className={classes.content}>
        {account && account.logo ? (
          <div
            className={classes.imageCircle}
            style={{ backgroundImage: `url("${account.logo}")` }}
          ></div>
        ) : (
          <div
            className={classes.brandingCircle}
            style={{ background: "#6462F3" }}
          >
            <ClearBrandIcon style={{ color: "#F6F6F6" }}/>
          </div>
        )}
        <div className={classes.headers}>
          <Typography className={classes.brandingTitle}>
            {account.name || "New Life"}
          </Typography>
          <Typography className={classes.patientFormTitle}>
            {t("Patient form")}
          </Typography>
        </div>
      </div>
    </div>
  );
}