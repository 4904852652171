import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    ...theme.typography.subtitle2,
    
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  radio: {
    color: theme.colors.grey5,
    paddingRight: 9,
    "& svg": {
      width: 14,
      height: 14
    }
  },
  text: {
    paddingRight: 3,
    
    ...theme.typography.txtBody1,
    color: theme.colors.grey5
  },
  checked: {
    color: theme.colors.clearMain,
    fontWeight: 700,
  }
  })
);

export default useStyles;