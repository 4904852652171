import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const CountrySWEIcon = (props: any) => {
    return (
        <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props} >
            <g clip-path="url(#clip0_27973_194975)">
                <rect x="-3.69531" y="0.75" width="32" height="24" rx="2" fill="white" />
                <mask id="mask0_27973_194975" style={{maskType: "luminance"}} maskUnits="userSpaceOnUse" x="-4" y="0" width="33" height="25">
                    <rect x="-3.69531" y="0.75" width="32" height="24" rx="2" fill="white" />
                </mask>
                <g mask="url(#mask0_27973_194975)">
                    <rect x="-3.69531" y="0.75" width="32" height="24" fill="#006AA7" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M-3.69531 15.15H5.44755V24.75H10.019V15.15H28.3047V10.35H10.019V0.75H5.44755V10.35H-3.69531V15.15Z" fill="#FFD34D" />
                </g>
            </g>
            <defs>
                <clipPath id="clip0_27973_194975">
                    <rect x="0.304688" y="0.75" width="24" height="24" rx="12" fill="white" />
                </clipPath>
            </defs>
        </SvgIcon>
    )
};

export default CountrySWEIcon;
