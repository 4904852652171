export class CustomerValidModel {
  name?: boolean;
  lastName?: boolean;
  mobile?: boolean;

  constructor(x?: any) {
    if (typeof x === "object") {
      this.name = x.name;
      this.lastName = x.lastName;
      this.mobile = x.mobile;
    }
  }
}
