import React from "react";
import { useTranslation } from "react-i18next";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Button from "@material-ui/core/Button";

import useStyles from "./css";
import { ConfirmActionModalProps } from "./props";
import { CrossIcon } from "../../../assets/icons";

const ConfirmActionModal = (props: ConfirmActionModalProps) => {

    const { t } = useTranslation(["general"]);
    const { open, content, onClose, onConfirm, buttonClass, classModal, confirmButtonText } = props;
    const classes = useStyles();

    const handleConfirm = () => {
        if (onConfirm) onConfirm();
    };

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={`${classes.modal}`}
            open={open}
            onClose={onClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}>
            <Fade in={open}>
                <div className={`${classes.modalPaper} ${classModal}`}>
                    <div className={classes.modalHeader}>
                        <div className={classes.modalClose}>
                            <span className={classes.closeButton} onClick={onClose}>
                                <CrossIcon />
                            </span>
                        </div>
                    </div>
                    <div className={classes.modalContent}>
                        {content}
                        <div className={classes.modalActions}>
                            <Button className={`${buttonClass ?? ""} ${classes.button} ${classes.goBack}`} onClick={onClose} >
                                {t("Go back")}
                            </Button>

                            <Button className={`${buttonClass ?? ""} ${classes.button} ${classes.confirmButton}`} onClick={handleConfirm} >
                                {confirmButtonText ?? t("Yes")}
                            </Button>
                        </div>

                    </div>
                </div>
            </Fade>
        </Modal>
    );
};

export default ConfirmActionModal;
