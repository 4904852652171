export class ScheduleTime {
  scheduleTime: string | null;
  userId: string | null;

  constructor(x?: any) {
    if (typeof x === "object") {
      this.scheduleTime = x.scheduleTime;
      this.userId = x.userId;
    } else {
      this.scheduleTime = null;
      this.userId = null;
    }
  }
}
