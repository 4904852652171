import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const CountryPERIcon = (props: any) => {
    return (
        <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props} >
            <path d="M23.25 12.0002C23.25 7.08769 20.1375 2.9252 15.75 1.3877V22.6127C20.1375 21.0752 23.25 16.9127 23.25 12.0002Z" fill="#ED4C5C"/>
            <path d="M0.75 12.0002C0.75 16.9127 3.9 21.0752 8.25 22.6127V1.3877C3.9 2.9252 0.75 7.08769 0.75 12.0002Z" fill="#ED4C5C"/>
            <path d="M15.75 1.3875C14.5875 0.975 13.3125 0.75 12 0.75C10.6875 0.75 9.4125 0.975 8.25 1.3875V22.6125C9.4125 23.025 10.6875 23.25 12 23.25C13.3125 23.25 14.5875 23.025 15.75 22.6125V1.3875Z" fill="#F9F9F9"/>
        </SvgIcon>
    )
};

export default CountryPERIcon;
