import { ApiClient } from "./api-client";

import PatientFormModel from "../models/patientform/patient-form-model";
import CustomerInfo from "../models/customer/customer-info";
import PatientFormAcceptanceModel from "../models/patientform/patient-form-acceptance-model";
import PatientFormSaveDataRequest from "../models/patientform/patient-form-save-data-request";
import PatientFormSectionModel from "../models/patientform/patient-form-section-model";


export class PatientFormService {
  private static baseUrl: string = "/CustomerSite";
  
  static async getPatientFormData(accountId: string, customerId: string | null): Promise<PatientFormModel> {
    const customerIdRequestValue = customerId === null || customerId === undefined 
    ? "" 
    : customerId;
    
    const data = await ApiClient.get(
      `${this.baseUrl}/GetPatientFormData?accountId=${accountId}&customerId=${customerIdRequestValue}`
    );
    
    const customerInfo = data?.customerInfo ?? new CustomerInfo();
    
    const generalInfo = data?.generalInfo.map((x: any) => new PatientFormSectionModel(x)) ?? [];
    const clinicHistory = data?.clinicHistory.map((x: any) => new PatientFormSectionModel(x)) ?? [];
    
    const newSignatureModel = new PatientFormAcceptanceModel({
      isPrivacyNoticeEnabled: data?.acceptance?.isPrivacyNoticeEnabled ?? false,
      hasAcceptedPrivacyNotice: data?.acceptance?.hasAcceptedPrivacyNotice ?? false,
      isConsentLetterEnabled: data?.acceptance?.isConsentLetterEnabled ?? false,
      hasReadConsentLetter: data?.acceptance?.hasReadConsentLetter ?? false,
      signature: data?.acceptance?.signature ?? null,
      ipAddress: data?.acceptance?.ipAddress ?? "",
      date: data?.acceptance?.date ?? new Date(),
    });

    return new PatientFormModel({
      customerInfo: customerInfo,
      generalInfo: generalInfo,
      clinicHistory: clinicHistory,
      acceptance: newSignatureModel
    });
  }
  
  static async savePatientFormData(accountId: string, customerId: string, patientFormData: PatientFormModel): Promise<void> {
    const request = new PatientFormSaveDataRequest(accountId, customerId, patientFormData);
    const response = await ApiClient.post(`${this.baseUrl}/SavePatientFormData`, request);
  }
  
  static async getPrivacyNotice(accountId: string): Promise<{ privacyNotice: string, privacyNoticeEnabled: boolean }> {
    const data = await ApiClient.get(
      `${this.baseUrl}/GetPrivacyNotice?accountId=${accountId}`
    );
  
    return data;
  }
  
  static async getInformedConsentLetter(accountId: string): Promise<{ consentLetter: string, consentLetterEnabled: boolean }> {
    const data = await ApiClient.get(
      `${this.baseUrl}/GetInformedConsentLetter?accountId=${accountId}`
    );
    
    return data;
  }
}