import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import Button from "@material-ui/core/Button";

import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { schedulerActions } from "../../../redux/scheduler-slice";

import useStyles from "./css";
import GoBackIcon from "../../../assets/icons/GoBackIcon";
import ConfirmActionModal from "../../common/ConfirmActionModal";
import { ServiceUserModel } from "../../../models/service/service-user.model";
import { ScheduleAppointment } from "../../../models/schedule";
import { GroupServiceModel } from "../../../models/service";

export default function BackToEstablishmentsButton() {
  
  const classes = useStyles();
  const { t } = useTranslation(["general"]);
  const account = useAppSelector((state) => state.scheduler.account);
  const serviceUser = useAppSelector((state) => state.scheduler.serviceUser);
  const scheduleAppointment = useAppSelector((state) => state.scheduler.scheduleAppointment);
  const showLoader = useAppSelector((state) => state.scheduler.showLoader);
  const [closeConfirmationModalIsOpen, setCloseConfirmationModalIsOpen] = useState<boolean>(false);
  const history = useHistory();
  
  const dispatch = useAppDispatch();
  const {
    setIsOpenCustomerModal,
    setIsOpenScheduleBusyModal,
    setSchedule,
    setOldSchedule,
    setServiceUser,
    setSuggestedServices,
    setCurrentDate,
    setCustomerValid,
    setScheduleAppointment,
    setIsUpdate
  } = schedulerActions;

  const handleClick = () => {
    const dataWasChanged = serviceUser.some((userService: ServiceUserModel) => userService.serviceId !== null || userService.userId !== null);
    const appointmentWasScheduled = scheduleAppointment.accountId;
    if (dataWasChanged && !appointmentWasScheduled) {
      setCloseConfirmationModalIsOpen(true);
      return;
    }
    cleanInputData();
    history.push("/" + account.enterpriseBusinessNameUrl);
  }
  
  function cleanInputData() {
      dispatch(setIsOpenCustomerModal(false));
      dispatch(setIsOpenScheduleBusyModal(false));
      dispatch(setSchedule(undefined));
      dispatch(setOldSchedule(null));
      dispatch(setServiceUser([new ServiceUserModel()]));
      dispatch(setSuggestedServices([new GroupServiceModel()]));
      dispatch(setCurrentDate(new Date()));
      dispatch(setCustomerValid(undefined));
      dispatch(setIsUpdate(false));
      dispatch(setScheduleAppointment(new ScheduleAppointment()));
  }
  
  const CloseModalContent: React.FC = () => {
    return (<div className={classes.closeConfirmationModalContent}>
        {t("Cancel appointment registration?")}
    </div>);
  };
  
  const handleOnConfirm = () => {
    setCloseConfirmationModalIsOpen(false);
    cleanInputData();
    history.push("/" + account.enterpriseBusinessNameUrl);
  };
  
  return (
      <div className={classes.buttonContainer}>
        <Button className={classes.button}
                disabled={showLoader}
                onClick={handleClick}>
          <div className={classes.goBackIconContainer}>
            <GoBackIcon/>
          </div>
          {t("Go back to establishments")}
        </Button>
        <ConfirmActionModal
          classModal={classes.closeConfirmationModal}
          content={<CloseModalContent/>}
          open={closeConfirmationModalIsOpen}
          onClose={() => setCloseConfirmationModalIsOpen(false)}
          confirmButtonText={t("Yes, cancel")}
          onConfirm={handleOnConfirm}
        />
      </div>
  );
}
