import React from "react";

import SvgIcon from "@material-ui/core/SvgIcon";


export default function MuiCheckIcon(props: any) {
  
  const { viewBox, style, ...otherProps } = props;
  
  return (
    <SvgIcon
      {...otherProps}
      style={style ?? { width: 17, height: 13 }}
      viewBox={viewBox || "0 0 17 13"}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.4926 0.0168258C15.1886 0.0584353 14.9075 0.206881 14.6963 0.437413L5.71449 9.75035L2.18008 6.82527C2.04252 6.71112 1.88461 6.62615 1.71539 6.57527C1.54616 6.52439 1.36893 6.5086 1.19381 6.52874C1.01869 6.54888 0.849107 6.6046 0.694748 6.69269C0.540389 6.78078 0.404274 6.89953 0.294177 7.04217C0.184081 7.1848 0.102159 7.34848 0.0530868 7.52394C0.00401435 7.69941 -0.0112457 7.88321 0.00817669 8.06479C0.0275991 8.24637 0.0813227 8.42218 0.166283 8.58223C0.251243 8.74228 0.365776 8.88341 0.50334 8.99757L4.9735 12.7018C5.23336 12.914 5.5597 13.0192 5.88983 12.9971C6.21996 12.9751 6.53058 12.8273 6.76202 12.5822L16.5954 2.38625C16.7966 2.18276 16.931 1.91894 16.9797 1.63201C17.0284 1.34507 16.989 1.04951 16.867 0.787039C16.745 0.524572 16.5466 0.308449 16.2998 0.169176C16.053 0.029904 15.7703 -0.0254612 15.4915 0.0108456L15.4926 0.0168258Z"
      />
    </SvgIcon>
  );
}