import React from 'react';

import { useTranslation } from "react-i18next";

import { InputBase } from "@material-ui/core";

import { PatientFormTextInputProps } from "./props";
import useStyles from "./css";

import useControlLabel from "../../../hooks/useControlLabel";
import useInputFirstTouch from "../../../hooks/useInputFirstTouch";


export default function PatientFormTextInput(props: PatientFormTextInputProps) {
  
  const { value, setValue, label, required, isMobile, placeholder, isValid, disabled, maxLength } = props;
  
  const classes = useStyles(isMobile)();
  
  const { t } = useTranslation("patient-form");
  
  function handleOnChange(event: any) {
    setValue(event.target.value);
  }
  
  function isError(): boolean {
    if (!isValid || isValid(value) === undefined) {
      return false;
    }
    return required && !isValid(value);
  }
  
  const maxPlaceholderLength = isMobile ? 46 : 99
  
  function getPlaceholder() {
    const fullText = `${t("Write here your answer for")} ${placeholder}`;
    return fullText.length > maxPlaceholderLength
      ? `${fullText.substring(0, maxPlaceholderLength)}...`
      : fullText
  }
  
  const ControlLabel = useControlLabel({ label, required });
  const { hasBeenTouched, handleFirstTouch } = useInputFirstTouch();
  
  return (
    <div className={classes.root}>
      <div className={classes.content}>
        {!!label &&
          <ControlLabel/>
        }
        <InputBase
          className={`${classes.input} ${hasBeenTouched && isError() ? classes.inputError : ""}`}
          classes={{
            focused: classes.inputFocused,
            disabled: classes.inputDisabled,
            input: classes.inputText,
          }}
          placeholder={getPlaceholder()}
          value={value}
          onChange={handleOnChange}
          onBlur={handleFirstTouch}
          disabled={disabled}
          inputProps={{
            maxLength: maxLength
          }}
        />
      </div>
    </div>
  );
}