import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const LocationIcon = (props: any) => {
  const { style, viewBox, ...otherProps } = props;
  return (
    <SvgIcon
      {...otherProps}
      style={{ width: 10, height: 12, color: "#919CA5", ...style }}
      viewBox={viewBox || "0 0 10 12"}
    >
      <path
        d="M9.12147 7.3338C9.48052 6.67825 9.66777 5.94675 9.66664 5.20405C9.66664 2.69826 7.57735 0.666992 4.99998 0.666992C2.42261 0.666992 0.33332 2.69826 0.33332 5.20405C0.331431 6.27434 0.720569 7.31049 1.43136 8.12778L1.43685 8.13446C1.43877 8.13606 1.44042 8.13819 1.44179 8.13979H1.43136L4.20061 10.9981C4.30327 11.1041 4.42712 11.1885 4.56456 11.2462C4.70201 11.3039 4.85014 11.3337 4.99984 11.3337C5.14955 11.3337 5.29768 11.3039 5.43512 11.2462C5.57257 11.1885 5.69642 11.1041 5.79908 10.9981L8.5686 8.13979H8.55817L8.56256 8.13472L8.56311 8.13419C8.58288 8.11124 8.60264 8.08828 8.62213 8.0648C8.81254 7.83728 8.97985 7.59238 9.12174 7.33353L9.12147 7.3338ZM5.00108 6.93881C4.56425 6.93881 4.14532 6.7701 3.83643 6.46979C3.52755 6.16949 3.35402 5.76219 3.35402 5.33749C3.35402 4.9128 3.52755 4.5055 3.83643 4.20519C4.14532 3.90489 4.56425 3.73618 5.00108 3.73618C5.43791 3.73618 5.85684 3.90489 6.16572 4.20519C6.47461 4.5055 6.64814 4.9128 6.64814 5.33749C6.64814 5.76219 6.47461 6.16949 6.16572 6.46979C5.85684 6.7701 5.43791 6.93881 5.00108 6.93881Z"
      />
    </SvgIcon>
  );
};

export default LocationIcon;
