export class ServiceModel {
  id: string | null;
  name: string | null;
  categoryName: string | null;
  duration: number | null;
  price: number | null;
  photo: string | null;
  notes: string | null;
  description: string | null;
  workAreaId?: string | null;
  workArea?: string | null;
  
  constructor(x?: any) {
    if (typeof x === "object") {
      this.id = x.id;
      this.name = x.name;
      this.categoryName = x.categoryName;
      this.duration = x.duration;
      this.price = x.price;
      this.photo = x.photo;
      this.workAreaId = x.workAreaId;
      this.workArea = x.workArea;
      this.description = x.description;
      this.notes = x.notes;
    } else {
      this.id = null;
      this.name = null;
      this.categoryName = null;
      this.duration = null;
      this.price = null;
      this.photo = null;
      this.workAreaId = null;
      this.workArea = null;
      this.description = null;
      this.notes = null;
    }
  }
}
