import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const CountryGTMIcon = (props: any) => {
    return (
        <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
            <path d="M23.25 12.0002C23.25 7.08769 20.1375 2.9252 15.75 1.3877V22.6127C20.1375 21.0752 23.25 16.9127 23.25 12.0002Z" fill="#42ADE2"/>
            <path d="M0.75 12.0002C0.75 16.9127 3.9 21.0752 8.25 22.6127V1.3877C3.9 2.9252 0.75 7.08769 0.75 12.0002Z" fill="#42ADE2"/>
            <path d="M15.75 1.3875C14.5875 0.975 13.3125 0.75 12 0.75C10.6875 0.75 9.4125 0.975 8.25 1.3875V22.6125C9.4125 23.025 10.6875 23.25 12 23.25C13.3125 23.25 14.5875 23.025 15.75 22.6125V1.3875Z" fill="#F9F9F9"/>
            <path d="M10.1626 9.7123C10.1626 9.7123 9.90008 9.6373 9.71258 9.6373C9.86258 9.37481 9.93758 8.9248 9.93758 8.9248C9.93758 8.9248 9.52508 9.14981 9.37508 9.4123C9.30008 9.2248 9.15008 9.03731 9.15008 9.03731C9.15008 9.03731 8.96258 9.26231 9.00008 9.44981C9.03758 9.6373 9.30008 9.9748 9.30008 9.9748C9.30008 9.9748 9.67508 10.0873 9.90008 10.0123C10.0126 9.9748 10.1626 9.7123 10.1626 9.7123Z" fill="#699635"/>
            <path d="M9.78755 10.3126C9.78755 10.3126 9.52505 10.3126 9.33755 10.3501C9.41255 10.0876 9.37505 9.6001 9.37505 9.6001C9.37505 9.6001 9.03755 9.9376 8.96255 10.2001C8.85005 10.0501 8.62505 9.9001 8.62505 9.9001C8.62505 9.9001 8.51255 10.1626 8.58755 10.3501C8.66255 10.5376 9.00005 10.7626 9.00005 10.7626C9.00005 10.7626 9.41255 10.7626 9.56255 10.6501C9.71255 10.5751 9.78755 10.3126 9.78755 10.3126Z" fill="#83BF4F"/>
            <path d="M9.56259 10.9873C9.56259 10.9873 9.30009 11.0623 9.11259 11.1373C9.11259 10.8373 8.92509 10.4248 8.92509 10.4248C8.92509 10.4248 8.70009 10.8373 8.70009 11.0998C8.55009 10.9873 8.32509 10.9123 8.32509 10.9123C8.32509 10.9123 8.28759 11.1748 8.40009 11.3623C8.51259 11.5123 8.92509 11.6623 8.92509 11.6623C8.92509 11.6623 9.30009 11.5498 9.45009 11.3998C9.60009 11.2498 9.56259 10.9873 9.56259 10.9873Z" fill="#699635"/>
            <path d="M9.5624 11.6626C9.5624 11.6626 9.3374 11.7751 9.1874 11.9251C9.1124 11.6251 8.8499 11.2876 8.8499 11.2876C8.8499 11.2876 8.7374 11.7376 8.8124 12.0001C8.6249 11.9251 8.3999 11.9251 8.3999 11.9251C8.3999 11.9251 8.3999 12.1876 8.5874 12.3376C8.7374 12.4501 9.1499 12.4876 9.1499 12.4876C9.1499 12.4876 9.4874 12.3001 9.5999 12.1126C9.6374 11.9251 9.5624 11.6626 9.5624 11.6626Z" fill="#83BF4F"/>
            <path d="M9.7124 12.3375C9.7124 12.3375 9.5249 12.525 9.4124 12.7125C9.2624 12.45 8.8874 12.1875 8.8874 12.1875C8.8874 12.1875 8.8874 12.6375 9.0374 12.9C8.8499 12.9 8.5874 12.9375 8.5874 12.9375C8.5874 12.9375 8.6624 13.2 8.8874 13.275C9.0749 13.35 9.4874 13.275 9.4874 13.275C9.4874 13.275 9.7874 13.0125 9.8249 12.7875C9.8624 12.5625 9.7124 12.3375 9.7124 12.3375Z" fill="#699635"/>
            <path d="M10.0499 12.9375C10.0499 12.9375 9.8999 13.1625 9.8624 13.35C9.6749 13.125 9.2249 12.975 9.2249 12.975C9.2249 12.975 9.3374 13.425 9.5624 13.65C9.3749 13.6875 9.1499 13.8 9.1499 13.8C9.1499 13.8 9.2999 14.025 9.5249 14.0625C9.7499 14.1 10.0874 13.9125 10.0874 13.9125C10.0874 13.9125 10.3124 13.575 10.2749 13.35C10.2749 13.125 10.0499 12.9375 10.0499 12.9375Z" fill="#83BF4F"/>
            <path d="M10.4999 13.4624C10.4999 13.4624 10.4249 13.7249 10.4249 13.9124C10.1624 13.7624 9.7124 13.6874 9.7124 13.6874C9.7124 13.6874 9.9374 14.0999 10.1999 14.2499C10.0124 14.3249 9.8249 14.4749 9.8249 14.4749C9.8249 14.4749 10.0499 14.6624 10.2374 14.6249C10.4249 14.5874 10.7624 14.3249 10.7624 14.3249C10.7624 14.3249 10.8749 13.9499 10.7999 13.7249C10.7249 13.4999 10.4999 13.4624 10.4999 13.4624Z" fill="#699635"/>
            <path d="M11.1 13.8374C11.1 13.8374 11.1 14.0999 11.1375 14.2874C10.875 14.2124 10.3875 14.2499 10.3875 14.2499C10.3875 14.2499 10.725 14.5874 10.9875 14.6624C10.875 14.7749 10.725 14.9999 10.725 14.9999C10.725 14.9999 10.9875 15.1124 11.175 15.0374C11.3625 14.9624 11.5875 14.6249 11.5875 14.6249C11.5875 14.6249 11.5875 14.2124 11.475 14.0624C11.4 13.8749 11.1 13.8374 11.1 13.8374Z" fill="#83BF4F"/>
            <path d="M13.8374 9.7123C13.8374 9.7123 14.0999 9.6373 14.2874 9.6373C14.1374 9.37481 14.0624 8.9248 14.0624 8.9248C14.0624 8.9248 14.4749 9.14981 14.6249 9.4123C14.6999 9.2248 14.8499 9.03731 14.8499 9.03731C14.8499 9.03731 15.0374 9.26231 14.9999 9.44981C14.9624 9.6373 14.6999 9.9748 14.6999 9.9748C14.6999 9.9748 14.3249 10.0873 14.0999 10.0123C13.9874 9.9748 13.8374 9.7123 13.8374 9.7123Z" fill="#699635"/>
            <path d="M14.2124 10.3126C14.2124 10.3126 14.4749 10.3126 14.6624 10.3501C14.5874 10.0876 14.6249 9.6001 14.6249 9.6001C14.6249 9.6001 14.9624 9.9376 15.0374 10.2001C15.1499 10.0501 15.3749 9.9001 15.3749 9.9001C15.3749 9.9001 15.4874 10.1626 15.4124 10.3501C15.3374 10.5376 14.9999 10.7626 14.9999 10.7626C14.9999 10.7626 14.5874 10.7626 14.4374 10.6501C14.2874 10.5751 14.2124 10.3126 14.2124 10.3126Z" fill="#83BF4F"/>
            <path d="M14.4374 10.9873C14.4374 10.9873 14.6999 11.0623 14.8874 11.1373C14.8874 10.8373 15.0749 10.4248 15.0749 10.4248C15.0749 10.4248 15.2999 10.8373 15.3374 11.0998C15.4874 10.9873 15.7124 10.9123 15.7124 10.9123C15.7124 10.9123 15.7499 11.1748 15.6374 11.3623C15.5249 11.5123 15.1124 11.6623 15.1124 11.6623C15.1124 11.6623 14.7374 11.5498 14.5874 11.3998C14.3999 11.2498 14.4374 10.9873 14.4374 10.9873Z" fill="#699635"/>
            <path d="M14.4374 11.6626C14.4374 11.6626 14.6624 11.7751 14.8124 11.9251C14.8874 11.6251 15.1499 11.2876 15.1499 11.2876C15.1499 11.2876 15.2624 11.7376 15.1874 12.0001C15.3749 11.9251 15.5999 11.9251 15.5999 11.9251C15.5999 11.9251 15.5999 12.1876 15.4124 12.3376C15.2624 12.4501 14.8499 12.4876 14.8499 12.4876C14.8499 12.4876 14.5124 12.3001 14.3999 12.1126C14.3624 11.9251 14.4374 11.6626 14.4374 11.6626Z" fill="#83BF4F"/>
            <path d="M14.2875 12.3375C14.2875 12.3375 14.475 12.525 14.5875 12.7125C14.7375 12.45 15.1125 12.1875 15.1125 12.1875C15.1125 12.1875 15.1125 12.6375 14.9625 12.9C15.15 12.9 15.4125 12.9375 15.4125 12.9375C15.4125 12.9375 15.3375 13.2 15.1125 13.275C14.925 13.35 14.5125 13.275 14.5125 13.275C14.5125 13.275 14.2125 13.0125 14.175 12.7875C14.1375 12.5625 14.2875 12.3375 14.2875 12.3375Z" fill="#699635"/>
            <path d="M13.9499 12.9375C13.9499 12.9375 14.0999 13.1625 14.1374 13.35C14.3249 13.125 14.7749 12.975 14.7749 12.975C14.7749 12.975 14.6624 13.425 14.4374 13.65C14.6249 13.6875 14.8499 13.8 14.8499 13.8C14.8499 13.8 14.6999 14.025 14.4749 14.0625C14.2499 14.1 13.9124 13.9125 13.9124 13.9125C13.9124 13.9125 13.6874 13.575 13.7249 13.35C13.7624 13.125 13.9499 12.9375 13.9499 12.9375Z" fill="#83BF4F"/>
            <path d="M13.5001 13.4624C13.5001 13.4624 13.5751 13.7249 13.5751 13.9124C13.8376 13.7624 14.2876 13.6874 14.2876 13.6874C14.2876 13.6874 14.0626 14.0999 13.8001 14.2499C13.9876 14.3249 14.1751 14.4749 14.1751 14.4749C14.1751 14.4749 13.9501 14.6624 13.7626 14.6249C13.5751 14.5874 13.2376 14.3249 13.2376 14.3249C13.2376 14.3249 13.1251 13.9499 13.2001 13.7249C13.2376 13.5749 13.5001 13.4624 13.5001 13.4624Z" fill="#699635"/>
            <path d="M12.9 13.8374C12.9 13.8374 12.9 14.0999 12.8625 14.2874C13.125 14.2124 13.6124 14.2499 13.6124 14.2499C13.6124 14.2499 13.275 14.5874 13.0125 14.6624C13.1625 14.7749 13.3125 14.9999 13.3125 14.9999C13.3125 14.9999 13.05 15.1124 12.8625 15.0374C12.675 14.9624 12.45 14.6249 12.45 14.6249C12.45 14.6249 12.45 14.2124 12.5625 14.0624C12.6 13.8749 12.9 13.8374 12.9 13.8374Z" fill="#83BF4F"/>
            <path d="M15 9.0376L9.75 13.8751L10.2 14.2876L15 9.0376Z" fill="#94989B"/>
            <path d="M9 9.0376L14.25 13.8751L13.8 14.2876L9 9.0376Z" fill="#94989B"/>
            <path d="M10.95 12.375H12.45C12.45 12.375 12.825 12 12.825 10.875C12.825 9.75 12.45 9.375 12.45 9.375H10.95C10.95 9.375 11.325 9.75 11.325 10.875C11.325 12 10.95 12.375 10.95 12.375Z" fill="#FFDD7D"/>
            <path d="M11.6251 7.7998V7.8748C11.6251 7.9123 11.6251 7.98731 11.6626 8.09981C11.6626 8.13731 11.7001 8.1748 11.7001 8.2498C11.7376 8.2873 11.7376 8.3623 11.7751 8.3998C11.8126 8.4373 11.8501 8.5123 11.8876 8.5498C11.9251 8.5873 11.9626 8.66231 12.0376 8.69981C12.1501 8.81231 12.2626 8.88731 12.4126 8.9998C12.4876 9.0373 12.5626 9.1123 12.6376 9.1498C12.7126 9.2248 12.7876 9.2623 12.9001 9.3373C13.0876 9.5248 13.2376 9.6748 13.3876 9.8998C13.4626 10.0123 13.5376 10.0873 13.5751 10.1998C13.6501 10.3123 13.6876 10.4248 13.7251 10.5373C13.8001 10.7623 13.8751 11.0248 13.8751 11.2873C13.9126 11.5498 13.8376 11.7748 13.8001 12.0373C13.7251 12.2623 13.6126 12.4873 13.5001 12.6748C13.4626 12.7498 13.3501 12.8248 13.3126 12.8998C13.2376 12.9748 13.1626 13.0498 13.0876 13.0873C13.0126 13.1248 12.9376 13.1998 12.8626 13.2373L12.6376 13.3498C12.5251 13.4248 12.3751 13.4623 12.3001 13.4623C12.1126 13.4998 12.0001 13.4998 12.0001 13.4998C12.0001 13.4998 12.0751 13.4248 12.2251 13.3123C12.3001 13.2748 12.3751 13.1623 12.4876 13.0873C12.6001 13.0123 12.6751 12.8998 12.7876 12.7873C12.8251 12.7498 12.8626 12.6748 12.9001 12.5998C12.9376 12.5248 12.9751 12.4873 13.0126 12.3748C13.0876 12.2248 13.1251 12.0748 13.1626 11.8873C13.2001 11.6998 13.2001 11.5498 13.1626 11.3623C13.1251 11.1748 13.0876 10.9873 13.0126 10.8373C12.9751 10.7623 12.9376 10.6498 12.9001 10.5748C12.8626 10.4998 12.8251 10.3873 12.7501 10.3123C12.6376 10.1623 12.5626 9.9748 12.4501 9.8623C12.3001 9.7123 12.1876 9.56231 12.0376 9.44981C11.9626 9.37481 11.8876 9.29981 11.8501 9.22481C11.8126 9.14981 11.7376 9.07481 11.7001 8.9998L11.5876 8.7748C11.5501 8.6998 11.5126 8.6248 11.5126 8.5498C11.5126 8.4748 11.4751 8.39981 11.4751 8.32481V8.13731C11.4751 8.02481 11.5126 7.94981 11.5501 7.91231C11.5876 7.83731 11.6251 7.7998 11.6251 7.7998Z" fill="#699635"/>
            <path d="M9.5251 14.9623C9.4126 15.0748 9.2251 15.0748 9.0751 14.9623C8.9626 14.8498 8.9626 14.6623 9.0751 14.5123L9.9376 13.6498C10.0501 13.5373 10.2376 13.5373 10.3876 13.6498C10.5001 13.7623 10.5001 13.9498 10.3876 14.0998L9.5251 14.9623Z" fill="#594640"/>
            <path d="M14.4752 14.9623C14.5877 15.0748 14.7752 15.0748 14.9252 14.9623C15.0377 14.8498 15.0377 14.6623 14.9252 14.5123L14.0627 13.6498C13.9502 13.5373 13.7627 13.5373 13.6127 13.6498C13.5002 13.7623 13.5002 13.9498 13.6127 14.0998L14.4752 14.9623Z" fill="#594640"/>
        </SvgIcon>
    )
};

export default CountryGTMIcon;
