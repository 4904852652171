import React, { useEffect, useState } from 'react';

import { useTranslation } from "react-i18next";

import { useAppSelector } from "../../../redux/hooks";

import Modal from "@material-ui/core/Modal";

import MaskedInput from "react-text-mask";

import useStyles from "./css";
import Country from "../../../models/common/country";
import { GetMobileMaskByTemp } from "../../../constants/masks";
import CountryFlagIcon from "../../../assets/flags";
import { SearchIcon, SortDownIcon } from "../../../assets/icons";
import { PatientFormMobileInputProps } from "./props";
import useControlLabel from "../../../hooks/useControlLabel";
import MobileNumber from '../../../models/common/mobile-number';
import Input from "../Input";


export default function PatientFormMobileInput(props: PatientFormMobileInputProps) {  
  const { t } = useTranslation(["patient-form"]);
    
  const countries = useAppSelector(state => state.scheduler.countries);
  const countryInSettings = useAppSelector(state => state.scheduler.countryInSettings);
  const {
    mobileNumber,
    setMobileNumber,
    isMobileView,
    required,
    label,
    isValid,
    onBlur 
  } = props;
  
  const classes = useStyles(isMobileView)();
  
  const [openModal, setOpenModal] = useState<boolean>(false);
  
  const [countrySelected, setCountrySelected] = useState<Country>(countryInSettings);
  const [searchValue, setSearchValue] = useState<string | null>(null);
  const [masking, setMasking] = useState<any>(GetMobileMaskByTemp(countryInSettings.dialTemplate));
  const [isValidNumber, setIsValidNumber] = useState<boolean>(true);
    
  function setMobile(body: string) {
    if (!mobileNumber?.countryIso) {
      setMobileNumber(new MobileNumber({ countryIso: countrySelected.iso3, body }))
    } else {
      setMobileNumber(new MobileNumber({ ...mobileNumber, body }))
    }
  }
  
  function setIso3(countryIso: string) {
    setMobileNumber(new MobileNumber({ ...mobileNumber, countryIso }))
  }
  
  function handleCountrySelected(country: Country) {
    setSearchValue(null);
    setCountrySelected(country);
    setOpenModal(false);
    
    let newNumber: string;
    if (mobileNumber?.body) {
      newNumber = mobileNumber.body?.substring(0, country.dialDigits || countryInSettings.dialDigits);
      setMobile(newNumber);
    } else {
      newNumber = "";
    }
    
    const m = GetMobileMaskByTemp(country.dialTemplate || countryInSettings.dialTemplate);
    setMasking(m);
    setMobileNumber({ countryIso: country.iso3, body: newNumber })
  
  }
  
  function handleSearch(searchValue: string) {
    setSearchValue(searchValue);
  }
  
  function filterCountries (item: Country) {
    const lowercaseSearchValue = searchValue!.toLowerCase();
    let isSearchByDialCode = /^\+?\d+$/.test(searchValue!);
    
    if (searchValue!.trim().length < 2 && !isSearchByDialCode) {
      return true;
    }
    
    return (
      item.name.toLowerCase().includes(lowercaseSearchValue) ||
      item.dialCode.toString().startsWith(lowercaseSearchValue.replace("+", ""))
    );
  }
  
  const handleMobileChange = (event: React.ChangeEvent<{ value: string }>) => {
    const mobile = event.target.value.replace(/[^0-9]/g, "");
    
    setMobile(mobile);
  };
  
  const validate = (mobileComplete: string, digits: number): boolean => {
    let validDig = false;
    
    if (mobileComplete) {
      const mobile = mobileComplete.replace(/[^0-9]/g, "");
      validDig = (digits === 7 && /^\d{7}$/.test(mobile!)) ||
        (digits === 8 && /^\d{8}$/.test(mobile!)) ||
        (digits === 9 && /^\d{9}$/.test(mobile!)) ||
        (digits === 10 && /^\d{10}$/.test(mobile!)) ||
        (digits === 11 && /^\d{11}$/.test(mobile!)) ||
        (digits === 12 && /^\d{12}$/.test(mobile!));
      
      setIsValidNumber(validDig);
      if (isValid) isValid(validDig);
    }
    
    return validDig;
  }
  
  useEffect(() => {
    
    (async () => {
      
      let iso = mobileNumber?.countryIso ?? countryInSettings?.iso3;
      
      const countryByIso = countries.filter((x: Country) => x.iso3 === iso);
      
      if (countryByIso.length > 0) {
        const country = countryByIso[0];
        setCountrySelected(country);
        setIso3(iso)
        const m = GetMobileMaskByTemp(country.dialTemplate ?? countryInSettings.dialTemplate);
        setMasking(m);
      }
    })();
  }, [mobileNumber?.countryIso, countries]);
  
  useEffect(() => {
    
    if (mobileNumber?.body) {
      const m = GetMobileMaskByTemp(countrySelected.dialTemplate);
      setMasking(m);
    }
    
  }, [countrySelected]);
  
  const handleOpenModal = () => {
    setOpenModal(true);
  }
  
  const handleCloseModal = () => {
    setSearchValue(null);
    setOpenModal(false);
  }
  
  const inputLabel = t(label ?? "Mobile") + " ";
  const ControlLabel = useControlLabel({ label: inputLabel, required })
  
  function countryAsListItem(country: Country) {
    return (
      <div key={country.iso3} className={classes.listCountry}>
        <div className={`${classes.elementList} ${classes.classRow}`} onClick={() => handleCountrySelected(country)}>
          <span className={classes.countryIcon}>
            <CountryFlagIcon iso={country.iso3} />
          </span>
          <div className={classes.countryName}>
            {country.name} (+{country.dialCode})
          </div>
        </div>
      </div>
    );
  }
  
  return (
    <div className={classes.root}>
      <div className={classes.divDropdown}>
        
        <ControlLabel />
        
        <div className={`${classes.inputGroup} ${!isValidNumber ? classes.inputError : ""}`}>
          <MaskedInput
            className={`${classes.inputClassic} ${classes.inputSelectBtn} `}
            placeholder={countrySelected?.dialTemplate || countryInSettings.dialTemplate}
            mask={masking}
            placeholderChar=" "
            onChange={handleMobileChange}
            onBlur={() => {if (onBlur) onBlur(validate(mobileNumber.body, countrySelected?.dialDigits))}}
            value={mobileNumber?.body || ""}
          />
          
          <div className={classes.divCodes} onClick={handleOpenModal}>
            <div className={classes.inputFlag}>
              <CountryFlagIcon iso={countrySelected?.iso3 || "MEX"}/>
            </div>
            <div className={classes.iconDropdown}>
              <SortDownIcon/>
            </div>
            <div className={classes.divDialCode}>
              {countrySelected.dialCode !== undefined ? `+${countrySelected.dialCode}` : "+52"}
            </div>
          </div>
        
        </div>
      </div>
      
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        disableScrollLock={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        BackdropProps={{ style: { backgroundColor: "transparent" } }}
        disablePortal={true}
        className={classes.modal}
        style={{ position: "relative", left: isMobileView ? -144 : -342, top: 65 }}
      >
        <div className={classes.divModalSelect}>
          <div className={classes.titleCountries}>{t("Countries")}</div>
          <div className={classes.searchInputContainer}>
            <Input
              placeholder={t("Search country code")}
              width={"-webkit-fill-available"}
              onChange={(event) => handleSearch(event.target.value)}
            />
            <div className={classes.searchIconContainer}><SearchIcon style={{ fontSize: 17, color: "#919CA5" }}/></div>
          </div>
          <div className={classes.listCountryScroll}>
            {(searchValue === null
              ? countries
              : countries.filter((item: Country) => filterCountries(item))).map(countryAsListItem)}
          </div>
        </div>
      </Modal>
    </div>
  );
}