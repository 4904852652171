import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const ErrorIcon = () => (
  <SvgIcon viewBox="0 0 28 28">
    <path
      d="M2 14C2 7.373 7.373 2 14 2s12 5.373 12 12-5.373 12-12 12S2 20.627 2 14z"
      fill="#F15857"
    />
    <path
      d="M19.657 8.343a1.371 1.371 0 0 0-1.94 0L14 12.06l-3.717-3.717a1.371 1.371 0 1 0-1.94 1.94L12.06 14l-3.717 3.717a1.372 1.372 0 1 0 1.94 1.94l3.713-3.713 3.718 3.717a1.372 1.372 0 0 0 1.94-1.94l-3.714-3.714 3.72-3.72a1.374 1.374 0 0 0-.003-1.944z"
      fill="#F6F6F6"
    />
    <path
      d="M14 24C8.477 24 4 19.523 4 14H0c0 7.732 6.268 14 14 14v-4zm10-10c0 5.523-4.477 10-10 10v4c7.732 0 14-6.268 14-14h-4zM14 4c5.523 0 10 4.477 10 10h4c0-7.732-6.268-14-14-14v4zm0-4C6.268 0 0 6.268 0 14h4C4 8.477 8.477 4 14 4V0z"
      fill="#F15857"
    />
  </SvgIcon>
);

export default ErrorIcon;
