import React from 'react';

import { useTranslation } from "react-i18next";
import { useLocation } from 'react-router-dom';


import { PatientFormBasicInformationProps } from "./props";
import useStyles from "./css";
import MobileNumber from "../../../models/common/mobile-number";
import { Gender } from "../../../models/customer/gender";
import PatientFormTextInput from "../../common/PatientFormTextInput";
import { Validation } from "../../../constants";
import { GetPatientFormFieldKey } from '../../../constants/local-storage-keys';
import PatientFormRadioGroup from "../../common/PatientFormRadioGroup";
import PatientFormMobileInput from "../../common/PatientFormMobileInput";
import PatientFormDateInput from "../../common/PatientFormDateInput";
import RadioValue from "../../../models/common/radio-value";
import RadioItem from "../../../models/common/radio-item";


export default function PatientFormBasicInformation(props: PatientFormBasicInformationProps) {
  
  const { customerInfo, setCustomerInfo, isMobile } = props;
  
  const classes = useStyles(isMobile)();

  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);
  const customerId = searchParams.get("customerId");
  
  const { t } = useTranslation("patient-form");
  
  const maleGender = new RadioItem({ fieldItemId: Gender[Gender.Male], value: t("Male")});
  const femaleGender = new RadioItem({ fieldItemId: Gender[Gender.Female], value: t("Female")});
  const secretGender = new RadioItem({ fieldItemId: Gender[Gender.PreferNotToSay], value: t("I prefer not to say") });
  const customGender = new RadioItem({ fieldItemId: Gender[Gender.Custom], value: t("Custom") });
  
  const genders = [
    maleGender,
    femaleGender,
    secretGender,
    customGender
  ];
  
  function setName(name: string) {
    setCustomerInfo({ ...customerInfo, name })
    localStorage.setItem(GetPatientFormFieldKey(customerId!, "first_name"), name);
  }
  
  function setLastName(lastName: string) {
    setCustomerInfo({ ...customerInfo, lastName })
    localStorage.setItem(GetPatientFormFieldKey(customerId!, "last_name"), lastName);
  }
  
  function setEmail(email: string) {
    setCustomerInfo({ ...customerInfo, email })
    localStorage.setItem(GetPatientFormFieldKey(customerId!, "email"), email);
  }
  
  function setGender(value: RadioValue) {
    if (!value?.item?.fieldItemId) return;
    let gender;
    switch (value.item.fieldItemId) {
      case Gender[Gender.Male]:
        gender = Gender.Male;
        break;
      case Gender[Gender.Female]:
        gender = Gender.Female;
        break;
      case Gender[Gender.PreferNotToSay]:
        gender = Gender.PreferNotToSay;
        break;
      default:
        gender = Gender.Custom;
        break;
    }
    setCustomerInfo({ ...customerInfo, gender })
    localStorage.setItem(GetPatientFormFieldKey(customerId!, "gender"), JSON.stringify(gender));
  }
  
  function setCustomGender(customGender: string) {
    setCustomerInfo({ ...customerInfo, customGender })
    localStorage.setItem(GetPatientFormFieldKey(customerId!, "gender_custom"), customGender);
  }
  
  function setMobile(mobile: MobileNumber) {
    setCustomerInfo({ ...customerInfo, mobile })
    localStorage.setItem(GetPatientFormFieldKey(customerId!, "mobile"), JSON.stringify(mobile));
  }
  
  function setDateOfBirth(dateOfBirth: Date) {
    setCustomerInfo({ ...customerInfo, dateOfBirth })
    localStorage.setItem(GetPatientFormFieldKey(customerId!, "dob"), JSON.stringify(dateOfBirth));
  }
  
  function genderAsRadioValue(gender: Gender | null) {
    switch (gender) {
      case Gender.Male:
        return new RadioValue({ item: maleGender, freeTextValue: "" });
      case Gender.Female:
        return new RadioValue({ item: femaleGender, freeTextValue: "" });
      case Gender.PreferNotToSay:
        return new RadioValue({ item: secretGender, freeTextValue: "" });
      case Gender.Custom:
        return new RadioValue({ item: customGender, freeTextValue: "" });
      default:
        return new RadioValue();
    }
  }
  
  return (
    <div className={classes.root}>
      <div className={classes.sectionHeader}>{t("Basic patient information")}</div>
      <PatientFormTextInput 
        value={customerInfo.name}
        setValue={setName}
        label={t("First name(s)")}
        placeholder={t("First name(s)")}
        isValid={Validation.validateName}
        required={true}
        isMobile={isMobile}
      />
      <PatientFormTextInput
        value={customerInfo.lastName}
        setValue={setLastName}
        label={t("Last name")}
        placeholder={t("Last name")}
        isValid={Validation.validateName}
        required={true}
        isMobile={isMobile}
      />
      <PatientFormMobileInput 
        mobileNumber={customerInfo.mobile}
        setMobileNumber={setMobile}
        isMobileView={isMobile}
        required={true} 
      />
      <PatientFormTextInput
        value={customerInfo.email}
        setValue={setEmail}
        label={t("Email")}
        placeholder={t("Email")}
        isValid={Validation.validateEmail}
        required={false}
        isMobile={isMobile}
      />
      <PatientFormRadioGroup
        values={genders} 
        value={genderAsRadioValue(customerInfo.gender)}
        setValue={setGender}
        label={t("Gender")} 
        required={false}
        isMobile={isMobile}
        hasFreeText={false}
      />
      <PatientFormTextInput
        value={customerInfo.customGender ?? ""}
        setValue={setCustomGender}
        placeholder={t("Custom")}
        isValid={Validation.validateName}
        required={customerInfo.gender === Gender.Custom}
        disabled={customerInfo.gender !== Gender.Custom}
        isMobile={isMobile}
        maxLength={60}
      />
      <PatientFormDateInput 
        value={customerInfo.dateOfBirth}
        setValue={setDateOfBirth} 
        label={t("Date of Birth")}
        required={false}
        isMobile={isMobile} 
        placeholder={t("Date of Birth")}
      />
    </div>
  );
}