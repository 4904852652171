import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  selectEstablishmentPhotoEmpty: {
    height: "33%",
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    backgroundColor: "#FAFAFA",
  },
  selectEstablishmentPhoto: {
    width: '100%',
    height: "33%",
    objectFit: "cover",
    cursor: "pointer",
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10
  },
  })
);

export default useStyles;