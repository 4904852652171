import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        serviceCard: {
            width: 256,
            height: 276,
            border: 'solid',
            borderWidth: 1,
            borderColor: theme.colors.grey2,
            borderRadius: 8,
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        serviceImageContainer: {
            width: '100%',
            height: '100%',
            overflow: 'hidden',
        },
        serviceImage: {
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            objectPosition: 'center',
        },
        serviceInfo: {
            display: 'flex',
            height: 190,
            padding: 16,
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: 16,
            width: '100%'
        },
        serviceNamePriceContainer: {
            width: '100%'
        },
        serviceName: {
            ...theme.typography.txtBody1,
            color: theme.colors.grey6,
            fontWeight: 700,
            margin: 0,
            whiteSpace: 'nowrap',      
            overflow: 'hidden',    
            textOverflow: 'ellipsis'
        },
        servicePrice: {
            ...theme.typography.txtBody2,
            color: '#49454F',
            fontSize: '14px',
            lineHeight: '20px',
            letterSpacing: '0.25px',
            margin: 0,
        },
        serviceDescription: {
            ...theme.typography.txtBody2,
            color: '#49454F',
            display: '-webkit-box',
            WebkitLineClamp: 4,
            WebkitBoxOrient: 'vertical',
            whiteSpace: 'normal',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            position: 'relative',
            maxHeight: 'calc(1.5em * 4)',
        },
        serviceDescriptionContainer: {
            height: 32,
        },
        addButtonContainer: {
            alignSelf: 'flex-end',
            paddingTop: 24,
            [theme.breakpoints.down("sm")]: {
                textAlign: "center"
            },
        },
        addButton: {
            ...theme.typography.buttons,
            fontWeight: 700,
            color: theme.colors.clearMain,
            width: 168,
            height: 32,
            borderRadius: 5,
            paddingTop: 11,
            paddingBottom: 11,
            paddingLeft: 16,
            paddingRight: 16,
            border: 'solid',
            borderWidth: 1,
            borderColor: theme.colors.clearMain,
            justifyContent: 'space-around',
            "&.Mui-disabled": {
                color: theme.colors.clearMain,
            },
            [theme.breakpoints.down("sm")]: {
                width: 163
            },
        },
        addButtonItems: {
            width: '100%',
            display: 'flex',
            justifyContent: 'space-around'
        },
        tooltip: {
            [`& .MuiTooltip-tooltip`]: {
                paddingTop: 4,
                paddingBottom: 4,
                paddingLeft: 8,
                paddingRight: 8,
                borderRadius: 5,
                backgroundColor: theme.colors.grey1,
                ...theme.typography.txtBody2,
                color: theme.colors.grey5,
                boxShadow: '0px 0px 4px #00000034',
                position: 'absolute',
                height: 'auto',
                width: 'max-content',
            },
        }
    })
);

export default useStyles;
