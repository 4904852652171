import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const CountryAUSIcon = (props: any) => {
    return (
        <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props} >
            <g clip-path="url(#clip0_27973_194967)">
                <rect x="-3.69531" y="0.75" width="32" height="24" rx="2" fill="white" />
                <mask id="mask0_27973_194967" style={{maskType: "luminance"}} maskUnits="userSpaceOnUse" x="-4" y="0" width="33" height="25">
                    <rect x="-3.69531" y="0.75" width="32" height="24" rx="2" fill="white" />
                </mask>
                <g mask="url(#mask0_27973_194967)">
                    <rect x="-3.69531" y="0.75" width="32" height="24" fill="#2A5F9E" />
                    <path d="M-3.69531 0.416667H-4.56397L-3.9183 0.997765L1.30469 5.69845V6.98333L-3.89531 10.8833L-4.02865 10.9833V11.15V11.95V12.5675L-3.51243 12.2287L2.49953 8.28333H3.81262L8.98794 12.1648C9.09051 12.2418 9.21527 12.2833 9.34347 12.2833C9.88654 12.2833 10.1435 11.6136 9.73987 11.2503L5.01897 7.00155V5.71667L10.0856 1.91667C10.2535 1.79076 10.3523 1.59317 10.3523 1.38333V0.75V0.132549L9.83609 0.471317L3.82413 4.41667H2.51104L-2.73341 0.483333L-2.8223 0.416667H-2.93341H-3.69531Z" fill="#FF2E3B" stroke="white" stroke-width="0.666667" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M-3.69531 4.75V7.95H1.63802V12.0833C1.63802 12.4515 1.9365 12.75 2.30469 12.75H4.01897C4.38716 12.75 4.68564 12.4515 4.68564 12.0833V7.95H10.1142C10.4824 7.95 10.7809 7.65152 10.7809 7.28333V5.41667C10.7809 5.04848 10.4824 4.75 10.1142 4.75H4.68564V0.75H1.63802V4.75H-3.69531Z" fill="url(#paint0_linear_27973_194967)" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M-3.69531 5.55H2.39993V4.75V0.75H3.92374V4.75V5.55H10.019V7.15H3.92374V7.95V11.95H2.39993V7.95V7.15H-3.69531V5.55Z" fill="url(#paint1_linear_27973_194967)" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.16071 20.35L1.81721 21.0916L2.07379 19.5208L0.986871 18.4084L2.48896 18.1792L3.16071 16.75L3.83247 18.1792L5.33456 18.4084L4.24764 19.5208L4.50422 21.0916L3.16071 20.35Z" fill="white" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M19.1644 21.5484L18.0869 21.8798L18.4025 20.7484L18.0869 19.6171L19.1644 19.9484L20.2419 19.6171L19.9263 20.7484L20.2419 21.8798L19.1644 21.5484Z" fill="white" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M19.1644 6.35313L18.0869 6.6845L18.4025 5.55313L18.0869 4.42175L19.1644 4.75313L20.2419 4.42175L19.9263 5.55313L20.2419 6.6845L19.1644 6.35313Z" fill="white" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M23.7347 11.15L22.6573 11.4814L22.9728 10.35L22.6573 9.21863L23.7347 9.55L24.8122 9.21863L24.4967 10.35L24.8122 11.4814L23.7347 11.15Z" fill="white" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M14.5941 12.7516L13.5166 13.0829L13.8322 11.9516L13.5166 10.8202L14.5941 11.1516L15.6716 10.8202L15.356 11.9516L15.6716 13.0829L14.5941 12.7516Z" fill="white" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M21.4494 14.7469L20.9107 14.9126L21.0685 14.3469L20.9107 13.7812L21.4494 13.9469L21.9882 13.7812L21.8304 14.3469L21.9882 14.9126L21.4494 14.7469Z" fill="white" />
                </g>
            </g>
            <defs>
                <linearGradient id="paint0_linear_27973_194967" x1="-3.69531" y1="0.75" x2="-3.69531" y2="12.75" gradientUnits="userSpaceOnUse">
                    <stop stop-color="white" />
                    <stop offset="1" stop-color="#F0F0F0" />
                </linearGradient>
                <linearGradient id="paint1_linear_27973_194967" x1="-3.69531" y1="0.75" x2="-3.69531" y2="11.95" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#ED4C5C" />
                    <stop offset="1" stop-color="#FC0D1B" />
                </linearGradient>
                <clipPath id="clip0_27973_194967">
                    <rect x="0.304688" y="0.75" width="24" height="24" rx="12" fill="white" />
                </clipPath>
            </defs>
        </SvgIcon>
    )
};

export default CountryAUSIcon;
