import React from 'react';

import { Interweave } from "interweave";

import { InterweaveComponentProps } from "./props";


export default function InterweaveComponent(props: InterweaveComponentProps) {
  
  const { text } = props;
  
  const linkRegex = new RegExp("[Hh]ttps?:\\/\\/(www\\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\\.[a-z]{2,4}\\b([-a-zA-Z0-9@:%_\+.~#?&/=]*)");
  
  const encaseLinksWithTags = (str: string) => {
    if (str === null) return str;
    const matches = str.match(linkRegex);
    if (matches && matches.length > 0) {
      let newStr = str;
      newStr = newStr.replace(matches[0], `<a target="_blank" href="${matches[0]}">${matches[0]}</a>`)
      return newStr;
    } else {
      return str;
    }
  }
  
  return (
    <Interweave content={encaseLinksWithTags(text)} />
  );
}