import React, { useState } from "react";

import { Tooltip, ClickAwayListener, Typography } from "@material-ui/core";

import Input from "../Input";
import { SelectTimeProps } from "./props";
import useStyles from "./css";
import ArrowDownIcon from "../../../assets/icons/ArrowDownIcon";
import TimeIcon from "../../../assets/icons/TimeIcon";
import { useTranslation } from "react-i18next";
import NoAvailableTimeIcon from "../../../assets/icons/NoAvailableTimeIcon";


export default function SelectTime (props: SelectTimeProps) {

  const {
    items,
    itemSelected,
    placeholderSelect,
    isValid,
    disabled,
    onSelect
  } = props;

  const classes = useStyles();
  const { t } = useTranslation(["general"]);

  const [openDropdown, setOpenDropdown] = useState(false);
  const showToolTip = (!openDropdown && !disabled && itemSelected);

  function handleItemSelected(selectedItem: string) {
    onSelect(selectedItem);
    setOpenDropdown(false);
  };

  function handleOpenDropdown() {
    !disabled && setOpenDropdown(!openDropdown);
  }

  function handleCloseDropdown() {
    setOpenDropdown(false);
  }

  const InputWithIcons = () => {
    return (
      <div onClick={handleOpenDropdown} style={{ cursor: disabled ? 'default' : 'pointer' }}>
        <Input
          value={itemSelected}
          placeholder={placeholderSelect}
          inputClass={classes.inputSelect}
          isValid={() => isValid}
        />
        <div className={`${classes.iconDropdown} ${classes.timeIconContainer}`}>
          <TimeIcon className={classes.timeIcon} />
        </div>
        <div className={`${classes.iconDropdown} ${classes.arrowIconContainer} ${openDropdown ? classes.arrowIconRotated : ''}`}>
          <ArrowDownIcon className={classes.arrowIcon} />
        </div>
      </div>
    );
  }

  const NoAvailableTimes = () => {
    return (
      <div className={classes.noAvailableTimesContainer}>
        <NoAvailableTimeIcon />
        <Typography className={classes.noAvailableTimesTitle}>
          {t("There are no available times on the selected date.")}
        </Typography>
        <Typography className={classes.noAvailableTimesBody}>
          {t("Please, select another date or person in charge.")}
        </Typography>
      </div>
    );
  }

  return (
    <ClickAwayListener onClickAway={handleCloseDropdown}>
      <div className={classes.root}>
        <div className={classes.divInput}>
          {showToolTip
            ? (
              <Tooltip
                placement="bottom"
                classes={{ popper: classes.popper }}
                title={itemSelected ?? {}}
                style={{ maxHeight: 40 }}
              >
                <div>
                  <InputWithIcons />
                </div>
              </Tooltip>)
            : (
              <div>
                <InputWithIcons />
              </div>)
          }
        </div>
        {openDropdown && (
          <div className={classes.listDropdownContainer}>
            {items && items.length > 0
              ? (
                <div className={classes.listDropdown}>
                  {items.map((i) => (
                    <div
                      key={i}
                      onClick={() => handleItemSelected(i)}
                      className={`${classes.listItem} ${itemSelected === i ? classes.itemSelected : classes.itemNoSelected}`}>
                      <div>
                        {i}
                      </div>
                    </div>
                  ))}
                </div>
              )
              : <NoAvailableTimes />
            }
          </div>
        )}
      </div>
    </ClickAwayListener>
  );
};
