import PatientFormTypedControl from "./patient-form-typed-control";
import { PatientFormControlType } from "./patient-form-control-type";
import PatientFormMobileNumberControl from "./patient-form-mobile-number-control";
import PatientFormEmailControl from "./patient-form-email-control";
import PatientFormRadioGroupControl from "./patient-form-radio-group-control";
import PatientFormTextControl from "./patient-form-text-control";
import RadioValue from "../common/radio-value";
import MobileNumber from "../common/mobile-number";

export default class PatientFormSectionModel {
  id: string | null;
  name: string;
  controls: PatientFormTypedControl[];
  
  constructor(x?: PatientFormSectionModel) {
    if (typeof x === "object") {
      this.id = x.id;
      this.name = x.name;
      this.controls = x.controls?.map(PatientFormSectionModel.mapControl);
    } else {
      this.id = null;
      this.name = "";
      this.controls = [];
    }
  }
  
  private static mapControl(control: PatientFormTypedControl): PatientFormTypedControl {
    switch (control.type) {
      case PatientFormControlType.PhoneNumber:
      {
        let mobileNumberControl = control as PatientFormMobileNumberControl;
        mobileNumberControl.value = control.value ? new MobileNumber(JSON.parse(control.value as string)) : new MobileNumber();
        return mobileNumberControl;
      }
      case PatientFormControlType.Email:
        return control as PatientFormEmailControl;
      case PatientFormControlType.Radio:
      {
        let radioControl = control as PatientFormRadioGroupControl;
        radioControl.value = control.value ? new RadioValue(control.value as string) : null;
        return radioControl;
      }
      case PatientFormControlType.Text:
        return control as PatientFormTextControl;
      default:
        return control;
    }
  }
}