import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const ClockIcon = (props: any) => {
  const { style, viewBox, ...otherProps } = props;
  return (
    <SvgIcon
      {...otherProps}
      style={{ width: 16, height: 16, color: "#919CA5", ...style }}
      viewBox={viewBox || "0 0 16 16"}
    >
      <path
        d="M8 0.25C3.71875 0.25 0.25 3.71875 0.25 8C0.25 12.2812 3.71875 15.75 8 15.75C12.2812 15.75 15.75 12.2812 15.75 8C15.75 3.71875 12.2812 0.25 8 0.25ZM9.78125 11.2188L7 9.1875C6.90625 9.125 6.875 9.03125 6.875 8.90625V3.625C6.875 3.4375 7.03125 3.25 7.25 3.25H8.75C8.9375 3.25 9.125 3.4375 9.125 3.625V7.9375L11.0938 9.375C11.25 9.5 11.3125 9.75 11.1875 9.90625L10.2812 11.125C10.1875 11.2812 9.9375 11.3125 9.78125 11.2188Z"
      />
    </SvgIcon>
  );
};

export default ClockIcon;
