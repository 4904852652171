import React from 'react';

import { useTranslation } from "react-i18next";

import { useAppSelector } from "../../../redux/hooks";

import moment from "moment";
import 'moment/locale/es';
import 'moment/locale/en-gb';

import useStyles from "./css";
import { SignerInfoProps } from "./props";
import CheckIcon from "../../../assets/icons/CheckIcon";
import { conformToMask } from "react-text-mask";
import { GetMobileMaskByTemp } from "../../../constants/masks";
import Country from '../../../models/common/country';


export default function SignerInfo(props: SignerInfoProps) {
  
  const { IPAddress, date, mobileNumber, isMobile } = props;
  
  const { t } = useTranslation("patient-form");
  
  const classes = useStyles(isMobile)();
  
  const countries = useAppSelector(state => state.scheduler.countries);
  const localeApp = useAppSelector(state => state.scheduler.locale);
  
  const dateFormat = localeApp === "en" ? "MMMM DD, YYYY HH:mm" : "DD MMMM, YYYY HH:mm";
  
  const maskedMobileNumber = (): string => {
    const customerCountry = countries.find((x: Country) => x.iso3 === mobileNumber.countryIso);
    if (!customerCountry) return "";
    const mobile = conformToMask(mobileNumber.body, GetMobileMaskByTemp(customerCountry.dialTemplate)).conformedValue;
    return `+${customerCountry.dialCode} ${mobile}`
  }
  
  const CircledCheckIcon = () =>
    <div className={classes.sectionIcon}>
      <CheckIcon
        style={{ width: 6, height: 6 }}
        color={"#F6F6F6"}
      />
    </div>
  
  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <div className={classes.section}>
          <div className={classes.sectionHeader}>{t("Signer IP address")}</div>
          <div className={classes.sectionValue}>
            {IPAddress}
            <CircledCheckIcon />
          </div>
        </div>
        <div className={classes.section}>
          <div className={classes.sectionHeader}>{t("Signature date")}</div>
          <div className={classes.sectionValue}>
            {moment(date).locale(localeApp).format(dateFormat) + "hrs"}
            <CircledCheckIcon />
          </div>
        </div>
        <div className={classes.section}>
          <div className={classes.sectionHeader}>{t("Mobile")}</div>
          <div className={classes.sectionValue}>
            {maskedMobileNumber()}
            <CircledCheckIcon />
          </div>
        </div>
      </div>
    </div>
  );
}