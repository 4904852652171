import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const CountryCUBIcon = (props: any) => {
    return (
        <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props} >
            <g clip-path="url(#clip0_27973_194969)">
                <rect x="0.554688" y="1" width="31.5" height="23.5" rx="1.75" fill="white" stroke="#F5F5F5" stroke-width="0.5" />
                <mask id="mask0_27973_194969" style={{maskType: "luminance"}} maskUnits="userSpaceOnUse" x="0" y="0" width="33" height="25">
                    <rect x="0.554688" y="1" width="31.5" height="23.5" rx="1.75" fill="white" stroke="white" stroke-width="0.5" />
                </mask>
                <g mask="url(#mask0_27973_194969)">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.304688 5.55H32.3047V0.75H0.304688V5.55Z" fill="#2A5F9E" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.304688 15.1516H32.3047V10.3516H0.304688V15.1516Z" fill="#2A5F9E" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.304688 24.7531H32.3047V19.9531H0.304688V24.7531Z" fill="#2A5F9E" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.304688 0.75L15.5428 12.75L0.304688 24.75V0.75Z" fill="#ED4C5C" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.64137 13.9741L3.85002 15.3357L4.52979 13.1261L2.74291 11.758L4.95437 11.754L5.64137 9.54688L6.32836 11.754L8.53983 11.758L6.75295 13.1261L7.43271 15.3357L5.64137 13.9741Z" fill="white" />
                </g>
            </g>
            <defs>
                <clipPath id="clip0_27973_194969">
                    <rect x="0.304688" y="0.75" width="24" height="24" rx="12" fill="white" />
                </clipPath>
            </defs>
        </SvgIcon>
    )
};

export default CountryCUBIcon;
