import * as React from "react";

import CountryAllIcon from "./CountryAllIcon";
import CountryDEUIcon from "./CountryDEUIcon";
import CountryAGOIcon from "./CountryAGOIcon";
import CountryARGIcon from "./CountryARGIcon";
import CountryBOLIcon from "./CountryBOLIcon";
import CountryBRAIcon from "./CountryBRAIcon";
import CountryCANIcon from "./CountryCANIcon";
import CountryCHLIcon from "./CountryCHLIcon";
import CountryCOLIcon from "./CountryCOLIcon";
import CountryCRIIcon from "./CountryCRIIcon";
import CountryECUIcon from "./CountryECUIcon";
import CountryESPIcon from "./CountryESPIcon";
import CountryGBRIcon from "./CountryGBRIcon";
import CountryUSAIcon from "./CountryUSAIcon";
import CountryGTMIcon from "./CountryGTMIcon";
import CountryHNDIcon from "./CountryHNDIcon";
import CountryMEXIcon from "./CountryMEXIcon";
import CountryNICIcon from "./CountryNICIcon";
import CountryPANIcon from "./CountryPANIcon";
import CountryPRYIcon from "./CountryPRYIcon";
import CountryPERIcon from "./CountryPERIcon";
import CountryPRTIcon from "./CountryPRTIcon";
import CountryDOMIcon from "./CountryDOMIcon";
import CountrySLVIcon from "./CountrySLVIcon";
import CountryTTOIcon from "./CountryTTOIcon";
import CountryURYIcon from "./CountryURYIcon";
import CountryVENIcon from "./CountryVENIcon";
import CountryAUSIcon from "./CountryAUSIcon";
import CountryBELIcon from "./CountryBELIcon";
import CountryCUBIcon from "./CountryCUBIcon";
import CountryNORIcon from "./CountryNORIcon";
import CountryPRIIcon from "./CountryPRIIcon";
import CountrySWEIcon from "./CountrySWEIcon";
import CountrySVKIcon from "./CountrySVKIcon";
import CountryITAIcon from "./CountryITAIcon";
import CountryFRAIcon from "./CountryFRAIcon";


export {
      CountryDEUIcon
    , CountryAGOIcon
    , CountryARGIcon
    , CountryBOLIcon
    , CountryBRAIcon
    , CountryCANIcon
    , CountryCHLIcon
    , CountryCOLIcon
    , CountryCRIIcon
    , CountryECUIcon
    , CountryESPIcon
    , CountryGBRIcon
    , CountryUSAIcon
    , CountryGTMIcon
    , CountryHNDIcon
    , CountryMEXIcon
    , CountryNICIcon
    , CountryPANIcon
    , CountryPRYIcon
    , CountryPERIcon
    , CountryPRTIcon
    , CountryDOMIcon
    , CountrySLVIcon
    , CountryTTOIcon
    , CountryURYIcon
    , CountryVENIcon
    , CountryAUSIcon
    , CountryCUBIcon
    , CountryPRIIcon
    , CountryBELIcon
    , CountrySWEIcon
    , CountryNORIcon
    , CountrySVKIcon
    , CountryAllIcon
};


interface CountryFlagIconProps {
    iso: string
}


function CountryFlagIcon({ iso }: CountryFlagIconProps): JSX.Element {
    
    const flag: { [key: string] : JSX.Element } = {
        "DEU": <CountryDEUIcon />,
        "AGO": <CountryAGOIcon />,
        "ARG": <CountryARGIcon />,
        "BOL": <CountryBOLIcon />,
        "BRA": <CountryBRAIcon />,
        "CAN": <CountryCANIcon />,
        "CHL": <CountryCHLIcon />,
        "COL": <CountryCOLIcon />,
        "CRI": <CountryCRIIcon />,
        "ECU": <CountryECUIcon />,
        "ESP": <CountryESPIcon />,
        "FRA": <CountryFRAIcon />,
        "GBR": <CountryGBRIcon />,
        "USA": <CountryUSAIcon />,
        "GTM": <CountryGTMIcon />,
        "HND": <CountryHNDIcon />,
        "ITA": <CountryITAIcon />,
        "MEX": <CountryMEXIcon />,
        "NIC": <CountryNICIcon />,
        "PAN": <CountryPANIcon />,
        "PRY": <CountryPRYIcon />,
        "PER": <CountryPERIcon />,
        "PRT": <CountryPRTIcon />,
        "DOM": <CountryDOMIcon />,
        "SLV": <CountrySLVIcon />,
        "TTO": <CountryTTOIcon />,
        "URY": <CountryURYIcon />,
        "VEN": <CountryVENIcon />,
        "AUS": <CountryAUSIcon />,
        "CUB": <CountryCUBIcon />,
        "PRI": <CountryPRIIcon />,
        "BEL": <CountryBELIcon />,
        "SWE": <CountrySWEIcon />,
        "NOR": <CountryNORIcon />,
        "SVK": <CountrySVKIcon />,

        "ALL": <CountryAllIcon />
    };
    
    return flag[iso] || <CountryAllIcon />;
}

export default CountryFlagIcon;