export default class PatientFormAcceptanceModel {
  isPrivacyNoticeEnabled: boolean;
  hasAcceptedPrivacyNotice: boolean;
  isConsentLetterEnabled: boolean;
  hasReadConsentLetter: boolean;
  signature: string | null;
  ipAddress: string;
  date: Date;
  
  constructor(x?: PatientFormAcceptanceModel) {
    if (typeof x === "object") {
      this.isPrivacyNoticeEnabled = x.isPrivacyNoticeEnabled;
      this.hasAcceptedPrivacyNotice = x.hasAcceptedPrivacyNotice;
      this.isConsentLetterEnabled = x.isConsentLetterEnabled;
      this.hasReadConsentLetter = x.hasReadConsentLetter;
      this.signature = x.signature;
      this.ipAddress = x.ipAddress;
      this.date = x.date;
    } else {
      this.isPrivacyNoticeEnabled = false;
      this.hasAcceptedPrivacyNotice = false;
      this.isConsentLetterEnabled = false;
      this.hasReadConsentLetter = false;
      this.signature = null;
      this.ipAddress = "";
      this.date = new Date();
    }
  }
}