import React from 'react';

import { Link, useLocation, useParams } from "react-router-dom";

import { useTranslation } from "react-i18next";

import { PatientFormSignatureStepProps } from "./props";
import useStyles from "./css";
import CheckboxWithLabel from "../../common/CheckboxWithLabel";
import PatientFormAcceptanceModel from '../../../models/patientform/patient-form-acceptance-model';
import PatientSignature from "../PatientSignature";
import SignerInfo from "../SignerInfo";
import { GetPatientFormFieldKey } from '../../../constants/local-storage-keys';


export default function PatientFormSignatureStep(props: PatientFormSignatureStepProps) {
  
  const { value, setValue, customerMobile, isMobile } = props;
  
  const classes = useStyles(isMobile)();
  
  const { accountBusinessName } = useParams<{ accountBusinessName: string }>();

  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);
  const customerId = searchParams.get("customerId");
  
  const { t } = useTranslation("patient-form");
  
  function setHasAgreedToPrivacyNotice(hasAcceptedPrivacyNotes: boolean) {
    setValue(new PatientFormAcceptanceModel({ ...value, hasAcceptedPrivacyNotice: hasAcceptedPrivacyNotes }))
    localStorage.setItem(GetPatientFormFieldKey(customerId!, "agreed_to_privacy_notice"), JSON.stringify(hasAcceptedPrivacyNotes))
  }
  
  function setHasReadInformedConsentLetter(hasReadConsentLetter: boolean) {
    setValue(new PatientFormAcceptanceModel({ ...value, hasReadConsentLetter }))
    localStorage.setItem(GetPatientFormFieldKey(customerId!, "read_informed_consent_letter"), JSON.stringify(hasReadConsentLetter))
  }
  
  function setSignature(signature: string | null) {
    setValue(new PatientFormAcceptanceModel({ ...value, date: new Date(), signature }))
    localStorage.setItem(GetPatientFormFieldKey(customerId!, "signature"), JSON.stringify(signature))
  }
  
  function getPermissionSectionHeader(): string | null {
    if (value.isPrivacyNoticeEnabled && value.isConsentLetterEnabled) return t("Privacy notice and Informed consent letter");
    if (value.isPrivacyNoticeEnabled) return t("Privacy notice");
    if (value.isConsentLetterEnabled) return t("Informed consent letter");
    return null;
  }
  
  function checkBoxDisplayText(textKey: string, linkKey: string, linkTo: string) {
    return (
      <span 
        className={classes.checkBoxText}
      >
        {t(textKey)} <Link className={classes.link} to={linkTo} target="_blank">{t(linkKey)}</Link>
      </span>
    );
  }
  
  return (
    <div className={classes.root}>
      {
        (value.isPrivacyNoticeEnabled || value.isConsentLetterEnabled) &&
        <>
          <div className={classes.sectionHeader}>{getPermissionSectionHeader()}</div>
          <div className={classes.permissionsSection}>
            {
              value.isPrivacyNoticeEnabled &&
              <div className={classes.checkboxContainer}>
                <CheckboxWithLabel
                  checked={value.hasAcceptedPrivacyNotice}
                  setChecked={setHasAgreedToPrivacyNotice}
                  label={t("Privacy notice")}
                  text={checkBoxDisplayText("PrivacyNoticeAcceptance", "privacy notice", `/${accountBusinessName}/privacy-notice`)}
                  required
                />
              </div>
            }
            {
              value.isConsentLetterEnabled &&
              <div className={classes.checkboxContainer}>
                <CheckboxWithLabel
                  checked={value.hasReadConsentLetter}
                  setChecked={setHasReadInformedConsentLetter}
                  label={t("Informed consent letter")}
                  text={checkBoxDisplayText("ConsentLetterAcceptance", "informed consent letter", `/${accountBusinessName}/informed-consent-letter`)}
                  required
                />
              </div>
            }
          </div>
        </>
      }
      <div className={classes.sectionHeader}>{t("Patient's signature")}</div>
      <PatientSignature 
        signature={value.signature}
        setSignature={setSignature}
        isMobile={isMobile}
      />
      {
        value.signature !== null && 
        <SignerInfo
          IPAddress={value.ipAddress}
          date={value.date}
          mobileNumber={customerMobile}
          isMobile={isMobile}
        />
      }
    </div>
  );
}