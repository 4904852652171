import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const TrashIcon = (props: any) => {
  const { style, viewBox, ...otherProps } = props;
  return (
    <SvgIcon
      {...otherProps}
      style={{ width: 13, height: 17, ...style }}
      viewBox={viewBox || "0 0 13 16"}
    >
      <path
        d="M0.928571 15.1111C0.928571 16.15 1.76429 17 2.78571 17H10.2143C11.2357 17 12.0714 16.15 12.0714 15.1111V3.77778H0.928571V15.1111ZM13 0.944444H9.75L8.82143 0H4.17857L3.25 0.944444H0V2.83333H13V0.944444Z"
        fill={style?.color || "#F15857"}
      />
    </SvgIcon>
  );
};

export default TrashIcon;
