export const Regex = {
  Name: /^\s*([a-zA-Z'-.À-ÿ\u00f1\u00d1]+((?: [a-zA-Z'-.À-ÿ\u00f1\u00d1]+)?)+(?: [a-zA-Z'-.À-ÿ\u00f1\u00d1]+)?)( *)$/,
  MultipleSpacesName: /^\s*([a-zA-Z'-.À-ÿ\u00f1\u00d1]+((?:\s{0,}[a-zA-Z'-.À-ÿ\u00f1\u00d1]+)?)+(?:\s{0,}[a-zA-Z'-.À-ÿ\u00f1\u00d1]+)?)( *)$/,
  Mobile: /^\d{10}$/,
  Email:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  Alphanumeric:
    /^(?!.*[ ]{2})[a-zA-Z ¡!¿?)(.,;:áéíóúÁÉÍÓÚäëïöüÄËÏÖÜÑñ1234567890]{2,80}$/,
  AlphanumericExtended:
    /^(?!.*[ ]{2})[a-zA-Z ¡!¿?@#$%^&*)(.,;:áéíóúÁÉÍÓÚäëïöüÄËÏÖÜ1234567890]{2,80}$/,
  Currency: /^(\d+(\.\d{0,2})?|\.?\d{1,2})$/,
};
