import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const CountryPANIcon = (props: any) => {
    return (
        <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props} >
            <path d="M12 0.75V12H23.25C23.25 5.775 18.225 0.75 12 0.75Z" fill="#ED4C5C"/>
            <path d="M12 23.25V12H0.75C0.75 18.225 5.775 23.25 12 23.25Z" fill="#2A5F9E"/>
            <path d="M12 23.25V12H23.25C23.25 18.225 18.225 23.25 12 23.25Z" fill="#F9F9F9"/>
            <path d="M12 0.75V12H0.75C0.75 5.775 5.775 0.75 12 0.75Z" fill="#F9F9F9"/>
            <path d="M6.375 7.7625L7.5375 8.625L7.0875 7.2L8.25 6.3H6.825L6.375 4.875L5.925 6.3H4.5L5.6625 7.2L5.2125 8.625L6.375 7.7625Z" fill="#2A5F9E"/>
            <path d="M17.625 17.5125L18.7875 18.375L18.3375 16.95L19.5 16.05H18.075L17.625 14.625L17.175 16.05H15.75L16.9125 16.95L16.4625 18.375L17.625 17.5125Z" fill="#ED4C5C"/>
        </SvgIcon>
    )
};

export default CountryPANIcon;
