import { makeStyles } from "@material-ui/core/styles";

const useStyles = (isMobile: boolean) => makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",  
    padding: 8,
    backgroundColor: theme.colors.white,
  },
  datePicker: {
    position: "relative",
    
    alignItems: "center",
    alignContent: "center",
    justifyContent: "center",
  },
  input: {
    width: isMobile ? 288 : 668,
    height: 40,
    
    backgroundColor: theme.colors.white,
    
    
    padding: "13px 0px 13px 12px",
    border: "solid 1px rgba(0, 0, 0, 0.1)",
    borderRadius: 5,
  
    ...theme.typography.txtBody2,
    color: theme.colors.grey5
  },
  inputText: {
    ...theme.typography.txtBody2,
    fontWeight: "bold",
    color: theme.colors.grey5,
    "&::placeholder": {
      fontWeight: "normal",
      color: "#676767",
    },
  },
  inputDisabled: {
    backgroundColor: theme.colors.grey1,
    color: theme.colors.grey4,
  },
  inputError: {
    color: theme.colors.clearRed,
    
    border: "solid",
    borderWidth: 1,
    borderColor: theme.colors.clearRed,
  },
  
  icon: {
    position: "absolute",
    right: 12,
    top: 12,
    
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    
    cursor: "text",
  },
  datePickerDialog: {
    minWidth: 304,
    width: "304px !important",
  },
}));

export default useStyles;
