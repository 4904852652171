import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const SuccessIcon = () => (
  <SvgIcon viewBox="0 0 28 28">
    <path
      d="M2 14C2 7.373 7.373 2 14 2s12 5.373 12 12-5.373 12-12 12S2 20.627 2 14z"
      fill="#36CE91"
    />
    <path
      d="M21.493 8.017c-.304.041-.585.19-.797.42l-8.982 9.313-3.534-2.925a1.296 1.296 0 0 0-1.485-.133c-.155.089-.29.207-.4.35a1.44 1.44 0 0 0-.128 1.54c.084.16.199.301.336.416l4.47 3.704a1.31 1.31 0 0 0 1.789-.12l9.833-10.196c.202-.203.336-.467.385-.754.048-.287.009-.582-.113-.845a1.37 1.37 0 0 0-.567-.618 1.3 1.3 0 0 0-.809-.158l.002.006z"
      fill="#F6F6F6"
    />
    <path
      d="M14 24C8.477 24 4 19.523 4 14H0c0 7.732 6.268 14 14 14v-4zm10-10c0 5.523-4.477 10-10 10v4c7.732 0 14-6.268 14-14h-4zM14 4c5.523 0 10 4.477 10 10h4c0-7.732-6.268-14-14-14v4zm0-4C6.268 0 0 6.268 0 14h4C4 8.477 8.477 4 14 4V0z"
      fill="#36CE91"
    />
  </SvgIcon>
);

export default SuccessIcon;
