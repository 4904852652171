import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) =>  ({
    modalYellow: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: "#FFC626",
    },
    modalPaperYellow: {
        backgroundColor: theme.palette.background.paper,
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: "#FFC626",
        borderRadius: "10px",
        boxShadow: theme.shadows[5],
        padding: "24px",
        width: 464,
        height: 400,
        "&:focus:not(:focus-visible)": {
            outline: "none",
        },
        "&:focus-visible": {
            outline: "none",
            border: "1px solid #FFC626",
        },
    },
    modalHeader: {
        display: "flex",
        alignItems: "center",
    },
    modalHeaderContent: {
        display: "flex",
        justifyContent: "flex-end",
        width: "100%",
    },
    modalClose: {
        display: "flex",
        justifyContent: "flex-end",
        width: "73%",
    },
    closeButton: {
        cursor: "pointer",
        color: "#919CA5",
    },
    modalTitle: {
        width: "100%",
    },
    modalTitleText: {
        fontSize: "18px",
        lineHeight: "22px",
        textAlign: "center",
        fontStyle: "normal",
        fontWeight: "bold",
        color: "#26292B",
    },
    modalContent: {},
    modalCircle: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "50%",
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: "#FFC626",
        width: "60px",
        height: "60px",
    },
    modalCenter: {
        display: "flex",
        justifyContent: "center",
        textAlign: "center"
    },
    modalTitleSpace: {
        margin: "30px 0px 20px 0px",
    },
    modalContentTextTitle: {
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "14px",
        lineHeight: "17px",
        textAlign: "center",
        color: "#4A4D51",
    },
    modalContentText: {
        fontStyle: "normal",
        fontSize: "14px",
        lineHeight: "17px",
        textAlign: "center",
        color: "#4A4D51",
    },
    modalDateText: {
        ...theme.typography.txtBody2,
        paddingTop: 7,
    },
    modalActions: {
        marginTop: 32,
        justifyContent: "center",
        textAlign: "center"
    },
    button: {
        width: 200,
        height: 32,
        borderRadius: 51,
        padding: "11px 16px",
        alignItems: "center",
        justifyContent: "center",
        fontFamily: "Inter, sans-serif",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: 12,
        margin: "auto"
    },
    btnCancel: {
        background: "#F6F6F6",
        color: theme.colors.grey4,
        fontSize: 12,
        fontWeight: "bold",
        margin: "auto"
    },
    btnOk: {
        background: "#FFC626",
        borderRadius: 51,
        color: "white",
        fontSize: 12,
        margin: "auto",
        fontWeight: 700,
        paddingTop: 10,
        paddingBottom: 10,
        height: 37,
        width: 225,
        "&:hover": {
            background: "#FFC626",
            color: "white",
            fontSize: 12,
        }
    },
    separator: {
        height: 0,
        width: 217,
        margin: "0px auto 20px auto",
        borderTop: "1px solid rgba(201, 205, 211, 0.38)",
    },
}));

export default useStyles;
