import React, { useState, useEffect } from "react";

import MuiAutocomplete from "@material-ui/lab/Autocomplete";

import InputSearch from "./../InputSearch";
import useDebounce from "./../../../hooks/useDebounce";

import { AutocompleteProps } from "./props";

const Autocomplete = (props: AutocompleteProps) => {
  const {
    id,
    style,
    items,
    placeholder,
    noBorder,
    getOptionSelected,
    getOptionLabel,
    renderOption,
    filterOptions,
    cleanInput,
    onChange,
    onSelected,
  } = props;

  const [open, setOpen] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>("");
  const [changeReason, setChangeReason] = useState<
    "input" | "reset" | "clear" | ""
  >("");

  const debouncedInputValue = useDebounce(inputValue, 400);

  useEffect(() => {
    if (onChange) {
      onChange(debouncedInputValue, changeReason);
    }
    if (inputValue) {
      setOpen(true);
    }

    if (changeReason === "reset") {
      setOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedInputValue]);

    useEffect(() => {
        setInputValue("");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cleanInput]);

  return (
    <MuiAutocomplete
      freeSolo
      id={id || "auto-complete"}
      style={style}
      autoComplete
      autoHighlight
      open={open}
      onOpen={() => {
        if (inputValue) {
          setOpen(true);
        }
      }}
      inputValue={inputValue}
      onInputChange={(e, value, reason) => {
        setChangeReason(reason);
        setInputValue(value);
        if (!value) {
          setOpen(false);
        }
      }}
      onClose={() => {
        setOpen(false);
      }}
      onChange={(e, value) => {
        if (onSelected) {
          onSelected(value);
        }
      }}
      getOptionSelected={getOptionSelected}
      getOptionLabel={getOptionLabel}
      options={items}
      renderOption={renderOption}
      filterOptions={filterOptions}
      renderInput={(params) => (
        <InputSearch
          {...params}
          placeholder={placeholder}
          noBorder={noBorder}
        />
      )}
    />
  );
};

export default Autocomplete;
