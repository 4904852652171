import React from "react";

import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "./../../../assets/icons/SearchIcon";

import { InputSearchProps } from "./props";
import useStyles from "./css";

const InputSearch = (props: InputSearchProps) => {
  const classes = useStyles();
  const { placeholder, noBorder, onKeyPress, handleSearch, ...params } = props;
  return (
    <TextField
      {...params}
      className={classes.autocomplete}
      placeholder={placeholder}
      onKeyPress={(e) => { if (onKeyPress) onKeyPress(e); }}
      InputProps={{
        ...params.InputProps,
        disableUnderline: true,
        className: noBorder ? classes.rootNoBorder : classes.root,
        classes: { focused: !noBorder ? classes.inputFocused : undefined },
        type: "text",
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={() => { if (handleSearch) handleSearch();}}>
              <SearchIcon style = {{width: 21, height: 21, color: "#7175FA"}}/>
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default InputSearch;
