import { Regex } from "./regex";
import MobileNumber from "../models/common/mobile-number";
import Country from "../models/common/country";
import RadioValue from "../models/common/radio-value";


export const Validation = {
  validateText: (value: string): boolean => value !== null && value !== "",
  validateName: (value: string): boolean => value !== null && value !== "" && Regex.Name.test(value),
  validateEmail: (value: string): boolean => Regex.Email.test(value),
  validateMobileNumberWithCountries: (value: MobileNumber, countries: Country[]): boolean => {
    if (!(value && value.body && value.countryIso && countries.length > 0)) return false;
    const country = countries.find(x => x.iso3 === value.countryIso);
    if (!country) return false;
    return validateMobileNumberByDigits(value, country.dialDigits)
  },
  validateRadio: (value: RadioValue): boolean => !!(value && value.item),
}

function validateMobileNumberByDigits(value: MobileNumber, digits: number): boolean {
  if (!(value && value.countryIso)) return false;
  if (value.body === "") return false;
  const mobile = value.body.replace(/[^0-9]/g, "");
  return (digits === 7 && /^\d{7}$/.test(mobile)) ||
    (digits === 8 && /^\d{8}$/.test(mobile)) ||
    (digits === 9 && /^\d{9}$/.test(mobile)) ||
    (digits === 10 && /^\d{10}$/.test(mobile)) ||
    (digits === 11 && /^\d{11}$/.test(mobile)) ||
    (digits === 12 && /^\d{12}$/.test(mobile));
}
