import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const CreditIcon = (props: any) => {
  const { style, viewBox, ...otherProps } = props;
  return (
    <SvgIcon
      {...otherProps}
      style={{ width: 18, height: 14, color: "#919CA5", ...style }}
      viewBox={viewBox || "0 0 18 14"}
    >
      <path
        d="M16.4688 0H1.5C0.65625 0 0 0.6875 0 1.5V12.5C0 13.3438 0.65625 14 1.5 14H16.4688C17.3125 14 18 13.3438 18 12.5V1.5C18 0.6875 17.3125 0 16.4688 0ZM1.6875 1.5H16.2812C16.4062 1.5 16.4688 1.59375 16.4688 1.6875V3H1.5V1.6875C1.5 1.59375 1.5625 1.5 1.6875 1.5ZM16.2812 12.5H1.6875C1.5625 12.5 1.5 12.4375 1.5 12.3125V7H16.4688V12.3125C16.4688 12.4375 16.4062 12.5 16.2812 12.5ZM6 9.375C6 9.1875 5.8125 9 5.625 9H3.375C3.15625 9 3 9.1875 3 9.375V10.625C3 10.8438 3.15625 11 3.375 11H5.625C5.8125 11 6 10.8438 6 10.625V9.375ZM12 9.375C12 9.1875 11.8125 9 11.625 9H7.375C7.15625 9 7 9.1875 7 9.375V10.625C7 10.8438 7.15625 11 7.375 11H11.625C11.8125 11 12 10.8438 12 10.625V9.375Z"
      />
    </SvgIcon>
  );
};

export default CreditIcon;
