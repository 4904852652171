import React from 'react';

import { Typography } from "@material-ui/core";

import { UseControlLabelProps } from "./props";
import useStyles from "./css";


export default function useControlLabel(props: UseControlLabelProps) {
  
  const { label, required, labelClass } = props;
  
  const LabelComponent = label;
  
  const classes = useStyles();
  
  const LabelWithDot = () =>
    <div>
      <Typography className={labelClass ?? classes.label}>
        {typeof label === "string" ? label : <LabelComponent />}
      </Typography>
      <span className={classes.dot}>*</span>
    </div>
  
  const LabelWithoutDot = () =>
    <div>
      <Typography className={labelClass ?? classes.label}>
        {typeof label === "string" ? label : <LabelComponent />}
      </Typography>
    </div>
  
  return required ? LabelWithDot : LabelWithoutDot;
}