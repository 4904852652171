import React from "react";
import { useTranslation } from "react-i18next";
import { InfoCircleIcon } from "../../../assets/icons";
import { useAppSelector } from "../../../redux/hooks";

import useStyles from "./css";

const TimeToEditExpiredMessage = () => {
  
  const classes = useStyles();
  const { t } = useTranslation("general");

  const isEditable = useAppSelector((state) => state.scheduler.isEditable);
  
  return (<>
  { 
    !isEditable && <>
    <div className={classes.message}>
        <div className={classes.messageIcon}>
            <InfoCircleIcon style={{ width: 15, height: 15 }} />
        </div>
        <div className={classes.messageLabel} >
            {t("The time limit to modify your appointment has ended.")}
        </div>
    </div>
    </>}
</>);
};

export default TimeToEditExpiredMessage;
