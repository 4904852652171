import React from 'react';

import { useTranslation } from 'react-i18next';

import { Stepper, Step, StepConnector, StepLabel, withStyles } from "@material-ui/core";

import { PatientFormStepperProps } from "./props";
import useStyles, { useStepIconStyles } from './css';
import MuiCheckIcon from '../../../assets/icons/MuiCheckIcon';


export default function PatientFormStepper(props: PatientFormStepperProps) {

  const { activeStep, isMobile, isClinicalHistoryStepEnabled, stepsProgress, stepsProgressRequired } = props;

  const { t } = useTranslation("patient-form");

  const classes = useStyles(isMobile)();

  const StyledStepperConnector = withStyles((theme) => ({
    alternativeLabel: {
      top: 10,
      left: 'calc(-50% + 16px)',
      right: 'calc(50% + 16px)',
    },
    active: {
      '& $line': {
        borderColor: theme.colors.clearGreen,
      },
    },
    completed: {
      '& $line': {
        borderColor: theme.colors.clearGreen,
      },
    },
    line: {
      borderColor: theme.colors.grey2,
      borderTopWidth: 1,
      borderRadius: 1,
    },
  }))(StepConnector);

  function StepIcon(props: any) {
    const classes = useStepIconStyles();
    const { active, completed } = props;

    return (
      <div
        className={classes.root}
      >
        {completed ? <MuiCheckIcon className={classes.completed}/> : active ? <div className={classes.activeCircle}/> :
          <div className={classes.circle}/>}
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <Stepper
        alternativeLabel
        activeStep={activeStep}
        connector={<StyledStepperConnector/>}
        className={classes.stepper}
      >
        <Step key={0}>
          <StepLabel
            StepIconComponent={StepIcon}
            classes={{
              alternativeLabel: classes.labelAlternativeLabel,
              completed: classes.labelCompleted
            }}
          >
            <div>
              {t("General info")}
            </div>
            <div>
              {`${stepsProgress[0]}/${stepsProgressRequired[0]}`}
            </div>
          </StepLabel>
        </Step>
        {
          isClinicalHistoryStepEnabled &&
          <Step key={1}>
            <StepLabel
              StepIconComponent={StepIcon}
              classes={{
                alternativeLabel: classes.labelAlternativeLabel,
                completed: classes.labelCompleted
              }}
            >
              <div>
                {t("Clinic history")}
              </div>
              <div>
                {`${stepsProgress[1]}/${stepsProgressRequired[1]}`}
              </div>
            </StepLabel>
          </Step>
        }
        <Step key={2}>
          <StepLabel
            StepIconComponent={StepIcon}
            classes={{
              alternativeLabel: classes.labelAlternativeLabel,
              completed: classes.labelCompleted
            }}
          >
            <div>
              {t("Signature")}
            </div>
            <div>
              {`${stepsProgress[2]}/${stepsProgressRequired[2]}`}
            </div>
          </StepLabel>
        </Step>
      </Stepper>
    </div>
  );
}