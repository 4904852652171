export default class MobileNumber {
  body: string;
  countryIso: string | null;
  
  constructor(x?: any) {
    if (typeof x === "object") {
      this.body = x.body ?? "";
      this.countryIso = x.countryIso;
    } 
    else if (typeof x === "string") {
      const parsedValue = JSON.parse(x);
      this.body = parsedValue?.body ?? "";
      this.countryIso = parsedValue?.countryIso ?? null;
    }
    else {
      this.body = "";
      this.countryIso = null;
    }
  }
}