import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

function DeleteIcon(props: any) {
  const { color, ...rest } = props;
  
  return (
    <SvgIcon {...rest} style={{ width: 16, height: 16 }} viewBox="0 0 16 16">
      <path
        d="M8 0.25C3.71875 0.25 0.25 3.71875 0.25 8C0.25 12.2812 3.71875 15.75 8 15.75C12.2812 15.75 15.75 12.2812 15.75 8C15.75 3.71875 12.2812 0.25 8 0.25ZM3.875 9.25C3.65625 9.25 3.5 9.09375 3.5 8.875V7.125C3.5 6.9375 3.65625 6.75 3.875 6.75H12.125C12.3125 6.75 12.5 6.9375 12.5 7.125V8.875C12.5 9.09375 12.3125 9.25 12.125 9.25H3.875Z"
        fill={color || "#F15857"}
      />
    </SvgIcon>
  );
};

export default DeleteIcon;