import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const CountryCANIcon = (props: any) => {
    return (
        <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props} >
            <path d="M18 2.475C16.275 1.3875 14.2125 0.75 12 0.75C9.7875 0.75 7.725 1.3875 6 2.475V21.4875C7.725 22.575 9.7875 23.2125 12 23.2125C14.2125 23.2125 16.275 22.575 18 21.4875V2.475Z" fill="#F9F9F9"/>
            <path d="M18 2.4751V21.4876C21.15 19.5376 23.25 15.9376 23.25 11.9626C23.25 7.9876 21.15 4.4626 18 2.4751Z" fill="#ED4C5C"/>
            <path d="M6 2.4751C2.85 4.4626 0.75 8.0626 0.75 12.0001C0.75 15.9376 2.85 19.5376 6 21.5251V2.4751Z" fill="#ED4C5C"/>
            <path d="M16.0875 11.85C15.9375 11.775 15.9 11.625 15.9375 11.55L16.3125 10.2L15 10.4625C14.9625 10.4625 14.8125 10.4625 14.775 10.2L14.6625 9.75L13.7625 10.8C13.7625 10.8 13.1625 11.4375 13.35 10.4625L13.725 8.4L13.0125 8.775C12.975 8.775 12.825 8.8125 12.6375 8.4375L12 7.125L11.325 8.3625C11.1375 8.7375 10.9875 8.7 10.95 8.7L10.2375 8.325L10.6125 10.3875C10.8 11.3625 10.2 10.725 10.2 10.725L9.3 9.675L9.1875 10.125C9.1125 10.3875 9 10.3875 8.9625 10.3875L7.65 10.125L8.025 11.475C8.025 11.5875 8.025 11.7 7.875 11.775L7.5 12C7.5 12 9 13.2 9.4875 13.6125C9.6 13.6875 9.825 13.9125 9.75 14.175L9.5625 14.7L11.625 14.4C11.7375 14.4 11.9625 14.4 11.925 14.7375L11.8125 16.875H12.1875L12.075 14.7375C12.075 14.4 12.3 14.4 12.375 14.4L14.4375 14.7L14.25 14.175C14.175 13.9125 14.4 13.6875 14.5125 13.6125C15 13.2 16.5 12 16.5 12L16.0875 11.85Z" fill="#ED4C5C"/>
        </SvgIcon>
    )
};

export default CountryCANIcon;
