import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const CountryUSAIcon = (props: any) => {
    return (
        <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props} >
            <path d="M18 2.475C16.2375 1.3875 14.2125 0.75 12 0.75V2.475H18Z" fill="#ED4C5C"/>
            <path d="M12 4.2001H20.1C19.4625 3.5626 18.75 2.9626 18 2.4751H12V4.2001Z" fill="white"/>
            <path d="M12 5.9252H21.4875C21.075 5.2877 20.625 4.7252 20.1375 4.2002H12V5.9252Z" fill="#ED4C5C"/>
            <path d="M12 7.6498H22.3875C22.125 7.0498 21.825 6.4498 21.4875 5.9248H12V7.6498Z" fill="white"/>
            <path d="M12 9.3749H22.95C22.8 8.7749 22.6125 8.2124 22.3875 7.6499H12V9.3749Z" fill="#ED4C5C"/>
            <path d="M12 11.1376H23.2125C23.175 10.5376 23.0625 9.9751 22.95 9.4126H12V11.1376Z" fill="white"/>
            <path d="M23.2125 11.1377H12V12.0002H0.75C0.75 12.3002 0.75 12.5627 0.7875 12.8627H23.2125C23.25 12.5627 23.25 12.3002 23.25 12.0002C23.25 11.7002 23.25 11.4002 23.2125 11.1377Z" fill="#ED4C5C"/>
            <path d="M1.0501 14.5873H22.9501C23.1001 14.0248 23.1751 13.4623 23.2126 12.8623H0.787598C0.825098 13.4248 0.900098 14.0248 1.0501 14.5873Z" fill="white"/>
            <path d="M1.61255 16.3124H22.3876C22.6126 15.7499 22.8001 15.1874 22.9501 14.5874H1.05005C1.20005 15.1874 1.38755 15.7499 1.61255 16.3124Z" fill="#ED4C5C"/>
            <path d="M2.51255 18.0375H21.4876C21.8251 17.475 22.1251 16.9125 22.3876 16.3125H1.61255C1.87505 16.9125 2.17505 17.475 2.51255 18.0375Z" fill="white"/>
            <path d="M3.86245 19.7626H20.1374C20.625 19.2376 21.1124 18.6376 21.4874 18.0376H2.51245C2.88745 18.6751 3.37495 19.2376 3.86245 19.7626Z" fill="#ED4C5C"/>
            <path d="M5.96255 21.4877H18.0375C18.825 21.0002 19.5001 20.4002 20.1376 19.7627H3.86255C4.50005 20.4377 5.21255 21.0002 5.96255 21.4877Z" fill="white"/>
            <path d="M11.9999 23.2498C14.2124 23.2498 16.2749 22.6123 18.0374 21.4873H5.9624C7.7249 22.6123 9.7874 23.2498 11.9999 23.2498Z" fill="#ED4C5C"/>
            <path d="M6 2.475C5.2125 2.9625 4.5 3.5625 3.8625 4.2C3.3375 4.725 2.8875 5.325 2.5125 5.925C2.175 6.4875 1.8375 7.05 1.6125 7.65C1.3875 8.2125 1.2 8.775 1.05 9.375C0.9 9.9375 0.825 10.5 0.7875 11.1C0.75 11.4 0.75 11.7 0.75 12H12V0.75C9.7875 0.75 7.7625 1.3875 6 2.475Z" fill="#428BC1"/>
            <path d="M9.375 1.125L9.5625 1.6875H10.125L9.675 2.0625L9.825 2.625L9.375 2.2875L8.925 2.625L9.075 2.0625L8.625 1.6875H9.1875L9.375 1.125Z" fill="white"/>
            <path d="M10.875 3.375L11.0625 3.9375H11.625L11.175 4.3125L11.325 4.875L10.875 4.5375L10.425 4.875L10.575 4.3125L10.125 3.9375H10.6875L10.875 3.375Z" fill="white"/>
            <path d="M7.875 3.375L8.0625 3.9375H8.625L8.175 4.3125L8.325 4.875L7.875 4.5375L7.425 4.875L7.575 4.3125L7.125 3.9375H7.6875L7.875 3.375Z" fill="white"/>
            <path d="M9.375 5.625L9.5625 6.1875H10.125L9.675 6.5625L9.825 7.125L9.375 6.7875L8.925 7.125L9.075 6.5625L8.625 6.1875H9.1875L9.375 5.625Z" fill="white"/>
            <path d="M6.375 5.625L6.5625 6.1875H7.125L6.675 6.5625L6.825 7.125L6.375 6.7875L5.925 7.125L6.075 6.5625L5.625 6.1875H6.1875L6.375 5.625Z" fill="white"/>
            <path d="M3.375 5.625L3.5625 6.1875H4.125L3.675 6.5625L3.825 7.125L3.375 6.7875L2.925 7.125L3.075 6.5625L2.625 6.1875H3.1875L3.375 5.625Z" fill="white"/>
            <path d="M10.875 7.875L11.0625 8.4375H11.625L11.175 8.8125L11.325 9.375L10.875 9.0375L10.425 9.375L10.575 8.8125L10.125 8.4375H10.6875L10.875 7.875Z" fill="white"/>
            <path d="M7.875 7.875L8.0625 8.4375H8.625L8.175 8.8125L8.325 9.375L7.875 9.0375L7.425 9.375L7.575 8.8125L7.125 8.4375H7.6875L7.875 7.875Z" fill="white"/>
            <path d="M4.875 7.875L5.0625 8.4375H5.625L5.175 8.8125L5.325 9.375L4.875 9.0375L4.425 9.375L4.575 8.8125L4.125 8.4375H4.6875L4.875 7.875Z" fill="white"/>
            <path d="M9.375 10.125L9.5625 10.6875H10.125L9.675 11.0625L9.825 11.625L9.375 11.2875L8.925 11.625L9.075 11.0625L8.625 10.6875H9.1875L9.375 10.125Z" fill="white"/>
            <path d="M6.375 10.125L6.5625 10.6875H7.125L6.675 11.0625L6.825 11.625L6.375 11.2875L5.925 11.625L6.075 11.0625L5.625 10.6875H6.1875L6.375 10.125Z" fill="white"/>
            <path d="M3.375 10.125L3.5625 10.6875H4.125L3.675 11.0625L3.825 11.625L3.375 11.2875L2.925 11.625L3.075 11.0625L2.625 10.6875H3.1875L3.375 10.125Z" fill="white"/>
            <path d="M4.42485 4.875L4.87485 4.5375L5.32485 4.875L5.13735 4.3125L5.58735 3.9375H5.02485L4.87485 3.375L4.68735 3.9375H4.16235L4.61235 4.275L4.42485 4.875Z" fill="white"/>
            <path d="M1.4249 9.375L1.8749 9.0375L2.3249 9.375L2.1374 8.8125L2.5874 8.4375H2.0624L1.8749 7.875L1.6874 8.4375H1.3124C1.3124 8.475 1.2749 8.5125 1.2749 8.55L1.5749 8.775L1.4249 9.375Z" fill="white"/>
        </SvgIcon>
    )
};

export default CountryUSAIcon;
