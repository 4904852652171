import React from "react";

import { Checkbox } from "@material-ui/core";

import { CheckboxWithLabelProps } from "./props";
import useStyles from "./css";
import useControlLabel from "../../../hooks/useControlLabel";


export default function CheckboxWithLabel(props: CheckboxWithLabelProps) {
  
  const { checked, setChecked, text, label, required } = props;
  
  const classes = useStyles();
  
  function handleChecking() {
    setChecked(!checked);
  }
  
  const ControlLabel = useControlLabel({ label, required });
  
  return (
    <div className={classes.root}>
      <ControlLabel />
      <div className={classes.checkboxRoot}>
        <Checkbox
          checked={checked}
          onChange={handleChecking}
          className={classes.checkbox}
          size={"small"}
        />
        {typeof text === "string"
          ? <div className={classes.text}>{text}</div>
          : text ?? null
        }
      </div>
    </div>
  );
}