import CustomerInfo from "../customer/customer-info";
import PatientFormSectionModel from "./patient-form-section-model";
import PatientFormAcceptanceModel from "./patient-form-acceptance-model";


export default class PatientFormModel {
  customerInfo: CustomerInfo;
  generalInfo: PatientFormSectionModel[];
  clinicHistory: PatientFormSectionModel[];
  acceptance: PatientFormAcceptanceModel;
  
  constructor(x?: PatientFormModel) {
    if (typeof x === "object") {
      this.customerInfo = x.customerInfo;
      this.generalInfo = x.generalInfo;
      this.clinicHistory = x.clinicHistory;
      this.acceptance = x.acceptance;
    } else {
      this.customerInfo = new CustomerInfo();
      this.generalInfo = [];
      this.clinicHistory = [];
      this.acceptance = new PatientFormAcceptanceModel();
    }
  }
}