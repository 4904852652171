import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const CountryNICIcon = (props: any) => {
    return (
        <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props} >
            <path d="M12 0.75C7.08745 0.75 2.92495 3.9 1.38745 8.25H22.6124C21.0749 3.9 16.9124 0.75 12 0.75ZM12 23.25C16.9124 23.25 21.0749 20.1375 22.6124 15.75H1.38745C2.92495 20.1375 7.08745 23.25 12 23.25Z" fill="#428BC1"/>
            <path d="M1.3875 8.25C0.975 9.4125 0.75 10.6875 0.75 12C0.75 13.3125 0.975 14.5875 1.3875 15.75H22.6125C23.025 14.5875 23.25 13.3125 23.25 12C23.25 10.6875 23.025 9.4125 22.6125 8.25H1.3875Z" fill="#F9F9F9"/>
            <path d="M12 9.4873L9.63745 13.2748H14.3624L12 9.4873Z" fill="#42ADE2"/>
            <path d="M10.8375 11.4004L9.63745 13.2754H14.3624L13.1624 11.4004H10.8375Z" fill="#428BC1"/>
            <path d="M12.375 11.6248V11.4748C12.375 11.3623 12.3 11.2123 12.1875 11.0623C12.1125 10.9123 12 10.8748 11.8125 10.9873C11.7375 11.0248 11.7 11.0623 11.6625 11.1748V11.2873C11.6625 11.2873 11.7375 11.3248 11.775 11.2498C11.8125 11.2498 11.8125 11.2123 11.8125 11.2123C11.775 11.2123 11.775 11.2498 11.775 11.2498C11.7375 11.2873 11.7 11.3248 11.6625 11.3998C11.625 11.4748 11.625 11.5498 11.625 11.5873C11.625 11.5873 11.6625 11.6623 11.7375 11.6623C11.8125 11.6998 11.85 11.7373 11.8875 11.7748C11.925 11.8123 11.925 11.8498 11.925 11.9248C11.925 11.9623 11.925 12.0373 11.9625 12.0373C12 12.0373 12.0375 11.9998 12.075 11.9623C12.075 11.8873 12.1125 11.8498 12.1125 11.8123C12.15 11.7748 12.15 11.7748 12.225 11.7748C12.2625 11.7748 12.3 11.7748 12.3 11.7373V11.6998C12.375 11.6998 12.3375 11.6623 12.375 11.6248Z" fill="url(#paint0_linear_19508_8217)"/>
            <path d="M14.0999 12.9004C13.7249 12.8629 13.3874 12.5254 13.3124 12.1879C13.3124 12.1879 13.2749 12.1504 13.2374 12.1504C13.1624 12.1504 13.1624 12.1879 13.1624 12.1879C13.1249 12.3004 13.0499 12.4504 12.9374 12.5629C12.8249 12.4504 12.7499 12.3004 12.7124 12.1879C12.7124 12.1879 12.6749 12.1504 12.6374 12.1504C12.5624 12.1504 12.5624 12.1879 12.5624 12.1879C12.5249 12.3004 12.4499 12.4504 12.3374 12.5629C12.2249 12.4504 12.1499 12.3004 12.1124 12.1879C12.1124 12.1879 12.0749 12.1504 12.0374 12.1504C11.9999 12.1504 11.9624 12.1879 11.9624 12.1879C11.9249 12.3004 11.8499 12.4504 11.7374 12.5629C11.6249 12.4504 11.5499 12.3004 11.5124 12.1879C11.5124 12.1879 11.4749 12.1504 11.4374 12.1504C11.3624 12.1504 11.3624 12.1879 11.3624 12.1879C11.3249 12.3004 11.2499 12.4504 11.1374 12.5629C11.0249 12.4504 10.9499 12.3004 10.9124 12.1879C10.9124 12.1879 10.8749 12.1504 10.8374 12.1504C10.7624 12.1504 10.7624 12.1879 10.7624 12.1879C10.6499 12.5254 10.3124 12.9379 9.8999 12.9754L9.7124 13.2379H14.3624L14.0999 12.9004Z" fill="#83BF4F"/>
            <path d="M12 8.625C10.125 8.625 8.625 10.125 8.625 12C8.625 13.875 10.125 15.375 12 15.375C13.875 15.375 15.375 13.875 15.375 12C15.375 10.125 13.875 8.625 12 8.625ZM12 14.8125C10.4625 14.8125 9.1875 13.5375 9.1875 12C9.1875 10.4625 10.4625 9.1875 12 9.1875C13.5375 9.1875 14.8125 10.4625 14.8125 12C14.8125 13.5375 13.5375 14.8125 12 14.8125Z" fill="#DBB471"/>
            <defs>
            <linearGradient id="paint0_linear_19508_8217" x1="11.9632" y1="11.4328" x2="12.1852" y2="12.0204" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FF2A2A"/>
            <stop offset="1" stopColor="#FF0000"/>
            </linearGradient>
            </defs>
        </SvgIcon>
    )
};

export default CountryNICIcon;
