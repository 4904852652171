import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const CountryDOMIcon = (props: any) => {
    return (
        <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
            <path d="M14.25 0.975098V9.7501H23.025C22.125 5.3251 18.675 1.8751 14.25 0.975098Z" fill="#C94747"/>
            <path d="M9.7501 23.025V14.25H0.975098C1.8751 18.675 5.3251 22.125 9.7501 23.025Z" fill="#C94747"/>
            <path d="M14.25 14.25V23.025C18.675 22.125 22.125 18.675 23.025 14.25H14.25Z" fill="#2A5F9E"/>
            <path d="M0.975098 9.7501H9.7501V0.975098C5.3251 1.8751 1.8751 5.3251 0.975098 9.7501Z" fill="#2A5F9E"/>
            <path d="M23.025 9.75H14.25V0.975C13.5375 0.825 12.7875 0.75 12 0.75C11.2125 0.75 10.4625 0.825 9.75 0.975V9.75H0.975C0.825 10.4625 0.75 11.2125 0.75 12C0.75 12.7875 0.825 13.5375 0.975 14.25H9.75V23.025C10.4625 23.175 11.2125 23.25 12 23.25C12.7875 23.25 13.5375 23.175 14.25 23.025V14.25H23.025C23.175 13.5375 23.25 12.7875 23.25 12C23.25 11.2125 23.175 10.4625 23.025 9.75Z" fill="#F9F9F9"/>
            <path d="M10.875 10.9875C10.875 10.9875 10.725 10.95 10.575 10.95C10.6875 10.8 10.725 10.5 10.725 10.5C10.725 10.5 10.4625 10.65 10.3875 10.8C10.35 10.6875 10.2375 10.575 10.2375 10.575C10.2375 10.575 10.125 10.725 10.125 10.8375C10.125 10.95 10.3125 11.1375 10.3125 11.1375C10.3125 11.1375 10.5375 11.2125 10.6875 11.175C10.8 11.175 10.875 10.9875 10.875 10.9875Z" fill="#699635"/>
            <path d="M10.6501 11.3627C10.6501 11.3627 10.5001 11.3627 10.3501 11.4002C10.3876 11.2127 10.3501 10.9502 10.3501 10.9502C10.3501 10.9502 10.1626 11.1377 10.0876 11.3252L9.90005 11.1377C9.90005 11.1377 9.82505 11.2877 9.86255 11.4002C9.90005 11.5127 10.1251 11.6627 10.1251 11.6627C10.1251 11.6627 10.3876 11.6627 10.5001 11.5877C10.6126 11.5502 10.6501 11.3627 10.6501 11.3627Z" fill="#83BF4F"/>
            <path d="M10.5375 11.775C10.5375 11.775 10.3875 11.8125 10.275 11.8875C10.275 11.7 10.1625 11.4375 10.1625 11.4375C10.1625 11.4375 10.0125 11.7 10.0125 11.85C9.89995 11.775 9.78745 11.7375 9.78745 11.7375C9.78745 11.7375 9.74995 11.925 9.82495 12C9.89995 12.1125 10.125 12.1875 10.125 12.1875C10.125 12.1875 10.35 12.1125 10.4625 12.0375C10.5375 11.925 10.5375 11.775 10.5375 11.775Z" fill="#699635"/>
            <path d="M10.5 12.1873C10.5 12.1873 10.35 12.2623 10.275 12.3373C10.2375 12.1498 10.05 11.9248 10.05 11.9248C10.05 11.9248 9.975 12.1873 10.0125 12.3748C9.9 12.3748 9.75 12.3373 9.75 12.3373C9.75 12.3373 9.75 12.5248 9.8625 12.5998C9.975 12.6748 10.2 12.7123 10.2 12.7123C10.2 12.7123 10.425 12.5998 10.4625 12.4873C10.575 12.3748 10.5 12.1873 10.5 12.1873Z" fill="#83BF4F"/>
            <path d="M10.6125 12.5998C10.6125 12.5998 10.5 12.7123 10.425 12.8248C10.35 12.6748 10.125 12.4873 10.125 12.4873C10.125 12.4873 10.125 12.7873 10.2 12.9373H9.9375C9.9375 12.9373 9.975 13.0873 10.125 13.1623C10.2375 13.1998 10.5 13.1623 10.5 13.1623C10.5 13.1623 10.6875 12.9748 10.725 12.8623C10.6875 12.7498 10.6125 12.5998 10.6125 12.5998Z" fill="#699635"/>
            <path d="M10.8 12.9751C10.8 12.9751 10.725 13.1251 10.6875 13.2376C10.575 13.0876 10.3125 13.0126 10.3125 13.0126C10.3125 13.0126 10.3875 13.2751 10.5 13.4251C10.3875 13.4626 10.2375 13.5001 10.2375 13.5001C10.2375 13.5001 10.35 13.6501 10.4625 13.6501C10.575 13.6501 10.8 13.5751 10.8 13.5751C10.8 13.5751 10.9125 13.3501 10.9125 13.2376C10.95 13.0876 10.8 12.9751 10.8 12.9751Z" fill="#83BF4F"/>
            <path d="M11.1 13.3125C11.1 13.3125 11.0625 13.4625 11.0625 13.575C10.9125 13.4625 10.6125 13.4625 10.6125 13.4625C10.6125 13.4625 10.7625 13.6875 10.9125 13.8C10.8 13.8375 10.6875 13.95 10.6875 13.95C10.6875 13.95 10.8375 14.0625 10.95 14.0625C11.0625 14.0625 11.25 13.875 11.25 13.875C11.25 13.875 11.325 13.65 11.2875 13.5C11.25 13.3875 11.1 13.3125 11.1 13.3125Z" fill="#699635"/>
            <path d="M11.475 13.5376C11.475 13.5376 11.475 13.6876 11.5125 13.8376C11.325 13.8001 11.0625 13.8376 11.0625 13.8376C11.0625 13.8376 11.25 14.0251 11.4375 14.1001L11.25 14.2876C11.25 14.2876 11.4 14.3626 11.5125 14.3251C11.625 14.2876 11.775 14.0626 11.775 14.0626C11.775 14.0626 11.775 13.8001 11.7 13.7251C11.625 13.6501 11.475 13.5376 11.475 13.5376Z" fill="#83BF4F"/>
            <path d="M13.1626 10.9875C13.1626 10.9875 13.3126 10.95 13.4626 10.95C13.3501 10.8 13.3126 10.5 13.3126 10.5C13.3126 10.5 13.5751 10.65 13.6501 10.8C13.6876 10.6875 13.8001 10.575 13.8001 10.575C13.8001 10.575 13.9126 10.6875 13.9126 10.8375C13.9126 10.95 13.7251 11.1375 13.7251 11.1375C13.7251 11.1375 13.5001 11.2125 13.3501 11.175C13.2001 11.1375 13.1626 10.9875 13.1626 10.9875Z" fill="#699635"/>
            <path d="M13.3875 11.3627C13.3875 11.3627 13.5375 11.3627 13.6875 11.4002C13.6125 11.2127 13.6875 10.9502 13.6875 10.9502C13.6875 10.9502 13.875 11.1377 13.95 11.3252L14.1375 11.1377C14.1375 11.1377 14.2124 11.2877 14.1749 11.4002C14.1374 11.5127 13.9124 11.6627 13.9124 11.6627C13.9124 11.6627 13.6499 11.6627 13.5375 11.5877C13.425 11.5502 13.3875 11.3627 13.3875 11.3627Z" fill="#83BF4F"/>
            <path d="M13.5 11.775C13.5 11.775 13.65 11.8125 13.7625 11.8875C13.7625 11.7 13.875 11.4375 13.875 11.4375C13.875 11.4375 14.025 11.7 14.025 11.85C14.1375 11.775 14.25 11.7375 14.25 11.7375C14.25 11.7375 14.2875 11.925 14.2125 12C14.1375 12.1125 13.9125 12.1875 13.9125 12.1875C13.9125 12.1875 13.6875 12.1125 13.575 12.0375C13.5 11.925 13.5 11.775 13.5 11.775Z" fill="#699635"/>
            <path d="M13.5374 12.1873C13.5374 12.1873 13.6874 12.2623 13.7624 12.3373C13.7999 12.1498 13.9874 11.9248 13.9874 11.9248C13.9874 11.9248 14.0624 12.1873 14.0249 12.3748C14.1374 12.3373 14.2874 12.3373 14.2874 12.3373C14.2874 12.3373 14.2874 12.5248 14.1749 12.5998C14.0624 12.6748 13.8374 12.7123 13.8374 12.7123C13.8374 12.7123 13.6124 12.5998 13.5749 12.4873C13.4624 12.3748 13.5374 12.1873 13.5374 12.1873Z" fill="#83BF4F"/>
            <path d="M13.4249 12.5998C13.4249 12.5998 13.5374 12.7123 13.6124 12.8248C13.6874 12.6748 13.9124 12.4873 13.9124 12.4873C13.9124 12.4873 13.9124 12.7873 13.8374 12.9373H14.0999C14.0999 12.9373 14.0624 13.0873 13.9124 13.1623C13.7999 13.1998 13.5374 13.1623 13.5374 13.1623C13.5374 13.1623 13.3499 12.9748 13.3124 12.8623C13.2749 12.7498 13.4249 12.5998 13.4249 12.5998Z" fill="#699635"/>
            <path d="M13.2374 12.9751C13.2374 12.9751 13.3124 13.1251 13.3499 13.2376C13.4624 13.0876 13.7249 13.0126 13.7249 13.0126C13.7249 13.0126 13.6499 13.2751 13.5374 13.4251C13.6499 13.4626 13.7999 13.5001 13.7999 13.5001C13.7999 13.5001 13.6874 13.6501 13.5749 13.6501C13.4624 13.6501 13.2374 13.5751 13.2374 13.5751C13.2374 13.5751 13.1249 13.3501 13.1249 13.2376C13.0874 13.0876 13.2374 12.9751 13.2374 12.9751Z" fill="#83BF4F"/>
            <path d="M12.9375 13.3125C12.9375 13.3125 12.975 13.4625 12.975 13.575C13.125 13.4625 13.425 13.4625 13.425 13.4625C13.425 13.4625 13.275 13.6875 13.125 13.8C13.2375 13.8375 13.35 13.95 13.35 13.95C13.35 13.95 13.2 14.0625 13.0875 14.0625C12.975 14.0625 12.7875 13.875 12.7875 13.875C12.7875 13.875 12.7125 13.65 12.75 13.5C12.7875 13.3875 12.9375 13.3125 12.9375 13.3125Z" fill="#699635"/>
            <path d="M12.5625 13.5376C12.5625 13.5376 12.5625 13.6876 12.525 13.8376C12.7125 13.8001 12.9749 13.8376 12.9749 13.8376C12.9749 13.8376 12.7874 14.0251 12.6 14.1001L12.7874 14.2876C12.7874 14.2876 12.6375 14.3626 12.525 14.3251C12.4125 14.2876 12.2625 14.0626 12.2625 14.0626C12.2625 14.0626 12.2625 13.8001 12.3375 13.7251C12.4125 13.5376 12.5625 13.5376 12.5625 13.5376Z" fill="#83BF4F"/>
            <path d="M10.875 10.9123V12.7123C10.875 12.8623 10.875 12.9748 10.95 13.0873C11.025 13.1623 11.1 13.2373 11.2125 13.2748H11.6625C11.8875 13.2748 11.8875 13.3123 11.9625 13.4248C12.0375 13.2748 12.15 13.2748 12.3375 13.2748H12.7125C12.825 13.2373 12.9 13.1998 12.9375 13.1248C13.05 13.0123 13.05 12.8623 13.05 12.7123V10.9873C13.05 10.9123 13.05 10.9123 13.0875 10.8748L13.3499 10.6123H10.575L10.875 10.9123Z" fill="white"/>
            <path d="M11.7375 11.6998V10.6123H10.6125L10.9125 10.9123V11.6998H11.7375Z" fill="#002D62"/>
            <path d="M12.2251 11.6998V10.6123H13.3876L13.0876 10.9123V11.6998H12.2251Z" fill="#CE1126"/>
            <path d="M11.7375 12.1499H10.875V12.8624C10.9125 13.1624 11.1375 13.2749 11.325 13.2749H11.7375V12.1499Z" fill="#CE1126"/>
            <path d="M12.2249 12.1504H13.0874V12.8629C13.0499 13.1629 12.8249 13.2754 12.6374 13.2754H12.2249V12.1504Z" fill="#002D62"/>
            <path d="M11.325 9.71266C10.9875 9.71266 10.6125 9.78766 10.35 9.97516C10.2 10.0502 10.2 10.0877 10.275 10.2377L10.0875 10.3127C9.97495 10.3502 9.86245 10.3877 9.74995 10.4627C9.67495 10.5002 9.67495 10.5377 9.63745 10.5752L9.89995 10.5377L9.86245 10.6127C9.82495 10.6877 9.78745 10.7252 9.74995 10.8002C9.82495 10.7627 9.89995 10.6877 9.97495 10.6502C10.1625 10.5377 10.5375 10.5377 10.6875 10.3877C10.725 10.3502 10.6875 10.3127 10.6875 10.2377C10.8 10.2002 10.8375 10.1627 10.95 10.1627C11.2875 10.0877 11.475 10.1627 11.625 10.1252C11.6625 10.1252 11.7375 10.0877 11.7375 10.0502L11.7 9.90016H12.225C12.225 9.97516 12.15 10.1252 12.3 10.1627C12.45 10.2002 12.75 10.1252 12.975 10.2002L13.2375 10.2752L13.2 10.3502V10.4252C13.2375 10.4627 13.275 10.5002 13.3125 10.5002C13.3875 10.5377 13.5 10.5752 13.6125 10.6127C13.9125 10.6877 13.95 10.6877 14.175 10.8377C14.175 10.8002 14.0625 10.6502 14.025 10.5752L14.2874 10.6127C14.2499 10.5377 14.1 10.4627 14.025 10.4252L13.6875 10.3127C13.725 10.2377 13.7625 10.2002 13.7249 10.1627C13.6874 10.0877 13.3875 9.93766 13.3125 9.93766C13.2 9.90016 13.05 9.86266 12.9375 9.86266C12.825 9.82516 12.6 9.82516 12.6 9.82516C12.6 9.82516 12.6375 9.71266 12.5625 9.63766C12.525 9.60016 12.45 9.60016 12.375 9.60016C12.1125 9.56266 11.775 9.60016 11.5125 9.60016C11.4375 9.60016 11.4 9.60016 11.325 9.63766V9.71266Z" fill="#002D62"/>
            <path d="M10.8749 13.8C10.7249 13.8375 10.6499 13.875 10.4999 13.95C10.4624 13.875 10.3874 13.7625 10.3124 13.6875L10.1999 13.575C10.1249 13.5375 10.0874 13.5375 10.0499 13.575L10.0124 13.5C9.9374 13.3875 9.8624 13.3125 9.7874 13.2375L9.7499 13.2L9.7124 13.1625C9.7499 13.275 9.7499 13.35 9.7874 13.4625C9.7499 13.425 9.7124 13.425 9.6749 13.3875C9.6374 13.3875 9.5624 13.35 9.5249 13.35L9.5624 13.3875C9.6374 13.4625 9.7124 13.575 9.7499 13.6875C9.7874 13.8 9.8624 13.95 9.9749 14.025C9.9749 14.025 10.0499 14.1 10.0874 14.1C10.1999 14.1375 10.1999 14.1 10.2374 14.0625C10.3124 14.2125 10.3499 14.25 10.4624 14.3625C10.5749 14.475 10.7249 14.475 10.8374 14.475C10.9124 14.475 10.9499 14.4375 10.9874 14.4C11.1749 14.2875 11.0624 14.2125 11.0624 14.1375C11.1374 14.1375 11.3624 14.0625 11.4374 14.025C11.7374 13.9875 12.2624 13.95 12.5999 14.025C12.6374 14.025 12.9749 14.1375 13.0124 14.1375C12.9749 14.2125 12.9749 14.2125 12.9749 14.2875C12.9749 14.3625 13.0499 14.3625 13.0874 14.4C13.1249 14.4375 13.1624 14.4375 13.2374 14.475H13.4249C13.5374 14.4375 13.6124 14.3625 13.6874 14.325C13.7624 14.2125 13.7999 14.1375 13.8749 14.0625L13.9124 14.1H13.9874C14.0249 14.1 14.0624 14.0625 14.0999 14.025C14.2124 13.95 14.2874 13.8 14.3249 13.6875C14.3624 13.5375 14.4374 13.4625 14.5499 13.35C14.3999 13.35 14.3624 13.3875 14.2874 13.425L14.3249 13.275C14.3249 13.2375 14.3624 13.1625 14.3624 13.125C14.2874 13.2 14.2499 13.275 14.1749 13.35C14.1374 13.3875 14.0999 13.425 14.0999 13.4625C14.0249 13.5375 14.0624 13.5375 14.0249 13.575C13.8374 13.4625 13.7249 13.725 13.5749 13.9125C13.3124 13.7625 13.0874 13.725 12.7874 13.65C12.7124 13.65 12.6749 13.65 12.5999 13.6125C12.2249 13.575 11.8124 13.575 11.4374 13.6125C11.1749 13.725 11.0249 13.7625 10.8749 13.8Z" fill="#CE1126"/>
            <path d="M11.8875 10.5376V10.7626H11.625V10.9876H11.8875V11.8876H12.1125V10.9876H12.3375V10.7626H12.1125V10.5376H11.8875Z" fill="#EAC102"/>
            <path d="M11.2124 12.0751L12.2249 11.4751L12.7874 12.2626L11.6999 12.9751L11.2124 12.0751Z" fill="#201B18"/>
            <path d="M11.325 11.8874L12.1875 11.3999L12.6375 12.0374L11.7375 12.6374L11.325 11.8874Z" fill="#C9C9C9"/>
        </SvgIcon>
    )
};

export default CountryDOMIcon;
