import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const TimeIcon = (props: any) => {
  const { style, viewBox, ...otherProps } = props;
  return (
    <SvgIcon
      {...otherProps}
      style={{ width: 24, height: 25, color: "#919CA5", ...style }}
      viewBox={viewBox || "0 0 24 25"}
    >
      <path
          d="M12.286 20.4795C10.6966 20.4795 9.14292 20.0082 7.82141 19.1252C6.4999 18.2422 5.46992 16.9871 4.86169 15.5187C4.25347 14.0503 4.09435 12.4346 4.40442 10.8757C4.71449 9.31691 5.47978 7.88502 6.60364 6.76116C7.72749 5.63731 9.15943 4.87195 10.7183 4.56188C12.2771 4.25181 13.8928 4.41096 15.3612 5.01919C16.8296 5.62741 18.0846 6.65741 18.9677 7.97892C19.8507 9.30043 20.322 10.8541 20.322 12.4435C20.322 14.5748 19.4753 16.6187 17.9683 18.1258C16.4612 19.6328 14.4173 20.4795 12.286 20.4795ZM12.286 5.74648C10.9609 5.74648 9.66553 6.13943 8.56372 6.87563C7.46191 7.61184 6.60305 8.65823 6.09595 9.8825C5.58884 11.1068 5.4562 12.4539 5.71472 13.7536C5.97324 15.0533 6.61133 16.2471 7.54834 17.1841C8.48535 18.1211 9.67921 18.7592 10.9789 19.0177C12.2786 19.2763 13.6257 19.1436 14.85 18.6365C16.0742 18.1294 17.1206 17.2706 17.8568 16.1688C18.593 15.067 18.986 13.7716 18.986 12.4465C18.986 10.6695 18.2801 8.96536 17.0236 7.70887C15.7671 6.45237 14.063 5.74648 12.286 5.74648ZM13.78 14.9215L11.818 12.9535C11.7498 12.8863 11.6967 12.8053 11.6622 12.716C11.6277 12.6267 11.6127 12.5311 11.618 12.4355V9.09548C11.6146 9.00538 11.6293 8.91551 11.6614 8.83124C11.6935 8.74698 11.7422 8.67005 11.8047 8.60508C11.8672 8.5401 11.9422 8.48841 12.0251 8.4531C12.1081 8.41779 12.1973 8.3996 12.2875 8.3996C12.3776 8.3996 12.4668 8.41779 12.5498 8.4531C12.6328 8.48841 12.7078 8.5401 12.7703 8.60508C12.8328 8.67005 12.8816 8.74698 12.9137 8.83124C12.9458 8.91551 12.9605 9.00538 12.957 9.09548V12.1295L14.765 13.9375C14.8418 13.999 14.9047 14.0761 14.9497 14.1636C14.9947 14.251 15.0207 14.347 15.0261 14.4453C15.0315 14.5435 15.0161 14.6418 14.981 14.7337C14.9458 14.8256 14.8917 14.9091 14.8221 14.9786C14.7526 15.0482 14.6692 15.1023 14.5773 15.1375C14.4854 15.1726 14.3871 15.188 14.2888 15.1826C14.1906 15.1772 14.0946 15.1512 14.0071 15.1062C13.9196 15.0612 13.8416 14.9983 13.78 14.9215Z" fill="#919CA5" stroke="#919CA5" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
          fill={style?.color || "#919CA5"}
    </SvgIcon>
  );
};

export default TimeIcon;
