import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const CloseIcon = (props: any) => {
  const { style, viewBox, ...otherProps } = props;
  return (
    <SvgIcon
      {...otherProps}
      style={{ width: 14, height: 14, color: "#F6F6F6", ...style }}
      viewBox={viewBox || "0 0 14 14"}
    >
      <path d="M12.6568 1.34338C12.3996 1.08616 12.0508 0.94166 11.687 0.94166C11.3232 0.94166 10.9744 1.08616 10.7172 1.34338L7 5.06054L3.28284 1.34338C3.02562 1.08616 2.67675 0.941657 2.31299 0.941657C1.94923 0.941656 1.60036 1.08616 1.34314 1.34338C1.08592 1.6006 0.94142 1.94946 0.94142 2.31323C0.94142 2.67699 1.08592 3.02585 1.34314 3.28307L5.0603 7.00023L1.34315 10.7174C1.08593 10.9746 0.941422 11.3235 0.941423 11.6872C0.941423 12.051 1.08593 12.3999 1.34315 12.6571C1.60037 12.9143 1.94923 13.0588 2.31299 13.0588C2.67676 13.0588 3.02562 12.9143 3.28284 12.6571L6.99647 8.94345L10.7136 12.6606C10.9709 12.9178 11.3197 13.0623 11.6835 13.0623C12.0472 13.0623 12.3961 12.9178 12.6533 12.6606C12.9105 12.4034 13.055 12.0545 13.055 11.6908C13.055 11.327 12.9105 10.9781 12.6533 10.7209L8.93969 7.00729L12.6604 3.2866C12.788 3.15895 12.8892 3.00734 12.9582 2.84048C13.0271 2.67363 13.0624 2.4948 13.0621 2.31426C13.0618 2.13372 13.0258 1.95503 12.9563 1.78842C12.8867 1.62182 12.785 1.47058 12.6568 1.34338V1.34338Z" />
    </SvgIcon>
  );
};

export default CloseIcon;
