import { createSlice } from "@reduxjs/toolkit";
import { AccountInfoModel } from "../models/account";
import { CustomerValidModel } from "../models/customer";
import { ScheduleAppointment, ScheduleTime } from "../models/schedule";
import { ServiceUserModel } from "../models/service/service-user.model";
import { ServiceWithUsersModel } from "../models/service/service-with-users-model";
import Country from "../models/common/country";
import { GroupServiceModel } from "../models/service";

interface SchedulerState {
  currentDate: Date;
  oldCurrentDate: Date | null;
  daysOff: number[];
  account: AccountInfoModel;
  countries: Country[];
  countryInSettings: Country;
  isOpenCustomerModal: boolean;
  isOpenScheduleBusyModal: boolean;
  scheduleAppointment: ScheduleAppointment;
  isUpdate: boolean;
  isModified: boolean;
  isEditable: boolean;
  isServicesPerformedSimultaneously: boolean;
  serviceUser: ServiceUserModel[];
  availableHours: ScheduleTime[];
  isValidSelection: boolean;
  isValidUser: boolean;
  servicesWithUsers: ServiceWithUsersModel[];
  suggestedServices: GroupServiceModel[];

  oldSchedule: string | null;
  schedule?: string;
  scheduleTime?: ScheduleTime;
  customerValid?: CustomerValidModel;
  appointmentId?: string;
  refreshAvailableHours: number;
  maxServiceIndex: number;
  locale: string;
  showLoader: boolean;
  isPreselectedServiceHandled: boolean;
}

const initialState: SchedulerState = {
  currentDate: new Date(),
  oldCurrentDate: null,
  daysOff: [],
  serviceUser: [new ServiceUserModel()],
  availableHours: [new ScheduleTime()],
  servicesWithUsers: [],
  suggestedServices: [],
  isValidSelection: false,
  isValidUser: false,
  account: new AccountInfoModel(),
  countries: [],
  countryInSettings: new Country(),
  isOpenCustomerModal: false,
  isOpenScheduleBusyModal: false,
  isUpdate: false,
  oldSchedule: null,
  isModified: false,
  isEditable: true,
  isServicesPerformedSimultaneously: false,
  scheduleAppointment: new ScheduleAppointment(),
  refreshAvailableHours: 0,
  maxServiceIndex: 0,
  locale: process.env.REACT_APP_DEFAULT_LANGUAGE ?? 'es',
  showLoader: false,
  isPreselectedServiceHandled: false
};

const schedulerSlice = createSlice({
  name: "scheduler",
  initialState: initialState,
  reducers: {
    setCurrentDate: (state, action) => {
      state.currentDate = action.payload;
    },
    setOldCurrentDate: (state, action) => {
      state.oldCurrentDate = action.payload;
    },
    setDaysOff: (state, action) => {
      state.daysOff = action.payload;
    },
    setServiceUser: (state, action) => {
      state.serviceUser = [...action.payload];
    },
    setAvailableHours: (state, action) => {
      state.availableHours = [...action.payload];
    },
    setServicesWithUsers: (state, action) => {
      state.servicesWithUsers = [...action.payload];
    },
    setSuggestedServices: (state, action) => {
      state.suggestedServices = action.payload;
    },
    setIsValidSelection: (state, action) => {

      state.isValidSelection = action.payload;
    },
    setIsValidUser: (state, action) => {
      state.isValidUser = action.payload;
    },
    setSchedule: (state, action) => {
      state.schedule = action.payload;
    },
    setOldSchedule: (state, action) => {
      state.oldSchedule = action.payload;
    },
    setScheduleTime: (state, action) => {
      state.scheduleTime = action.payload;
    },
    setAccount: (state, action) => {
      state.account = action.payload;
    },
    setCountries: (state, action) => {
      state.countries = action.payload;
    },
    setCountryInSettings: (state, action) => {
      state.countryInSettings = action.payload;
    },
    setIsOpenCustomerModal: (state, action) => {
      state.isOpenCustomerModal = action.payload;
    },
    setIsOpenScheduleBusyModal: (state, action) => {
      state.isOpenScheduleBusyModal = action.payload;
    },
    setScheduleAppointment: (state, action) => {
      state.scheduleAppointment = action.payload;
    },
    setCustomerValid: (state, action) => {
      state.customerValid = action.payload;
    },
    setAppointmentId: (state, action) => {
      state.appointmentId = action.payload;
    },
    setIsUpdate: (state, action) => {
      state.isUpdate = action.payload;
    },
    setIsModified: (state, action) => {
      state.isModified = action.payload;
    },
    setIsEditable: (state, action) => {
      state.isEditable = action.payload;
    },
    setIsServicesPerformedSimultaneously: (state, action) => {
      state.isServicesPerformedSimultaneously = action.payload;
    },
    setRefreshAvailableHours: (state, action) => {
      state.refreshAvailableHours = action.payload;
    },
    setMaxServiceIndex: (state, action) => {
      state.maxServiceIndex = action.payload;
    },
    setLocale(state, action) {
      state.locale = action.payload;
    },
    setShowLoader(state, action) {
      state.showLoader = action.payload;
    },
    setIsPreselectedServiceHandled(state, action) {
      state.isPreselectedServiceHandled = action.payload;
    }
  },
});

export const schedulerActions = schedulerSlice.actions;
export default schedulerSlice.reducer;
