import { makeStyles } from "@material-ui/core/styles";

const useStyles = (isMobile: boolean) =>
  makeStyles((theme) => ({
    root: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "center",
  
      padding: 8,
  
      backgroundColor: theme.colors.white
    },
    content: {
    },
    input: {
      backgroundColor: theme.colors.white,
  
      width: isMobile ? 288 : 668,
      height: 40,
      
      padding: "13px 0px 13px 12px",
      border: "solid 1px rgba(0, 0, 0, 0.1)",
      borderRadius: 5,
      
      ...theme.typography.txtBody2,
      color: theme.colors.grey5
    },
    inputText: {
      ...theme.typography.txtBody2,
      fontWeight: "bold",
      color: theme.colors.grey5,
      "&::placeholder": {
        fontWeight: "normal",
        color: theme.colors.grey5,
        opacity: 0.8
      },
    },
    inputFocused: {
      border: "solid 1px #6462F3",
      padding: "14px 0px 14px 12px",
      width: isMobile ? 288 : 668,
    },
    inputDisabled: {
      color: "#676767",
    },
    inputError: {
      color: theme.colors.clearRed,
      border: "solid",
      borderWidth: 1,
      borderColor: theme.colors.clearRed
    },
  }));

export default useStyles;
