import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    emptyPhoto: {
      ...theme.typography.header1,
      color: theme.colors.clearMain,
      fontWeight: 700,
      minWidth: 24,
      border: "solid",
      height: 24,
      display: "flex",
      fontSize: 10,
      alignItems: "center",
      borderColor: theme.colors.clearMain,
      borderWidth: 1,
      borderRadius: "50%",
      justifyContent: "center",
      alignSelf: "flex-end",
      marginRight: 7,
      marginBottom: "auto",
      marginTop: "auto"
    },
    photo: {
      minWidth: 24,
      maxWidth: 24,
      height: 24,
      display: "flex",
      borderRadius: 100,
      alignSelf: "flex-end",
      marginRight: 7,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      cursor: "pointer",
      "&:hover $largerPhoto": {
        display: "block",
      },
    },
    largerPhoto: {
      width: 140,
      height: 140,
      borderRadius: 100,
      background: theme.colors.white,
      backgroundSize: 350,
      backgroundRepeat: "no-repeat",
      display: "none",
      position: "absolute",
      alignSelf: "flex-end",
      marginLeft: -160,
      marginBottom: -50,
      [theme.breakpoints.down('md')]: {
        width: 100,
        height: 100,
        marginLeft: -100,
        marginBottom: -30,
      }
    }
  })
);

export default useStyles;
