import MobileNumber from "../common/mobile-number";
import { Gender } from "./gender";


export default class CustomerInfo {
  name: string;
  lastName: string;
  mobile: MobileNumber;
  email: string;
  gender: Gender | null;
  customGender: string | null;
  dateOfBirth: Date | null;
  
  constructor(x?: CustomerInfo) {
    if (typeof x === "object") {
      this.name = x.name;
      this.lastName = x.lastName;
      this.mobile = x.mobile;
      this.email = x.email;
      this.gender = x.gender;
      this.customGender = x.customGender;
      this.dateOfBirth = x.dateOfBirth;
    } else {
      this.name = "";
      this.lastName = "";
      this.mobile = new MobileNumber();
      this.email = "";
      this.gender = null;
      this.customGender = null;
      this.dateOfBirth = null;
    }
  }
}