import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  label: {
    ...theme.typography.buttons,
    fontFamily: "Inter, sans-serif",
    fontWeight: "bold",
    display: "inline-flex",
    color: theme.colors.grey5,
    marginBottom: 8,
    overflowWrap: "break-word",
    wordBreak: "break-word"
  },
  dot: {
    ...theme.typography.buttons,
    color: theme.colors.clearMain,
    marginLeft: 5
  },
}));

export default useStyles;
