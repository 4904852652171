import React from 'react';

import { useTranslation } from "react-i18next";

import { PatientFormControlProps } from "./props";
import { PatientFormControlType } from "../../../models/patientform/patient-form-control-type";
import PatientFormMobileNumberControl from "../../../models/patientform/patient-form-mobile-number-control";
import PatientFormEmailControl from "../../../models/patientform/patient-form-email-control";
import PatientFormRadioGroupControl from "../../../models/patientform/patient-form-radio-group-control";
import PatientFormTextControl from "../../../models/patientform/patient-form-text-control";
import PatientFormMobileInput from "../../common/PatientFormMobileInput";
import PatientFormTextInput from "../../common/PatientFormTextInput";
import { Regex } from "../../../constants";
import PatientFormRadioGroup from "../../common/PatientFormRadioGroup";


export default function PatientFormControl(props: PatientFormControlProps) {
  
  const { control, setControlValue, isMobile } = props;
  
  const { t } = useTranslation("patient-form-sections");
  
  function validateEmailInput(value: string) {
    return Regex.Email.test(value);
  }
  
  function validateTextInput(value: string) {
    return value !== "";
  }
  
  function concreteControl() {
    switch (control.type) {
      case PatientFormControlType.PhoneNumber:
        const mobileNumberControl = control as PatientFormMobileNumberControl;
        return (
          <PatientFormMobileInput 
            mobileNumber={mobileNumberControl.value} 
            setMobileNumber={setControlValue}
            isMobileView={isMobile}
            required={mobileNumberControl.isRequired}
            label={t(mobileNumberControl.name)}
          />
        );
      case PatientFormControlType.Email:
        const emailControl = control as PatientFormEmailControl;
        return (
          <PatientFormTextInput 
            value={emailControl.value}
            setValue={setControlValue}
            label={t(emailControl.name)}
            isValid={validateEmailInput}
            required={emailControl.isRequired}
            isMobile={isMobile}
            placeholder={t(emailControl.name)}
          />
        );
      case PatientFormControlType.Radio:
        const radioControl = control as PatientFormRadioGroupControl;
        return (
          <PatientFormRadioGroup
            values={radioControl.values}
            value={radioControl.value}
            setValue={setControlValue}
            label={t(radioControl.name)}
            required={radioControl.isRequired}
            isMobile={isMobile}
            hasFreeText={radioControl.hasFreeText}
          />
        );
      case PatientFormControlType.Text:
        const textControl = control as PatientFormTextControl;
        return (
          <PatientFormTextInput
            value={textControl.value}
            setValue={setControlValue}
            label={t(textControl.name)}
            isValid={validateTextInput}
            required={textControl.isRequired}
            isMobile={isMobile}
            placeholder={t(textControl.name)}
          />
        );
      default:
        return null;
    }
  }
  
  return (
    <>
      {concreteControl()}
    </>
  );
}