import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import { Regex, Url } from "../../../constants";
import { AppointmentService } from "../../../api";
import {useAppDispatch, useAppSelector} from "../../../redux/hooks";
import { useTranslation } from 'react-i18next';
import { AccountInfoModel } from "../../../models/account";
import { schedulerActions } from "../../../redux/scheduler-slice";
import { ScheduleAppointment, ScheduleTime } from "../../../models/schedule";
import { ServiceModel } from "../../../models/service";
import { CustomerValidModel } from "../../../models/customer";
import { ServiceUserModel } from "../../../models/service/service-user.model";
import {AppointmentServiceDtoModel} from "../../../models/appointment";
import AppointmentScheduler from "../../../components/appointment/AppointmentScheduler";

export default function AppointmentEdition() {
  
  const dispatch = useAppDispatch();
  const { i18n } = useTranslation("general");
  const {
    setAccount,
    setServiceUser,
    setCurrentDate,
    setSchedule,
    setOldSchedule,
    setScheduleTime,
    setOldCurrentDate,
    setAppointmentId,
    setIsUpdate,
    setIsModified,
    setScheduleAppointment,
    setCustomerValid,
    setIsEditable,
    setIsOpenScheduleBusyModal,
    setLocale,
    setIsServicesPerformedSimultaneously,
    setShowLoader
  } = schedulerActions;
  const { appointmentId: protectedAppointmentId, accountBusinessName } = useParams<any>();
  const showLoader = useAppSelector((state) => state.scheduler.showLoader);

  
  const _setCloseScheduleBusyModal = () => {
    dispatch(setIsOpenScheduleBusyModal(false));
  };

  dispatch(setIsUpdate(true));

  const _setAccount = (value: AccountInfoModel) => {
    const language = value.languageCode ?? process.env.REACT_APP_DEFAULT_LANGUAGE ?? 'es';
    i18n.changeLanguage(language).finally(() => {
      dispatch(setLocale(language));
      dispatch(setAccount(value));
    });
  };

  const _setCustomerValid = (value: CustomerValidModel) => {
    dispatch(setCustomerValid(value));
  };
  
  function _setServiceUser(value: ServiceUserModel[]) {
    dispatch(setServiceUser(value));
  }

  const _setCurrentDate = (value: Date) => {
    dispatch(setCurrentDate(value));
  };
  function _setIsServicesPerformedSimultaneously(value: boolean) {
    dispatch(setIsServicesPerformedSimultaneously(value));
  };

  const _setSchedule = (value: string) => {
    dispatch(setSchedule(value));
  };
  
  const _setOldSchedule = (value: string) => {
    dispatch(setOldSchedule(value));
  };

  const _setScheduleTime = (value: ScheduleTime) => {
    dispatch(setScheduleTime(value));
  };
  
  const _setOldCurrentDate = (value: Date) => {
    dispatch(setOldCurrentDate(value));
  };

  const _setAppointmentId = (value: string) => {
    dispatch(setAppointmentId(value));
  };

  const _setIsEditable = (value: boolean) => {
    dispatch(setIsEditable(value));
  };

  const _setScheduleAppointment = (value: ScheduleAppointment) => {
    dispatch(setScheduleAppointment(value));
    _setCustomerValid(
      new CustomerValidModel({
        name: Regex.Name.test(value.firstName!),
        lastName: Regex.Name.test(value.lastName!),
        mobile: true
      })
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      await loadAccount();
    };

    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const redirectToNotFound = () => {
    window.location.href = Url.NotFound;
  };
  
  const redirectToHome = () => {
    window.location.href = "/" + accountBusinessName;
  };

  const loadAccount = async () => {
    if (accountBusinessName) {
      try {
        dispatch(setShowLoader(true));
        const account = await AppointmentService.getByBusinessName(accountBusinessName);
        dispatch(setShowLoader(false));
        if (account && account.id) {
          _setAccount(account);
          dispatch(setShowLoader(true));
          const appointment = await AppointmentService.getAppointmentByEncryptedAppointmentId(
            account.id,
            protectedAppointmentId
          );
          dispatch(setShowLoader(false));
          
          if (
            appointment &&
            appointment.startDate &&
            appointment.services &&
            appointment.services.length > 0
          ) {
            _setAppointmentId(appointment.id!);
            _setIsEditable(appointment.isEditable as boolean);
            _setIsServicesPerformedSimultaneously(appointment.scheduledSimultaneousServices);
            
            const actualDate = new Date(appointment.startDate);
            const appointmentServicesAsModels = appointment.services.map((service, index) => {
              return new ServiceUserModel({
                service: new ServiceModel({
                  id: service.serviceId,
                  name: service.name,
                  categoryName: service.categoryName,
                  duration: service.duration,
                  price: service.price,
                  photo: service.photo,
                  notes: service.notes
                }),
                serviceId: service.serviceId,
                userId: service.userId,
                services: [],
                users: [],
                index
              })
            })
            _setServiceUser(appointmentServicesAsModels)
            _setCurrentDate(actualDate);
            const fromTime = actualDate.toLocaleString(undefined, {
              hour: "2-digit",
              minute: "2-digit",
              hour12: false,
            });
            const date = new Date(
              actualDate.getTime() +
                (appointmentDuration(appointment.services, appointment.scheduledSimultaneousServices)) * 60000
            );
            const toTime = date.toLocaleString(undefined, {
              hour: "2-digit",
              minute: "2-digit",
              hour12: false,
            });
            _setSchedule(`${fromTime} - ${toTime}`);
            _setOldSchedule(`${fromTime} - ${toTime}`);
            _setOldCurrentDate(date);
            _setScheduleTime(
              new ScheduleTime({
                scheduleTime: `${fromTime} - ${toTime}`,
                userId: appointment.services[0].userId as string,
              })
            );
            _setScheduleAppointment(
              new ScheduleAppointment({
                ...appointment,
                accountId: account.id,
                services: appointmentServicesAsModels,
              })
            );
          } else {
            redirectToHome();
          }
        } else {
          redirectToNotFound();
        }
      } catch (error) {
        redirectToNotFound();
      }
    } else {
      redirectToNotFound();
    }
  };
  
  function appointmentDuration(services: AppointmentServiceDtoModel[], scheduledSimultaneousServices: boolean) {
    if (scheduledSimultaneousServices) {
      return Math.max(...services.map(service => service?.duration || 0))
    }
    return services.reduce((previousValue, currentValue) => previousValue + (currentValue?.duration === null ? 0 : currentValue.duration), 0);
  }

  return (
    <div>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <AppointmentScheduler />

    </div>
  );
};
