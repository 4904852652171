import RadioItem from "./radio-item";

export default class RadioValue {
  item: RadioItem | null;
  additionalFreeTextValue: string;
  
  constructor(x?: any) {
    if (typeof x === "object") {
      this.item = x.item;
      this.additionalFreeTextValue = x.additionalFreeTextValue;
    }
    else if (typeof x === "string") {
      const parsedValue = JSON.parse(x);
      this.item = new RadioItem(parsedValue?.item)
      this.additionalFreeTextValue = parsedValue?.additionalFreeTextValue ?? "";
    } else {
      this.item = null;
      this.additionalFreeTextValue = "";
    }
  }
}