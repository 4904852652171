import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    height: 3,
  },
  colorPrimary: {
    backgroundColor: "#C9CDD3"
  },
  barColorPrimary: {
    backgroundColor: "#6462F3"
  },
}));
