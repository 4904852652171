import React from 'react';

import { useTranslation } from "react-i18next";

import { PatientFormSectionProps } from "./props";
import useStyles from "./css";
import PatientFormTypedControl from "../../../models/patientform/patient-form-typed-control";
import PatientFormControl from "../PatientFormControl";
import MobileNumber from "../../../models/common/mobile-number";
import RadioValue from "../../../models/common/radio-value";
import { useLocation } from 'react-router-dom';
import { GetPatientFormFieldKey } from '../../../constants/local-storage-keys';


export default function PatientFormSection(props: PatientFormSectionProps) {
  
  const { section, setSection, isMobile } = props;
  
  const classes = useStyles(isMobile)();

  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);
  const customerId = searchParams.get("customerId");
  
  const { t } = useTranslation("patient-form-sections");
  
  const setControlValue = (index: number, fieldId: string) => (newControlValue: string | MobileNumber | RadioValue) => {
    const newSectionValue = { ...section }
    newSectionValue.controls[index].value = newControlValue;
    localStorage.setItem(GetPatientFormFieldKey(customerId!, fieldId), JSON.stringify(newControlValue))
    setSection(newSectionValue);
  }
  
  function mapControlToRenderElement(control: PatientFormTypedControl, index: number) {
    return <PatientFormControl
      key={control.fieldId}
      control={control}
      setControlValue={setControlValue(index, control.fieldId!)}
      isMobile={isMobile}
    />
  }
  
  return (
    <div className={classes.root}>
      <div className={classes.header}>{t(section.name)}</div>
      {
        section.controls.map(mapControlToRenderElement)
      }
    </div>
  );
}