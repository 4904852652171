import React from 'react';

import { useTranslation } from "react-i18next";

import { InputBase } from "@material-ui/core";

import { PatientFormFreeTextInputProps } from "./props";
import useStyles from "./css";


export default function PatientFormFreeTextInput(props: PatientFormFreeTextInputProps) {
  
  const { value, setValue, placeholder, isMobile, disabled } = props;
  
  const { t } = useTranslation("patient-form");
  
  const maxLength = 180;
  
  const classes = useStyles(isMobile)();
  
  function handleOnChange(event: any) {
    setValue(event.target.value);
  }
  
  const maxPlaceholderLength = isMobile ? 46 : 99
  
  function getPlaceholder() {
    const fullText = `${t("Write here your answer for")} ${placeholder}`;
    return fullText.length > maxPlaceholderLength
      ? `${fullText.substring(0, maxPlaceholderLength)}...`
      : fullText
  }
  
  return (
    <div className={classes.root}>
      <InputBase
        className={classes.input}
        placeholder={getPlaceholder()}
        value={value}
        onChange={handleOnChange}
        disabled={disabled}
        inputProps={{
          maxLength: maxLength
        }}
      />
    </div>
  );
}