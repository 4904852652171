import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {},
    message: {
      height: 32,
      background: "rgba(255, 198, 38, 0.2)",
      textAlign: "center",
      paddingTop: 2,
      borderRadius: 10,
      marginTop: 27,
      marginRight: "auto",
      marginLeft: "auto",
      width: "105%",
      [theme.breakpoints.down("sm")]: {
        width: "95%",
        display: "flex",
        height: 40,
        alignItems: "center"
      },
    },
    messageIcon: {
      color: "#B49A48",
      display: "inline-block",
      marginRight: 10,
      [theme.breakpoints.down("sm")]: {
        marginLeft: 16,
        alignSelf: "center",
        paddingBottom: 7
      },
    },
    messageLabel: {
      fontFamily: 'Roboto',
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: 12,
      alignItems: "center",
      color: "#B49A48",
      display: "inline-block",
      [theme.breakpoints.down("sm")]: {
        textAlign: "left",
        paddingRight: 20
      },
    }
  })
);

export default useStyles;