import React, { useState, useEffect } from "react";

import { useTranslation } from "react-i18next";

import { conformToMask } from "react-text-mask";

import { useSwipeable } from 'react-swipeable';

import Typography from "@material-ui/core/Typography";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import MobileStepper from "@material-ui/core/MobileStepper";
import IconButton from "@material-ui/core/IconButton";

import {
  LocationIcon,
  CreditIcon,
  CashIcon,
  CloseIcon,
  ArrowIcon,
  BankIcon,
} from "./../../../assets/icons";
import { GetMobileMaskByTemp } from "../../../constants/masks";
import { DayOfWeekEnum } from "../../../models/enum";
import useStyles from "./css";
import CountryFlagIcon from "../../../assets/flags";
import WhatsAppIcon from "../../../assets/icons/WhatsAppIcon";
import PersonalizedAttentionModal from "../../common/PersonalizedAttentionModal"
import { useAppSelector } from "../../../redux/hooks";
import { MercadoPagoIcon, OxxoIcon } from "../../../assets/icons";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CancellationPoliciesBlock from "../CancellationPoliciesBlock";
import BackToEstablishmentsButton from "../BackToEstablishmentsButton";
import { AccountWorkDayModel } from "../../../models/account";


export default function AccountInfo() {
  
  const classes = useStyles();
  const { t } = useTranslation(["general"]);
  
  const account = useAppSelector((state) => state.scheduler.account);
  const isUpdate = useAppSelector((state) => state.scheduler.isUpdate);
  
  const [isCarouselOpen, setIsCarouselOpen] = useState<boolean>(false);
  const [isPersonalizedAttentionOpen, setIsPersonalizedAttentionOpen] = useState<boolean>(false);
  const [activeStep, setActiveStep] = useState<number>(0);
  const [maxSteps, setMaxSteps] = useState<number>(0);
  const [pictures, setPictures] = useState<string[]>([]);
  
  const theme = useTheme();
  const isMdMedia = useMediaQuery(theme.breakpoints.up("md"));
  
  const mobileTemplate: string = "000-000-0000";
  
  const handlerSwipe = useSwipeable({
    onSwipedLeft: () => nextImage(),
    onSwipedRight: () => previousImage(),
    onSwipedUp: () => setIsCarouselOpen(false),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true
  });
  
  const keyDownImage = (event: any) => {
    if (event.key === "ArrowLeft") {
      previousImage();
    }
    
    if (event.key === "ArrowRight") {
      nextImage();
    }
    
    if (event.key === "Escape") {
      setIsCarouselOpen(false);
    }
  }
  
  const previousImage = () => {
    setActiveStep((prevActiveStep) =>
      prevActiveStep <= 0 ? maxSteps - 1 : prevActiveStep - 1
    );
  }
  const nextImage = () => {
    setActiveStep((prevActiveStep) =>
      prevActiveStep >= maxSteps - 1 ? 0 : prevActiveStep + 1
    );
  }
  
  const openImage = (index: number) => {
    setActiveStep(index);
    setIsCarouselOpen(true);
  }
  
  useEffect(() => {
    if (account && account.pictures && account.pictures.length) {
      setPictures([...account.pictures]);
      setMaxSteps(account.pictures.length);
    }
  }, [account]);
  
  return (
    <div className={`${classes.accountInfoRoot} ${!isUpdate && account.enterpriseBusinessNameUrl ? classes.noMargin : ''}`}>
      {!isUpdate && account.enterpriseBusinessNameUrl && <BackToEstablishmentsButton/>}
      <div className={classes.accountInfoContainer}>
        <div className={classes.accountInfoLeftSideContainer}>
          <div className={classes.whatsAppContainer}>
            <WhatsAppIcon className={classes.whatsAppIconSmall}/>
            <Typography className={classes.accountTitle}>
              {t("WhatsApp")}
            </Typography>
          </div>
          <div className={classes.whatsAppNumberContainer}>
            <div className={classes.divFlag}>
              <CountryFlagIcon iso={account?.phoneCodeIso3 || "MEX"}/>
            </div>
            <div className={classes.divCode}>
              {account?.phoneCode !== undefined ? `+${account?.phoneCode}` : "+52"}
            </div>
            <div className={classes.divMobile}>
              {account?.phoneNumber ? conformToMask(account?.phoneNumber, GetMobileMaskByTemp(account.phoneTemplate || mobileTemplate)).conformedValue : ""}
            </div>
          </div>
          <div className={classes.titleContainer}>
            <LocationIcon style={{ marginRight: 5, width: 18, height: 18, color: "#5C6477" }}/>
            <Typography className={classes.accountTitle}>{t("Address")}</Typography>
          </div>
          <Typography
            className={`${classes.accountLabel} ${classes.itemWithoutImageMargin}`}
          >
            {account?.address || ""}
          </Typography>
          {account?.addressReferences &&
            <>
              <div className={classes.titleContainer} style={{ marginLeft: 23 }}>
                <Typography className={classes.accountTitle}>
                  {t("Establishment references")}
                </Typography>
              </div>
              <Typography
                className={`${classes.accountLabel} ${classes.itemWithoutImageMargin}`}
              >
                {account?.addressReferences || ""}
              </Typography>
            </>
          }
          <div className={`${classes.divider} ${classes.shortDesktopDivider}`}/>
          {account?.showCancellationPolicies && account?.cancellationPolicies &&
            <>
              <div className={classes.titleContainer} style={{ marginLeft: 23 }}>
                <Typography className={classes.accountTitle}>
                  {t("Cancellation policies")}
                </Typography>
              </div>

              <div className={`${classes.accountLabel} ${classes.itemWithoutImageMargin}`}>
                <CancellationPoliciesBlock text={account.cancellationPolicies} />
              </div>
            </>
          }
        </div>
        <div className={classes.accountInfoRightSideContainer}>
          <div style={{ marginTop: 13 }}>
            <div className={`${classes.titleContainer} ${classes.titleContainerMargin}`}
                 style={{ marginTop: !isMdMedia && !(account.showCancellationPolicies && account?.cancellationPolicies) ? -15 : 25, marginBottom: 8 }}>
              <Typography className={classes.accountTitle}>
                {t("Photographs")}
              </Typography>
            </div>
            <div className={`${classes.imagesContainer} ${classes.titleContainerMargin}`}>
              {account && account.pictures && account.pictures.length
                ? account.pictures.slice(0, 3).map((picture: string, index: number) => (
                  <div
                    className={classes.imageContainer}
                    style={{ backgroundImage: `url("${picture}")` }}
                    key={index}
                    onClick={() => openImage(index)}
                  />
                ))
                : null}
            </div>
            <div className={classes.divider}/>
          </div>
          <div className={`${classes.titleContainer} ${classes.titleContainerMargin}`}>
            <Typography className={classes.accountTitle}>
              {t("Payment methods")}
            </Typography>
          </div>
          {account?.isPaymentCash ? (
            <div className={classes.paymentContainerItem}>
              <CashIcon style={{ marginRight: 5, color: "#323947" }}/>
              <Typography className={classes.accountLabel}>{t("Cash")}</Typography>
            </div>
          ) : null}
          {account?.isPaymentCard ? (
            <div className={classes.paymentContainerItem}>
              <CreditIcon style={{ height: 12, marginRight: 6, color: "#323947" }}/>
              <Typography className={classes.accountLabel}>
                {t("Credit / debit cards")}
              </Typography>
            </div>
          ) : null}
          {account?.isPaymentMercadoPago ? (
            <div className={classes.paymentContainerItem} style={{ alignItems: "end", marginTop: 6 }}>
              <MercadoPagoIcon style={{ height: 18, width: 17, marginRight: 9, color: "#323947" }}/>
              <Typography className={classes.accountLabel}>
                {t("Mercado Pago")}
              </Typography>
            </div>
          ) : null}
          {account?.isPaymentOxxo ? (
            <div className={classes.paymentContainerItem} style={{ alignItems: "end", marginTop: 6 }}>
              <OxxoIcon style={{ height: 18, width: 17, marginRight: 9, color: "#323947" }}/>
              <Typography className={classes.accountLabel}>
                {t("Oxxo")}
              </Typography>
            </div>
          ) : null}
          {account?.isPaymentBankTransfer ? (
            <div className={classes.paymentContainerItem}>
              <BankIcon style={{ height: 14, marginRight: 9, color: "#323947" }}/>
              <Typography className={classes.accountLabel}>
                {t("Bank transfers")}
              </Typography>
            </div>
          ) : null}
          <div className={classes.divider}/>
          <div className={`${classes.titleContainer} ${classes.titleContainerMargin}`}>
            <Typography className={classes.accountTitle}>
              {t("Opening hours")}
            </Typography>
          </div>
          {account?.workDays?.map((workDay: AccountWorkDayModel, index: number) => (
            <div key={index} className={classes.workHoursItemContainer}>
              <div className={classes.dayOfWeekItemContainer}>
                <Typography className={classes.accountLabel}>
                  {t(DayOfWeekEnum[workDay.dayNumber as number])}
                </Typography>
              </div>
              <div className={classes.hoursItemContainer}>
                <Typography className={classes.accountLabel}>
                  {`${workDay.opening} - ${workDay.closing}hrs`}
                </Typography>
              </div>
            </div>
          ))}
        </div>
        <PersonalizedAttentionModal
          open={isPersonalizedAttentionOpen}
          businessNameUrl={account?.businessNameUrl || ""}
          name={account?.name || ""}
          phone={account?.whatsAppChannelNumber || ""}
          onClose={() => {
            setIsPersonalizedAttentionOpen(false)
          }}/>
      </div>
      <Modal
        {...handlerSwipe}
        open={isCarouselOpen}
        onClose={() => setIsCarouselOpen(!isCarouselOpen)}
        className={classes.modal}
        BackdropComponent={Backdrop}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        onKeyDown={keyDownImage}
      >
        <Fade in={isCarouselOpen}>
          <div className={classes.paper}>
            <div
              className={classes.imageModalContainer}
              style={{
                borderRadius: `${pictures.length > 1 ? "20px 20px 0px 0px" : "20px"}`,
                backgroundImage: `url("${pictures[activeStep]}")`,
              }}
            >
              <div style={{ display: "flex", width: "100%", justifyContent: "flex-end", }}>
                <IconButton
                  style={{
                    width: 32,
                    height: 32,
                    background: "rgba(29, 32, 39, 0.15)",
                    borderRadius: 30,
                    marginRight: 10,
                    marginTop: 10
                  }}
                  onClick={() => {
                    setIsCarouselOpen(false);
                  }}
                >
                  <CloseIcon/>
                </IconButton>
              </div>
              <div style={{ display: "flex", width: "100%", marginTop: "25%" }}>
                <div
                  style={{
                    display: `${pictures.length > 1 ? "flex" : "none"}`,
                    justifyContent: "flex-start",
                    width: "50%",
                  }}
                >
                  <IconButton
                    style={{
                      width: 32,
                      height: 32,
                      background: "rgba(29, 32, 39, 0.15)",
                      borderRadius: 30,
                      marginLeft: 10,
                    }}
                    onClick={previousImage}
                  >
                    <ArrowIcon style={{ color: "#F6F6F6" }}/>
                  </IconButton>
                </div>
                <div
                  style={{
                    display: `${pictures.length > 1 ? "flex" : "none"}`,
                    justifyContent: "flex-end",
                    width: "50%",
                  }}
                >
                  <IconButton
                    style={{
                      width: 32,
                      height: 32,
                      background: "rgba(29, 32, 39, 0.15)",
                      borderRadius: 30,
                      marginRight: 10,
                      justifyContent: "flex-end"
                    }}
                    onClick={nextImage}
                  >
                    <ArrowIcon style={{ transform: "rotate(180deg)", color: "#F6F6F6" }}/>
                  </IconButton>
                </div>
              </div>
            </div>
            <MobileStepper
              variant="dots"
              steps={maxSteps}
              position="static"
              activeStep={activeStep}
              nextButton={null}
              backButton={null}
              style={{ display: `${pictures.length > 1 ? "flex" : "none"}` }}
              classes={{ root: classes.stepper, dot: `${pictures.length > 1 ? classes.dot : classes.dotNone}` }}
            />
          </div>
        </Fade>
      </Modal>
    </div>
  );
}