import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const ExclamationIcon = (props: any) => {
    return (
        <SvgIcon {...props}>
            <path
                d="M5.5 13.5C5.5 12.125 4.375 11 3 11C1.59375 11 0.5 12.125 0.5 13.5C0.5 14.9062 1.59375 16 3 16C4.375 16 5.5 14.9062 5.5 13.5ZM0.78125 0.8125L1.1875 9.3125C1.21875 9.6875 1.5625 10 1.9375 10H4.03125C4.40625 10 4.75 9.6875 4.78125 9.3125L5.1875 0.8125C5.21875 0.375 4.875 0 4.4375 0H1.53125C1.09375 0 0.75 0.375 0.78125 0.8125Z"
                fill="#FFC626"
            />
        </SvgIcon>
    )
};

export default ExclamationIcon;
