import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const ArrowLineDown = (props: any) => (
    <SvgIcon {...props}>
        <path
            d="M7.06135 7.08109L12.7658 1.38671C12.9159 1.23278 13 1.02605 13 0.810756C13 0.595466 12.9159 0.388729 12.7658 0.234802C12.6124 0.08463 12.4064 0.000568337 12.192 0.000568337C11.9776 0.000568337 11.7717 0.08463 11.6182 0.234802L6.49972 5.36486L1.38239 0.234802C1.22888 0.0842813 1.0227 0 0.807994 0C0.593286 0 0.38711 0.0842813 0.2336 0.234802C0.083834 0.388676 0 0.595165 0 0.810175C0 1.02518 0.083834 1.23167 0.2336 1.38555L5.91952 7.08109C6.07271 7.22953 6.27741 7.3125 6.49043 7.3125C6.70346 7.3125 6.90816 7.22953 7.06135 7.08109Z"
        />
    </SvgIcon>
);

export default ArrowLineDown;
