import { createSlice } from "@reduxjs/toolkit";
import { AlertProps } from "./../components/common/notifications/AlertProvider";

interface AlertState {
  alerts: AlertProps[];
}

const defaultState: AlertState = {
  alerts: [],
};

const newGuid = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

const alertsSlice = createSlice({
  name: "alerts",
  initialState: defaultState,
  reducers: {
    enqueueAlert: (state, action) => {
      const key = action.payload.key ? action.payload.key : newGuid();
      state.alerts = [
        ...state.alerts,
        {
          ...action.payload,
          key: key,
        },
      ];
    },
    closeAlert: (state, action) => {
      state.alerts = state.alerts.map((alert) =>
        alert.key === action.payload
          ? { ...alert, dismissed: true }
          : { ...alert }
      );
    },
    removeAlert: (state, action) => {
      state.alerts = state.alerts.filter(
        (alert) => alert.key !== action.payload
      );
    },
  },
});

export const alertsActions = alertsSlice.actions;
export default alertsSlice.reducer;
