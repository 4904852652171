import { makeStyles } from "@material-ui/core/styles";

const useStyles = (isMobile: boolean) => makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    
    backgroundColor: theme.colors.backgroundGrey1
  },
  content: {
  
    width: isMobile ? 304 : 716,
  },
  buttonsBlock: {
    position: "fixed",
    bottom: 0,
    zIndex: 3,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: 10,
    width: isMobile ? 304 : 716,
    height: 64,
    
    marginTop: isMobile ? 6 : 0,
    
    backgroundColor: theme.colors.white,
    
    borderRadius: 10,
  },
  continueButton: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    
    width: 288,
    height: 32,
    
    backgroundColor: theme.colors.clearGreen,
    
    borderRadius: 51,
    
    ...theme.typography.buttons,
    color: theme.colors.grey1,
    fontWeight: 700,
    
    "&:disabled": {
      opacity: 0.3,
      color: theme.colors.grey1,
    },
    "&:hover": {
      backgroundColor: theme.colors.clearGreen + "!important",
      color: theme.colors.grey1 + "!important",
    },
    
  },
  goBackButton: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    
    width: 195,
    height: 32,
    
    backgroundColor: theme.colors.white,
    
    borderRadius: 51,
    
    ...theme.typography.buttons,
    color: "#ACB7C0",
    fontWeight: 700,
    
    "&:disabled": {
      opacity: 0.3,
      color: theme.colors.grey3,
    },
    "&:hover": {
      backgroundColor: theme.colors.white + "!important",
      color: theme.colors.grey4 + "!important",
    }, 
  },
  marginForFixedButtonsBlock: {
    height: 64
  }
}));

export default useStyles;
