import { configureStore } from "@reduxjs/toolkit";
import schedulerReducer from "./scheduler-slice";
import alertsReducer from "./alerts-slice";

const store = configureStore({
  reducer: {
    scheduler: schedulerReducer,
    alerts: alertsReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
