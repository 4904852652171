import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  notification: {
    background: "#FFFFFF",
    boxShadow: "0px 4px 4px rgba(0,0,0,0.1)",
    borderRadius: "10px",
    width: "282px",
    minHeight: "74px",
    display: "flex",
    alignItems: "center"
  },
  notificationLink: {
    background: "#FFFFFF",
    boxShadow: "0px 4px 4px rgba(0,0,0,0.1)",
    borderRadius: "10px",
    width: "282px",
    // minHeight: "96px",
    display: "flex"
  },
  notificationType: {
    alignSelf: "flex-start",
    borderRadius: "10px 0px 0px 10px",
    minHeight: "74px",
    height: "100%",
    width: 5,
  },
  notificationSuccess: {
    backgroundColor: "#36CE91",
  },
  notificationError: {
    backgroundColor: "#F15857",
  },
  notificationWarning: {
    backgroundColor: "#FFC626",
  },
  notificationBody: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    height: "100%",
    width: "100%",
  },
  notificationIconContainer: {
    width: 56,
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  notificationIcon: {
    width: 28,
    height: 28,
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  notificationContent: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    height: "100%",
  },
  notificationText: {
    height: "100%",
    width: "100%",
    padding: "16px 0px",
  },
  notificationTitle: {
    ...theme.typography.txtBody2,
    color: theme.colors.grey5,
    fontWeight: "bold"
  },
  notificationDescription: {
    ...theme.typography.txtBody2,
    color: theme.colors.grey5,
    whiteSpace: "pre-wrap",
    wordBreak: "break-word"
  },
  notificationClose: {
    padding: "8px 8px 0 8px",
    height: "100%",
    color: theme.colors.grey4,
    display: "flex",
    alignSelf: "flex-start"
  },
  closeIconButton: {
    alignSelf: "flex-start",
  },
  notificationCloseIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  link: {
    ...theme.typography.txtBody2,
    color: theme.colors.clearMain,
    "&:hover": {
      color: theme.colors.clearMain,
    },
  },
}));

export default useStyles;
