import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const CountryBRAIcon = (props: any) => {
    return (
        <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props} >
            <path d="M23.0625 10.05C22.1625 4.7625 17.55 0.75 12 0.75C6.45 0.75 1.8375 4.7625 0.9375 10.05L12 4.5L23.0625 10.05Z" fill="#16A260"/>
            <path d="M0.9375 13.9502C1.8375 19.2377 6.45 23.2502 12 23.2502C17.55 23.2502 22.1625 19.2377 23.0625 13.9502L12 19.5002L0.9375 13.9502Z" fill="#16A260"/>
            <path d="M12 4.5L0.9375 10.05C0.825 10.6875 0.75 11.325 0.75 12C0.75 12.675 0.825 13.3125 0.9375 13.95L12 19.5L23.0625 13.95C23.175 13.3125 23.25 12.675 23.25 12C23.25 11.325 23.175 10.6875 23.0625 10.05L12 4.5Z" fill="#FFE62E"/>
            <path d="M9.75 10.6499C8.55 10.6499 7.425 10.9124 6.4125 11.3624C6.375 11.5874 6.375 11.7749 6.375 11.9999C6.375 15.1124 8.8875 17.6249 12 17.6249C14.1 17.6249 15.9375 16.4624 16.9125 14.7749C15.525 12.3374 12.825 10.6499 9.75 10.6499Z" fill="#0D5FDB"/>
            <path d="M17.55 12.9C17.5875 12.6 17.625 12.3 17.625 12C17.625 8.8875 15.1125 6.375 12 6.375C9.7875 6.375 7.875 7.65 6.9375 9.525C7.8375 9.2625 8.775 9.1125 9.75 9.1125C12.9375 9.1125 15.75 10.6125 17.55 12.9Z" fill="#0D5FDB"/>
            <path d="M9.7501 9.1123C8.7751 9.1123 7.8376 9.2623 6.9376 9.5248C6.6751 10.0873 6.4876 10.6873 6.4126 11.3623C7.4251 10.9123 8.5501 10.6498 9.7501 10.6498C12.8251 10.6498 15.5251 12.2998 16.9126 14.7373C17.2501 14.1748 17.4376 13.5373 17.5501 12.8998C15.7501 10.6123 12.9376 9.1123 9.7501 9.1123Z" fill="white"/>
            <path d="M8.25 12.375C8.45711 12.375 8.625 12.2071 8.625 12C8.625 11.7929 8.45711 11.625 8.25 11.625C8.04289 11.625 7.875 11.7929 7.875 12C7.875 12.2071 8.04289 12.375 8.25 12.375Z" fill="white"/>
            <path d="M9.75 14.625C9.95711 14.625 10.125 14.4571 10.125 14.25C10.125 14.0429 9.95711 13.875 9.75 13.875C9.54289 13.875 9.375 14.0429 9.375 14.25C9.375 14.4571 9.54289 14.625 9.75 14.625Z" fill="white"/>
            <path d="M12 14.625C12.2071 14.625 12.375 14.4571 12.375 14.25C12.375 14.0429 12.2071 13.875 12 13.875C11.7929 13.875 11.625 14.0429 11.625 14.25C11.625 14.4571 11.7929 14.625 12 14.625Z" fill="white"/>
            <path d="M12 16.125C12.2071 16.125 12.375 15.9571 12.375 15.75C12.375 15.5429 12.2071 15.375 12 15.375C11.7929 15.375 11.625 15.5429 11.625 15.75C11.625 15.9571 11.7929 16.125 12 16.125Z" fill="white"/>
            <path d="M15 14.625C15.2071 14.625 15.375 14.4571 15.375 14.25C15.375 14.0429 15.2071 13.875 15 13.875C14.7929 13.875 14.625 14.0429 14.625 14.25C14.625 14.4571 14.7929 14.625 15 14.625Z" fill="white"/>
            <path d="M15 16.125C15.2071 16.125 15.375 15.9571 15.375 15.75C15.375 15.5429 15.2071 15.375 15 15.375C14.7929 15.375 14.625 15.5429 14.625 15.75C14.625 15.9571 14.7929 16.125 15 16.125Z" fill="white"/>
            <path d="M13.5 15.375C13.7071 15.375 13.875 15.2071 13.875 15C13.875 14.7929 13.7071 14.625 13.5 14.625C13.2929 14.625 13.125 14.7929 13.125 15C13.125 15.2071 13.2929 15.375 13.5 15.375Z" fill="white"/>
            <path d="M8.25 13.875C8.45711 13.875 8.625 13.7071 8.625 13.5C8.625 13.2929 8.45711 13.125 8.25 13.125C8.04289 13.125 7.875 13.2929 7.875 13.5C7.875 13.7071 8.04289 13.875 8.25 13.875Z" fill="white"/>
        </SvgIcon>
    )
};

export default CountryBRAIcon;
