import React from "react";

import SvgIcon from "@material-ui/core/SvgIcon";


export default function CountrySVKIcon(props: any) {
    return (
        <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props} >
          <path d="M9.51953 11.0856L9.82028 11.3931L9.94928 11.0418L9.99241 10.822C9.94928 10.207 9.99241 9.592 9.99241 9.592L10.0787 9.68012L9.73441 9.32875L9.51953 9.10938V11.0856Z" fill="#034EA2"/>
          <path d="M9.99219 10.8159L10.2929 9.89344L9.99219 9.58594V10.8159Z" fill="#ED1C24"/>
          <path d="M9.52003 9.10928L9.73491 9.32865L8.70328 8.2749C8.66016 8.23065 8.66016 9.19703 8.66016 10.2512L9.52003 11.1294V9.10928Z" fill="#FFDD00"/>
          <path d="M9.8201 14.8142C9.0896 14.3751 9.00335 13.0135 9.00335 12.7937V10.4222L9.2186 10.642L8.1866 9.58786C8.10073 9.50011 8.1866 12.4424 8.1866 13.7162C8.1866 14.3312 8.5736 16.483 10.7231 15.385C10.7231 15.385 11.7979 14.9897 12.1417 14.5067H11.9269C11.8837 14.5506 11.8406 14.5506 11.7979 14.5506C10.551 15.4289 9.8201 14.8142 9.8201 14.8142Z" fill="#FFDD00"/>
          <path d="M9.00391 12.7964C9.00391 12.9722 9.09016 14.3339 9.77791 14.7726C9.77791 14.7726 10.5088 15.3876 11.7984 14.5533C11.4114 14.7291 11.1107 14.7291 10.8954 14.6849C10.2935 14.5094 9.64891 13.8066 9.56303 13.06C9.47678 12.3138 9.47678 10.9079 9.47678 10.9079L9.56303 10.996L9.21916 10.6446L9.00391 10.4688V12.7964Z" fill="#034EA2"/>
          <path d="M9.90653 11.8726V11.3015C9.77753 11.1703 9.94928 11.3458 9.60541 10.994L9.51953 10.9062V10.9505C9.51953 11.126 9.51953 12.3995 9.60578 13.0584C9.69166 13.805 10.3363 14.5077 10.9382 14.6832C11.153 14.7275 11.4542 14.7275 11.8408 14.552C11.8839 14.552 11.8839 14.5078 11.927 14.5078C10.766 14.3761 10.0355 13.322 9.90653 11.8726Z" fill="#ED1C24"/>
          <path d="M14.2042 14.8559C14.8923 14.4168 15.0213 13.0552 14.9782 12.8797V10.5078L14.7633 10.7276L15.795 9.67381C15.8812 9.58569 15.795 12.5283 15.795 13.8018C15.795 14.4168 15.408 16.5686 13.2585 15.4706C13.2585 15.4706 12.1837 15.0757 11.8398 14.5923H12.0551C12.0978 14.5923 12.141 14.6362 12.141 14.6362C13.4737 15.4709 14.2042 14.8559 14.2042 14.8559Z" fill="#FFDD00"/>
          <path d="M14.9781 12.8406C14.9781 13.0161 14.8918 14.3777 14.2041 14.8169C14.2041 14.8169 13.4736 15.4319 12.1836 14.5971C12.5706 14.773 12.8717 14.773 13.0866 14.7291C13.6885 14.5532 14.3331 13.8505 14.4193 13.1039C14.5052 12.3576 14.5052 10.9521 14.5052 10.9521L14.419 11.0399L14.7632 10.6885L14.9781 10.4688V12.8406Z" fill="#034EA2"/>
          <path d="M14.1185 11.9191V11.3484L14.4193 11.0409L14.5052 10.9531V10.997C14.5052 11.1725 14.5052 12.446 14.4189 13.1049C14.333 13.8515 13.7312 14.5542 13.0865 14.7736C12.8717 14.8179 12.5705 14.8179 12.1835 14.6424C12.1408 14.6424 12.1408 14.5981 12.0977 14.5981C13.2152 14.4226 13.9892 13.3685 14.1182 11.9191" fill="#ED1C24"/>
          <path d="M9.86328 11.6266C9.86328 13.1641 10.8383 14.4016 12.0008 14.4016C13.1633 14.4016 14.1383 13.1641 14.1383 11.6266C14.1383 10.0891 13.1633 8.85156 12.0008 8.85156C10.8383 8.85156 9.86328 10.1266 9.86328 11.6266Z" fill="#8CDDEA"/>
          <path d="M9.86328 11.7344C9.86328 13.2719 10.8383 14.5094 12.0008 14.5094C13.2008 14.5094 14.1383 13.2719 14.1383 11.7344H9.86328Z" fill="#6DD337"/>
          <path d="M9.9375 11.8152L13.9875 11.8902L14.025 11.5902C14.025 11.5527 14.1 11.5152 13.9875 11.5152C13.5375 11.4777 13.725 11.4402 13.4625 11.4402C13.3875 11.4402 11.6625 11.0277 11.5125 11.0277C11.3625 10.9902 10.0125 11.2152 10.0125 11.2152L9.9375 11.8152Z" fill="#557176"/>
          <path d="M9.89844 11.0983L10.5359 11.3608C10.5359 11.3608 11.3609 11.2108 11.4359 11.2483C11.5109 11.2858 12.2234 11.3983 12.2609 11.3983H13.3484H13.3109C13.2359 11.3608 12.4109 11.1358 12.3359 11.0983C12.2984 11.0608 11.6609 10.8358 11.6234 10.7608C11.5484 10.6858 11.0984 10.4233 11.0234 10.4233C10.9484 10.3858 9.89844 11.0983 9.89844 11.0983Z" fill="white"/>
          <path d="M12.8992 8.92656C12.8992 8.92656 12.4867 8.66406 11.9617 8.66406C11.5492 8.66406 11.1367 8.88906 11.1367 8.88906C10.6867 9.15156 10.3117 9.63906 10.0867 10.2391C9.93672 10.6516 9.82422 11.1391 9.82422 11.6266C9.82422 13.0891 10.6117 14.2141 11.6242 14.5141C11.6242 14.5141 11.7742 14.5891 11.9617 14.5891C12.1492 14.5891 12.3367 14.5516 12.3367 14.5516C13.3492 14.2891 14.1742 13.1266 14.1742 11.6641C14.1742 9.75156 12.7117 8.77656 12.8992 8.92656ZM12.3742 14.3641C12.3742 14.3641 12.1867 14.4016 11.9992 14.4016C11.8117 14.4016 11.6242 14.3641 11.6242 14.3641C10.7242 14.1016 10.0117 13.0141 10.0117 11.7016C10.0117 11.2516 10.1242 10.7641 10.2742 10.3891C10.4992 9.86406 10.8367 9.41406 11.2492 9.18906C11.2492 9.18906 11.6617 8.92656 11.9992 8.92656C12.4867 8.92656 12.8617 9.18906 12.8617 9.18906C13.5367 9.60156 14.0242 10.5766 14.0242 11.6641C13.9867 13.0141 13.2742 14.1391 12.3742 14.3641Z" fill="#B1BABF"/>
          <path d="M10.7242 11.7L11.3617 12.5625L10.3867 13.125C10.3867 13.125 10.4617 13.35 10.6492 13.6125C10.8367 13.8375 11.2867 14.325 11.6992 14.3625C12.1492 14.4 12.4492 14.4 12.7117 14.25C12.9367 14.1 13.2742 13.9125 13.4992 13.5375C13.7242 13.2 13.7242 13.125 13.7242 13.125C13.7242 13.125 12.2992 12.825 12.2617 12.825C12.2242 12.7875 10.8742 11.7375 10.8367 11.7C10.7992 11.6625 11.1367 11.25 11.1367 11.25L10.7242 11.7Z" fill="#034EA2"/>
          <path d="M11.6641 14.9219H12.3766V16.7594H11.6641V14.9219Z" fill="#ED1C24"/>
          <path d="M11.1016 15.0781H11.8141V16.9156H11.1016V15.0781Z" fill="#034EA2"/>
          <path d="M12.189 15.0781H12.9015V16.9156H12.189V15.0781Z" fill="#034EA2"/>
          <path d="M10.9492 15.0781H11.3992V17.4031H10.9492V15.0781Z" fill="#FFDD00"/>
          <path d="M12.5991 15.0781H13.0491V17.4031H12.5991V15.0781Z" fill="#FFDD00"/>
          <path d="M10.3125 14.2891H13.6875V15.1891H10.3125V14.2891Z" fill="white"/>
          <path d="M12.25 23.4981C18.4632 23.4981 23.5 18.4617 23.5 12.2491C23.5 6.03637 18.4632 1 12.25 1C6.0368 1 1 6.03637 1 12.2491C1 18.4617 6.0368 23.4981 12.25 23.4981Z" fill="#F0F0F0"/>
          <path d="M12.25 23.5C18.4632 23.5 23.5 18.4636 23.5 12.251C23.5 10.875 23.2525 9.55685 22.8003 8.33826H1.69974C1.24754 9.55685 1 10.875 1 12.251C1 18.4636 6.03684 23.5 12.25 23.5Z" fill="#0052B4"/>
          <path d="M12.2495 23.4963C17.0866 23.4963 21.2102 20.4436 22.7997 16.1599H1.69922C3.28876 20.4436 7.41237 23.4963 12.2495 23.4963Z" fill="#D80027"/>
          <path d="M3.90625 7.35693V12.9685C3.90625 16.1606 8.0767 17.1387 8.0767 17.1387C8.0767 17.1387 12.2471 16.1606 12.2471 12.9685V7.35693H3.90625Z" fill="#F0F0F0"/>
          <path d="M4.89062 7.35693V12.9685C4.89062 13.3433 4.97386 13.6972 5.13874 14.029H11.0268C11.1917 13.6972 11.275 13.3433 11.275 12.9685V7.35693H4.89062Z" fill="#D80027"/>
          <path d="M10.038 11.2728H8.57069V10.2946H9.54896V9.3164H8.57069V8.33826H7.59242V9.3164H6.6142V10.2946H7.59242V11.2728H6.125V12.251H7.59242V13.2291H8.57069V12.251H10.038V11.2728Z" fill="#F0F0F0"/>
          <path d="M6.46712 15.3973C7.09752 15.7934 7.74519 16.0234 8.07675 16.1257C8.40832 16.0235 9.05599 15.7934 9.68638 15.3973C10.3221 14.9978 10.7677 14.5409 11.0208 14.0318C10.7417 13.8344 10.4012 13.7179 10.0333 13.7179C9.89934 13.7179 9.76922 13.7338 9.64411 13.7629C9.37898 13.1606 8.77715 12.7397 8.0768 12.7397C7.37644 12.7397 6.77457 13.1606 6.50949 13.7629C6.38437 13.7338 6.25421 13.7179 6.12031 13.7179C5.75244 13.7179 5.41191 13.8344 5.13281 14.0318C5.38572 14.5408 5.83137 14.9977 6.46712 15.3973Z" fill="#0052B4"/>
          <path d="M12.0008 7.8375C12.2493 7.8375 12.4508 7.63603 12.4508 7.3875C12.4508 7.13897 12.2493 6.9375 12.0008 6.9375C11.7523 6.9375 11.5508 7.13897 11.5508 7.3875C11.5508 7.63603 11.7523 7.8375 12.0008 7.8375Z" fill="#F9F9F9"/>
        </SvgIcon>
    )
}