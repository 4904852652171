import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const CountryPRYIcon = (props: any) => {
    return (
        <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props} >
            <path d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z" fill="#F0F0F0"/>
            <path d="M12 0C7.24242 0 3.13167 2.76867 1.19067 6.78263H22.8092C20.8682 2.76867 16.7574 0 12 0Z" fill="#0052B4"/>
            <path d="M12 23.9999C16.7574 23.9999 20.8682 21.2312 22.8092 17.2173H1.19067C3.13167 21.2312 7.24242 23.9999 12 23.9999Z" fill="#0052B4"/>
            <path d="M9.59009 12.5215L11.9999 8.34766L14.4098 12.5215H9.59009Z" fill="#FFDA44"/>
            <path d="M15.1305 13.8999L12.0001 15.4652L8.86963 13.8999V11.813H15.1305V13.8999Z" fill="#6DA544"/>
            <path d="M14.9514 8.52686L13.8446 9.63362C14.3167 10.1057 14.6088 10.7579 14.6088 11.4782C14.6088 12.919 13.4408 14.0869 12.0001 14.0869C10.5594 14.0869 9.39138 12.9189 9.39138 11.4782C9.39138 10.7579 9.68341 10.1057 10.1555 9.63362L9.04872 8.52686C8.29333 9.28211 7.82617 10.3256 7.82617 11.4782C7.82617 13.7835 9.69489 15.6521 12.0001 15.6521C14.3052 15.6521 16.174 13.7834 16.174 11.4782C16.1739 10.3256 15.7068 9.28211 14.9514 8.52686Z" fill="#FFDA44"/>
        </SvgIcon>
    )
};

export default CountryPRYIcon;
