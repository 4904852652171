import { UserModel } from "../user";
import { ServiceModel } from "./service-model";

export class ServiceUserModel {
  
  service: ServiceModel | null;   // service selected
  users: UserModel[] | null;      // list of user to select
  
  serviceId: string | null;
  userId: string | null;
  index: number;
  
  constructor(x?: any) {
    if (typeof x === "object" && x !== null && x) {
      this.service = x.service;
      this.users = x.users;
      this.serviceId = x.serviceId;
      this.userId = x.userId;
      this.index = x.index;
    } else {
      this.service = null;
      this.users = null;
      this.serviceId = null;
      this.userId = null;
      this.index = 0;
    }
  }
  
}

