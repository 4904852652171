import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const CountryVENIcon = (props: any) => {
    return (
        <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props} >
            <path d="M12 0.75C7.08745 0.75 2.92495 3.9 1.38745 8.25H22.6124C21.0749 3.9 16.9124 0.75 12 0.75Z" fill="#FFE62E"/>
            <path d="M12 23.25C16.9124 23.25 21.0749 20.1375 22.6124 15.75H1.38745C2.92495 20.1375 7.08745 23.25 12 23.25Z" fill="#ED4C5C"/>
            <path d="M1.3875 8.25C0.975 9.4125 0.75 10.6875 0.75 12C0.75 13.3125 0.975 14.5875 1.3875 15.75H22.6125C23.025 14.5875 23.25 13.3125 23.25 12C23.25 10.6875 23.025 9.4125 22.6125 8.25H1.3875Z" fill="#2A5F9E"/>
            <path d="M12.9749 10.1624L13.3499 10.5749L13.2749 10.0124L13.7624 9.7874L13.2374 9.6749L13.1624 9.1499L12.8999 9.6374L12.3374 9.5249L12.7124 9.9374L12.4499 10.4249L12.9749 10.1624Z" fill="white"/>
            <path d="M10.9499 10.1999L11.4374 10.4249L11.1749 9.9374L11.5499 9.5249L10.9874 9.6374L10.7249 9.1499L10.6499 9.7124L10.0874 9.7874L10.6124 10.0499L10.5749 10.5749L10.9499 10.1999Z" fill="white"/>
            <path d="M9.07495 10.8751L9.63745 10.9501L9.18745 10.5751L9.37495 10.0876L8.88745 10.3501L8.43745 9.9751L8.62495 10.5376L8.13745 10.8001L8.69995 10.8376L8.84995 11.4001L9.07495 10.8751Z" fill="white"/>
            <path d="M7.57505 12.1876L8.13755 12.0376L7.57505 11.8501V11.2876L7.23755 11.7376L6.67505 11.5501L7.01255 12.0001L6.71255 12.4126L7.23755 12.2626L7.61255 12.7126L7.57505 12.1876Z" fill="white"/>
            <path d="M6.7125 13.8748L7.1625 13.5373L6.5625 13.5748L6.375 13.0498L6.225 13.5748H5.625L6.1125 13.8748L5.9625 14.3998L6.4125 14.0623L6.9375 14.3623L6.7125 13.8748Z" fill="white"/>
            <path d="M17.2874 13.7997L17.0999 14.3247L17.5874 13.9872L18.0374 14.3247L17.8874 13.7997L18.3749 13.4997H17.7749L17.6249 13.0122L17.4374 13.4997H16.8374L17.2874 13.7997Z" fill="white"/>
            <path d="M16.35 12.1125L16.3875 12.6375L16.725 12.225L17.25 12.3375L16.9124 11.925L17.25 11.475L16.725 11.6625L16.35 11.25V11.775L15.825 11.9625L16.35 12.1125Z" fill="white"/>
            <path d="M14.8501 10.8375L15.0751 11.3625L15.2251 10.8L15.7876 10.7625L15.3001 10.5L15.4501 9.9375L15.0001 10.3125L14.5126 10.05L14.7376 10.5375L14.2876 10.9125L14.8501 10.8375Z" fill="white"/>
        </SvgIcon>
    )
};

export default CountryVENIcon;
