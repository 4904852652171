import { makeStyles } from "@material-ui/core/styles";

const useStyles = (width?: number | string) =>
  makeStyles((theme) => ({
    input: {
      backgroundColor: theme.colors.grey1,
      height: 40,
      padding: "13px 0px 13px 12px",
      border: "solid 1px rgba(0, 0, 0, 0.1)",
      borderRadius: 5,
      width: width || 370,
    },
    inputText: {
      ...theme.typography.txtBody2,
      fontWeight: "bold",
      color: theme.colors.grey5,
      "&::placeholder": {
        fontWeight: "normal",
        color: "#676767",
      },
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    },
    inputFocused: {
      border: "solid 1px #6462F3",
      padding: "14px 0px 14px 12px",
      width: width || 370,
    },
    inputDisabled: {
      color: "#676767",
    },
    inputError: {
      color: theme.colors.clearRed,
      border: `solid 1px ${theme.colors.clearRed}`,
    },
  }));

export default useStyles;
