import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Typography from "@material-ui/core/Typography";


import ExclamationModal from "../../common/ExclamationModal";
import { AppointmentScheduleBusyModalProps } from "./props";
import useStyles from "./css";
import { AppointmentDetailDtoModel } from "../../../models/appointment";
import { useAppSelector } from "../../../redux/hooks";
import { ServiceUserModel } from "../../../models/service/service-user.model";

const AppointmentScheduleBusyModal = (props: AppointmentScheduleBusyModalProps) => {

    const { t } = useTranslation(["general"]);
    const localeApp = useAppSelector(state => state.scheduler.locale);
    const { open, appointment, onClose } = props;
    const classes = useStyles();
    
    const [appointmentSchedule, setAppointmentSchedule] = useState<AppointmentDetailDtoModel>()

    const serviceUser = useAppSelector((state) => state.scheduler.serviceUser);
    const isServicesPerformedSimultaneously = useAppSelector((state) => state.scheduler.isServicesPerformedSimultaneously);
    const appointmentDuration = countAppointmentDuration(serviceUser);

    useEffect(() => {
        if (appointment.startDate && appointmentDuration) {

            const endDate = new Date(new Date(appointment.startDate).getTime() + (appointmentDuration || 0) * 60000);

            setAppointmentSchedule({
                ...appointment,
                endDate: endDate
            });

        }

    }, [appointment]);

    function countAppointmentDuration(services: ServiceUserModel[]) {
        if (isServicesPerformedSimultaneously) {
            return Math.max(...services.map(service => service?.service?.duration || 0))
        }
        return services.reduce((previousValue, currentValue) => previousValue + (currentValue?.service?.duration ? currentValue.service.duration : 0), 0);
    }


    const date = (startDate: Date, endDate: Date): string => {

        const start = new Date(startDate);
        const end = new Date(endDate);

        let weekDay = start.toLocaleString(localeApp, {
        weekday: "long",
        });

        weekDay = `${weekDay.charAt(0).toUpperCase()}${weekDay.slice(1)}`;

        const monthDay = start.toLocaleString(localeApp, {
        day: "2-digit",
        month: "long",
        });

        const startTime = start.toLocaleString(localeApp, {
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
        });

        const endTime = end.toLocaleString(localeApp, {
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
        });

        return `${weekDay}, ${monthDay} \u00B7 ${startTime} - ${endTime}hrs`;
    };


    const timeDifference = (today: Date, endDate: Date): string => {
        const hours = parseInt(
        `${
            (Math.abs(endDate.getTime() - today.getTime()) / (1000 * 60 * 60)) % 24
        }`
        );
        const minutes = parseInt(
        `${(Math.abs(endDate.getTime() - today.getTime()) / (1000 * 60)) % 60}`
        );
        if (hours > 0 && minutes < 1) {
        return `${hours}hr`;
        } else if (hours < 1 && minutes > 0) {
        return `${minutes}min`;
        } else {
        return `${hours}hr ${minutes}min`;
        }
    };


    const BusyScheduleInfo = () => <>
      <div>
        <Typography className={classes.normalText}>
            {t("The schedule:")}
        </Typography>
      </div>
      
      <div className={classes.modalDateText}>
        <Typography className={classes.scheduleText}>
          {appointmentSchedule && `${date(
            new Date(appointmentSchedule.startDate!),
            new Date(appointmentSchedule.endDate!)
          )} (${timeDifference(
            new Date(appointmentSchedule.startDate!),
            new Date(appointmentSchedule.endDate!)
          )})`}
        </Typography>
      </div>
  
      <div>
        <Typography className={classes.normalText}>
            {t("No longer available. Please, select another.")}
        </Typography>
      </div>
    </>


    return (<>
    
        <ExclamationModal
            open={open}
            title={t("Busy schedule")}
            body={<BusyScheduleInfo />}
            okTextButton={t("OkUnderstand")}
            height={330}
            onClose={onClose}
        />

    </>)
};

export default AppointmentScheduleBusyModal;
