import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import { Url } from "./../../../constants/url";
import CanceledConfirmation from "../CanceledConfirmation";

const CancelConfirmationRouter = () => {
  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <Switch>
        <Route
          exact
          path={Url.CanceledConfirmation.Main}
          component={CanceledConfirmation} />
      </Switch>
    </>
  );
};

export default CancelConfirmationRouter;
