import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const CountryHNDIcon = (props: any) => {
    return (
        <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props} >
            <path d="M12 23.25C16.9124 23.25 21.0749 20.1375 22.6124 15.75H1.38745C2.92495 20.1375 7.08745 23.25 12 23.25Z" fill="#42ADE2"/>
            <path d="M12 0.75C7.08745 0.75 2.92495 3.8625 1.38745 8.25H22.6124C21.0749 3.8625 16.9124 0.75 12 0.75Z" fill="#42ADE2"/>
            <path d="M22.6125 15.75C23.025 14.5875 23.25 13.3125 23.25 12C23.25 10.6875 23.025 9.4125 22.6125 8.25H1.3875C0.975 9.4125 0.75 10.6875 0.75 12C0.75 13.3125 0.975 14.5875 1.3875 15.75H22.6125Z" fill="#F9F9F9"/>
            <path d="M12 12.6L12.7125 13.125L12.45 12.2625L13.125 11.7375H12.2625L12 10.875L11.7375 11.7375H10.875L11.55 12.2625L11.2875 13.125L12 12.6Z" fill="#42ADE2"/>
            <path d="M8.25 10.725L8.9625 11.25L8.7 10.3875L9.375 9.8625H8.5125L8.25 9L7.9875 9.8625H7.125L7.8 10.3875L7.5375 11.25L8.25 10.725Z" fill="#42ADE2"/>
            <path d="M15.75 10.725L16.4625 11.25L16.2 10.3875L16.875 9.8625H16.0125L15.75 9L15.4875 9.8625H14.625L15.3 10.3875L15.0375 11.25L15.75 10.725Z" fill="#42ADE2"/>
            <path d="M8.25 14.475L8.9625 15L8.7 14.1375L9.375 13.6125H8.5125L8.25 12.75L7.9875 13.6125H7.125L7.8 14.1375L7.5375 15L8.25 14.475Z" fill="#42ADE2"/>
            <path d="M15.75 14.475L16.4625 15L16.2 14.1375L16.875 13.6125H16.0125L15.75 12.75L15.4875 13.6125H14.625L15.3 14.1375L15.0375 15L15.75 14.475Z" fill="#42ADE2"/>
        </SvgIcon>
    )
};

export default CountryHNDIcon;
