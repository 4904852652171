import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const CountryPRIIcon = (props: any) => {
    return (
        <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props} >
            <g clip-path="url(#clip0_27973_194971)">
                <rect x="0.554688" y="1" width="31.5" height="23.5" rx="1.75" fill="white" stroke="#F5F5F5" stroke-width="0.5" />
                <mask id="mask0_27973_194971" style={{maskType: "luminance"}} maskUnits="userSpaceOnUse" x="0" y="0" width="33" height="25">
                    <rect x="0.554688" y="1" width="31.5" height="23.5" rx="1.75" fill="white" stroke="white" stroke-width="0.5" />
                </mask>
                <g mask="url(#mask0_27973_194971)">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.304688 0.75H32.3047V5.55H0.304688V0.75ZM0.304688 10.35H32.3047V15.15H0.304688V10.35ZM0.304688 19.95V24.75H32.3047V19.95H0.304688Z" fill="#ED4C5C" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.304688 0.75L15.5428 12.75L0.304688 24.75V0.75Z" fill="#2A5F9E" />
                    <g filter="url(#filter0_d_27973_194971)">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M5.63765 14.2789L3.39847 15.9861L4.25282 13.2225L2.01458 11.5139L4.78178 11.5131L5.63765 8.75L6.49352 11.5131L9.26072 11.5139L7.02247 13.2225L7.87683 15.9861L5.63765 14.2789Z" fill="url(#paint0_linear_27973_194971)" />
                    </g>
                </g>
            </g>
            <defs>
                <filter id="filter0_d_27973_194971" x="2.01562" y="8.75" width="7.24219" height="8.23438" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="1" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_27973_194971" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_27973_194971" result="shape" />
                </filter>
                <linearGradient id="paint0_linear_27973_194971" x1="1.82812" y1="8.75" x2="1.82812" y2="16.75" gradientUnits="userSpaceOnUse">
                    <stop stop-color="white" />
                    <stop offset="1" stop-color="#F0F0F0" />
                </linearGradient>
                <clipPath id="clip0_27973_194971">
                    <rect x="0.304688" y="0.75" width="24" height="24" rx="12" fill="white" />
                </clipPath>
            </defs>
        </SvgIcon>
    )
};

export default CountryPRIIcon;