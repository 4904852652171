import React, { useState } from 'react';


export default function useInputFirstTouch() {
  
  const [hasBeenTouched, setHasBeenTouched] = useState<boolean>(false)
  
  function handleFirstTouch() {
    if (hasBeenTouched) return;
    setHasBeenTouched(true);
  }
  
  return { hasBeenTouched, handleFirstTouch }
}