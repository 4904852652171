import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import backgroundTop from "../../../assets/images/background-top.png";
import backgroundBottom from "../../../assets/images/background-bottom.png";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rightTitle: {
      ...theme.typography.txtBody1,
      fontWeight: "bold",
      color: theme.colors.clear2,
      marginBottom: 20,
    },
    successStepContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column"
      },
    },
    successStepRoot: {
      position: "absolute",
      left: 0,
      width: "100vw",
      
      [theme.breakpoints.down("sm")]: {
        position: "static",
        width: "80vw"
      },
    },
    rowCenter: {
      display: "flex",
      width: "100%",
      justifyContent: "center",
    },
    brandingCircle: {
      height: 80,
      width: 80,
      borderRadius: "50%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    imageCircle: {
      height: 80,
      width: 80,
      borderRadius: "50%",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      boxShadow: "0px 3px 20px rgba(229, 231, 234, 0.56)",
    },
    brandingTitle: {
      ...theme.typography.header4,
      fontWeight: "bold",
      color: theme.colors.grey5,
    },
    greetingsTxt: {
      ...theme.typography.txtBody1,
      fontWeight: "bold",
      color: theme.colors.clearMain,
    },
    confirmationTxt: {
      ...theme.typography.header4,
      color: theme.colors.grey4,
    },
    label: {
      ...theme.typography.txtBody1,
      color: theme.colors.grey4,
    },
    caption: {
      ...theme.typography.txtBody1,
      fontWeight: "bold",
      color: theme.colors.grey4,
      marginTop: 10,
    },
    dividerTop: {
      borderTop: "1px solid #C9CDD3",
      width: "100%",
      marginTop: 34,
      [theme.breakpoints.down("sm")]: {
        marginTop: 24
      },
    },
    buttonContainer: {
      marginTop: 40,
      width: "100%",
      
      [theme.breakpoints.down("sm")]: {
        marginTop: 22
      },
    },
    cancelButtonContainer: {
      marginTop: 16
    },
    cancelButton: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      
      width: "100%",
      height: 32,
      
      backgroundColor: "#FAFAFA",
      
      borderRadius: 5,
      border: "1px solid #6462F3",
      
      ...theme.typography.buttons,
      color: theme.colors.clearMain,
      fontWeight: 700,
    },
    summaryContainer: {
      paddingRight: "80px",
      flexBasis: "21%",
      [theme.breakpoints.down("sm")]: {
        paddingRight: 0,
        marginBottom: 32
      },
    },
    dividerBottom: {
      borderTop: "1px solid #C9CDD3",
      width: "100%",
      marginTop: 50,
      
      [theme.breakpoints.down("sm")]: {
        marginTop: 24
      },
    },
    button: {
      ...theme.typography.buttons,
      fontWeight: "bold",
      borderRadius: 5,
      width: "100%",
      height: 37,
    },
    appntInfoContainer: {
      flexBasis: "30%",
      paddingTop: 90,
      textAlign: "center",
      
      [theme.breakpoints.down("sm")]: {
        paddingTop: 20
      },
    },
    leftSideImage: {
      top: 0,
      left: 0,
      backgroundImage: `url(${backgroundTop}), url(${backgroundBottom})`,
      backgroundPosition: "left top, left bottom",
      backgroundRepeat: "no-repeat, no-repeat",
      flexBasis: "10%",
      height: "90vh",
      paddingTop: 107,
      display: "flex",
      justifyContent: "center",
      
      [theme.breakpoints.down("sm")]: {
        display: "none"
      },
    },
    buttonCreate: {
      color: theme.colors.clearMain,
      border: `1px solid ${theme.colors.clearMain}`,
      "&:hover": {
        background: theme.colors.clearMain,
        color: theme.colors.grey1,
      }
    },
    personalizedAttentionButton: {
      color: "#00A884",
      border: `1px solid #00A884`,
      "& svg path": {
        fill: "#00A884"
      },
      "&:hover": {
        background: "#00A884",
        color: theme.colors.grey1,
        "& svg path": {
          fill: theme.colors.grey1
        },
      },
      "& .MuiButton-label": {
        gap: "12px"
      }
    },
  })
);

export default useStyles;
