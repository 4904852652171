import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const CountryBOLIcon = (props: any) => {
    return (
        <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props} >
            <path d="M12 0.75C7.08745 0.75 2.92495 3.9 1.38745 8.25H22.6124C21.0749 3.9 16.9124 0.75 12 0.75Z" fill="#C94747"/>
            <path d="M12 23.25C16.9124 23.25 21.0749 20.1375 22.6124 15.75H1.38745C2.92495 20.1375 7.08745 23.25 12 23.25Z" fill="#83BF4F"/>
            <path d="M1.3875 8.25C0.975 9.4125 0.75 10.6875 0.75 12C0.75 13.3125 0.975 14.5875 1.3875 15.75H22.6125C23.025 14.5875 23.25 13.3125 23.25 12C23.25 10.6875 23.025 9.4125 22.6125 8.25H1.3875Z" fill="#FFCE31"/>
            <path d="M11.7375 14.1377H12.2626V15.2252H11.7375V14.1377Z" fill="#ED1C24"/>
            <path d="M11.325 14.25H11.85V15.3375H11.325V14.25Z" fill="#FFE000"/>
            <path d="M12.1499 14.25H12.6749V15.3375H12.1499V14.25Z" fill="#FFE000"/>
            <path d="M11.25 14.25H11.5875V15.6375H11.25V14.25Z" fill="#699635"/>
            <path d="M12.4126 14.25H12.7501V15.6375H12.4126V14.25Z" fill="#699635"/>
            <path d="M11.1001 8.7749C11.1001 8.7749 10.9876 8.7374 10.8751 8.7374C10.9501 8.6249 10.9876 8.3999 10.9876 8.3999C10.9876 8.3999 10.8001 8.5124 10.7251 8.6249C10.6876 8.5499 10.6126 8.4374 10.6126 8.4374C10.6126 8.4374 10.5376 8.5499 10.5376 8.6249C10.5376 8.7374 10.6876 8.8874 10.6876 8.8874C10.6876 8.8874 10.8751 8.9249 10.9876 8.9249C11.0251 8.8874 11.1001 8.7749 11.1001 8.7749Z" fill="#699635"/>
            <path d="M10.9125 9.0377C10.9125 9.0377 10.8 9.0377 10.6875 9.0752C10.725 8.9252 10.6875 8.7002 10.6875 8.7002C10.6875 8.7002 10.5375 8.8877 10.5 9.0002L10.35 8.8502C10.35 8.8502 10.275 8.9627 10.3125 9.0752C10.35 9.1502 10.5 9.2627 10.5 9.2627C10.5 9.2627 10.6875 9.2627 10.8 9.2252C10.9125 9.1877 10.9125 9.0377 10.9125 9.0377Z" fill="#83BF4F"/>
            <path d="M10.8374 9.37481L10.6124 9.44981C10.6124 9.29981 10.5374 9.1123 10.5374 9.1123C10.5374 9.1123 10.4249 9.29981 10.4249 9.44981C10.3499 9.41231 10.2374 9.37481 10.2374 9.37481C10.2374 9.37481 10.1999 9.52481 10.2749 9.59981C10.3499 9.67481 10.5374 9.74981 10.5374 9.74981C10.5374 9.74981 10.7249 9.71231 10.7999 9.63731C10.8374 9.52481 10.8374 9.37481 10.8374 9.37481Z" fill="#699635"/>
            <path d="M10.7999 9.7125C10.7999 9.7125 10.6875 9.7875 10.6125 9.8625C10.575 9.7125 10.425 9.5625 10.425 9.5625C10.425 9.5625 10.3875 9.7875 10.3875 9.9C10.3125 9.8625 10.2 9.8625 10.2 9.8625C10.2 9.8625 10.2 10.0125 10.275 10.05C10.35 10.125 10.5375 10.125 10.5375 10.125C10.5375 10.125 10.725 10.05 10.7625 9.9375C10.875 9.825 10.7999 9.7125 10.7999 9.7125Z" fill="#83BF4F"/>
            <path d="M10.875 10.0501C10.875 10.0501 10.8 10.1251 10.725 10.2376C10.65 10.1251 10.4625 9.9751 10.4625 9.9751C10.4625 9.9751 10.4625 10.2001 10.5375 10.3126H10.3125C10.3125 10.3126 10.35 10.4251 10.4625 10.4626C10.575 10.5001 10.7625 10.4626 10.7625 10.4626C10.7625 10.4626 10.9125 10.3126 10.9125 10.2376C10.95 10.1626 10.875 10.0501 10.875 10.0501Z" fill="#699635"/>
            <path d="M11.0624 10.3125C11.0624 10.3125 10.9874 10.425 10.9874 10.5C10.8749 10.3875 10.6874 10.3125 10.6874 10.3125C10.6874 10.3125 10.7624 10.5375 10.8374 10.65C10.7624 10.65 10.6499 10.725 10.6499 10.725C10.6499 10.725 10.7249 10.8375 10.8374 10.8375C10.9499 10.8375 11.0999 10.7625 11.0999 10.7625C11.0999 10.7625 11.2124 10.6125 11.2124 10.5C11.1374 10.425 11.0624 10.3125 11.0624 10.3125Z" fill="#83BF4F"/>
            <path d="M11.2876 10.5752C11.2876 10.5752 11.2501 10.6877 11.2501 10.8002C11.1376 10.7252 10.9126 10.6877 10.9126 10.6877C10.9126 10.6877 11.0251 10.8752 11.1376 10.9502C11.0626 10.9877 10.9501 11.0627 10.9501 11.0627C10.9501 11.0627 11.0626 11.1377 11.1376 11.1377C11.2501 11.1377 11.4001 10.9877 11.4001 10.9877C11.4001 10.9877 11.4376 10.8002 11.4376 10.6877C11.4001 10.6502 11.2876 10.5752 11.2876 10.5752Z" fill="#699635"/>
            <path d="M11.5499 10.7627C11.5499 10.7627 11.5499 10.8752 11.5874 10.9877C11.4374 10.9502 11.2124 10.9877 11.2124 10.9877C11.2124 10.9877 11.3624 11.1377 11.5124 11.1752L11.3624 11.3252C11.3624 11.3252 11.4749 11.4002 11.5874 11.3627C11.6624 11.3252 11.7749 11.1752 11.7749 11.1752C11.7749 11.1752 11.7749 10.9877 11.7374 10.9127C11.6999 10.7627 11.5499 10.7627 11.5499 10.7627Z" fill="#83BF4F"/>
            <path d="M12.8999 8.7749C12.8999 8.7749 13.0124 8.7374 13.1249 8.7374C13.0499 8.6249 13.0124 8.3999 13.0124 8.3999C13.0124 8.3999 13.1999 8.5124 13.2749 8.6249C13.3124 8.5499 13.3874 8.4374 13.3874 8.4374C13.3874 8.4374 13.4624 8.5499 13.4624 8.6249C13.4624 8.7374 13.3124 8.8874 13.3124 8.8874C13.3124 8.8874 13.1249 8.9249 13.0124 8.9249C12.9749 8.8874 12.8999 8.7749 12.8999 8.7749Z" fill="#699635"/>
            <path d="M13.0874 9.0377C13.0874 9.0377 13.1999 9.0377 13.3124 9.0752C13.2749 8.9252 13.3124 8.7002 13.3124 8.7002C13.3124 8.7002 13.4624 8.8502 13.4999 9.0002L13.6499 8.8502C13.6499 8.8502 13.7249 8.9627 13.6874 9.0752C13.6499 9.1502 13.4999 9.2627 13.4999 9.2627C13.4999 9.2627 13.3124 9.2627 13.1999 9.2252C13.0874 9.1877 13.0874 9.0377 13.0874 9.0377Z" fill="#83BF4F"/>
            <path d="M13.1626 9.37481L13.3876 9.44981C13.3876 9.29981 13.4626 9.1123 13.4626 9.1123C13.4626 9.1123 13.5751 9.29981 13.5751 9.44981C13.6501 9.41231 13.7626 9.37481 13.7626 9.37481C13.7626 9.37481 13.8001 9.52481 13.7251 9.59981C13.6501 9.67481 13.4626 9.74981 13.4626 9.74981C13.4626 9.74981 13.2751 9.71231 13.2001 9.63731C13.1626 9.52481 13.1626 9.37481 13.1626 9.37481Z" fill="#699635"/>
            <path d="M13.2 9.7125C13.2 9.7125 13.3125 9.7875 13.3875 9.8625C13.425 9.7125 13.575 9.5625 13.575 9.5625C13.575 9.5625 13.6125 9.7875 13.6125 9.9C13.6875 9.8625 13.8 9.8625 13.8 9.8625C13.8 9.8625 13.8 10.0125 13.725 10.05C13.65 10.125 13.4625 10.125 13.4625 10.125C13.4625 10.125 13.275 10.05 13.2375 9.9375C13.125 9.825 13.2 9.7125 13.2 9.7125Z" fill="#83BF4F"/>
            <path d="M13.1249 10.0501C13.1249 10.0501 13.1999 10.1251 13.2749 10.2376C13.3499 10.1251 13.5374 9.9751 13.5374 9.9751C13.5374 9.9751 13.5374 10.2001 13.4624 10.3126H13.6874C13.6874 10.3126 13.6499 10.4251 13.5374 10.4626C13.4624 10.5001 13.2374 10.4626 13.2374 10.4626C13.2374 10.4626 13.0874 10.3126 13.0874 10.2376C13.0874 10.1626 13.1249 10.0501 13.1249 10.0501Z" fill="#699635"/>
            <path d="M12.9376 10.3125C12.9376 10.3125 13.0126 10.425 13.0126 10.5C13.1251 10.3875 13.3126 10.3125 13.3126 10.3125C13.3126 10.3125 13.2376 10.5375 13.1626 10.65C13.2376 10.65 13.3501 10.725 13.3501 10.725C13.3501 10.725 13.2751 10.8375 13.1626 10.8375C13.0501 10.8375 12.9001 10.7625 12.9001 10.7625C12.9001 10.7625 12.7876 10.6125 12.7876 10.5C12.7876 10.3875 12.9376 10.3125 12.9376 10.3125Z" fill="#83BF4F"/>
            <path d="M12.7125 10.5752C12.7125 10.5752 12.75 10.6877 12.75 10.8002C12.8625 10.7252 13.0875 10.6877 13.0875 10.6877C13.0875 10.6877 12.975 10.8752 12.8625 10.9502C12.9375 10.9877 13.05 11.0627 13.05 11.0627C13.05 11.0627 12.9375 11.1377 12.8625 11.1377C12.75 11.1377 12.6 10.9877 12.6 10.9877C12.6 10.9877 12.5625 10.8002 12.5625 10.6877C12.6 10.6502 12.7125 10.5752 12.7125 10.5752Z" fill="#699635"/>
            <path d="M12.4501 10.7627C12.4501 10.7627 12.4501 10.8752 12.4126 10.9877C12.5626 10.9502 12.7876 10.9877 12.7876 10.9877C12.7876 10.9877 12.6376 11.1377 12.4876 11.1752L12.6376 11.3252C12.6376 11.3252 12.5251 11.4002 12.4126 11.3627C12.3376 11.3252 12.2251 11.1752 12.2251 11.1752C12.2251 11.1752 12.2251 10.9877 12.2626 10.9127C12.3001 10.7627 12.4501 10.7627 12.4501 10.7627Z" fill="#83BF4F"/>
            <path d="M13.2375 9.6001C12.8624 9.6001 12.4125 10.0501 12 10.0501C11.5875 10.0501 11.1375 9.6001 10.7625 9.6001C10.3875 9.6001 8.88745 10.0501 8.88745 10.0501C10.95 10.0501 12 11.1751 12 11.1751C12 11.1751 13.05 10.0501 15.1124 10.0501C15.1124 10.0501 13.6124 9.6001 13.2375 9.6001Z" fill="#594640"/>
            <path d="M12 10.1251C12.145 10.1251 12.2626 10.0076 12.2626 9.8626C12.2626 9.71762 12.145 9.6001 12 9.6001C11.8551 9.6001 11.7375 9.71762 11.7375 9.8626C11.7375 10.0076 11.8551 10.1251 12 10.1251Z" fill="white"/>
            <path d="M10.2 12.075L10.425 12.2625L10.5375 12.0375L10.575 11.925V11.175L10.65 11.2125C10.575 11.1375 10.5 11.0625 10.3875 10.9875L10.2375 10.875C10.2 10.9125 10.2 11.6625 10.2 12.075Z" fill="#FFE000"/>
            <path d="M10.575 11.9248L10.8 11.3623L10.575 11.1748C10.5375 11.1748 10.5375 11.5498 10.575 11.9248Z" fill="#699635"/>
            <path d="M10.2 10.9125L10.35 11.025C10.0875 10.8 9.75 10.5375 9.6 10.3875C9.5625 10.35 9.5625 10.9125 9.5625 11.55L10.2 12.075V10.9125Z" fill="#D52B1E"/>
            <path d="M13.8001 12.075L13.5751 12.2625L13.4626 12.0375L13.4251 11.925V11.175L13.3501 11.2125C13.4251 11.1375 13.5001 11.0625 13.6126 10.9875L13.7626 10.875C13.8001 10.9125 13.8001 11.6625 13.8001 12.075Z" fill="#FFE000"/>
            <path d="M13.425 11.9248L13.2 11.3623L13.425 11.1748C13.4625 11.1748 13.4625 11.5498 13.425 11.9248Z" fill="#699635"/>
            <path d="M13.7999 10.9125L13.6499 11.025C13.9124 10.8 14.2499 10.5375 14.3999 10.3875C14.4374 10.35 14.4374 10.9125 14.4374 11.55L13.7999 12.075V10.9125Z" fill="#D52B1E"/>
            <path d="M10.425 14.2499C9.93752 13.9874 9.82502 13.1999 9.86252 13.0874V11.6999L10.0125 11.8124C9.75002 11.5874 9.41252 11.3249 9.26252 11.1749C9.22502 11.1374 9.26252 12.8624 9.26252 13.6124C9.26252 13.9874 9.52502 15.2249 11.1 14.5874C11.1 14.5874 11.8875 14.3624 12.1125 14.0624H11.9625L11.8875 14.0999C10.95 14.6249 10.425 14.2499 10.425 14.2499Z" fill="#D52B1E"/>
            <path d="M9.82495 13.0875C9.82495 13.2 9.89995 13.9875 10.3875 14.25C10.3875 14.25 10.9125 14.625 11.85 14.1C11.5875 14.2125 11.3625 14.2125 11.2125 14.175C10.7625 14.0625 10.3125 13.65 10.2375 13.2C10.1625 12.7875 10.2 11.925 10.2 11.925L10.275 11.9625C10.2 11.8875 10.125 11.8125 10.0125 11.7375L9.86245 11.625C9.86245 11.7 9.86245 12.975 9.82495 13.0875Z" fill="#FFE000"/>
            <path d="M10.4626 12.5248V12.1873C10.3501 12.1123 10.5001 12.2248 10.2376 11.9998L10.1626 11.9248V11.9998C10.1626 12.1123 10.1626 12.8623 10.2001 13.2373C10.2751 13.6498 10.7251 14.0998 11.1751 14.2123C11.3251 14.2498 11.5501 14.2123 11.8501 14.1373L11.8876 14.0998C11.1376 13.9873 10.5751 13.3873 10.4626 12.5248Z" fill="#699635"/>
            <path d="M13.575 14.2875C14.0625 14.025 14.175 13.2375 14.1375 13.125V11.7375L13.9875 11.85C14.25 11.625 14.5875 11.3625 14.7375 11.2125C14.775 11.175 14.7375 12.9 14.7375 13.65C14.7375 14.025 14.475 15.2625 12.9 14.625C12.9 14.625 12.1125 14.4 11.8875 14.1H12.0375L12.1125 14.1375C13.05 14.6625 13.575 14.2875 13.575 14.2875Z" fill="#D52B1E"/>
            <path d="M14.1749 13.1251C14.1749 13.2376 14.0999 14.0251 13.6124 14.2876C13.6124 14.2876 13.0874 14.6626 12.1499 14.1376C12.4124 14.2501 12.6374 14.2501 12.7874 14.2126C13.2374 14.1001 13.6874 13.6876 13.7624 13.2376C13.8374 12.8251 13.7999 11.9626 13.7999 11.9626L13.7249 12.0001C13.7999 11.9251 13.8749 11.8501 13.9874 11.7751L14.1374 11.6626C14.1374 11.7376 14.1374 13.0126 14.1749 13.1251Z" fill="#FFE000"/>
            <path d="M13.5375 12.5624V12.2249C13.65 12.1499 13.5 12.2624 13.7625 12.0374L13.8376 11.9624V11.9999C13.8376 12.1124 13.8375 12.8624 13.8 13.2374C13.725 13.6499 13.275 14.0999 12.825 14.2124C12.675 14.2499 12.45 14.2124 12.15 14.1374L12.1125 14.0999C12.8625 14.0249 13.425 13.4249 13.5375 12.5624Z" fill="#699635"/>
            <path d="M12 14.3249C12.8699 14.3249 13.5751 13.5022 13.5751 12.4874C13.5751 11.4726 12.8699 10.6499 12 10.6499C11.1302 10.6499 10.425 11.4726 10.425 12.4874C10.425 13.5022 11.1302 14.3249 12 14.3249Z" fill="#00A6DE"/>
            <path d="M13.3126 12.5248C13.3126 13.3873 10.7251 13.3873 10.7251 12.5248C10.7251 11.6623 11.2876 10.9873 12.0376 10.9873C12.7876 10.9873 13.3126 11.6998 13.3126 12.5248Z" fill="#E8A30E"/>
            <path d="M11.9625 12.3752C12.3145 12.3752 12.5999 12.1401 12.5999 11.8502C12.5999 11.5602 12.3145 11.3252 11.9625 11.3252C11.6104 11.3252 11.325 11.5602 11.325 11.8502C11.325 12.1401 11.6104 12.3752 11.9625 12.3752Z" fill="#D52B1E"/>
            <path d="M11.925 11.7373C11.55 11.7373 10.6875 12.5623 10.6875 12.5623C10.6875 13.3498 11.25 13.9873 12 13.9873C12.75 13.9873 13.3125 13.3498 13.3125 12.5623C13.3125 12.5623 12.3375 11.7373 11.925 11.7373Z" fill="#699635"/>
            <path d="M12.1125 13.125H12.4501V13.65H12.1125V13.125Z" fill="#E8A30E"/>
            <path d="M12.6001 12.5249H12.7876V13.5749H12.6001V12.5249Z" fill="white"/>
        </SvgIcon>
    )
};

export default CountryBOLIcon;
