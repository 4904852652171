import React, { useEffect } from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import { Url } from "./../../../constants/url";

import AppointmentEdition from "./../AppointmentEdition";
import AppointmentNew from "../AppointmentNew";
import { useAppSelector } from "../../../redux/hooks";
import SelectEstablishment from "../../SelectEstablishment";

const AppointmentRouter = () => {
  const account = useAppSelector((state) => state.scheduler.account);

  return (
    <Switch>
      <Route
        exact
        path={Url.Appointments.Main}
        component={account.isEnterprise ? SelectEstablishment : AppointmentNew }
      />
      <Route
        exact
        path={"/:accountBusinessName"}
        render={props => (
          <Redirect to={`${props.match.params.accountBusinessName}/appointment`} />
        )}
      />
      <Route
        exact
        path={`${Url.Appointments.Main}/:appointmentId`}
        component={AppointmentEdition}
      />
      <Route path={Url.Appointments.Main}>
        <Redirect to={Url.Appointments.Main} />
      </Route>
    </Switch>
  );
};

export default AppointmentRouter;
