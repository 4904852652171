import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const CountryCRIIcon = (props: any) => {
    return (
        <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
            <path d="M19.6501 3.75C17.6251 1.875 14.9626 0.75 12.0001 0.75C9.0376 0.75 6.3751 1.875 4.3501 3.75H19.6501Z" fill="#2A5F9E"/>
            <path d="M4.3501 20.25C6.3751 22.125 9.0376 23.25 12.0001 23.25C14.9626 23.25 17.6251 22.125 19.6501 20.25H4.3501Z" fill="#2A5F9E"/>
            <path d="M0.75 12C0.75 13.6125 1.0875 15.1125 1.6875 16.5H22.3125C22.9125 15.1125 23.25 13.6125 23.25 12C23.25 10.3875 22.9125 8.8875 22.3125 7.5H1.6875C1.0875 8.8875 0.75 10.3875 0.75 12Z" fill="#C94747"/>
            <path d="M4.3499 20.25H19.6124C20.7374 19.2 21.6749 17.925 22.2749 16.5H1.6499C2.3249 17.925 3.2249 19.2 4.3499 20.25Z" fill="#F9F9F9"/>
            <path d="M19.65 3.75H4.35C3.225 4.8 2.2875 6.075 1.6875 7.5H22.3125C21.675 6.075 20.775 4.8 19.65 3.75Z" fill="#F9F9F9"/>
        </SvgIcon>
    )
};

export default CountryCRIIcon;
