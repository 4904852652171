import { createTheme } from "@material-ui/core/styles";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as customTypes from "./material-ui";

const clearTheme = createTheme({
  palette: {
    primary: {
      main: "#6462F3",
      light: "#a697df",
    },
  },
  colors: {
    clearMain: "#6462F3",
    clear2: "#7175FA",
    clear3: "#9FA9E6",
    clear4: "#BDC7F8",
    clear5: "#E1E9FE",
    clear6: "#E9F1FE",
    grey7: "#1D2027",
    grey6: "#323947",
    grey5: "#5C6477",
    grey4: "#919CA5",
    grey3: "#ACB7C0",
    grey2: "#C9CDD3",
    grey1: "#F6F6F6",
    white: "#FFFFFF",
    clearGreen: "#36CE91",
    clearRed: "#F15857",
    clearYellow: "#FFC626",
    clearBlue: "#0BCBE0",
    clearLilac: "#EAE9FF",
    backgroundGrey1: "#FAFAFA"
  },
  typography: {
    fontFamily: "Roboto, sans-serif",
    header1: {
      fontFamily: "Inter, sans-serif",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "34px",
      lineHeight: "41px",
    },
    header2: {
      fontFamily: "Inter, sans-serif",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "24px",
      lineHeight: "29px",
    },
    header3: {
      fontFamily: "Inter, sans-serif",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "21px",
      lineHeight: "25px",
    },
    header4: {
      fontFamily: "Inter, sans-serif",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "18px",
      lineHeight: "22px",
    },
    txtBody1: {
      fontFamily: "Inter, sans-serif",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "14px",
      lineHeight: "17px",
    },
    txtBody2: {
      fontFamily: "Roboto, sans-serif",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "12px",
      lineHeight: "14px",
    },
    txtBody3: {
      fontFamily: "Inter, sans-serif",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "11px",
      lineHeight: "13px",
    },
    caption1: {
      fontFamily: "Inter, sans-serif",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "10px",
      lineHeight: "12px",
    },
    caption2: {
      fontFamily: "Inter, sans-serif",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "9px",
      lineHeight: "11px",
    },
    buttons: {
      fontFamily: "Inter, sans-serif",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "12px",
      lineHeight: "15px",
    },
  },
  overrides: {
    MuiAppBar: {
      root: {
        boxShadow: "none",
      },
    },
    MuiToolbar: {
      dense: {
        minHeight: 54,
      },
    },
    MuiPopover: {
      root: {
        zIndex: 1150,
      },
    },
    MuiButtonBase: {
      root: {
        "&:focus": {
          outline: "none",
        },
      },
    },
    MuiButton: {
      root: {
        fontSize: "0.725rem",
        textTransform: "none",
      },
      contained: {
        boxShadow: "none",
        "&:hover": {
          boxShadow: "none",
        },
        "&:disabled": {
          boxShadow: "none",
          backgroundColor: "#6462F3",
          color: "#fff",
          opacity: 0.3,
        },
      },
    },
    MuiIconButton: {
      root: {
        "&:focus": {
          outline: "none",
        },
      },
    },
    MuiInputBase: {
      root: {
        fontSize: "0.9rem",
      },
    },
    MuiOutlinedInput: {
      input: {
        textOverflow: "ellipsis",
        borderRadius: 4,
        position: "relative",
        backgroundColor: "#fff",
        border: "1px #9ea3a8",
        "&focus": {
          borderColor: "#593ea3",
        },
        padding: "8px 10px",
      },
      inputMarginDense: {
        paddingTop: 8,
        paddingBottom: 8,
      },
      multiline: {
        padding: "8px 10px",
      },
      adornedStart: {
        paddingLeft: 8,
      },
    },
    MuiInputLabel: {
      root: {
        marginBottom: 0,
      },
    },
    MuiTab: {
      root: {
        textTransform: "capitalize",
      },
    },
    MuiTableCell: {
      stickyHeader: {
        backgroundColor: "white",
      },
    },
    MuiPickersBasePicker: {
      container: {
        border: `1px solid #F6F6F6`,
        boxSizing: "border-box",
        borderRadius: 8,
        boxShadow: "0px 3px 20px rgba(229, 231, 234, 0.56)",
      },
    },
    MuiPickersCalendarHeader: {
      iconButton: {
        color: "#7175FA",
      },
      transitionContainer: {
        color: "#7175FA",
        textTransform: "capitalize",
        fontFamily: "Inter, sans-serif",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "14px",
        lineHeight: "17px",
      },
      dayLabel: {
        textTransform: "capitalize",
      },
    },
  },
});

export default clearTheme;
