export default class RadioItem {
  fieldItemId: string | null;
  value: string;
  
  constructor(x?: RadioItem) {
    if (typeof x === "object") {
      this.fieldItemId = x.fieldItemId;
      this.value = x.value;
    } else {
      this.fieldItemId = null;
      this.value = "";
    }
  }
}