import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const SortDownIcon = () => (
    <SvgIcon>
        <path
            d="M9.6 10c-.537 0-.805.62-.414.98l2.907 2.84c.22.24.586.24.83 0l2.908-2.84c.366-.36.097-.98-.416-.98H9.601z"
            fill="#5C6477"
        />
    </SvgIcon>
);

export default SortDownIcon;
