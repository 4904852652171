import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const CountryESPIcon = (props: any) => {
    return (
        <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props} >
            <path d="M0.75 12C0.75 14.2125 1.3875 16.275 2.475 18H21.4875C22.575 16.275 23.2125 14.2125 23.2125 12C23.2125 9.7875 22.575 7.725 21.4875 6H2.475C1.3875 7.725 0.75 9.7875 0.75 12Z" fill="#FFCE31"/>
            <path d="M21.5251 6C19.5376 2.85 16.0126 0.75 12.0001 0.75C7.9876 0.75 4.4626 2.85 2.4751 6H21.5251Z" fill="#ED4C5C"/>
            <path d="M2.4751 18C4.4626 21.15 7.9876 23.25 12.0001 23.25C16.0126 23.25 19.5376 21.15 21.5251 18H2.4751Z" fill="#ED4C5C"/>
            <path d="M3.44995 10.7627H4.64995V11.4377H3.44995V10.7627Z" fill="#C8B100"/>
            <path d="M3.44995 15.7129H4.68745V16.3504H3.44995V15.7129Z" fill="#C8B100"/>
            <path d="M3.3374 14.6626C3.2249 14.7376 3.1499 14.8126 3.1499 14.8501C3.1499 14.8876 3.1874 14.9251 3.2624 14.9626C3.3374 15.0001 3.4124 15.0751 3.3749 15.1501C3.4499 15.0751 3.4874 15.0001 3.4874 14.9251C3.4874 14.8126 3.4124 14.7001 3.3374 14.6626Z" fill="#ED4C5C"/>
            <path d="M3.63745 11.4375H4.49995V15.7125H3.63745V11.4375Z" fill="white"/>
            <path d="M5.39999 13.0125C5.21249 12.9375 4.87499 12.8625 4.49999 12.8625C4.38749 12.8625 4.23749 12.8625 4.08749 12.9C3.56249 12.975 3.14999 13.2 3.18749 13.35L2.99999 12.9375C2.96249 12.75 3.41249 12.525 3.97499 12.4125C4.16249 12.375 4.34999 12.375 4.49999 12.375C4.87499 12.375 5.21249 12.4125 5.39999 12.4875V13.0125Z" fill="#ED4C5C"/>
            <path d="M3.6376 13.575C3.4126 13.575 3.2251 13.5 3.2251 13.3875C3.2251 13.3125 3.3001 13.2 3.4501 13.125H3.6751L3.6376 13.575Z" fill="#ED4C5C"/>
            <path d="M4.5 13.2373C4.65 13.2748 4.7625 13.3123 4.8375 13.3498C4.875 13.3873 4.725 13.5373 4.5 13.6498V13.2373Z" fill="#ED4C5C"/>
            <path d="M3.07511 14.4001C3.03761 14.3251 3.30011 14.1751 3.63761 14.0626C3.78761 14.0251 3.90011 13.9501 4.08761 13.8751C4.53761 13.6876 4.91261 13.4251 4.83761 13.3501L4.91261 13.8001C4.95011 13.8751 4.65011 14.1001 4.20011 14.3251C4.05011 14.4001 3.78761 14.5126 3.63761 14.5501C3.37511 14.6251 3.15011 14.7751 3.15011 14.8126L3.07511 14.4001Z" fill="#ED4C5C"/>
            <path d="M11.5127 10.7627H12.7127V11.4377H11.5127V10.7627Z" fill="#C8B100"/>
            <path d="M11.4751 15.7129H12.7126V16.3504H11.4751V15.7129Z" fill="#C8B100"/>
            <path d="M12.825 14.6626C12.9376 14.7376 13.0126 14.8126 13.0126 14.8501C13.0126 14.8876 12.9751 14.9251 12.9001 14.9626C12.8251 15.0376 12.75 15.1501 12.7875 15.1876C12.7125 15.1126 12.675 15.0376 12.675 14.9626C12.675 14.8126 12.75 14.7001 12.825 14.6626Z" fill="#ED4C5C"/>
            <path d="M11.6626 11.4375H12.5251V15.7125H11.6626V11.4375Z" fill="white"/>
            <path d="M10.7625 13.0125C10.95 12.9375 11.2875 12.8625 11.6625 12.8625C11.775 12.8625 11.925 12.8625 12.075 12.9C12.6 12.975 13.0124 13.2 12.9749 13.35L13.1624 12.9C13.1999 12.7125 12.75 12.4875 12.1875 12.375H11.6625C11.2875 12.375 10.95 12.4125 10.7625 12.4875V13.0125Z" fill="#ED4C5C"/>
            <path d="M12.525 13.575C12.75 13.575 12.9375 13.5 12.9375 13.3875C12.9375 13.3125 12.8625 13.2 12.7125 13.125H12.4875L12.525 13.575Z" fill="#ED4C5C"/>
            <path d="M11.6626 13.2373C11.5126 13.2748 11.4001 13.3123 11.3251 13.3498C11.2876 13.3873 11.4376 13.5373 11.6626 13.6498V13.2373Z" fill="#ED4C5C"/>
            <path d="M13.0875 14.3996C13.125 14.3246 12.8625 14.1746 12.525 14.0621C12.375 14.0246 12.2625 13.9496 12.075 13.8746C11.625 13.6871 11.25 13.4246 11.325 13.3496L11.25 13.7996C11.2125 13.8746 11.5125 14.0996 11.9625 14.3246C12.1125 14.3996 12.375 14.5121 12.525 14.5496C12.7875 14.6246 13.0125 14.8121 12.975 14.8496L13.0875 14.3996Z" fill="#ED4C5C"/>
            <path d="M8.06255 8.3623C8.77505 8.3623 10.2375 8.51231 10.7625 9.03731C10.2 10.3873 9.30005 9.82481 8.06255 9.82481C6.86255 9.82481 5.92505 10.3873 5.36255 9.03731C5.88755 8.51231 7.31255 8.3623 8.06255 8.3623Z" fill="#ED4C5C"/>
            <path d="M9.9001 9.8625C9.4501 9.6 8.7751 9.5625 8.0626 9.5625C7.3501 9.5625 6.6751 9.6375 6.2251 9.8625L6.3751 10.5C6.7876 10.6125 7.3876 10.6875 8.0626 10.6875C8.7376 10.6875 9.3001 10.6125 9.7501 10.5L9.9001 9.8625Z" fill="#C8B100"/>
            <path d="M10.5375 8.25C10.3875 8.1375 10.0875 8.025 9.82505 8.025C9.71255 8.025 9.60005 8.025 9.48755 8.0625C9.48755 8.0625 9.26255 7.7625 8.73755 7.7625C8.55005 7.7625 8.40005 7.8 8.25005 7.875V7.8375C8.21255 7.7625 8.13755 7.6875 8.06255 7.6875C7.98755 7.6875 7.87505 7.8 7.87505 7.875V7.9125C7.72505 7.8375 7.57505 7.8 7.38755 7.8C6.86255 7.8 6.63755 8.1375 6.63755 8.1C6.52505 8.0625 6.41255 8.0625 6.30005 8.0625C4.57505 8.0625 5.43755 9.225 5.43755 9.225L5.62505 9C5.21255 8.475 5.58755 8.175 6.33755 8.175C6.45005 8.175 6.52505 8.175 6.60005 8.2125C6.33755 8.5875 6.82505 8.925 6.82505 8.925L6.93755 8.7375C6.67505 8.55 6.63755 7.9125 7.38755 7.9125C7.57505 7.9125 7.72505 7.95 7.87505 8.0625C7.87505 8.1 7.83755 8.625 7.80005 8.7L8.10005 8.9625L8.40005 8.7C8.36255 8.5875 8.32505 8.1 8.32505 8.0625C8.43755 7.9875 8.62505 7.9125 8.81255 7.9125C9.60005 7.9125 9.60005 8.55 9.26255 8.7375L9.37505 8.925C9.37505 8.925 9.78755 8.5875 9.60005 8.2125C9.67505 8.2125 9.78755 8.175 9.86255 8.175C10.7625 8.175 10.8 8.85 10.575 9L10.725 9.225C10.65 9.225 11.0625 8.7 10.5375 8.25Z" fill="#C8B100"/>
            <path d="M7.8374 7.5375C7.8374 7.425 7.9499 7.3125 8.0624 7.3125C8.2124 7.3125 8.2874 7.425 8.2874 7.5375C8.2874 7.65 8.1749 7.7625 8.0624 7.7625C7.9499 7.7625 7.8374 7.65 7.8374 7.5375Z" fill="#005BBF"/>
            <path d="M7.9874 6.8999V7.0124H7.8749V7.1249H7.9874V7.4999H7.8374V7.6124H8.2874L8.3249 7.5374L8.2874 7.4999H8.1374V7.1249H8.2499V7.0124H8.1374V6.8999H7.9874Z" fill="#C8B100"/>
            <path d="M8.0624 10.6123C7.4624 10.6123 6.9374 10.5373 6.5249 10.4248C6.9374 10.3123 7.4624 10.2373 8.0624 10.2373C8.6624 10.2373 9.1874 10.3123 9.5999 10.4248C9.2249 10.5373 8.6624 10.6123 8.0624 10.6123Z" fill="#ED4C5C"/>
            <path d="M8.0999 17.1001C7.3874 17.1001 6.7124 16.9126 6.1124 16.6501C5.6624 16.4251 5.3999 16.0126 5.3999 15.5251V13.7251H10.7999V15.5251C10.7999 16.0126 10.4999 16.4626 10.0874 16.6501C9.4874 16.9501 8.8124 17.1001 8.0999 17.1001Z" fill="white"/>
            <path d="M8.0625 10.7251H10.7625V13.7251H8.0625V10.7251Z" fill="white"/>
            <path d="M8.0999 15.5251C8.0999 16.2376 7.4999 16.8001 6.7499 16.8001C5.9999 16.8001 5.3999 16.2376 5.3999 15.5251V13.7251H8.0999V15.5251Z" fill="#ED4C5C"/>
            <path d="M5.96265 16.5749C6.03765 16.6124 6.15015 16.6874 6.30015 16.7249V13.6499H6.00015L5.96265 16.5749Z" fill="#C8B100"/>
            <path d="M5.36255 15.4874C5.36255 15.8624 5.51255 16.1624 5.66255 16.3124V13.6499H5.36255V15.4874Z" fill="#C8B100"/>
            <path d="M6.5625 16.7999H6.8625V13.6499H6.5625V16.7999Z" fill="#C7B500"/>
            <path d="M7.1626 16.7249C7.2751 16.6874 7.4251 16.6124 7.5001 16.5749V13.6499H7.2001L7.1626 16.7249Z" fill="#C8B100"/>
            <path d="M5.36255 10.7251H8.06255V13.7251H5.36255V10.7251Z" fill="#ED4C5C"/>
            <path d="M7.80005 16.3125C7.95005 16.2 8.06255 15.9375 8.10005 15.6375V13.6875H7.80005V16.3125Z" fill="#C8B100"/>
            <path d="M10.7999 13.7246V15.5246C10.7999 16.2371 10.1999 16.7996 9.44985 16.7996C8.69985 16.7996 8.09985 16.2371 8.09985 15.5246V13.7246H10.7999Z" fill="#ED4C5C"/>
            <path d="M9.8248 11.2498C9.9373 11.4748 9.9373 12.0373 9.5998 11.9248C9.6748 11.9623 9.7123 12.2248 9.8248 12.3748C10.0123 12.5998 10.2373 12.4123 10.1998 12.1498C10.1248 11.7373 10.1623 11.4748 10.2373 11.0623C10.2373 11.0998 10.4248 11.0998 10.4998 11.0248C10.4623 11.1373 10.4248 11.2873 10.4998 11.2873C10.4248 11.3998 10.2373 11.5873 10.1998 11.6998C10.1623 11.9623 10.5748 12.4498 10.1248 12.5623C9.8248 12.6373 10.0123 12.8623 10.1248 12.9748C10.1248 12.9748 9.9748 13.4623 10.0498 13.4248C9.7498 13.5373 9.8248 13.2748 9.8248 13.2748C9.9748 12.8248 9.5623 12.7873 9.5998 12.7123C9.2248 12.6748 9.6373 13.0498 9.2998 13.0498C9.2248 13.0498 9.0748 13.1248 9.0748 13.1248C8.6623 13.0873 8.8873 12.7123 9.0373 12.7498C9.1498 12.7873 9.2623 12.9748 9.2623 12.7123C9.2623 12.7123 9.0748 12.4123 9.5623 12.4123C9.3748 12.4123 9.2623 12.2623 9.1873 12.0748C9.1123 12.1123 8.9998 12.2998 8.5873 12.3373C8.5873 12.3373 8.4748 11.9248 8.5873 11.9998C8.7373 12.0748 8.8123 12.0748 8.9623 11.9248C8.8873 11.8123 8.4373 11.6623 8.5123 11.3998C8.5123 11.3248 8.7373 11.2123 8.7373 11.2123C8.6998 11.3998 8.8123 11.5873 9.0373 11.5873C9.3373 11.6248 9.2248 11.5123 9.2623 11.4373C9.2998 11.3623 9.5248 11.4748 9.4498 11.2873C9.4498 11.2498 9.1873 11.2123 9.2623 11.0998C9.4123 10.9123 9.6373 11.0623 9.8248 11.2498Z" fill="#ED4C5C"/>
            <path d="M8.0999 16.725L8.0249 16.5375L8.0999 16.3125L8.1749 16.5375L8.0999 16.725Z" fill="#ED4C5C"/>
            <path d="M6.1874 11.3625V11.55H6.2624V11.7H6.0749V12.075H6.1874V12.9H5.9624V13.3125H7.4999V12.9H7.3124V12.075H7.3874V11.7H7.1999V11.55H7.3124V11.3625H6.9374V11.55H7.0124V11.7H6.8249V11.25H6.9374V11.0625H6.5249V11.25H6.6374V11.7H6.4499V11.55H6.5249V11.3625H6.1874Z" fill="#C8B100"/>
            <path d="M10.4249 15.9751V14.1001H8.47485V15.9751L9.37485 16.3876H9.48735L10.4249 15.9751ZM9.37485 14.2501V14.8876L8.73735 14.2501H9.37485ZM8.58735 14.2876L9.33735 15.0376L8.58735 15.7876V14.2876ZM8.66235 15.9376L9.37485 15.2251V16.2751L8.66235 15.9376ZM9.48735 16.2376V15.1876L10.1999 15.9001L9.48735 16.2376ZM10.2749 15.7876L9.52485 15.0376L10.2749 14.2876V15.7876ZM9.48735 14.2501H10.1249L9.48735 14.8876V14.2501Z" fill="#C8B100"/>
            <path d="M7.19995 13.6874C7.19995 13.1249 7.57495 12.7124 8.06245 12.7124C8.54995 12.7124 8.92495 13.1624 8.92495 13.6874C8.92495 14.2124 8.54995 14.6624 8.06245 14.6624C7.57495 14.6624 7.19995 14.2499 7.19995 13.6874Z" fill="#ED4C5C"/>
            <path d="M7.4624 13.6876C7.4624 13.2751 7.7249 12.9751 8.0624 12.9751C8.3999 12.9751 8.6624 13.3126 8.6624 13.6876C8.6624 14.1001 8.3999 14.4001 8.0624 14.4001C7.7624 14.4376 7.4624 14.1001 7.4624 13.6876Z" fill="#005BBF"/>
            <path d="M7.7999 13.2002L7.6499 13.6127L7.7624 13.6502L7.6874 13.8002H7.9124L7.8374 13.6502L7.9499 13.6127L7.7999 13.2002Z" fill="#C8B100"/>
            <path d="M8.3624 13.2002L8.2124 13.6127L8.3249 13.6502L8.2499 13.8002H8.4749L8.4374 13.6502L8.5499 13.6127L8.3624 13.2002Z" fill="#C8B100"/>
            <path d="M8.09985 13.6875L7.91235 14.1L8.02485 14.1375L7.98735 14.2875H8.17485L8.13735 14.1375L8.24985 14.1L8.09985 13.6875Z" fill="#C8B100"/>
        </SvgIcon>
    )
};

export default CountryESPIcon;
