import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const CashIcon = (props: any) => {
  const { style, viewBox, ...otherProps } = props;
  return (
    <SvgIcon
      {...otherProps}
      style={{ width: 20, height: 12, color: "#919CA5", ...style }}
      viewBox={viewBox || "0 0 20 12"}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.59473 0H18.4053C18.921 0 19.3392 0.419693 19.3392 0.937207V10.3093C19.3392 10.8268 18.921 11.2465 18.4053 11.2465H1.59473C1.07903 11.2465 0.660812 10.8268 0.660812 10.3093V0.937207C0.660812 0.419693 1.07903 0 1.59473 0ZM16.0705 9.84067C16.0705 8.80535 16.9066 7.96626 17.9383 7.96626V3.28022C16.9066 3.28022 16.0705 2.44113 16.0705 1.40581H3.92953C3.92953 2.44113 3.09338 3.28022 2.06169 3.28022V7.96626C3.09338 7.96626 3.92953 8.80535 3.92953 9.84067H16.0705ZM9.93424 7.76086C11.1329 7.76086 12.1046 6.78915 12.1046 5.59048C12.1046 4.39182 11.1329 3.42011 9.93424 3.42011C8.73557 3.42011 7.76386 4.39182 7.76386 5.59048C7.76386 6.78915 8.73557 7.76086 9.93424 7.76086Z"
      />
    </SvgIcon>
  );
};

export default CashIcon;
