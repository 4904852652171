import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    tabsRoot: {
      minHeight: 55,
      position: "relative",
      top: 5,
      [theme.breakpoints.down("sm")]: {
        width: "100%"
      },
    },
    tabs: {
      ...theme.typography.txtBody2,
      "& .MuiTabs-indicator": {
        backgroundColor: "#7175FA",
        
        [theme.breakpoints.down("sm")]: {
          width: 4,
          left: 0,
        },
      },
      "& .Mui-selected": {
        ...theme.typography.txtBody2,
        color: theme.colors.clearMain,
        fontWeight: "bold",
        [theme.breakpoints.down("sm")]: {
          backgroundColor: "#f5f6f6",
          fontFamily: "Inter, sans-serif",
          fontSize: "14px",
        },
      },
      "& .MuiButtonBase-root": {
        minWidth: 0,
        alignItems: "left",
        marginRight: 25,
        [theme.breakpoints.down("sm")]: {
          marginRight: 0
        },
      },
      "& .MuiTab-root": {
        paddingLeft: 0,
        paddingRight: 0,
        flexDirection: "column"
      },
      "& .MuiTab-wrapper": {
        [theme.breakpoints.down("sm")]: {
          alignItems: "flex-start",
          paddingLeft: 23
        },
      }
    },
    tab: {
      ...theme.typography.txtBody2,
      textTransform: "none",
      root: {
        ...theme.typography.txtBody2,
      },
      selected: {
        fontWeight: "bold",
      },
      [theme.breakpoints.down("sm")]: {
        ...theme.typography.txtBody1
      },
      "&:hover": {
        textDecoration: "none",
        color: theme.colors.clearMain
      }
    },
  })
);

export default useStyles;