import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const CountryAGOIcon = (props: any) => {
    return (
        <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props} >
            <path d="M12 23.25C18.225 23.25 23.25 18.225 23.25 12H0.75C0.75 18.225 5.775 23.25 12 23.25Z" fill="#3E4347"/>
            <path d="M12 0.75C5.775 0.75 0.75 5.775 0.75 12H23.25C23.25 5.775 18.225 0.75 12 0.75Z" fill="#ED4C5C"/>
            <path d="M10.3125 9.1874L9.825 10.6874L11.1 9.7499L12.4125 10.6874L11.925 9.1874L13.2 8.2499H11.5875L11.1 6.7124L10.6125 8.2499H9L10.3125 9.1874Z" fill="#FFE62E"/>
            <path d="M18 18.2247C17.8125 17.9247 17.5875 17.6997 17.2875 17.4372C16.9875 17.1747 16.575 16.8747 16.2375 16.5747L15.6375 17.2497C15.6 17.2872 15.6375 17.3622 15.6375 17.3622C16.3125 17.4747 16.5 17.6997 16.7625 17.9247C17.1375 18.2997 17.25 18.7122 17.5125 18.8997C17.9625 19.1622 18.3 18.6747 18 18.2247Z" fill="#FFE62E"/>
            <path d="M12.675 15.6375C12.0375 15.8625 11.325 15.9375 10.6125 15.8625C9.67495 15.75 8.81245 15.4125 8.09995 14.8125L7.38745 15.675C7.68745 15.9375 8.02495 16.125 8.32495 16.3125L8.09995 16.7625C8.47495 16.95 8.84995 17.1375 9.26245 17.25L9.41245 16.8C9.78745 16.9125 10.1625 16.9875 10.5375 17.025L10.5 17.475C10.9125 17.5125 11.325 17.5125 11.7375 17.475L11.7 16.9875C12.075 16.95 12.45 16.875 12.825 16.7625L12.975 17.2125C13.1625 17.1375 13.35 17.1 13.575 16.9875L13.3125 15.975L12.675 15.6375Z" fill="#FFE62E"/>
            <path d="M15.8625 11.6248C15.7125 12.8998 15.1125 13.9873 14.2125 14.7748L15.5625 15.7123C15.75 15.5248 15.9 15.3748 16.05 15.1873L15.675 14.8873C15.9375 14.5873 16.125 14.2498 16.3125 13.9498L16.7625 14.1748C16.95 13.7998 17.1375 13.4248 17.25 13.0123L16.8 12.8623C16.9125 12.4873 16.9875 12.1123 17.025 11.7373L17.5125 11.7748C17.55 11.3623 17.55 10.9498 17.5125 10.5373L17.025 10.5748C16.9875 10.1998 16.9125 9.82481 16.8 9.44981L17.25 9.2998C17.1375 8.8873 16.9875 8.51231 16.7625 8.13731L16.3125 8.3623C16.125 8.0248 15.9375 7.68731 15.675 7.38731L16.05 7.0873C15.7875 6.7498 15.4875 6.4873 15.15 6.1873L14.85 6.5623C14.55 6.2998 14.25 6.1123 13.9125 5.9248L14.1375 5.4748C13.7625 5.2873 13.3875 5.0998 12.975 4.9873L12.825 5.4748C12.4875 5.3623 12.1125 5.2873 11.7375 5.2498L11.625 6.3748C14.2125 6.6373 16.125 8.99981 15.8625 11.6248Z" fill="#FFE62E"/>
            <path d="M13.9875 15.45C12.7125 14.55 11.4375 13.65 10.3125 12.6L10.3875 12.525C11.475 13.5375 12.7875 14.475 14.025 15.3375C14.6625 15.7875 15.3 16.2375 15.9 16.6875L16.125 16.5C14.025 15 9.07497 11.55 8.99997 10.3125C8.02497 12.225 9.26247 13.35 10.05 13.8375C12.075 15 13.425 15.75 15.5625 17.175L15.8625 16.8375C15.2625 16.3125 14.625 15.8625 13.9875 15.45Z" fill="#FFE62E"/>
        </SvgIcon>
    )
};

export default CountryAGOIcon;
