import React from "react";
import { useTranslation } from "react-i18next";
import Typography from "@material-ui/core/Typography";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Button from "@material-ui/core/Button";

import { ExclamationIcon, CrossIcon } from "../../../assets/icons";

import { ExclamationModalProps } from "./props";
import useStyles from "./css";

const ExclamationModal = (props: ExclamationModalProps) => {

    const { t } = useTranslation(["general"]);
    const { open, title, body, okTextButton, height, onClose } = props;
    const classes = useStyles();

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modalYellow}
            open={open}
            onClose={onClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}>

            <Fade in={open}>
                <div className={classes.modalPaperYellow} style={{height: height || 388}}>
                    <div className={classes.modalHeader}>
                        <div className={classes.modalHeaderContent}>
                            <div className={classes.modalCircle}>
                                <ExclamationIcon viewBox="0 0 5 16" />
                            </div>
                        </div>
                        <div className={classes.modalClose}>
                            <span className={classes.closeButton} onClick={onClose}>
                                <CrossIcon />
                            </span>
                        </div>
                    </div>

                    <div className={classes.modalContent}>

                        <div className={`${classes.modalCenter} ${classes.modalTitleSpace}`} >
                            <Typography className={classes.modalTitleText}>
                                {title}
                            </Typography>
                        </div>
                        
                        <div className={classes.separator}></div>

                        <div>
                            {body}
                        </div>

                        <div className={classes.modalActions}>
                            <Button className={`${classes.button} ${classes.btnOk}`} onClick={onClose} >
                                {okTextButton ? okTextButton : t("Ok")}
                            </Button>
                        </div>

                    </div>
                </div>
            </Fade>
        </Modal>
    );
};

export default ExclamationModal;
