import React, { useState } from "react";

import { DatePicker, KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import enLocale from "date-fns/locale/en-US";

import { DateInputProps } from "./props";
import { CalendarIcon } from "../../../assets/icons";
import useStyles from "./css";
import { useTheme } from "@material-ui/core/styles";
import { useAppSelector } from "../../../redux/hooks";
import useMediaQuery from "@material-ui/core/useMediaQuery";


export default function DateInput(props: DateInputProps) {
  
  const {
    id,
    name,
    value,
    isValid,
    disabled,
    placeholder,
    width,
    format,
    minDate,
    maxDate,
    inputTextClass,
    iconColor,
    handleDisableDate,
    onChange,
    onKeyPress,
    onBlur
  } = props;
  
  const [open, setOpen] = useState<boolean>(false);
  const classes = useStyles(width)();
  const theme = useTheme();
  const isMdMedia = useMediaQuery(theme.breakpoints.up("md"));
  const localeApp = useAppSelector(state => state.scheduler.locale);
  
  const getId = (): string => {
    if (id) {
      return id;
    }
    
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  };
  
  const [inputId] = useState<string>(id || getId());
  
  const handleOnChange = (event: any) => {
    if (onChange) {
      onChange(event);
    }
  };
  
  const handleOnKeyPress = (event: any) => {
    if (onKeyPress) {
      onKeyPress(event);
    }
  };
  
  const handleOnBlur = (event: any) => {
    if (onBlur) {
      onBlur(event);
    }
  }
  
  const isError = (): boolean => {
    if (!isValid) {
      return false;
    }
    
    if (isValid() === undefined) {
      return false;
    }
    
    return !isValid();
  };
  
  const getLocale = (): any => {
    switch (localeApp) {
      case "es":
        return esLocale;
      
      default:
        return enLocale;
    }
  };
  
  const disabledDate = (date: any) => {
    return handleDisableDate ? handleDisableDate(date) : true;
  }
  
  const formatDatePicker = (date: Date) => {
    const now = new Date();
    if (date.toDateString() === now.toDateString()) {
      return localeApp == 'es'
        ? "'Hoy', dd 'de' MMMM yyyy"
        : "'Today', MMMM dd, yyyy";
    }
    return localeApp == 'es'
      ? "EEEE, dd 'de' MMMM yyyy"
      : "EEEE, MMMM dd, yyyy";
  };
  
  return (
    <div className={classes.root}>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={getLocale()}>
        <DatePicker
          id={id}
          name={name}
          placeholder={placeholder}
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          autoOk
          variant="inline"
          shouldDisableDate={disabledDate}
          format={format ?? formatDatePicker(value || new Date())}
          disabled={disabled ?? false}
          PopoverProps={{
            style: {
              marginTop: 10,
              marginLeft: isMdMedia ? -5 : 0,
              display: isMdMedia ? "inline" : "flex",
              justifyContent: "center",
              alignItems: "center"
            },
            classes: {
              paper: classes.paper
            },
          }}
          InputProps={{
            disableUnderline: true,
            className: `${classes.input} ${isError() ? classes.inputError : ""}`,
            classes:
              {
                input: inputTextClass ?? classes.inputText,
                disabled: classes.inputDisabled
              },
          }}
          margin="none"
          value={value || null}
          minDate={minDate}
          maxDate={maxDate}
          onChange={handleOnChange}
          onKeyPress={handleOnKeyPress}
          helperText={null}
        />
        <div className={classes.icon} onClick={() => setOpen(!disabled ?? true)}>
          <CalendarIcon width={15} height={18} style={{ color: disabled ? "#919CA5" : iconColor ?? "#6462F3" }}/>
        </div>
      </MuiPickersUtilsProvider>
    </div>
  );
}