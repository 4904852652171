import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const CountryMEXIcon = (props: any) => {
    return (
        <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props} >
            <path d="M23.25 12.0002C23.25 7.08769 20.1375 2.9252 15.75 1.3877V22.6127C20.1375 21.0752 23.25 16.9127 23.25 12.0002Z" fill="#ED4C5C"/>
            <path d="M0.75 12.0002C0.75 16.9127 3.9 21.0752 8.25 22.6127V1.3877C3.9 2.9252 0.75 7.08769 0.75 12.0002Z" fill="#75A843"/>
            <path d="M15.75 1.3875C14.5875 0.975 13.3125 0.75 12 0.75C10.6875 0.75 9.4125 0.975 8.25 1.3875V22.6125C9.4125 23.025 10.6875 23.25 12 23.25C13.3125 23.25 14.5875 23.025 15.75 22.6125V1.3875Z" fill="white"/>
            <path d="M15.2625 11.8876C15.225 11.7751 15.1875 11.9251 15.1875 11.9626C15.1875 11.8501 15.15 11.7751 15.075 11.6626C14.9625 11.7751 14.925 11.9251 14.925 12.0751C14.8875 11.9626 14.85 11.8501 14.7375 11.8126C14.8125 11.9251 14.775 12.0001 14.775 12.1126C14.775 12.2626 14.8875 12.3751 14.8875 12.5251C14.8875 12.6751 14.8125 12.7876 14.7 12.9001V12.7876C14.325 12.9376 14.4 13.1251 14.4 13.4626C14.4 13.8001 14.2125 13.7251 13.9875 13.9126C14.025 13.8376 14.025 13.7251 13.9875 13.6501C13.9875 13.8751 13.6875 13.8751 13.65 14.0626C13.6125 14.1376 13.65 14.2876 13.5375 14.3251C13.4625 14.3626 13.35 14.4376 13.35 14.5126C13.275 14.4751 13.2 14.4751 13.0875 14.4751C13.125 14.4376 13.1625 14.4376 13.2 14.4001C12.825 14.2126 12.7125 14.6251 12.375 14.6626C12.2625 14.6626 12 14.5126 12 14.6626C12.3375 14.6626 12.4875 14.9626 12.75 14.9626C12.975 14.9626 13.1625 14.7751 13.3875 14.7751C13.35 14.7751 13.2375 14.7001 13.125 14.7001C13.3125 14.5876 13.425 14.3626 13.65 14.3626C14.025 14.3626 14.2125 14.2876 14.325 13.9126C14.2875 13.9126 14.25 13.9126 14.2125 13.9501C14.2125 13.8001 14.3625 13.6126 14.5125 13.5376C14.8875 13.4626 14.85 13.1626 15.075 12.9001C15 12.9001 14.925 12.9376 14.85 13.0126C14.85 12.7876 14.8125 12.5626 15 12.4126C15.375 12.2251 15.3375 12.1501 15.2625 11.8876Z" fill="#75A843"/>
            <path d="M8.73741 11.8876C8.66241 12.1876 8.62491 12.2626 8.92491 12.4501C9.14991 12.6001 9.07491 12.8251 9.07491 13.0501C8.99991 12.9751 8.96241 12.9376 8.84991 12.9376C9.07491 13.1626 9.03741 13.5001 9.41241 13.5751C9.52491 13.6501 9.71241 13.8376 9.71241 13.9876C9.67491 13.9876 9.63741 13.9501 9.59991 13.9501C9.71241 14.3251 9.89991 14.4001 10.2749 14.4001C10.4999 14.4001 10.6124 14.6251 10.7999 14.7376C10.6874 14.7376 10.5749 14.7751 10.5374 14.8126C10.7624 14.8126 10.9499 15.0376 11.1749 15.0001C11.4374 15.0001 11.6249 14.7001 11.9249 14.7001C11.9249 14.5501 11.6624 14.7001 11.5499 14.7001C11.2499 14.6251 11.1374 14.2501 10.7249 14.4001C10.7624 14.4376 10.7999 14.4751 10.8374 14.4751C10.7624 14.4751 10.6499 14.4376 10.5749 14.5126C10.5374 14.4376 10.4624 14.3626 10.3874 14.3251C10.3124 14.2501 10.3124 14.1376 10.2749 14.0626C10.1999 13.8751 9.93741 13.8376 9.93741 13.6501C9.89991 13.7251 9.89991 13.8376 9.93741 13.9126C9.71241 13.7251 9.52491 13.8001 9.52491 13.4626C9.52491 13.1626 9.59991 12.9376 9.22491 12.7876V12.9001C9.11241 12.7876 9.03741 12.7126 9.03741 12.5251C9.03741 12.3751 9.18741 12.2626 9.14991 12.1126C9.14991 12.0001 9.11241 11.9251 9.18741 11.8126C9.07491 11.8501 9.03741 11.9626 8.99991 12.0751C8.99991 11.9251 8.96241 11.7751 8.84991 11.6626C8.81241 11.7376 8.77491 11.8501 8.73741 11.9626C8.77491 11.8876 8.73741 11.7751 8.73741 11.8876Z" fill="#75A843"/>
            <path d="M13.0124 14.1373C12.9374 14.1373 12.8999 14.0623 12.8624 14.0248C12.8624 13.9498 13.1624 13.8748 13.1999 13.8748V13.7998C12.7124 13.9498 11.2499 14.0623 10.9499 13.5373C10.7999 13.7623 10.5374 13.6123 10.2749 13.4248C10.3124 13.4998 10.4624 13.8748 10.4624 13.8373C10.5374 13.8748 11.0249 13.9873 11.0249 14.0998L10.9124 14.2123C11.6999 14.4748 12.1874 14.5498 13.0124 14.1373Z" fill="#428BC1"/>
            <path d="M12.525 14.1377C12.5625 14.1002 12.5625 14.0627 12.5625 13.9877L12.525 13.8752C12.6 13.8002 12.525 13.6127 12.4125 13.6502C12.3375 13.6877 12.375 13.6877 12.3 13.6502C12.2625 13.6502 12.2625 13.6127 12.225 13.6127H11.55C11.475 13.6127 11.475 13.5752 11.4 13.5752C11.325 13.5377 11.2875 13.6127 11.25 13.6502C11.25 13.7252 11.2875 13.7252 11.2875 13.7627C11.2875 13.8002 11.25 13.8377 11.25 13.9127C11.25 13.9877 11.2875 14.0252 11.325 14.0627C11.175 14.1752 11.325 14.3627 11.475 14.2502C11.5125 14.3252 11.625 14.3252 11.7375 14.3252H12.15C12.225 14.3252 12.375 14.3627 12.4125 14.2502C12.525 14.4377 12.675 14.2127 12.525 14.1377Z" fill="#ED4C5C"/>
            <path d="M13.7251 13.1624C13.4251 12.7874 13.2751 13.3499 12.9376 13.3124C13.0126 12.8624 12.3376 13.0124 12.1501 13.1249C12.1876 13.0874 12.2251 13.0124 12.2626 12.9374C12.1126 12.9374 11.9251 13.0124 11.8126 12.8999C11.5876 12.7499 11.2876 12.6749 11.1001 12.9374C10.9501 12.7124 10.5376 12.4874 10.3126 12.7124C10.3126 12.4874 10.0876 12.1499 9.82512 12.1874C9.52512 12.2249 9.63762 12.5999 9.78762 12.7499C9.90012 12.8624 10.0501 12.8999 10.2001 12.8999C10.2001 12.9749 10.2376 13.0124 10.2751 13.0499C10.4626 13.1999 10.8376 13.2374 11.0251 13.0874C11.0251 13.4624 11.7751 13.4624 11.9626 13.2749C11.8876 13.4624 11.8126 13.8374 12.0751 13.8374C12.3001 13.8374 12.2251 13.6124 12.4126 13.5374C12.5626 13.4624 12.7501 13.4249 12.9001 13.4999C13.1626 13.6499 13.9876 13.6124 13.7251 13.1624Z" fill="#428BC1"/>
            <path d="M10.6499 12.2246C10.6124 12.1496 10.5374 12.0746 10.5374 12.0746C10.3874 12.1121 10.3874 11.9996 10.3499 11.8871C10.3124 11.6996 10.0499 11.6246 10.0499 11.4746C10.0499 11.3246 10.1999 11.1746 10.0874 11.0246C9.97493 10.8746 9.78743 10.7996 9.78743 10.8746C9.74993 10.9496 9.93743 10.9496 9.93743 11.1371C9.93743 11.3621 9.71243 11.5496 9.97493 11.7371C10.1249 11.9621 10.1249 12.3746 10.3874 12.3746C10.4999 12.3746 10.6124 12.3371 10.6499 12.2246Z" fill="#75A843"/>
            <path d="M11.2125 12.0375C11.1375 11.8875 11.2125 11.6625 11.0625 11.55C10.875 11.4 10.5 11.7 10.4625 11.325C10.4625 11.25 10.875 10.8 10.95 10.7625C11.0625 10.6125 11.025 10.35 10.9125 10.275C10.7625 10.2 10.725 10.275 10.6125 10.425C10.65 10.4625 10.8 10.5375 10.8 10.575C10.6875 10.8375 10.35 10.875 10.275 11.1375C10.2 11.325 10.2375 11.625 10.425 11.7375C10.5375 11.8125 10.6125 11.8125 10.725 11.8125C11.0625 11.775 10.875 11.85 10.9125 11.925C10.9125 12 11.025 12.2625 11.2125 12.0375Z" fill="#75A843"/>
            <path d="M10.0874 10.1998C10.0874 10.4248 10.3124 10.3873 10.4624 10.3498C10.4624 10.2748 10.4624 10.1998 10.4999 10.1248C10.4249 10.1623 10.3124 10.1623 10.2749 10.1248C10.1624 10.0498 10.5374 9.89984 10.4999 9.89984C10.4999 9.89984 10.1249 10.0873 10.2749 9.86234C10.3124 9.78734 10.3874 9.71234 10.4249 9.63734C10.4624 9.56234 10.3124 9.63734 10.3124 9.63734C10.2749 9.63734 10.2374 9.63734 10.1999 9.67484C9.97488 9.86234 10.0499 9.97484 10.0874 10.1998Z" fill="#75A843"/>
            <path d="M14.0624 10.2C13.8749 9.9375 13.1624 9.075 12.1499 9C12.0374 9 11.7374 9.1125 11.6249 9.15C11.2874 9.375 11.9999 9.5625 12.0374 9.7125C12.0374 9.7875 12.0749 9.825 12.0749 9.9C11.9624 9.7125 11.8499 9.6 11.8124 9.525C11.6249 9.6 11.3249 9.4875 11.2874 9.3C11.2499 9.375 11.2499 9.4125 11.1749 9.4875C11.1749 9.375 11.1374 9.375 11.0999 9.3C11.0999 9.4125 11.0999 9.5625 10.9874 9.6C11.0249 9.525 10.9874 9.45 10.9499 9.375C10.9499 9.4875 10.9499 9.5625 10.8749 9.6375C10.9124 9.5625 10.8374 9.525 10.8374 9.45C10.7999 9.75 10.3874 9.8625 10.7999 9.975C10.9499 10.0125 11.0249 9.975 11.1749 10.05C11.2124 10.0875 11.2874 10.1625 11.2874 10.125C11.2874 10.1625 11.1374 10.3125 11.2499 10.35C11.2124 10.3875 11.0999 10.6125 11.0999 10.6125C11.2124 10.6125 11.1374 10.6875 11.0624 10.7625C10.9499 10.95 11.0624 11.1375 11.0999 11.3625C11.0999 11.2875 11.2124 11.4 11.2124 11.5125C11.2124 11.6625 11.3624 11.7375 11.3999 11.85C11.2124 11.6625 11.1749 11.85 11.3999 11.9625C11.2124 11.9625 11.1749 12.075 11.3624 12.15C10.9124 12.15 11.2874 12.3375 11.3624 12.375C11.6624 12.45 11.8874 12.225 11.8874 12.1875C11.8874 12.1875 12.4874 12.7875 12.5249 12.7875C12.5624 12.7875 12.8624 12.6375 12.9374 12.7125C13.0124 12.825 13.0874 12.7125 13.1624 12.75C13.2374 12.825 13.5374 12.75 13.6124 12.7125C13.6499 12.7125 14.0249 12.6375 13.9499 12.5625C13.7624 12.4125 13.5374 12.2625 13.3499 12.1125C13.1624 11.9625 12.9749 11.85 12.8249 11.7C12.7499 11.625 12.7499 11.475 12.7124 11.3625C13.0499 11.7 13.3874 11.8875 13.3499 11.25C13.5749 11.4375 14.0249 12.4875 14.0624 12.4875C14.1749 12.4875 14.0624 11.3625 14.0624 11.25C14.2124 11.4 14.2124 12.6375 14.2499 12.6375C14.4374 12.675 14.6999 11.025 14.0624 10.2Z" fill="#89664C"/>
            <path d="M11.3624 12.1127C11.3249 12.1127 11.1374 11.8502 11.1374 11.8127C11.2499 12.2627 10.7999 12.0002 10.9124 11.8127C10.7624 11.7752 10.3874 12.0002 10.5374 12.2252C10.4624 12.3752 11.0624 12.2627 11.0999 12.1877C11.2499 12.5252 11.6249 12.1502 11.3624 12.1127Z" fill="#FFCE31"/>
            <path d="M13.1251 13.2376C13.3126 12.9751 12.5251 12.9001 12.6001 12.6001C12.4876 12.6751 12.3376 12.9376 12.1876 12.8626C12.1126 12.8251 11.8126 13.0126 11.9626 13.1251C11.9626 12.9751 12.0376 13.0876 12.1501 13.0501C12.1126 13.1626 12.0001 13.2751 12.1876 13.3501C12.1126 13.1251 12.4126 13.2376 12.3751 13.1251C12.3001 13.0501 12.6376 13.0501 12.6751 13.0501C12.7876 13.0501 13.1626 13.1626 13.0126 13.2751C13.0126 13.2751 13.0876 13.2751 13.1251 13.2376Z" fill="#FFCE31"/>
            <path d="M10.6125 10.0125C10.575 10.0875 10.425 10.2 10.425 10.3125C10.425 10.3875 10.6125 10.575 10.6875 10.4625C10.4625 10.3125 10.725 10.2 10.8375 10.2C10.875 10.2 10.875 10.275 10.875 10.275C10.9125 10.3125 11.2125 10.2 11.175 10.125C11.2125 9.97503 10.725 9.97503 10.6125 10.0125Z" fill="#FFCE31"/>
        </SvgIcon>
    )
};

export default CountryMEXIcon;
