import React from "react";

import { useHistory } from "react-router-dom";

import { useTranslation, Trans } from "react-i18next";

import { useAppSelector, useAppDispatch } from "../../../redux/hooks";
import { schedulerActions } from "../../../redux/scheduler-slice";

import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import AppointmentSummary from "./../AppointmentSummary";
import { ClearBrandIcon } from "./../../../assets/icons";
import { ScheduleAppointment } from "../../../models/schedule";
import useStyles from "./css";
import { ServiceUserModel } from "../../../models/service/service-user.model";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Grid from "@material-ui/core/Grid";
import CancelAppointmentButton from "../CancelAppointmentButton";


export default function ScheduleAppointmentSuccessfullyStep() {
  
  const classes = useStyles();
  const { t } = useTranslation(["general"]);
  
  const history = useHistory();
  
  const dispatch = useAppDispatch();
  
  const theme = useTheme();
  const isMdMedia = useMediaQuery(theme.breakpoints.up("md"));
  
  const account = useAppSelector((state) => state.scheduler.account);
  const serviceUser = useAppSelector((state) => state.scheduler.serviceUser);
  const isUpdate = useAppSelector((state) => state.scheduler.isUpdate);
  
  const {
    setIsOpenCustomerModal,
    setIsOpenScheduleBusyModal,
    setSchedule,
    setOldSchedule,
    setServiceUser,
    setCurrentDate,
    setCustomerValid,
    setIsUpdate,
    setScheduleAppointment,
  } = schedulerActions;
  
  const handleCreation = () => {
    dispatch(setIsOpenCustomerModal(false));
    dispatch(setIsOpenScheduleBusyModal(false));
    dispatch(setSchedule(undefined));
    dispatch(setOldSchedule(null));
    dispatch(setServiceUser([new ServiceUserModel()]));
    dispatch(setCurrentDate(new Date()));
    dispatch(setCustomerValid(undefined));
    dispatch(setIsUpdate(false));
    dispatch(setScheduleAppointment(new ScheduleAppointment()));
    history.push(`/${account.businessNameUrl}`);
  };
  
  return (
    <div className={classes.successStepRoot}>
      <div className={classes.successStepContainer}>
        {/* Left Desktop Begin */}
        <div className={classes.leftSideImage}/>
        <div className={classes.appntInfoContainer}>
          <div className={classes.rowCenter}>
            {account && account.logo ? (
              <div className={classes.imageCircle} style={{ backgroundImage: `url("${account.logo}")` }}/>
            ) : (
              <div className={classes.brandingCircle} style={{ background: "#6462F3" }}
              >
                <ClearBrandIcon style={{ color: "#F6F6F6" }}/>
              </div>
            )}
          </div>
          <div className={classes.rowCenter} style={{ marginTop: 16 }}>
            <Typography className={classes.brandingTitle}>
              {account.name || "New Life"}
            </Typography>
          </div>
          <div className={classes.rowCenter}>
            <Typography className={classes.greetingsTxt}>
              {t("Thanks for your preference")}
            </Typography>
          </div>
          <div className={classes.rowCenter} style={{ marginTop: isMdMedia ? 32 : 24 }}>
            <Typography
              className={classes.confirmationTxt}
              component={"span"}
            >
              {isUpdate ? (
                <Trans i18nKey="YourAppointmentHasBeenCorrectlyModify" t={t}
                />
              ) : (
                <Trans i18nKey="YourAppointmentHasBeenCorrectlyScheduled" t={t}
                />
              )}
            </Typography>
          </div>
          <div className={classes.dividerTop}/>
          {serviceUser.length && serviceUser.some((user: ServiceUserModel) => user.service?.notes) && (
            <>
              <Typography
                className={classes.label}
                style={{ marginTop: isMdMedia ? 46 : 24 }}
              >
                {t("Service notes:")}
              </Typography>
              
              {serviceUser.map((user: ServiceUserModel) => (
                user.service?.notes ? (
                  <div>
                    <Typography
                      className={classes.caption}
                    >
                      {user.service.notes || ""}
                    </Typography>
                  </div>
                ) : null
              ))}
              
              <div className={classes.dividerBottom}/>
            </>
          )}

          <div className={classes.rowCenter} style={{ marginTop: isMdMedia ? 32 : 24 }}>
            <Typography className={classes.label}>
              {t(
                "Feel free to close this window or make another appointment."
              )}
            </Typography>
          </div>
          <div className={classes.buttonContainer}>
            <Button
              className={`${classes.button} ${classes.buttonCreate}`}
              onClick={() => handleCreation()}
            >
              {t("Make another appointment")}
            </Button>
          </div>
        
        </div>
        {/* Left Desktop End */}
        {/* Right Desktop Begin */}
        <div className={classes.summaryContainer}>
          <Typography
            className={classes.rightTitle}
            style={{ marginTop: 40 }}
          >
            {t("APPOINTMENT SUMMARY")}
          </Typography>
          <div style={{ boxShadow: "0px 3px 20px rgba(229, 231, 234, 0.56)" }}>
            <AppointmentSummary/>
          </div>
          <div className={classes.cancelButtonContainer}>
            {account.allowCustomerToCancelFromSite && isUpdate &&

                <CancelAppointmentButton classes={classes} />
            }
          </div>
        </div>
        
        {/* Right Desktop End */}
      </div>
    </div>
  );
}