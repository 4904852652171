import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const CountryPRYIcon = (props: any) => {
    return (
        <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props} >
            <path d="M12 23.25C16.9124 23.25 21.0749 20.1375 22.6124 15.75H1.38745C2.92495 20.1375 7.08745 23.25 12 23.25Z" fill="#428BC1"/>
            <path d="M12 0.75C7.08745 0.75 2.92495 3.9 1.38745 8.25H22.6124C21.0749 3.9 16.9124 0.75 12 0.75Z" fill="#ED4C5C"/>
            <path d="M22.6125 15.75C23.025 14.5875 23.25 13.3125 23.25 12C23.25 10.6875 23.025 9.4125 22.6125 8.25H1.3875C0.975 9.4125 0.75 10.6875 0.75 12C0.75 13.3125 0.975 14.5875 1.3875 15.75H22.6125Z" fill="#F9F9F9"/>
            <path d="M12 15.375C10.125 15.375 8.625 13.875 8.625 12C8.625 10.125 10.125 8.625 12 8.625C13.875 8.625 15.375 10.125 15.375 12C15.375 13.875 13.875 15.375 12 15.375ZM12 8.7375C10.2 8.7375 8.7375 10.2 8.7375 12C8.7375 13.8 10.2 15.2625 12 15.2625C13.8 15.2625 15.2625 13.8 15.2625 12C15.2625 10.2 13.8 8.7375 12 8.7375Z" fill="#3E4347"/>
            <path d="M11.9999 9.1499C10.4249 9.1499 9.1499 10.4249 9.1499 11.9999C9.1499 13.5749 10.4249 14.8499 11.9999 14.8499C13.5749 14.8499 14.8499 13.5749 14.8499 11.9999C14.8499 10.4249 13.5749 9.1499 11.9999 9.1499ZM11.9999 14.1374C10.8374 14.1374 9.8624 13.1624 9.8624 11.9999C9.8624 10.8374 10.8374 9.8624 11.9999 9.8624C13.1624 9.8624 14.1374 10.8374 14.1374 11.9999C14.1374 13.1624 13.1624 14.1374 11.9999 14.1374Z" fill="#ED4C5C"/>
            <path d="M9.74995 12.0002C9.74995 10.7627 10.7625 9.75019 12 9.75019C13.2375 9.75019 14.2499 10.7627 14.2499 12.0002H14.7374C14.7374 10.5002 13.5 9.2627 12 9.2627C10.5 9.2627 9.26245 10.5002 9.26245 12.0002H9.74995Z" fill="#FFCE31"/>
            <path d="M13.0876 11.8873C13.0876 12.4873 12.6001 12.9748 12.0001 12.9748C11.4001 12.9748 10.9126 12.4873 10.9126 11.8873C10.9126 11.2873 11.4001 10.7998 12.0001 10.7998C12.6001 10.7998 13.0876 11.2873 13.0876 11.8873Z" fill="#428BC1"/>
            <path d="M12.6001 12.6001L12.0001 12.1876L11.4001 12.6001L11.6251 11.9626L11.1001 11.5501H11.7376L11.9626 10.9126L12.1876 11.5501H12.8251L12.3751 11.9626L12.6001 12.6001Z" fill="#FFCE31"/>
            <path d="M11.0249 11.0624C11.0249 11.0624 10.8749 11.0249 10.7999 11.0249C10.8749 10.9124 10.9124 10.6499 10.9124 10.6499C10.9124 10.6499 10.6874 10.7624 10.6124 10.9124C10.5749 10.8374 10.4999 10.7249 10.4999 10.7249C10.4999 10.7249 10.3874 10.8374 10.4249 10.9499C10.4249 11.0624 10.5749 11.2124 10.5749 11.2124C10.5749 11.2124 10.7624 11.2874 10.8749 11.2499C10.9499 11.1749 11.0249 11.0624 11.0249 11.0624Z" fill="#699635"/>
            <path d="M10.8376 11.3624C10.8376 11.3624 10.6876 11.3624 10.6126 11.3999C10.6501 11.2499 10.6126 11.0249 10.6126 11.0249C10.6126 11.0249 10.4251 11.2124 10.3876 11.3249C10.3126 11.2499 10.2001 11.1749 10.2001 11.1749C10.2001 11.1749 10.1251 11.3249 10.1626 11.3999C10.2001 11.4749 10.3876 11.6249 10.3876 11.6249C10.3876 11.6249 10.6126 11.6249 10.6876 11.5874C10.7626 11.5499 10.8376 11.3624 10.8376 11.3624Z" fill="#83BF4F"/>
            <path d="M10.7251 11.6999C10.7251 11.6999 10.5751 11.7374 10.5001 11.7749C10.5001 11.6249 10.4251 11.3999 10.4251 11.3999C10.4251 11.3999 10.3126 11.6249 10.3126 11.7749C10.2376 11.6999 10.1251 11.6624 10.1251 11.6624C10.1251 11.6624 10.0876 11.8124 10.1626 11.8874C10.2376 11.9624 10.4251 12.0374 10.4251 12.0374C10.4251 12.0374 10.6126 11.9999 10.6876 11.9249C10.7626 11.8499 10.7251 11.6999 10.7251 11.6999Z" fill="#699635"/>
            <path d="M10.7249 12.0752C10.7249 12.0752 10.6124 12.1502 10.5374 12.2252C10.4999 12.0752 10.3499 11.8877 10.3499 11.8877C10.3499 11.8877 10.2749 12.1127 10.3124 12.2627C10.1999 12.2252 10.0874 12.2252 10.0874 12.2252C10.0874 12.2252 10.0874 12.3752 10.1999 12.4502C10.2749 12.5252 10.4999 12.5252 10.4999 12.5252C10.4999 12.5252 10.6874 12.4127 10.7249 12.3377C10.7624 12.2627 10.7249 12.0752 10.7249 12.0752Z" fill="#83BF4F"/>
            <path d="M10.8 12.4124C10.8 12.4124 10.6875 12.5249 10.65 12.5999C10.575 12.4499 10.3875 12.3374 10.3875 12.3374C10.3875 12.3374 10.3875 12.5624 10.4625 12.7124H10.2375C10.2375 12.7124 10.275 12.8624 10.3875 12.8999C10.5 12.9374 10.6875 12.8999 10.6875 12.8999C10.6875 12.8999 10.8375 12.7499 10.875 12.6374C10.9125 12.5249 10.8 12.4124 10.8 12.4124Z" fill="#699635"/>
            <path d="M10.9874 12.75C10.9874 12.75 10.9124 12.8625 10.8749 12.975C10.7624 12.8625 10.5374 12.7875 10.5374 12.7875C10.5374 12.7875 10.6124 13.0125 10.6874 13.125C10.5749 13.125 10.4624 13.2 10.4624 13.2C10.4624 13.2 10.5374 13.3125 10.6499 13.35C10.7624 13.35 10.9499 13.275 10.9499 13.275C10.9499 13.275 11.0624 13.0875 11.0624 12.975C11.0999 12.825 10.9874 12.75 10.9874 12.75Z" fill="#83BF4F"/>
            <path d="M11.2125 13.0127C11.2125 13.0127 11.175 13.1627 11.175 13.2377C11.0625 13.1627 10.8 13.1252 10.8 13.1252C10.8 13.1252 10.9125 13.3127 11.0625 13.4252C10.9875 13.4627 10.875 13.5377 10.875 13.5377C10.875 13.5377 10.9875 13.6502 11.1 13.6127C11.2125 13.6127 11.3625 13.4627 11.3625 13.4627C11.3625 13.4627 11.4375 13.2752 11.4 13.1627C11.3625 13.0877 11.2125 13.0127 11.2125 13.0127Z" fill="#699635"/>
            <path d="M11.5499 13.2002C11.5499 13.2002 11.5499 13.3502 11.5874 13.4252C11.4374 13.3877 11.2124 13.4252 11.2124 13.4252C11.2124 13.4252 11.3999 13.5752 11.5124 13.6502L11.3624 13.8002C11.3624 13.8002 11.5124 13.8752 11.5874 13.8377C11.6999 13.8002 11.8124 13.6127 11.8124 13.6127C11.8124 13.6127 11.8124 13.3877 11.7749 13.3127C11.6624 13.2377 11.5499 13.2002 11.5499 13.2002Z" fill="#83BF4F"/>
            <path d="M12.9751 11.0624C12.9751 11.0624 13.1251 11.0249 13.2001 11.0249C13.1251 10.9124 13.0876 10.6499 13.0876 10.6499C13.0876 10.6499 13.3126 10.7624 13.3876 10.9124C13.4251 10.8374 13.5001 10.7249 13.5001 10.7249C13.5001 10.7249 13.6126 10.8374 13.5751 10.9499C13.5751 11.0624 13.4251 11.2124 13.4251 11.2124C13.4251 11.2124 13.2376 11.2499 13.1251 11.2124C13.0501 11.1749 12.9751 11.0624 12.9751 11.0624Z" fill="#699635"/>
            <path d="M13.1626 11.3624C13.1626 11.3624 13.3126 11.3624 13.3876 11.3999C13.3501 11.2499 13.3876 11.0249 13.3876 11.0249C13.3876 11.0249 13.5751 11.2124 13.6126 11.3249C13.6876 11.2499 13.8001 11.1749 13.8001 11.1749C13.8001 11.1749 13.8751 11.3249 13.8376 11.3999C13.8001 11.4749 13.5751 11.6249 13.5751 11.6249C13.5751 11.6249 13.3501 11.6249 13.2751 11.5874C13.2001 11.5499 13.1626 11.3624 13.1626 11.3624Z" fill="#83BF4F"/>
            <path d="M13.275 11.6999C13.275 11.6999 13.425 11.7374 13.5 11.7749C13.5 11.6249 13.575 11.3999 13.575 11.3999C13.575 11.3999 13.6875 11.6249 13.6875 11.7749C13.7625 11.6999 13.875 11.6624 13.875 11.6624C13.875 11.6624 13.9125 11.8124 13.8375 11.8874C13.7625 11.9624 13.575 12.0374 13.575 12.0374C13.575 12.0374 13.3875 11.9999 13.3125 11.9249C13.2375 11.8499 13.275 11.6999 13.275 11.6999Z" fill="#699635"/>
            <path d="M13.275 12.0752C13.275 12.0752 13.3875 12.1502 13.4625 12.2252C13.5 12.0752 13.65 11.8877 13.65 11.8877C13.65 11.8877 13.725 12.1127 13.6875 12.2627C13.8 12.2252 13.9125 12.2252 13.9125 12.2252C13.9125 12.2252 13.9125 12.3752 13.8 12.4502C13.725 12.5252 13.5 12.5252 13.5 12.5252C13.5 12.5252 13.3125 12.4127 13.275 12.3377C13.2375 12.2252 13.275 12.0752 13.275 12.0752Z" fill="#83BF4F"/>
            <path d="M13.2001 12.4124C13.2001 12.4124 13.3126 12.5249 13.3501 12.5999C13.4251 12.4499 13.6126 12.3374 13.6126 12.3374C13.6126 12.3374 13.6126 12.5624 13.5376 12.7124H13.7626C13.7626 12.7124 13.7251 12.8624 13.6126 12.8999C13.5001 12.9374 13.3126 12.8999 13.3126 12.8999C13.3126 12.8999 13.1626 12.7499 13.1251 12.6374C13.0876 12.5249 13.2001 12.4124 13.2001 12.4124Z" fill="#699635"/>
            <path d="M13.0126 12.75C13.0126 12.75 13.0876 12.8625 13.1251 12.975C13.2376 12.8625 13.4626 12.7875 13.4626 12.7875C13.4626 12.7875 13.3876 13.0125 13.3126 13.125C13.4251 13.125 13.5376 13.2 13.5376 13.2C13.5376 13.2 13.4626 13.3125 13.3501 13.35C13.2376 13.35 13.0501 13.275 13.0501 13.275C13.0501 13.275 12.9376 13.0875 12.9376 12.975C12.9001 12.825 13.0126 12.75 13.0126 12.75Z" fill="#83BF4F"/>
            <path d="M12.7876 13.0127C12.7876 13.0127 12.8251 13.1627 12.8251 13.2377C12.9376 13.1627 13.2001 13.1252 13.2001 13.1252C13.2001 13.1252 13.0876 13.3127 12.9376 13.4252C13.0126 13.4627 13.1251 13.5377 13.1251 13.5377C13.1251 13.5377 13.0126 13.6502 12.9001 13.6127C12.7876 13.6127 12.6376 13.4627 12.6376 13.4627C12.6376 13.4627 12.5626 13.2752 12.6001 13.1627C12.6376 13.0877 12.7876 13.0127 12.7876 13.0127Z" fill="#699635"/>
            <path d="M12.45 13.2002C12.45 13.2002 12.45 13.3502 12.4125 13.4252C12.5625 13.3877 12.7875 13.4252 12.7875 13.4252C12.7875 13.4252 12.6 13.5752 12.4875 13.6502L12.6375 13.8002C12.6375 13.8002 12.4875 13.8752 12.4125 13.8377C12.3 13.8002 12.1875 13.6127 12.1875 13.6127C12.1875 13.6127 12.1875 13.3877 12.225 13.3127C12.3375 13.2377 12.45 13.2002 12.45 13.2002Z" fill="#83BF4F"/>
        </SvgIcon>
    )
};

export default CountryPRYIcon;
