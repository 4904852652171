import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const CountryTTOIcon = (props: any) => {
    return (
        <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props} >
            <path d="M20.4903 3.50972C17.0061 0.0254982 11.9199 -0.855569 7.59467 0.826468L23.1735 16.4053C24.8556 12.0801 23.9745 6.99394 20.4903 3.50972ZM3.50972 20.4903C6.99394 23.9745 12.0801 24.8556 16.4053 23.1735L0.826468 7.59467C-0.855569 11.9199 0.0254982 17.0061 3.50972 20.4903Z" fill="#ED4C5C"/>
            <path d="M3.50974 3.50974C2.70877 4.31071 2.06799 5.15173 1.54736 6.07284L17.9272 22.4527C18.8483 21.932 19.7294 21.2913 20.4903 20.4903C21.2913 19.6893 21.932 18.8483 22.4527 17.9272L6.07284 1.54736C5.15173 2.06799 4.27066 2.70877 3.50974 3.50974Z" fill="#3E4347"/>
            <path d="M1.14706 6.83345C1.02691 7.07374 0.906767 7.35408 0.786621 7.63442L16.4055 23.1732C16.6859 23.0531 16.9262 22.973 17.2065 22.8128C17.4468 22.6927 17.6871 22.5725 17.9274 22.4123L1.54754 6.07253C1.4274 6.31282 1.2672 6.55311 1.14706 6.83345ZM7.59487 0.826172C7.31453 0.946317 7.07424 1.02641 6.7939 1.18661C6.55361 1.30675 6.31331 1.4269 6.07302 1.58709L22.4529 17.9669C22.573 17.7266 22.7332 17.4863 22.8533 17.2461C22.9735 16.9657 23.0936 16.7254 23.2138 16.4451L7.59487 0.826172Z" fill="white"/>
        </SvgIcon>
    )
};

export default CountryTTOIcon;
