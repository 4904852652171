import React, { useEffect, useState } from 'react';

import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { schedulerActions } from "../../../redux/scheduler-slice";

import { Button } from "@material-ui/core";

import useStyles from "./css";
import Country from "../../../models/common/country";
import { AppointmentService } from "../../../api";
import { PatientFormProps } from "./props";
import { PatientFormService } from "../../../api/patient-form-service";
import PatientFormModel from "../../../models/patientform/patient-form-model";
import PatientFormSection from "../PatientFormSection";
import PatientFormSectionModel from "../../../models/patientform/patient-form-section-model";
import PatientFormHeader from "../PatientFormHeader";
import PatientFormBasicInformation from "../PatientFormBasicInformation";
import CustomerInfo from "../../../models/customer/customer-info";
import PatientFormStepper from "../PatientFormStepper";
import MobileNumber from "../../../models/common/mobile-number";
import { Validation } from "../../../constants";
import PatientFormTypedControl from "../../../models/patientform/patient-form-typed-control";
import { PatientFormControlType } from "../../../models/patientform/patient-form-control-type";
import RadioValue from "../../../models/common/radio-value";
import PatientFormSignatureStep from "../PatientFormSignatureStep";
import PatientFormAcceptanceModel from "../../../models/patientform/patient-form-acceptance-model";
import PatientFormFinished from "../PatientFormFinished";
import { Gender } from '../../../models/customer/gender';


export default function PatientForm(props: PatientFormProps) {

  const { previewStep, customerId, isMobile } = props;

  const { t } = useTranslation("patient-form");

  const classes = useStyles(isMobile)();

  const [formData, setFormData] = useState<PatientFormModel>(new PatientFormModel())
  const [activeStep, setActiveStep] = useState<0 | 1 | 2 | 3>(0);

  const dispatch = useAppDispatch();
  const { setCountries, setCountryInSettings, setShowLoader } = schedulerActions;

  const account = useAppSelector((state) => state.scheduler.account);
  const localeApp = useAppSelector(state => state.scheduler.locale);
  const countries = useAppSelector(state => state.scheduler.countries);

  const isClinicalHistoryStepEnabled = account.isClinicalHistoryEnabled;

  useEffect(() => {

    switch (previewStep) {
      case "first":
        setActiveStep(0);
        break;
      case "second":
        setActiveStep(1);
        break;
      default:
        setActiveStep(0);
        break;
    }
  }, [previewStep]);

  const customerInfoProgress = [
    Validation.validateName(formData.customerInfo.name),
    Validation.validateName(formData.customerInfo.lastName),
    validateMobileNumber(formData.customerInfo.mobile)].reduce((previousValue, currentValue) => { return previousValue + (currentValue ? 1 : 0) }, 0)
  const customerInfoProgressRequired = 3;

  const generalInfoProgress = formData.generalInfo.reduce(getSectionGroupProgress, 0)
  const generalInfoProgressRequired = formData.generalInfo.reduce(getSectionGroupRequiredProgress, 0);

  const clinicHistoryProgress = formData.clinicHistory.reduce(getSectionGroupProgress, 0)
  const clinicHistoryProgressRequired = formData.clinicHistory.reduce(getSectionGroupRequiredProgress, 0);

  const signatureProgress = [
    formData.acceptance.isPrivacyNoticeEnabled && formData.acceptance.hasAcceptedPrivacyNotice,
    formData.acceptance.isConsentLetterEnabled && formData.acceptance.hasReadConsentLetter,
    formData.acceptance.signature !== null
  ].reduce((previousValue, currentValue) => { return previousValue + (currentValue ? 1 : 0) }, 0)
  const signatureProgressRequired = [
    formData.acceptance.isPrivacyNoticeEnabled,
    formData.acceptance.isConsentLetterEnabled,
    true //Signature is always required
  ].reduce((previousValue, currentValue) => { return previousValue + (currentValue ? 1 : 0) }, 0)

  function _setCountries(value: Country[]) {
    dispatch(setCountries(value))
  }

  function _setCountryInSettings(value: Country) {
    dispatch(setCountryInSettings(value))
  }

  async function loadCountries() {
    if (account && account.id) {
      const response = await AppointmentService.getCountries(account.id, localeApp);

      const listCountries: Country[] = response.country;
      const countryInSettings: Country = response.countryInSettings;

      _setCountries(listCountries || []);
      _setCountryInSettings(countryInSettings || new Country());
    }
  }

  async function loadFormData() {
    if (account && account.id) {
      dispatch(setShowLoader(true));
      const response = await PatientFormService.getPatientFormData(account.id, customerId);
      dispatch(setShowLoader(false));
      updateResponseWithLocalStorageValues(response);
      setFormData(response);
    }
  }

  useEffect(() => {
    (async () => loadCountries()
    )();
    (async () => loadFormData()
    )();
  }, [account?.id])

  function updateResponseWithLocalStorageValues(patientFormData: PatientFormModel) {
    if (previewStep !== null) return;

    for (const [key, value] of Object.entries(localStorage)) {
      if (!key.startsWith("patient_form") || !key.includes(customerId!))
        continue;

      if (key.endsWith("first_name")) {
        patientFormData.customerInfo.name = value;
        continue;
      }
      if (key.endsWith("last_name")) {
        patientFormData.customerInfo.lastName = value;
        continue;
      }
      if (key.endsWith("email")) {
        patientFormData.customerInfo.email = value;
        continue;
      }
      if (key.endsWith("gender")) {
        patientFormData.customerInfo.gender = JSON.parse(value) as Gender;
        continue;
      }
      if (key.endsWith("gender_custom")) {
        patientFormData.customerInfo.customGender = value;
        continue;
      }
      if (key.endsWith("mobile")) {
        patientFormData.customerInfo.mobile = JSON.parse(value) as MobileNumber;
        continue;
      }
      if (key.endsWith("dob")) {
        patientFormData.customerInfo.dateOfBirth = JSON.parse(value) as Date;
        continue;
      }
      if (key.endsWith("agreed_to_privacy_notice")) {
        patientFormData.acceptance.hasAcceptedPrivacyNotice = JSON.parse(value) as boolean;
        continue;
      }
      if (key.endsWith("read_informed_consent_letter")) {
        patientFormData.acceptance.hasReadConsentLetter = JSON.parse(value) as boolean;
        continue;
      }
      if (key.endsWith("signature")) {
        patientFormData.acceptance.signature = JSON.parse(value) as string | null;
        continue;
      }

      const allFields = patientFormData.generalInfo
        .flatMap(s => s.controls)
        .concat(patientFormData.clinicHistory
          .flatMap(s => s.controls));
      const fieldId = key.split("_").pop();

      const patientField = allFields.find(f => f.fieldId === fieldId);
      if (!patientField)
        continue;

      switch (patientField.type) {
        case PatientFormControlType.PhoneNumber:
          patientField.value = JSON.parse(value) as MobileNumber;
          break;
        case PatientFormControlType.Email:
          patientField.value = JSON.parse(value) as string;
          break;
        case PatientFormControlType.Radio:
          patientField.value = JSON.parse(value) as RadioValue;
          break;
        case PatientFormControlType.Text:
          patientField.value = JSON.parse(value) as string;
          break;
      }
    }
  }

  function validateMobileNumber(value: MobileNumber) {
    return Validation.validateMobileNumberWithCountries(value, countries);
  }

  function setCustomerInfo(newCustomerInfo: CustomerInfo) {
    setFormData({ ...formData, customerInfo: newCustomerInfo })
  }

  const setGeneralInfoSection = (index: number) => (newSection: PatientFormSectionModel) => {
    const newGeneralInfo = [...formData.generalInfo]
    newGeneralInfo[index] = newSection;
    setFormData({ ...formData, generalInfo: newGeneralInfo })
  }

  const setClinicHistorySection = (index: number) => (newSection: PatientFormSectionModel) => {
    const newClinicHistory = [...formData.clinicHistory]
    newClinicHistory[index] = newSection;
    setFormData({ ...formData, clinicHistory: newClinicHistory })
  }

  function setAcceptance(signature: PatientFormAcceptanceModel) {
    setFormData({ ...formData, acceptance: signature })
  }

  function getSectionGroupProgress(previousValue: number, currentValue: PatientFormSectionModel): number {
    return previousValue + currentValue.controls.reduce(getSectionProgress, 0)
  }

  function getSectionProgress(previousValue: number, currentValue: PatientFormTypedControl): number {
    return previousValue + getControlProgress(currentValue);
  }

  function sectionIsValidated(section: PatientFormSectionModel) {
    return section.controls.every(controlIsValidated);
  }

  function getControlProgress(control: PatientFormTypedControl): number {
    if (!control.isRequired) return 0;
    switch (control.type) {
      case PatientFormControlType.PhoneNumber:
        return Validation.validateMobileNumberWithCountries(control.value as MobileNumber, countries) ? 1 : 0;
      case PatientFormControlType.Email:
        return Validation.validateEmail(control.value as string) ? 1 : 0;
      case PatientFormControlType.Radio:
        return Validation.validateRadio(control.value as RadioValue) ? 1 : 0;
      case PatientFormControlType.Text:
        return Validation.validateText(control.value as string) ? 1 : 0;
    }
    return 0;
  }

  function isMobileControlValidated(isRequired: boolean, value: MobileNumber): boolean {
    return isRequired
      ? Validation.validateMobileNumberWithCountries(value, countries)
      : value === null || value.body === "" || Validation.validateMobileNumberWithCountries(value, countries);
  }

  function isEmailControlValidated(isRequired: boolean, value: string): boolean {
    return isRequired
      ? Validation.validateEmail(value)
      : value === null || value === "" || Validation.validateEmail(value);
  }

  function isRadioControlValidated(isRequired: boolean, value: RadioValue): boolean {
    return isRequired
      ? Validation.validateRadio(value)
      : value === null || value.item === null || Validation.validateRadio(value);
  }

  function controlIsValidated(control: PatientFormTypedControl): boolean {
    switch (control.type) {
      case PatientFormControlType.PhoneNumber:
        return isMobileControlValidated(control.isRequired, control.value as MobileNumber);
      case PatientFormControlType.Email:
        return isEmailControlValidated(control.isRequired, control.value as string);
      case PatientFormControlType.Radio:
        return isRadioControlValidated(control.isRequired, control.value as RadioValue);
      case PatientFormControlType.Text:
        return true;
    }
    return true;
  }

  function getSectionGroupRequiredProgress(previousValue: number, currentValue: PatientFormSectionModel): number {
    return previousValue + currentValue.controls.reduce(getSectionRequiredProgress, 0)
  }

  function getSectionRequiredProgress(previousValue: number, currentValue: PatientFormTypedControl): number {
    return previousValue + (currentValue.isRequired ? 1 : 0);
  }

  function isFormReadyToFinalize(): boolean {
    return activeStep === 2;
  }

  function stepOneIsReady(): boolean {
    return customerInfoProgress + generalInfoProgress === customerInfoProgressRequired + generalInfoProgressRequired;
  }

  function stepTwoIsReady(): boolean {
    return clinicHistoryProgress === clinicHistoryProgressRequired;
  }

  function stepThreeIsReady(): boolean {
    return signatureProgress === signatureProgressRequired;
  }

  function stepOneIsValidated(): boolean {
    return isEmailControlValidated(false, formData.customerInfo.email) && formData.generalInfo.every(sectionIsValidated);
  }

  function stepTwoIsValidated(): boolean {
    return formData.clinicHistory.every(sectionIsValidated);
  }

  async function handleFormFinish() {
    if (previewStep !== null || !customerId) {
      setActiveStep(3);
      return;
    }
    if (!(account && account.id)) return;

    await PatientFormService.savePatientFormData(account.id, customerId, formData)
      .finally(() => {
        setActiveStep(3);
        removeLocalStorageFieldsValues();
      });
  }

  function isContinueButtonDisabled(): boolean {
    switch (activeStep) {
      case 0: return !(stepOneIsReady() && stepOneIsValidated());
      case 1: return !(stepTwoIsReady() && stepTwoIsValidated());
      case 2: return !stepThreeIsReady();
      default: return false;
    }
  }

  async function handleContinueButtonClick() {
    if (!isClinicalHistoryStepEnabled && activeStep === 0) {
      setActiveStep(2)
      window.scrollTo(0, 0)
      return;
    }
    if (activeStep === 2) {
      await handleFormFinish();
    } else {
      setActiveStep((prev) => prev + 1 as 0 | 1 | 2);
      window.scrollTo(0, 0)
    }
  }

  function handleGoBackButtonClick() {
    if (!isClinicalHistoryStepEnabled && activeStep === 2) {
      setActiveStep(0)
      window.scrollTo(0, 0)
      return;
    }

    setActiveStep((prev) => prev - 1 as 0 | 1 | 2 | 3);
    window.scrollTo(0, 0);
  }

  function removeLocalStorageFieldsValues() {
    for (const [key, _] of Object.entries(localStorage)) {
      if (key.startsWith("patient_form") && key.includes(customerId!)) {
        localStorage.removeItem(key);
      }
    }
  }

  function mapGeneralInfoSectionToRenderElement(section: PatientFormSectionModel, index: number) {
    return (
      <PatientFormSection
        key={section.id}
        section={section}
        setSection={setGeneralInfoSection(index)}
        isMobile={isMobile}
      />
    );
  }

  function mapClinicHistorySectionToRenderElement(section: PatientFormSectionModel, index: number) {
    return (
      <PatientFormSection
        key={section.id}
        section={section}
        setSection={setClinicHistorySection(index)}
        isMobile={isMobile}
      />
    );
  }

  const ButtonsBlock = () =>
    <>
    <div className={classes.marginForFixedButtonsBlock}></div>
      <div className={classes.buttonsBlock}>
        {activeStep !== 0
          ? <Button
            disabled={previewStep === 'second'}
            className={classes.goBackButton}
            onClick={handleGoBackButtonClick}
          >
            {t("Go back")}
          </Button>
          : <></>}
        <Button
          className={classes.continueButton}
          disabled={isContinueButtonDisabled() || previewStep === 'second'}
          onClick={handleContinueButtonClick}
        >
          {isFormReadyToFinalize() ? t("Finish") : t("Continue")}
        </Button>
      </div>
    </>

  const FirstStepContent = () =>
    <>
      <PatientFormHeader
        account={account}
        isMobile={isMobile}
      />
      <PatientFormBasicInformation
        customerInfo={formData.customerInfo}
        setCustomerInfo={setCustomerInfo}
        isMobile={isMobile}
      />
      {
        formData.generalInfo.map(mapGeneralInfoSectionToRenderElement)
      }
      <ButtonsBlock />
    </>

  const SecondStepContent = () =>
    <>
      <PatientFormHeader
        account={account}
        isMobile={isMobile}
      />
      {
        formData.clinicHistory.map(mapClinicHistorySectionToRenderElement)
      }
      <ButtonsBlock />
    </>

  const ThirdStepContent = () =>
    <>
      <PatientFormHeader
        account={account}
        isMobile={isMobile}
      />
      <PatientFormSignatureStep
        value={formData.acceptance}
        setValue={setAcceptance}
        customerMobile={formData.customerInfo.mobile}
        isMobile={isMobile}
      />
      <ButtonsBlock />
    </>

  const FormFinishedContent = () =>
    <>
      <PatientFormFinished
        account={account}
        isMobile={isMobile}
      />
    </>

  return (
    <div className={classes.root}>
      <PatientFormStepper
        activeStep={activeStep}
        isMobile={isMobile}
        isClinicalHistoryStepEnabled={isClinicalHistoryStepEnabled ?? false}
        stepsProgress={[customerInfoProgress + generalInfoProgress, clinicHistoryProgress, signatureProgress]}
        stepsProgressRequired={[customerInfoProgressRequired + generalInfoProgressRequired, clinicHistoryProgressRequired, signatureProgressRequired]}
      />
      <div className={classes.content}>
        <>
          {activeStep === 0 && FirstStepContent()}
          {isClinicalHistoryStepEnabled && activeStep === 1 && SecondStepContent()}
          {activeStep === 2 && ThirdStepContent()}
          {activeStep == 3 && FormFinishedContent()}
        </>
      </div>
    </div>
  );
}