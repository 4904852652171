import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import {useTranslation, Trans} from "react-i18next";

import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import backgroundTop from "../../../assets/images/background-top.png";
import backgroundBottom from "../../../assets/images/background-bottom.png";
import {ClearBrandIcon} from "../../../assets/icons";
import {useAppSelector} from "../../../redux/hooks";

import useStyles from "./css";
import AccountInfoProvider from "../../../components/account/AccountInfoProvider/account-info-provider";

const AppointmentCanceledConfirmation = () => {
    const classes = useStyles();
    const history = useHistory();
    const {t} = useTranslation(["general"]);
    const { accountBusinessName } = useParams<any>();
    const account = useAppSelector((state) => state.scheduler.account);

    const handleCreation = () => {
        history.push(`/${account.businessNameUrl}`);
    };
  
  useEffect(() => {
    if (account.businessNameUrl === null) {
      history.push(`/${accountBusinessName}`);
    }
  }, []);

    return (
        <Grid container spacing={0}>
            <Grid item xs={12}>
                <Grid
                    container
                    spacing={0}
                    style={{
                        top: 0,
                        left: 0,
                        backgroundImage: `url(${backgroundTop}), url(${backgroundBottom})`,
                        backgroundPosition: "left top, left bottom",
                        backgroundRepeat: "no-repeat, no-repeat",
                        width: "100%",
                        height: "100vh",
                        paddingTop: 107,
                    }}
                    justifyContent="center"
                >
                    <Grid item xs={11} className={classes.container}>
                        <div className={classes.rowCenter}>
                            {account && account.logo ? (
                                <div
                                    className={classes.imageCircle}
                                    style={{backgroundImage: `url("${account.logo}")`}}
                                ></div>
                            ) : (
                                <div
                                    className={classes.brandingCircle}
                                    style={{background: "#6462F3"}}
                                >
                                    <ClearBrandIcon style={{color: "#F6F6F6"}}/>
                                </div>
                            )}
                        </div>
                        <div className={classes.rowCenter} style={{marginTop: 16}}>
                            <Typography className={classes.brandingTitle}>
                                {account.name || "New Life"}
                            </Typography>
                        </div>
                        <div className={classes.rowCenter}>
                            <Typography className={classes.greetingsTxt}>
                                {t("Thanks for your preference")}
                            </Typography>
                        </div>
                        <div className={classes.rowCenter} style={{marginTop: 32}}>
                            <Typography
                                className={classes.confirmationTxt}
                                component={"span"}
                            >
                                <Trans
                                    i18nKey="YourAppointmentSuccessfullyCancelled"
                                    t={t}
                                />
                            </Typography>
                        </div>

                        <div
                            style={{
                                borderTop: "1px solid rgba(201, 205, 211, 0.38)",
                                width: "100%",
                                marginTop: 34,/* Line 31 */
                            }}
                        ></div>
                        <div className={classes.instructionTxtContainer}>
                            <Typography
                                className={classes.instructionTxt}
                                component={"span"}
                            >
                                <Trans
                                    i18nKey="PressTheMakeAnotherAppointmentButton"
                                    t={t}
                                />
                            </Typography>
                        </div>
                      
                      <div className={classes.divider}></div>
                        <>
                            <Box display={{md: "flex"}} style={{marginTop: 32, justifyContent: "center", textAlign: "center"}}>
                                <Typography className={classes.label}>
                                    {t(
                                        "Feel free to close this window or schedule another appointment."
                                    )}
                                </Typography>
                            </Box>
                            <Grid container spacing={2} style={{marginTop: 32}}>
                                <Grid item xs={12} style={{textAlign: "center"}}>
                                    <Button
                                        className={`${classes.button} ${classes.buttonCreate}`}
                                        onClick={handleCreation}
                                    >
                                        {t("Make another appointment")}
                                    </Button>
                                </Grid>
                            </Grid>
                        </>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default AppointmentCanceledConfirmation;
