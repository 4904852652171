import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import enGeneral from './assets/locales/en/general.json';
import esGeneral from './assets/locales/es/general.json';
import enPatientFormSection from './assets/locales/en/patient-form-sections.json';
import esPatientFormSection from './assets/locales/es/patient-form-sections.json';
import enPatientForm from './assets/locales/en/patient-form.json';
import esPatientForm from './assets/locales/es/patient-form.json';

i18n
  .use(initReactI18next)
  .init({
    lng: process.env.REACT_APP_DEFAULT_LANGUAGE ?? 'es',
    fallbackLng: process.env.REACT_APP_FALLBACK_LANGUAGE ?? 'en', // use en if detected lng is not available
    resources: {},
    react: {
      defaultTransParent: 'div', // a valid react element - required before react 16
      transEmptyNodeValue: '', // what to return for empty Trans
      transSupportBasicHtmlNodes: true, // allow <br/> and simple html elements in translations
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'], // don't convert to <1></1> if simple react elements
      transWrapTextNodes: ''
    }
  });

// General
i18n.addResourceBundle('en', 'general', enGeneral, true, true);
i18n.addResourceBundle('es', 'general', esGeneral, true, true);

// patient form sections
i18n.addResourceBundle('en', 'patient-form-sections', enPatientFormSection, true, true);
i18n.addResourceBundle('es', 'patient-form-sections', esPatientFormSection, true, true);

// patient form
i18n.addResourceBundle('en', 'patient-form', enPatientForm, true, true);
i18n.addResourceBundle('es', 'patient-form', esPatientForm, true, true);


export default i18n;