import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const CrossIcon = (props: any) => {
  const { style, viewBox, ...otherProps } = props;
  return (
    <SvgIcon
      {...otherProps}
      style={style || { width: 24, height: 24 }}
      viewBox={viewBox || "0 0 24 24"}
    >
      <path d="M12.6568 1.34314C12.3996 1.08592 12.0508 0.941416 11.687 0.941415C11.3232 0.941415 10.9744 1.08592 10.7172 1.34314L7 5.0603L3.28284 1.34314C3.02562 1.08592 2.67675 0.941413 2.31299 0.941413C1.94923 0.941412 1.60036 1.08592 1.34314 1.34314C1.08592 1.60036 0.94142 1.94922 0.94142 2.31298C0.94142 2.67675 1.08592 3.02561 1.34314 3.28283L5.0603 6.99999L1.34315 10.7171C1.08593 10.9744 0.941422 11.3232 0.941422 11.687C0.941423 12.0508 1.08593 12.3996 1.34315 12.6568C1.60037 12.9141 1.94923 13.0586 2.31299 13.0586C2.67676 13.0586 3.02562 12.9141 3.28284 12.6568L6.99647 8.94321L10.7136 12.6604C10.9709 12.9176 11.3197 13.0621 11.6835 13.0621C12.0472 13.0621 12.3961 12.9176 12.6533 12.6604C12.9105 12.4032 13.055 12.0543 13.055 11.6905C13.055 11.3268 12.9105 10.9779 12.6533 10.7207L8.93969 7.00704L12.6604 3.28636C12.788 3.1587 12.8892 3.0071 12.9582 2.84024C13.0271 2.67338 13.0624 2.49456 13.0621 2.31402C13.0618 2.13348 13.0258 1.95478 12.9563 1.78818C12.8867 1.62157 12.785 1.47033 12.6568 1.34314Z" />
    </SvgIcon>
  );
};

export default CrossIcon;
