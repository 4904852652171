import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { schedulerActions } from "../../../redux/scheduler-slice";

import Typography from "@material-ui/core/Typography";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { Accordion, AccordionDetails, AccordionSummary, Button } from "@material-ui/core";

import { ArrowDownIcon, BookMarkIcon, DeleteIcon, ListPlusIcon, PlusIcon, UserIcon } from "../../../assets/icons";
import { AppointmentService } from "../../../api";
import { GroupServiceModel, ServiceModel } from "../../../models/service";
import SelectSearch from "../../common/SelectSearch";
import SelectTime from "../../common/SelectTime";
import { ServiceUserModel } from "../../../models/service/service-user.model";
import useStyles from "./css";
import ConfirmDeleteModal from "../../common/ConfirmDeleteModal";
import { ServiceItem } from "../../../models/service/service-item.model";
import { ServiceWithUsersModel } from "../../../models/service/service-with-users-model";
import UserItem from "../../../models/common/user-item-model";
import DateInput from "../../common/DateInput";
import { ScheduleTime } from "../../../models/schedule";
import SnackbarNotification, { SnackbarNotificationKind } from "../../common/notifications/SnackbarNotification";
import esLocale from "date-fns/locale/es";
import enLocale from "date-fns/locale/en-US";
import SuggestedServices from "../SuggestedServices";
import { AccountWorkDayModel } from "../../../models/account";
import { UserModel } from "../../../models/user";


export default function ScheduleAppointmentServicesStep() {
  const { t } = useTranslation(["general"]);
  const theme = useTheme();
  const isMdMedia = useMediaQuery(theme.breakpoints.up("md"));
  const classes = useStyles();
  const dispatch = useAppDispatch();
  
  const serviceUser = useAppSelector((state) => state.scheduler.serviceUser);
  const servicesWithUsers = useAppSelector((state) => state.scheduler.servicesWithUsers);
  const suggestedServices = useAppSelector((state) => state.scheduler.suggestedServices);

  const refreshAvailableHours = useAppSelector((state) => state.scheduler.refreshAvailableHours);
  const maxServiceIndex = useAppSelector((state) => state.scheduler.maxServiceIndex);
  const availableHours = useAppSelector((state) => state.scheduler.availableHours);
  
  const date = useAppSelector((state) => state.scheduler.currentDate);
  const oldDate = useAppSelector((state) => state.scheduler.oldCurrentDate);
  
  const localeApp = useAppSelector(state => state.scheduler.locale);
  const account = useAppSelector((state) => state.scheduler.account);
  const showPrices = account.showPrices;
  
  const scheduleTime = useAppSelector((state) => state.scheduler.scheduleTime);
  const scheduleAppointment = useAppSelector((state) => state.scheduler.scheduleAppointment);
  const appointmentId = useAppSelector((state) => state.scheduler.appointmentId);
  
  const isValidSelection = useAppSelector((state) => state.scheduler.isValidSelection);
  const isUpdate = useAppSelector((state) => state.scheduler.isUpdate);
  const isEditable = useAppSelector((state) => state.scheduler.isEditable);
  const isServicesPerformedSimultaneously = useAppSelector((state) => state.scheduler.isServicesPerformedSimultaneously);
  const isPreselectedServiceHandled = useAppSelector((state) => state.scheduler.isPreselectedServiceHandled);
  const location = useLocation();

  const {
    setServiceUser,
    setCurrentDate,
    setSchedule,
    setIsValidSelection,
    setIsValidUser,
    setScheduleAppointment,
    setRefreshAvailableHours,
    setMaxServiceIndex,
    setAvailableHours,
    setIsModified,
    setServicesWithUsers,
    setSuggestedServices,
    setScheduleTime,
    setShowLoader,
    setIsServicesPerformedSimultaneously,
    setIsPreselectedServiceHandled
  } = schedulerActions;
  
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [itemToDelete, setItemToDelete] = useState<ServiceItem | null>(null);
  const [showInvalidUserAlert, setShowInvalidUserAlert] = useState<boolean>(false);
  const [noAvailableHoursForCurrentDay, setNoAvailableHoursForCurrentDay] = useState<boolean | null>(null);
  const [noService, setNoService] = useState<boolean>(true);
  const [touched, setTouched] = useState<boolean>(false);
  
  const [servicesList, setServicesList] = useState<ServiceModel[]>([]);
  
  const [groupServices, setGroupServices] = useState<ServiceItem[]>([]);
  const [groupByCategoryServices, setGroupByCategoryServices] = useState<GroupServiceModel[]>([]);

  const [openedServiceSelectorIndex, setOpenedServiceSelectorIndex] = useState<number>(-1);
  const [openedUserSelectorIndex, setOpenedUserSelectorIndex] = useState<number>(-1);
  const [expandedAccordionIndex, setExpandedAccordionIndex] = useState<number>(-1);
  const [availableDays, setAvailableDays] = useState<number[]>([]);
  
  const servicesHaveRepeatedUsers = serviceUser.some((user: ServiceUserModel, index: number) => {
    return serviceUser.slice(0, index).some((prevUser: ServiceUserModel) => {
      return prevUser.userId !== "All" && prevUser.userId === user.userId;
    });
  });
  
  function _setServiceUser(value: ServiceUserModel[]) {
    dispatch(setServiceUser(value));
  }
  
  function _setIsValidSelection(value: boolean) {
    dispatch(setIsValidSelection(value));
  }
  
  function _setIsValidUser(value: boolean) {
    dispatch(setIsValidUser(value));
  }
  
  function _setRefreshHours() {
    dispatch(setAvailableHours([new ScheduleTime()]));
    dispatch(setRefreshAvailableHours(refreshAvailableHours + 1));
  }
  
  function _setServicesWithUsers(value: ServiceWithUsersModel[]) {
    dispatch(setServicesWithUsers(value));
  }

  function _setSuggestedServices(value: GroupServiceModel[]) {
    dispatch(setSuggestedServices(value));
  }

  function _setSchedule(value: any) {
    dispatch(setSchedule(value));
  }

  useEffect(() => {
    const fetchData = async () => {
      dispatch(setShowLoader(true));
      await loadServices();
      dispatch(setShowLoader(false));
    };
    
    if (account && account.id) {
      fetchData();
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account.id]);
  
  useEffect(() => {
    if (serviceUser[0].serviceId !== null) {
      setUsersForAllAppointmentServices();
      dispatch(setMaxServiceIndex(serviceUser.length - 1));
      setExpandedAccordionIndex(0);
    } else {
      setFirstServiceForNewAppointment();
    }
  }, [servicesWithUsers])
  
  useEffect(() => {
    if (account && account.workDays && account.workDays.length) {
      const dayOff = account?.workDays?.map((day: AccountWorkDayModel) => day.dayNumber as number);
      const days = [0, 1, 2, 3, 4, 5, 6].filter(
        (day) => dayOff.indexOf(day) === -1
      );
      setAvailableDays(days);
    }
  }, [account]);
  
  useEffect(() => {
    if (servicesHaveRepeatedUsers && isServicesPerformedSimultaneously) {
      dispatch(setIsServicesPerformedSimultaneously(false));
      _setRefreshHours();
    }
  }, [servicesHaveRepeatedUsers]);
  
  useEffect(() => {
    setNoService(serviceUser.length === 0 || !isValidSelection);
    
    if (availableHours.length > 1) {
      return;
    }
    
    const fetchData = async () => {
      dispatch(setShowLoader(true));
      await loadSchedules();
      dispatch(setShowLoader(false));
    };
    
    if (isValidSelection) {
      fetchData();
    }
    
    if (!isUpdate || (isUpdate && touched)) {
      dispatch(setScheduleTime(undefined));
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshAvailableHours]);
  
  useEffect(() => {
    if (!isPreselectedServiceHandled &&
      groupServices && groupServices.length &&
      servicesWithUsers && servicesWithUsers.length &&
      serviceUser && serviceUser.length) {
      dispatch(setIsPreselectedServiceHandled(true));
      const urlSearchParams = new URLSearchParams(location.search);
      const preselectedServiceId = urlSearchParams.get('serviceId');
      if (preselectedServiceId) {
        const item = groupServices.find(s => s.key === preselectedServiceId);
        if (item){
          const su = serviceUser.find((su: ServiceUserModel) => su.serviceId === preselectedServiceId);
          void handleServiceChange(item!, su?.index ?? 0);
        }
      }
    }
  }, [groupServices, servicesWithUsers, serviceUser])
 
  async function loadSchedules() {
    const getSchedules = async function () : Promise<ScheduleTime[]> {
      const schedules = await AppointmentService.getAvailableHours(
        account.id || "",
        serviceUser,
        date,
        isServicesPerformedSimultaneously,
        appointmentId
      );
      dispatch(setAvailableHours(schedules));
      return schedules;
    }
    
    let schedules : ScheduleTime[] | null = null;
    if (noAvailableHoursForCurrentDay === null) {
      const current = new Date();
      current.setHours(0, 0, 0, 0);
      const isCurrentDate =
        date.getDate() === current.getDate() &&
        date.getMonth() === current.getMonth();
      if (isCurrentDate) {
        if (account.allowCustomerToScheduleTodayFromSite){
          schedules = await getSchedules();
          const noAvailableHours = schedules.length == 0;
          setNoAvailableHoursForCurrentDay(noAvailableHours);
          if (noAvailableHours) {
            handleChangeDate(new Date(current.getTime() + 86400000));
          }
        } else {
          setNoAvailableHoursForCurrentDay(true);
          handleChangeDate(new Date(current.getTime() + 86400000));
        }
      }
    }
    if (schedules === null){
      await getSchedules();
    }
    
    if (!isUpdate || (isUpdate && touched)) {
      dispatch(setSchedule(undefined));
      dispatch(setScheduleTime(undefined));
    }
  }
  
  function _setScheduleTime(value: ScheduleTime | undefined) {
    if (value === undefined) {
      dispatch(setSchedule(undefined));
      dispatch(setScheduleTime(undefined));
      return;
    }
    dispatch(setScheduleTime(value));
    
    const startDate = new Date(scheduleAppointment.startDate as Date);
    if (value.scheduleTime && value.userId) {
      const startTime = value.scheduleTime.split("-")[0];
      startDate.setHours(
        +startTime.split(":")[0],
        +startTime.split(":")[1],
        0,
        0
      );
      dispatch(
        setScheduleAppointment({
          ...scheduleAppointment,
          startDate: startDate,
          userId: value.userId,
        })
      );
    }
  }
  
  function handleChangeDate(date: Date) {
    setDate(date);
    if (isUpdate && touched) {
      dispatch(setIsModified(true));
    }
    _setRefreshHours();
  }
  
  function numberWithCommas(x: any) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  function formatPrice(price: number, currencySymbol: string, valueTemplate: string) {
    const formattedPrice = numberWithCommas(price);

    return valueTemplate.includes("{0}")
      ? valueTemplate.replace("{0}", formattedPrice)
      : `${currencySymbol}${formattedPrice}`;
  }

  async function loadServices() {
    const servicesResponse = await AppointmentService.getServicesGroupByCategory(account.id || "");
    
    const serviceIds = servicesResponse.flatMap(gs => gs.services?.flatMap(s => s.id));
    let filteredServicesIds: string[] = [];
    
    serviceIds.forEach(s => {
      if (s !== null && s !== undefined) {
        filteredServicesIds.push(s);
      }
    })
    
    const servicesWithUsers = await AppointmentService.getUsersForServices(account.id, filteredServicesIds)
    _setServicesWithUsers(servicesWithUsers);
    
    let selectArray: ServiceItem[] = [];
    let servicesModel: ServiceModel[] = [];
    
    servicesResponse.forEach((category, index) => {
      selectArray.push({
        key: `${category.category}-${index}`,
        value: `${category.category} (${category.services?.length})`,
        isGroup: true,
      });
      category.services?.forEach((service) => {
        
        selectArray.push({
          key: service.id,
          value:
            `${service.name}` +
            (showPrices && service.price && account.currencySymbol && account.currencyTemplate
              ? ` (${formatPrice(service.price, account.currencySymbol, account.currencyTemplate)})`
              : ""),
          categoryService: `${category.category} \u00B7 ${service.name}`
        });
        
        servicesModel.push({ ...service, categoryName: category.category });
      });
    });
    
    setServicesList(servicesModel);
    setGroupServices(selectArray);
    setGroupByCategoryServices(servicesResponse);

  }
  
  function setUsersForAllAppointmentServices() {
    if (isUpdate) {
      let newServiceUser = serviceUser.map((su: ServiceUserModel) => {
        const serviceWithUsers = servicesWithUsers.find((x: ServiceWithUsersModel) => x.serviceId == su.serviceId);
        return {
          ...su,
          users: serviceWithUsers?.users ?? []
        };
      });
      
      dispatch(setServiceUser(newServiceUser));
      handleValidChange();
    }
  }
  
  function handleValidChange(su?: ServiceUserModel[]) {
    let servicesList = su ?? serviceUser;
    let isValidLocal = true;
    for (let i of servicesList) {
      const s = i.serviceId;
      
      if (s === null || s === undefined || s === "") { //  && s !== "All"
        isValidLocal = false;
        break;
      }
    }

    _setIsValidSelection(isValidLocal);
    
    isValidLocal = true;
    for (let item of servicesList) {
      if (item.serviceId && item.users !== null && item.users.length && !item.userId) {
        isValidLocal = false;
        break;
      }
    }
    _setIsValidUser(isValidLocal)
    
    _setRefreshHours();
  }

  function getSuggestedServicesForSelectedServices(selectedServices: ServiceUserModel[]) {
    const suggestedServices: GroupServiceModel[] = [];
    const selectedCategories: Set<string> = new Set();

    selectedServices.forEach(serviceUser => {
        if (serviceUser.service && serviceUser.service.categoryName) {
            selectedCategories.add(serviceUser.service.categoryName);
        }
    });

    selectedCategories.forEach(category => {
      const group = groupByCategoryServices.find(group => group.category === category);
        if (group && group.services) {
          let servicesInCategory: ServiceModel[] = group.services.filter((service: ServiceModel) =>
            !selectedServices.some(selectedService => selectedService.service?.id === service.id)
            );
          if (servicesInCategory.length > 0) {
              servicesInCategory = servicesInCategory.map(service => ({
                  ...service,
                  categoryName: category
              }));

              suggestedServices.push({
                  category: category,
                  services: servicesInCategory
              });
          }
        }
    });

    _setSuggestedServices(suggestedServices);
  }
  
  function handleServiceChange(event: ServiceItem, index: number) {
    const serviceKey = event.key;
    const service = servicesList.find(x => x.id === serviceKey);

    const serviceWithUsers = servicesWithUsers.find((x: ServiceWithUsersModel) => x.serviceId == serviceKey);
    const resultUsers = serviceWithUsers!.users ?? [];
    
    let newServiceUserModels: ServiceUserModel[] = [...serviceUser];
    
    const i = serviceUser.findIndex(((x: ServiceUserModel) => x.index === index));
    
    newServiceUserModels[i] = new ServiceUserModel({
      serviceId: serviceKey,
      service: service ?? null,
      userId: account.requireSelectSpecialistFromSite ? null : "All",
      users: resultUsers,
      index: index
    });
    
    _setServiceUser([...newServiceUserModels]);
    handleValidChange([...newServiceUserModels]);
    getSuggestedServicesForSelectedServices(newServiceUserModels);
    _setScheduleTime(undefined);
  }
  
  function handleUserChange(event: UserItem, index: number) {
    const value = event.key;
    if (isUpdate) {
      dispatch(setIsModified(true));
    }
    const i = serviceUser.findIndex(((obj: ServiceUserModel) => obj.index === index));
    let newServiceUser = [...serviceUser];
    newServiceUser[i] = { ...serviceUser[i], userId: value };
    _setServiceUser(newServiceUser);
    handleValidChange(newServiceUser);
    _setScheduleTime(undefined);
  }
  
  function getLocale(): any {
    switch (localeApp) {
      case "es":
        return esLocale;
      
      default:
        return enLocale;
    }
  }
  
  function setFirstServiceForNewAppointment() {
    const newIndex = 0;
    
    const firstService = new ServiceUserModel({
      services: groupServices,
      service: null,
      serviceId: null,
      userId: null,
      users: [],
      index: 0
    });
    const newServiceUser = [firstService];
    
    _setServiceUser(newServiceUser);
    handleValidChange();
    setOpenedServiceSelectorIndex(newIndex);
    setExpandedAccordionIndex(newIndex);
  }
  
  function handleAddService(service?: ServiceModel) {
    const services: ServiceUserModel[] = [...serviceUser];
    
    const newIndex = maxServiceIndex + 1;
    dispatch(setMaxServiceIndex(newIndex));

    const serviceWithUsers = servicesWithUsers.find((x: ServiceWithUsersModel) => x.serviceId === service?.id);

    const newService = new ServiceUserModel({
      services: groupServices,
      service: service ?? null,
      serviceId: service?.id ?? null,
      userId: null,
      users: service 
          ? serviceWithUsers?.users 
        : [],
      index: serviceUser.length === 0 ? maxServiceIndex : newIndex
    });

    services.push(newService);
    
    _setServiceUser([...services]);
    handleValidChange([...services]);
    setOpenedServiceSelectorIndex(newIndex);
    setExpandedAccordionIndex(newIndex);
    _setScheduleTime(undefined);
  }
  
  function handleAccordionChange(index: number) {
    const newIndex = expandedAccordionIndex === index ? -1 : index;
    setExpandedAccordionIndex(newIndex);
  }
  
  function handleAskToRemoveService(e: any, model: ServiceUserModel) {
    e.preventDefault();
    e.stopPropagation();
    
    const s = { key: model.service?.id, value: model.service?.name, index: model.index } as ServiceItem;
    setItemToDelete(s);
    setOpenDeleteDialog(true);
  }
  
  async function handleOnDelete(item: any) {
    const arrServices = serviceUser.filter((data: ServiceUserModel) => data.index !== item.index);
    
    const updatedServices = arrServices.map((data: ServiceUserModel, index: number) => ({
      ...data,
      index: index
    }));
    
    _setServiceUser([...updatedServices]);
    handleValidChange([...updatedServices]);
    getSuggestedServicesForSelectedServices(updatedServices);
    setItemToDelete(null);
    setOpenDeleteDialog(false);
  }
  
  function getUserItems(item: ServiceUserModel): UserItem[] {
    const selectArray: UserItem[] = [];
    
    if (account.showAnySpecialistFromSite) {
      selectArray.push({
        key: "All",
        value: t("Any specialist"),
      });
    }
    
    if (item.users) {
      const sortedUsers = [...item.users].sort((a, b) => a.firstName!.localeCompare(b.firstName!));
      sortedUsers.map((user: UserModel) => {
          const userItem: UserItem = {
            key: user.id,
            value: user.firstName + " " + user.lastName,
            photoURL: user.photoURL || undefined
          };
          selectArray.push(userItem);
        }
      );
    }
    
    return selectArray;
  }
  
  function setDate(date: Date) {
    if (oldDate !== null) {
      if (date !== oldDate) {
        setTouched(true)
      }
    }
    dispatch(setCurrentDate(date));
    _setSchedule(undefined);
  }
  
  function handleDisabledDay(date: MaterialUiPickersDate): boolean {
    const day = new Date(date as Date);
    const current = new Date();
    current.setHours(0, 0, 0, 0);
    const isCurrentDate =
      day.getDate() === current.getDate() &&
      day.getMonth() === current.getMonth();
    const dayOff =
      availableDays.findIndex((dayOff) => dayOff === day.getDay()) !== -1;
    return day < current || dayOff || (isUpdate && !isEditable)
      || isCurrentDate && (noAvailableHoursForCurrentDay === true || account.allowCustomerToScheduleTodayFromSite === false);
  }
  
  const DivInfoDelete: React.FC = () => {
    return (<>
      <div className={classes.modalCenter} style={{ marginTop: 20 }}>
        <Typography className={classes.modalContentText}>
          {t("Are you sure you want to delete this service?")}
        </Typography>
      </div>
      <div className={classes.subtitle}>
        <hr style={{ width: 250 }}/>
        {itemToDelete && itemToDelete.value ? itemToDelete.value : "- -"}
      </div>
    </>);
  };

  const renderServiceUserItem = (item: any, idx: any) => (
    <div key={item.index} className={classes.serviceUserContainer} style={{ zIndex: 99 - item.index }}>
      {isMdMedia && <div style={{ width: 0 }}>
        <div className={classes.deleteDiv}>
          {item.index > 0 && isEditable
            ? <span className={classes.deleteButton} onClick={(e) => handleAskToRemoveService(e, item)}>
              <DeleteIcon/>
            </span>
            : null
          }
        </div>
      </div>}
      <div className={classes.serviceUserBox}>
        <div style={{ marginTop: 0 }}>
          <Typography className={classes.stepCaption}>
            {t("Services")}
          </Typography>
        </div>
        <div style={{ marginTop: 10 }}>
          {groupServices!.length === 0
            ? "" :
            <SelectSearch
              key={`search${item.index}`}
              items={groupServices || []}
              itemId={item.serviceId}
              disableScrollLock={true}
              placeholderSelect={t("Select a service")}
              placeholderInput={t("Search for a service")}
              widthSelect={isMdMedia ? 290 : 272}
              widthList={"100%"}
              isOptional={false}
              onSelect={(e) => handleServiceChange(e, item.index)}
              disabled={isUpdate && !isEditable}
              iconSelect={<BookMarkIcon
                style={{ cursor: isEditable ? "pointer" : null, top: -10, right: 12, position: "relative" }}
                onClick={() => {
                  if (isEditable) {
                    setOpenedServiceSelectorIndex(item.index);
                  }
                }}
              />}
            />}
        </div>
      </div>
      <div className={classes.serviceUserBox}>
        <div style={{ marginTop: 0 }}>
          <Typography className={classes.stepCaption}>
            {t("Specialist")}
          </Typography>
        </div>
        <div style={{ marginTop: 10 }}>
          <SelectSearch
            key={`select${item.index}`}
            items={getUserItems(item)}
            disableScrollLock={true}
            itemId={(item.users !== null && item.users.length && item.userId) ? item.userId : null}
            isValid={!item.serviceId || item.users === null || !item.users.length || !!item.userId}
            placeholderSelect={t("Select a specialist")}
            placeholderInput={t("Search for specialist")}
            widthSelect={isMdMedia ? 290 : 272}
            widthList={"100%"}
            isOptional={false}
            onSelect={(e) => handleUserChange(e, item.index)}
            disabled={isUpdate && !isEditable}
            iconSelect={<UserIcon
              style={{ cursor: isEditable ? "pointer" : null, top: -10, right: 12, position: "relative" }}
              onClick={() => {
                if (isEditable) {
                  setOpenedUserSelectorIndex(item.index);
                }
              }}
            />}
            displayPhoto={true}
          />
        </div>
      </div>
      {idx === 0 && <>    
        <div className={classes.serviceUserBox}>
        <div style={{ marginTop: 0, marginBottom: 10}}>
            <Typography className={classes.stepCaption}>
              {t("Date")}
            </Typography>
        </div>
          <DateInput 
            placeholder={t("dd/mm/yy")}
            value={date}
            onChange={handleChangeDate}
            handleDisableDate={handleDisabledDay}
            inputTextClass={classes.dateText}
            iconColor={"#919CA5"}
            width={isMdMedia ? 210 : 272}
          />
        </div>
        <div className={classes.serviceUserBox}>
        <div style={{ marginTop: 0 }}>
          <Typography className={classes.stepCaption}>
            {t("Time")}
          </Typography>
        </div>
        <div style={{ marginTop: 10 }}>
          <SelectTime
              items={availableHours.map((hour: any) => (
                  hour.scheduleTime
              ))}
              itemSelected={scheduleTime?.scheduleTime || ""}
              isValid = {!(item.serviceId && (!item.userId || scheduleTime === undefined))}
              placeholderSelect={availableHours && availableHours.length > 0
                ? t("Time")
                : t("No available times")
              }
              onSelect={(selectedTime: string) => {
                  _setScheduleTime({ userId: item.userId, scheduleTime: selectedTime });
                  _setSchedule(selectedTime);
              }}
              disabled={!item.userId}               
          />
        </div>
      </div>
   </>}
    </div>
  );
  
  return (<div className={classes.stepRoot}>
    <div className={classes.stepContainer}>
      {serviceUser.map((item: ServiceUserModel, idx: number) => (
        <React.Fragment key={idx}>
          {!isMdMedia && serviceUser.length > 1
            ? <Accordion
              style={{ width: "100vw" }}
              elevation={0}
              expanded={expandedAccordionIndex === idx}
              onChange={() => handleAccordionChange(idx)}
            >
              <AccordionSummary
                className={classes.accordionSummary}
                expandIcon={<ArrowDownIcon style={{ height: 15, width: 15, color: "#5c6477" }}/>}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <div style={{ width: 25 }}>
                  {
                    item.index > 0 && isEditable
                      ? <span onClick={(e) => handleAskToRemoveService(e, item)}>
                          <DeleteIcon/>
                        </span>
                      : <span>
                          <ListPlusIcon/>
                        </span>
                  }
                </div>
                <Typography className={classes.accordionTitle}>
                  {`${t("Service")} ${idx + 1}`}
                </Typography>
              </AccordionSummary>
              <AccordionDetails style={{justifyContent: "center", backgroundColor: "#FAFAFA", borderBottom: "none!important"}}>
                {renderServiceUserItem(item, idx)}
              </AccordionDetails>
            </Accordion>
            : renderServiceUserItem(item, idx)
          }
        </React.Fragment>
      ))}
    </div>
    {isEditable
      ? <div className={classes.addButtonContainer}>
        <Button
          className={`${classes.addButton} ${!servicesList || servicesList.length < 2 ? classes.separatorWithoutLine : ""}`}
          onClick={() => handleAddService()}
          disabled={false}
        >
          <div className={classes.addButtonItems}>
            <PlusIcon />
            {t("Add Service")}
          </div>
        </Button>
      </div>
      : null}
    {account.showSuggestedServices && suggestedServices.some(suggestedService => !!suggestedService.services?.length) && 
      <SuggestedServices 
          services={suggestedServices
                  .flatMap((group: GroupServiceModel) => group.services)
                  .filter((service): service is ServiceModel => service !== null)}
              handleAddService={handleAddService}
          showPrices={account.showPrices || false}
      /> 
    }  
    <ConfirmDeleteModal
      width={isMdMedia ? undefined : 368}
      textButtonConfirm={t("Delete service")}
      classModal={classes.modalDelete}
      open={openDeleteDialog}
      item={itemToDelete}
      componentInfo={<DivInfoDelete/>}
      onClose={() => setOpenDeleteDialog(false)}
      onDelete={handleOnDelete}
    />
    <SnackbarNotification
      isOpen={showInvalidUserAlert}
      onClose={() => {
        setShowInvalidUserAlert(false)
      }}
      message={t("You need to choose a specialist to create the appointment")}
      notificationKind={SnackbarNotificationKind.alert}
    />
  </div>);
};
