import { makeStyles } from "@material-ui/core/styles";


const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
  divInput: {
    display: 'block',
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      width: 270,
    },
    [theme.breakpoints.up('md')]: {
      width: 210,
    }
  },
  inputSelect: {
    pointerEvents: 'none',
    padding: '13px 0px 13px 35px !important',
    [theme.breakpoints.down('md')]: {
      width: '270px !important',
    },
    [theme.breakpoints.up('md')]: {
      width: '210px !important',
    }
  },
  iconDropdown: {
    top: 12,
    height: 16,
    display: 'flex',
    position: 'absolute',
    alignItems: 'center',
  },
  listDropdownContainer: {
    position: 'absolute',
    backgroundColor: theme.colors.white,
    border: 'solid',
    borderWidth: 1,
    borderColor: '#DBDCDD',
    borderRadius: 5,
    marginTop: 3,
    overflowY: 'hidden',
    boxShadow: '0px 4px 4px rgba(166, 166, 166, 0.25)',
    [theme.breakpoints.down('md')]: {
      width: 270,
    },
    [theme.breakpoints.up('md')]: {
      width: 210,
    }
  },
  listDropdown: {
    maxHeight: 360,
    minHeight: 40,
    overflowY: 'auto',
    borderRadius: 5,
  },
  listItem: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    paddingTop: 12,
    paddingBottom: 12,
    paddingLeft: 32,
    paddingRight: 32,
    cursor: 'pointer',
    "&:hover": {
      backgroundColor: '#F5F5F5',
    },
  },
  listItemContent: {
    width: '55%',
    justifyContent: 'end',
    display: 'flex',
    fontFamily: 'Roboto',
    fontSize: '12px',
    fontWeight: 400,
    color: theme.colors.grey4
  },
  itemSelected: {
    fontWeight: 'bold',
    color: theme.colors.clearMain,
    backgroundColor: '#EBEBEB',
  },
  itemNoSelected: {
    fontWeight: 'normal',
    color: theme.colors.grey5,
  },
  popper: {
    "& .MuiTooltip-tooltip": {
      ...theme.typography.txtBody2,
      color: theme.colors.grey5,
      paddingTop: 4,
      paddingBottom: 4,
      paddingLeft: 8,
      paddingRight: 8,
      borderRadius: 5,
      backgroundColor: theme.colors.grey1,
      boxShadow: '0px 0px 4px #00000034',
    },
  },
  timeIconContainer: {
    left: '12px'
  },
  arrowIconContainer: {
    right: '12px'
  },
  timeIcon: {
    margin: '-5px'
  },
  arrowIcon: {
    color: theme.colors.grey4
  },
  arrowIconRotated: {
    transform: 'rotate(180deg)',
  },
  noAvailableTimesContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
    alignItems: 'center',
    padding: 15
  },
  noAvailableTimesTitle: {
    ...theme.typography.buttons,
    fontWeight: 700,
    color: theme.colors.grey4,
    textAlign: 'center'
  },
  noAvailableTimesBody: {
    ...theme.typography.caption1,
    color: theme.colors.grey4,
    textAlign: 'center'
  },
}));

export default useStyles;
