import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const CountryECUIcon = (props: any) => {
    return (
        <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props} >
            <path d="M23.25 12H0.75C0.75 14.0625 1.3125 15.975 2.25 17.625H21.75C22.6875 15.975 23.25 14.0625 23.25 12Z" fill="#2A5F9E"/>
            <path d="M12 0.75C5.775 0.75 0.75 5.775 0.75 12H23.25C23.25 5.775 18.225 0.75 12 0.75Z" fill="#FFE62E"/>
            <path d="M12 23.25C16.1625 23.25 19.8 21 21.75 17.625H2.25C4.2 21 7.8375 23.25 12 23.25Z" fill="#ED4C5C"/>
            <path d="M9.51855 11.0822L9.8193 11.3897L9.9483 11.0383L9.99143 10.8186C9.9483 10.2036 9.99143 9.58858 9.99143 9.58858L10.0777 9.67671L9.73343 9.32533L9.51855 9.10596V11.0822Z" fill="#034EA2"/>
            <path d="M9.99146 10.8189L10.2922 9.89637L9.99146 9.58887V10.8189Z" fill="#ED1C24"/>
            <path d="M9.51857 9.10635L9.73344 9.32572L8.70182 8.27197C8.65869 8.22772 8.65869 9.1941 8.65869 10.2482L9.51857 11.1265V9.10635Z" fill="#FFDD00"/>
            <path d="M9.81937 14.8157C9.08887 14.3766 9.00262 13.0149 9.00262 12.7952V10.4237L9.21787 10.6434L8.18587 9.58932C8.1 9.50157 8.18587 12.4438 8.18587 13.7177C8.18587 14.3327 8.57287 16.4844 10.7224 15.3864C10.7224 15.3864 11.7971 14.9912 12.141 14.5082H11.9261C11.883 14.5521 11.8399 14.5521 11.7971 14.5521C10.5502 15.4303 9.81937 14.8157 9.81937 14.8157Z" fill="#FFDD00"/>
            <path d="M9.00269 12.7949C9.00269 12.9708 9.08894 14.3324 9.77669 14.7712C9.77669 14.7712 10.5076 15.3862 11.7972 14.5518C11.4102 14.7277 11.1094 14.7277 10.8942 14.6834C10.2923 14.5079 9.64769 13.8052 9.56181 13.0585C9.47556 12.3123 9.47556 10.9064 9.47556 10.9064L9.56181 10.9945L9.21794 10.6432L9.00269 10.4673V12.7949Z" fill="#034EA2"/>
            <path d="M9.90556 11.8731V11.302C9.77656 11.1707 9.9483 11.3462 9.60443 10.9945L9.51855 10.9067V10.951C9.51855 11.1265 9.51856 12.4 9.60481 13.0589C9.69068 13.8055 10.3353 14.5082 10.9372 14.6837C11.1521 14.728 11.4532 14.728 11.8398 14.5525C11.8829 14.5525 11.8829 14.5082 11.9261 14.5082C10.7651 14.3766 10.0346 13.3225 9.90556 11.8731Z" fill="#ED1C24"/>
            <path d="M14.2042 14.8594C14.8923 14.4202 15.0213 13.0586 14.9782 12.8831V10.5112L14.7633 10.731L15.795 9.67723C15.8812 9.5891 15.795 12.5317 15.795 13.8052C15.795 14.4202 15.408 16.572 13.2585 15.474C13.2585 15.474 12.1837 15.0791 11.8398 14.5957H12.0551C12.0978 14.5957 12.141 14.6396 12.141 14.6396C13.4737 15.4744 14.2042 14.8594 14.2042 14.8594Z" fill="#FFDD00"/>
            <path d="M14.9783 12.8392C14.9783 13.0147 14.8921 14.3763 14.2043 14.8154C14.2043 14.8154 13.4738 15.4304 12.1838 14.5957C12.5708 14.7715 12.872 14.7715 13.0868 14.7277C13.6887 14.5518 14.3333 13.849 14.4196 13.1024C14.5055 12.3562 14.5055 10.9507 14.5055 10.9507L14.4192 11.0384L14.7635 10.687L14.9783 10.4673V12.8392Z" fill="#034EA2"/>
            <path d="M14.1188 11.9167V11.3459L14.4195 11.0384L14.5054 10.9507V10.9946C14.5054 11.1701 14.5054 12.4436 14.4191 13.1024C14.3333 13.8491 13.7314 14.5518 13.0868 14.7712C12.8719 14.8154 12.5708 14.8154 12.1838 14.6399C12.141 14.6399 12.141 14.5957 12.0979 14.5957C13.2154 14.4202 13.9894 13.3661 14.1184 11.9167" fill="#ED1C24"/>
            <path d="M9.86255 11.6251C9.86255 13.1626 10.8375 14.4001 12 14.4001C13.1625 14.4001 14.1376 13.1626 14.1376 11.6251C14.1376 10.0876 13.1625 8.8501 12 8.8501C10.8375 8.8501 9.86255 10.1251 9.86255 11.6251Z" fill="#8CDDEA"/>
            <path d="M9.86255 11.7373C9.86255 13.2748 10.8375 14.5123 12 14.5123C13.2 14.5123 14.1376 13.2748 14.1376 11.7373H9.86255Z" fill="#6DD337"/>
            <path d="M9.9375 11.8127L13.9875 11.8877L14.025 11.5877C14.025 11.5502 14.1 11.5127 13.9875 11.5127C13.5375 11.4752 13.725 11.4377 13.4625 11.4377C13.3875 11.4377 11.6625 11.0252 11.5125 11.0252C11.3625 10.9877 10.0125 11.2127 10.0125 11.2127L9.9375 11.8127Z" fill="#557176"/>
            <path d="M9.8999 11.0998L10.5374 11.3623C10.5374 11.3623 11.3624 11.2123 11.4374 11.2498C11.5124 11.2873 12.2249 11.3998 12.2624 11.3998H13.3499H13.3124C13.2374 11.3623 12.4124 11.1373 12.3374 11.0998C12.2999 11.0623 11.6624 10.8373 11.6249 10.7623C11.5499 10.6873 11.0999 10.4248 11.0249 10.4248C10.9499 10.3873 9.8999 11.0998 9.8999 11.0998Z" fill="white"/>
            <path d="M12.9 8.9251C12.9 8.9251 12.4875 8.6626 11.9625 8.6626C11.55 8.6626 11.1375 8.8876 11.1375 8.8876C10.6875 9.1501 10.3125 9.6376 10.0875 10.2376C9.93745 10.6501 9.82495 11.1376 9.82495 11.6251C9.82495 13.0876 10.6125 14.2126 11.625 14.5126C11.625 14.5126 11.775 14.5876 11.9625 14.5876C12.15 14.5876 12.3375 14.5501 12.3375 14.5501C13.35 14.2876 14.1749 13.1251 14.1749 11.6626C14.1749 9.7501 12.7125 8.7751 12.9 8.9251ZM12.375 14.3626C12.375 14.3626 12.1875 14.4001 12 14.4001C11.8125 14.4001 11.625 14.3626 11.625 14.3626C10.725 14.1001 10.0125 13.0126 10.0125 11.7001C10.0125 11.2501 10.125 10.7626 10.275 10.3876C10.5 9.8626 10.8375 9.4126 11.25 9.1876C11.25 9.1876 11.6625 8.9251 12 8.9251C12.4875 8.9251 12.8624 9.1876 12.8624 9.1876C13.5374 9.6001 14.025 10.5751 14.025 11.6626C13.9875 13.0126 13.275 14.1376 12.375 14.3626Z" fill="#B1BABF"/>
            <path d="M10.725 11.7L11.3625 12.5625L10.3875 13.125C10.3875 13.125 10.4625 13.35 10.65 13.6125C10.8375 13.8375 11.2875 14.325 11.7 14.3625C12.15 14.4 12.45 14.4 12.7125 14.25C12.9375 14.1 13.275 13.9125 13.5 13.5375C13.725 13.2 13.7249 13.125 13.7249 13.125C13.7249 13.125 12.3 12.825 12.2625 12.825C12.225 12.7875 10.875 11.7375 10.8375 11.7C10.8 11.6625 11.1375 11.25 11.1375 11.25L10.725 11.7Z" fill="#034EA2"/>
            <path d="M11.6626 14.9248H12.3751V16.7623H11.6626V14.9248Z" fill="#ED1C24"/>
            <path d="M11.1001 15.0752H11.8126V16.9127H11.1001V15.0752Z" fill="#034EA2"/>
            <path d="M12.1875 15.0752H12.9V16.9127H12.1875V15.0752Z" fill="#034EA2"/>
            <path d="M10.95 15.0752H11.4V17.4002H10.95V15.0752Z" fill="#FFDD00"/>
            <path d="M12.5999 15.0752H13.0499V17.4002H12.5999V15.0752Z" fill="#FFDD00"/>
            <path d="M10.3125 14.2876H13.6875V15.1876H10.3125V14.2876Z" fill="white"/>
            <path d="M14.1 6.9375C13.425 6.9375 12.7125 7.6875 12 7.6875C11.2875 7.6875 10.575 6.9375 9.9 6.9375C9.2625 6.9375 6.75 7.6875 6.75 7.6875C10.2375 7.6875 12 9.5625 12 9.5625C12 9.5625 13.7625 7.6875 17.25 7.6875C17.25 7.6875 14.7375 6.9375 14.1 6.9375Z" fill="#594640"/>
            <path d="M12 7.8375C12.2486 7.8375 12.4501 7.63603 12.4501 7.3875C12.4501 7.13897 12.2486 6.9375 12 6.9375C11.7515 6.9375 11.55 7.13897 11.55 7.3875C11.55 7.63603 11.7515 7.8375 12 7.8375Z" fill="#F9F9F9"/>
        </SvgIcon>
    )
};

export default CountryECUIcon;
