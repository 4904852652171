import React from 'react';

import { useTranslation } from "react-i18next";

import { Divider, Typography } from "@material-ui/core";

import { ClearBrandIcon } from "../../../assets/icons";
import { ReactComponent as OrnamentLeftBottom } from "../../../assets/images/ornament-left-bottom.svg";
import { ReactComponent as OrnamentRightBottom } from "../../../assets/images/ornament-right-bottom.svg";
import { ReactComponent as InformationSent } from "../../../assets/images/information-sent.svg";

import { PatientFormFinishedProps } from "./props";
import useStyles from "./css";


export default function PatientFormFinished(props: PatientFormFinishedProps) {
  
  const { account, isMobile } = props;
  
  const classes = useStyles(isMobile)();
  
  const { t } = useTranslation("patient-form");
  
  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <div className={classes.accountLogo}>
          {account && account.logo ? (
            <div
              className={classes.imageCircle}
              style={{ backgroundImage: `url("${account.logo}")` }}
            ></div>
          ) : (
            <div
              className={classes.brandingCircle}
              style={{ background: "#6462F3" }}
            >
              <ClearBrandIcon style={{ color: "#F6F6F6" }}/>
            </div>
          )}
        </div>
        <Typography className={classes.accountName}>
          {account.name || "New Life"}
        </Typography>
        <Typography className={classes.thankYouText}>
          {t("Thank you for your time")}
        </Typography>
        <Divider className={classes.divider} />
        <Typography className={classes.infoSentText}>
          {t("Your information has been sent successfully")}
        </Typography>
        <Typography className={classes.seeYouText}>
          {t("See you at your appointment")}
        </Typography>
        <div className={classes.imagesBlock}>
          <OrnamentLeftBottom className={classes.ornament} />
          <InformationSent className={classes.informationSentImage} />
          <OrnamentRightBottom className={classes.ornament} />
        </div>
      </div>
    </div>
  );
}