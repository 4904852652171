import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const WhatsAppIcon = (props: any) => {
  const { style, viewBox, color, ...otherProps } = props;
  return (
    <SvgIcon
      {...otherProps}
      style={{ width: 17, height: 16, color: "#FAFAFA", ...style }}
      viewBox={viewBox || "0 0 17 16"}
    >
      <path fillRule="evenodd"
            clipRule="evenodd"
            d="M8.39765 1.60087C9.95555 1.57519 11.4693 2.11915 12.6547 3.13066C13.8402 4.14218 14.6159 5.55171 14.8363 7.09474C15.0567 8.63778 14.7066 10.2082 13.8517 11.5114C12.9968 12.8145 11.696 13.7608 10.1932 14.1726C8.69049 14.5844 7.08917 14.4334 5.6898 13.748C5.5373 13.6733 5.365 13.6491 5.19784 13.6789L2.62694 14.1373L3.35272 12.4651C3.4638 12.2092 3.43357 11.9139 3.27295 11.6858C2.37564 10.4115 1.97402 8.85344 2.14347 7.30398C2.31291 5.75452 3.04178 4.32018 4.19328 3.27014C5.34478 2.22011 6.83976 1.62655 8.39765 1.60087ZM13.6926 1.91333C12.2108 0.648934 10.3187 -0.0310146 8.3713 0.0010869C6.42393 0.0331884 4.55521 0.775133 3.11584 2.08768C1.67646 3.40023 0.765378 5.19315 0.553568 7.12998C0.358378 8.91483 0.769398 10.7088 1.7129 12.2259L0.566173 14.8679C0.448824 15.1382 0.489589 15.4512 0.672268 15.6825C0.854946 15.9138 1.14994 16.0258 1.44001 15.9741L5.22864 15.2986C6.91899 16.0576 8.82347 16.2069 10.6158 15.7157C12.4942 15.201 14.1203 14.0182 15.1889 12.3892C16.2575 10.7603 16.6951 8.79722 16.4196 6.86843C16.1441 4.93964 15.1745 3.17772 13.6926 1.91333ZM6.40669 4.41406C6.08391 4.41406 5.77435 4.54228 5.54611 4.77052C5.31787 4.99876 5.18965 5.30832 5.18965 5.6311V6.44246C5.18965 7.73357 5.70254 8.97181 6.6155 9.88476C7.52845 10.7977 8.76669 11.3106 10.0578 11.3106H10.8692C11.1919 11.3106 11.5015 11.1824 11.7297 10.9542C11.958 10.7259 12.0862 10.4164 12.0862 10.0936C12.0862 9.7708 11.958 9.46124 11.7297 9.233C11.5015 9.00476 11.1919 8.87654 10.8692 8.87654H10.0578C9.73503 8.87654 9.42547 9.00476 9.19723 9.233C9.11515 9.31507 9.04601 9.40766 8.99112 9.5076C8.53317 9.34822 8.11232 9.08672 7.76293 8.73733C7.41355 8.38794 7.15205 7.9671 6.99267 7.50914C7.0926 7.45425 7.18519 7.38511 7.26726 7.30304C7.4955 7.0748 7.62373 6.76524 7.62373 6.44246V5.6311C7.62373 5.30832 7.4955 4.99876 7.26726 4.77052C7.03902 4.54228 6.72946 4.41406 6.40669 4.41406Z"
            fill={color || "#FAFAFA"}/>
    </SvgIcon>
  );
};

export default WhatsAppIcon;
