import React, { useState } from 'react';

import { useTranslation } from "react-i18next";

import { useAppSelector } from "../../../redux/hooks";

import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import esLocale from "date-fns/locale/es";
import enLocale from "date-fns/locale/en-US";

import useStyles from "./css";
import { PatientFormDateInputProps } from "./props";

import useControlLabel from "../../../hooks/useControlLabel";
import useInputFirstTouch from "../../../hooks/useInputFirstTouch";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import CalendarIcon from "../../../assets/icons/CalendarIcon";


export default function PatientFormDateInput(props: PatientFormDateInputProps) {
  
  const { value, setValue, label, placeholder, isValid, required, isMobile } = props;
  
  const classes = useStyles(isMobile)();
  
  const { t } = useTranslation("patient-form");
  
  const [open, setOpen] = useState<boolean>(false);
  
  const localeApp = useAppSelector(state => state.scheduler.locale);
  
  function isError(): boolean {
    if (!isValid || isValid(value) === undefined) {
      return false;
    }
    return required && !isValid(value);
  }
  
  function getLocale() {
    switch (localeApp) {
      case "es":
        return esLocale;
      
      default:
        return enLocale;
    }
  }
  
  function handleChangeDate(date: MaterialUiPickersDate) {
    const actualDate = date as Date;
    setValue(actualDate);
  }
  
  const ControlLabel = useControlLabel({ label, required });
  const { hasBeenTouched, handleFirstTouch } = useInputFirstTouch();
  
  const maxPlaceholderLength = isMobile ? 42 : 95
  
  function getPlaceholder() {
    const fullText = `${t("Write here your answer for")} ${placeholder}`;
    return fullText.length > maxPlaceholderLength
      ? `${fullText.substring(0, maxPlaceholderLength)}...`
      : fullText
  }
  
  return (
    <div className={classes.root}>
      <ControlLabel />
      <div className={classes.datePicker}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={getLocale()}>
          <DatePicker
            variant={isMobile ? "dialog" : "inline"}
            format={"dd/MM/yyyy"}
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            autoOk
            fullWidth
            openTo="date"
            value={value}
            onChange={handleChangeDate}
            onBlur={() => handleFirstTouch()}
            disableFuture
            InputProps={{
              disableUnderline: true,
              placeholder: getPlaceholder(),
              className: `${classes.input} ${isError() ? classes.inputError : ""}`,
              classes:
                {
                  input: classes.inputText,
                  disabled: classes.inputDisabled
                }
            }}
            DialogProps={{
              PaperProps: {
                classes: { 
                  root: classes.datePickerDialog 
                }
              }
            }}
          />
          <div className={classes.icon} onClick={() => setOpen(true)}>
            <CalendarIcon style={{ width: 14, height: 16, color: "#5C6477" }} />
          </div>
        </MuiPickersUtilsProvider>
      </div>
    </div>
  );
}