import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { schedulerActions } from "../../../redux/scheduler-slice";

import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { CircularProgress, Button, Tooltip } from "@material-ui/core";

import { AppointmentService } from "../../../api";
import { ServiceModel } from "../../../models/service";
import { ServiceUserModel } from "../../../models/service/service-user.model";
import useStyles from "./css";
import { ScheduleTime } from "../../../models/schedule";
import Autocomplete from "../../common/Autocomplete";
import CartModal from "../../common/CartModal";
import BackToEstablishmentsButton from "../BackToEstablishmentsButton";
import { ServiceWithUsersModel } from "../../../models/service/service-with-users-model";
import Select from "../../common/Select";
import { PlusIcon } from "../../../assets/icons";


export default function Services() {

  const { t } = useTranslation(["general"]);
  const theme = useTheme();
  const isMdMedia = useMediaQuery(theme.breakpoints.up("md"));
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const servicesWithUsers = useAppSelector((state) => state.scheduler.servicesWithUsers);
  const refreshAvailableHours = useAppSelector((state) => state.scheduler.refreshAvailableHours);
  const maxServiceIndex = useAppSelector((state) => state.scheduler.maxServiceIndex);
  const account = useAppSelector((state) => state.scheduler.account);

  const {
    setServiceUser,
    setSchedule,
    setIsValidSelection,
    setIsValidUser,
    setRefreshAvailableHours,
    setAvailableHours,
    setMaxServiceIndex,
    setServicesWithUsers,
    setScheduleTime,
    setShowLoader
  } = schedulerActions;

  const { accountBusinessName } = useParams<any>();
  const history = useHistory();

  const [servicesList, setServicesList] = useState<ServiceModel[]>([]);
  const [selectedServices, setSelectedServices] = useState<ServiceUserModel[]>([]);
  const [loadingServiceId, setLoadingServiceId] = useState<string | null>(null);
  const [selectedCategory, setSelectedCategory] = useState<string>(t('All'));
  const [serviceSearch, setServiceSearch] = useState<ServiceModel[]>([]);
  const [search, setSearch] = useState<string>();

  const [openCartModal, setOpenCartModal] = useState<boolean>(false);

  const categories = servicesList
    .map(service => service.categoryName)
    .filter((category): category is string => category !== null);

  const uniqueCategories: string[] = [
    t('All'),
    ...Array.from(new Set(categories)).sort((a, b) => a.localeCompare(b))
  ];

  const filteredServicesList = selectedCategory === t('All')
    ? servicesList
    : servicesList.filter(service => service.categoryName === selectedCategory);

  useEffect(() => {
    if (account.id) {
      loadServices();
    }
  }, [account.id]);

  useEffect(() => {
    if (search && search.length > 2) {
      (async () => {
        await loadSearchData();
      })();
    }
  }, [search]);

  useEffect(() => {
    if (selectedServices.length > 0) {
      setOpenCartModal(true);
    } else {
      setOpenCartModal(false);
    }
  }, [selectedServices]);

  async function loadServices() {
    dispatch(setShowLoader(true));
    const servicesResponse = await AppointmentService.getServices(account.id || "");
    dispatch(setShowLoader(false));
    const serviceIds = servicesResponse.map(service => service.id);
    let filteredServicesIds: string[] = [];

    serviceIds.forEach(s => {
      if (s !== null && s !== undefined) {
        filteredServicesIds.push(s);
      }
    })
    dispatch(setShowLoader(true));
    const servicesWithUsers = await AppointmentService.getUsersForServices(account.id, filteredServicesIds)
    dispatch(setShowLoader(false));
    dispatch(setServicesWithUsers(servicesWithUsers));

    setServicesList(servicesResponse);
  }

  async function loadSearchData() {
    dispatch(setShowLoader(true));
    const data = await AppointmentService.getServices(account.id!, search, ["Name", "Category"]);
    dispatch(setShowLoader(false));
    // avoid names duplicated
    const list: ServiceModel[] = data ?? [];
    const names = list.map((o) => o.name);
    const groupByName = list.filter(
      ({ name }, index) => !names.includes(name, index + 1)
    );

    setServiceSearch(groupByName || []);
  }

  function searchServicesOnSelected(service: ServiceModel) {
    if (service === undefined || !service.name) {
      loadServices();
      return;
    }
    const serviceName = removeAccents(service.name).toLowerCase();

    const filteredServices = servicesList.filter(s => 
      removeAccents(s.name ?? "").toLowerCase() === serviceName
    );
    setServicesList(filteredServices);
  }

  function removeAccents(value: string) {
    return value.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  }

  function handleAddService(serviceId: string | null) {
    setLoadingServiceId(serviceId);

    const services: ServiceUserModel[] = [...selectedServices];

    if (selectedServices.length && selectedServices[0].serviceId === null) {
      services.length = 0;
    }

    const newIndex = maxServiceIndex + 1;
    dispatch(setMaxServiceIndex(newIndex));


    let serviceWithUser = servicesWithUsers.find((x: ServiceWithUsersModel) => x.serviceId == serviceId);

    let newService = new ServiceUserModel({
      service: servicesList.find(x => x.id === serviceId) ?? null,
      serviceId: serviceId,
      userId: account.showAnySpecialistFromSite ? "All" : null,
      users: serviceWithUser?.users ?? [],
      index: maxServiceIndex
    });

    services.push(newService);
    setSelectedServices(services);

    setLoadingServiceId(null);
  }

  function onScheduleAppointmentClick() {
    dispatch(setServiceUser([...selectedServices]));
    dispatch(setSchedule(undefined));
    dispatch(setIsValidSelection(true));
    dispatch(setIsValidUser(account.showAnySpecialistFromSite));
    dispatch(setScheduleTime(undefined));
    dispatch(setAvailableHours([new ScheduleTime()]));
    dispatch(setRefreshAvailableHours(refreshAvailableHours + 1));
    history.push(`/${accountBusinessName}/appointment`);
  }
  function handleCategoryChange(e: any) {
    setSelectedCategory(e.target.value);
  }

  function formatPrice(price: number, currencySymbol: string, valueTemplate: string) {
    return valueTemplate.includes("{0}")
      ? valueTemplate.replace("{0}", price.toString())
      : `${currencySymbol}${price}`;
  }

  return (
    <div className={classes.servicesRoot}>
      {account.enterpriseBusinessNameUrl && <BackToEstablishmentsButton />}
      <div className={classes.serviceFlexContainer} style={{ paddingBottom: !isMdMedia && servicesList.length < 2 ? 236 : 52 }}>
        <div className={classes.servicesText}>
          {t("Services")}
        </div>
        <div className={classes.selectSearchContainer}>
          <div className={classes.categorySelectContainer}>
            <Select
              items={uniqueCategories}
              value={selectedCategory}
              onChange={handleCategoryChange}
              width={isMdMedia ? 190 : 230}
            />
          </div>
          <div className={`${classes.searchContainer} ${!isMdMedia && account.enterpriseBusinessNameUrl && classes.marginTopZero}`}>
            <Autocomplete
              style={{ width: isMdMedia ? 755 : 230 }}
              items={serviceSearch}
              placeholder={t("Search for a service")}
              renderOption={(option: any) => `${option.name}`}
              getOptionSelected={(option, value) => option.name === value.name}
              getOptionLabel={(option) => option.name}
              onChange={(value: any, reason) => {
                if (reason === "input" && value.length >= 3)
                  setSearch(value);
                else
                  setServiceSearch([]);
              }}
              onSelected={(service: any) => {
                searchServicesOnSelected(service);
              }}
            />
          </div>
        </div>
        <div className={classes.servicesContainer}>
          {filteredServicesList.map((service, index) => (
            <div className={classes.serviceWindow} key={index}>
              {service.photo ? (
                <img src={service.photo} className={classes.servicePhoto} />
              ) : (
                <div className={classes.servicePhotoEmpty}></div>
              )}
              <div className={classes.descriptionContainer}>
                <div>
                  <div className={classes.serviceName}>
                    {service.name}
                  </div>
                  <div className={classes.servicePrice}>
                    {account.showPrices
                      ? service.price && account.currencySymbol && account.currencyTemplate &&
                        `${formatPrice(service.price, account.currencySymbol, account.currencyTemplate)} | ${service.duration} ${t("minutes")}`
                      : `${service.duration} ${t("minutes")}`
                    }
                  </div>
                </div>
                <Tooltip
                  title={service.description ?? ""}
                  classes={{ tooltip: classes.tooltipBlock }}
                >
                  <div className={classes.serviceDescription} style={{ height: !isMdMedia && !service.photo ? 67 : 56 }}>
                    {service.description}
                  </div>
                </Tooltip>

                <div className={classes.buttonContainer}>
                  {<Button
                    className={`${classes.addButton}`}
                    onClick={() => handleAddService(service.id)}
                    disabled={selectedServices.some(x => x.serviceId === service.id)}
                    style={{ width: 163 }}
                  >
                    <div className={classes.addButtonItems}>
                      {loadingServiceId === service.id
                        ?
                        (<CircularProgress size={16} color={"inherit"} />)
                        :
                        (<PlusIcon />)
                      }
                      {t("Add Service")}
                    </div>
                  </Button>
                  }

                </div>
              </div>

            </div>
          ))}

        </div>
      </div>
      {openCartModal &&
        <CartModal
          onClose={() => setOpenCartModal(false)}
          services={selectedServices}
          sheduleAppt={() => onScheduleAppointmentClick()}
        />
      }
    </div>
  );
};