import { makeStyles } from "@material-ui/core/styles";

const useStyles = (isMobile: boolean) => makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    
    paddingLeft: isMobile ? 8 : 24,
    paddingBottom: 23,
  
    backgroundColor: theme.colors.white
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    
    width: 228,
  },
  section: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    
    marginBottom: 17,
  },
  sectionHeader: {
    ...theme.typography.txtBody2,
    color: theme.colors.grey4
  },
  sectionValue: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    
    ...theme.typography.txtBody1,
    fontWeight: 700,
    color: theme.colors.grey5
  },
  sectionIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    
    width: 12,
    height: 12,
    
    marginLeft: 4,
  
    backgroundColor: "#36CE91",
    borderRadius: 6,
  },
}));

export default useStyles;
