import { makeStyles } from "@material-ui/core/styles";

const useStyles = () => makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      width: "100%", 
      backgroundColor: theme.colors.white
    },
  },
  stepper: {
    height: 85,
    width: 560,
    
    padding: 8,
    paddingTop: 58,
    
    [theme.breakpoints.down("sm")]: {
      padding: "58px 0px 40px 0px",
      whiteSpace: "nowrap",
      width: "100%",
    },
  },
  labelAlternativeLabel: {
    
    ...theme.typography.txtBody2,
    color: `${theme.colors.grey3} !important`,
    "& .MuiStepLabel-active": {
      color: `${theme.colors.clearMain} !important`,
      fontWeight: 700,
    },
    "& .MuiStepLabel-label": {
      marginTop: -55,
    }
  },
  labelCompleted: {
    color: `${theme.colors.clearGreen} !important`
  },
}));

export const useStepIconStyles = makeStyles((theme) => ({
  root: {
    color: theme.colors.grey2,
    display: 'flex',
    height: 22,
    alignItems: 'center',

  },
  circle: {
    width: 14,
    height: 14,
    borderRadius: '50%',
    border: `1px solid ${theme.colors.grey2}`
  },
  activeCircle: {
    width: 14,
    height: 14,
    borderRadius: '50%',
    border: `1px solid ${theme.colors.clearMain}`,
    backgroundColor: theme.colors.clearMain
  },
  completed: {
    color: theme.colors.clearGreen,
    zIndex: 1,
    fontSize: 22,
    fontWeight: "bold"
  }})
);

export default useStyles;
