import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const CountryBELIcon = (props: any) => {
    return (
        <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props} >
            <g clip-path="url(#clip0_27973_194977)">
                <g clip-path="url(#clip1_27973_194977)">
                    <rect x="-3.69531" y="0.75" width="32" height="24" rx="2" fill="white" />
                    <mask id="mask0_27973_194977" style={{maskType: "luminance"}} maskUnits="userSpaceOnUse" x="-4" y="0" width="33" height="25">
                        <rect x="-3.69531" y="0.75" width="32" height="24" rx="2" fill="white" />
                    </mask>
                    <g mask="url(#mask0_27973_194977)">
                        <rect x="11.5391" y="0.75" width="16.7619" height="24" fill="#ED4C5C" />
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M-3.69531 24.75H6.97135V0.75H-3.69531V24.75Z" fill="#262626" />
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.96875 24.75H17.6354V0.75H6.96875V24.75Z" fill="#FFCF3C" />
                    </g>
                </g>
            </g>
            <defs>
                <clipPath id="clip0_27973_194977">
                    <rect x="0.304688" y="0.75" width="24" height="24" rx="12" fill="white" />
                </clipPath>
                <clipPath id="clip1_27973_194977">
                    <rect width="32" height="24" fill="white" transform="translate(-3.69531 0.75)" />
                </clipPath>
            </defs>
        </SvgIcon>
    )
};

export default CountryBELIcon;