import { makeStyles } from "@material-ui/core/styles";

const useStyles = (isMobile: boolean) => makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    
    width: isMobile ? 304 : 716,
    
    marginBottom: isMobile ? 18 : 32,
    
    backgroundColor: theme.colors.white,
  
    borderRadius: 10,
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    
    width: "100%",
    
    paddingTop: isMobile ? 24 : 36,
    paddingLeft: isMobile ? 16 : 60,
    paddingRight: isMobile ? 16 : 60,
  
    boxShadow: "0px 3px 20px rgba(229, 231, 234, 0.56)",
  },
  accountLogo: {
    marginBottom: isMobile ? 6 : 24,
  },
  accountName: {
    marginBottom: 4,
  
    fontFamily: theme.typography.header2.fontFamily,
    fontStyle: theme.typography.header2.fontStyle,
    fontSize: isMobile ? theme.typography.header4.fontSize : theme.typography.header2.fontSize,
    lineHeight: isMobile ? theme.typography.header4.lineHeight : theme.typography.header2.lineHeight,
    fontWeight: 700,
    color: theme.colors.grey5,
  },
  thankYouText: {
    fontFamily: isMobile ? theme.typography.txtBody2.fontFamily : theme.typography.txtBody1.fontFamily,
    fontStyle: theme.typography.header2.fontStyle,
  
    fontSize: isMobile ? theme.typography.txtBody2.fontSize : theme.typography.txtBody1.fontSize,
    lineHeight: isMobile ? theme.typography.txtBody2.lineHeight : theme.typography.txtBody1.lineHeight,
  
    fontWeight: 400,
    color: theme.colors.grey5,
  },
  divider: {
    width: isMobile ? 272 : 596,
    
    marginTop: isMobile ? 16 : 24,
    marginBottom: isMobile ? 16 : 24,
    
    backgroundColor: theme.colors.grey2
  },
  infoSentText: {
    fontFamily: isMobile ? theme.typography.txtBody2.fontFamily : theme.typography.header4.fontFamily,
    fontStyle: theme.typography.header4.fontStyle,
    fontSize: isMobile ? theme.typography.txtBody2.fontSize : theme.typography.header4.fontSize,
    lineHeight: isMobile ? theme.typography.txtBody2.lineHeight : theme.typography.header4.lineHeight,
  
    fontWeight: 400,
    color: theme.colors.clearMain,
  },
  seeYouText: {
    fontFamily: isMobile ? theme.typography.header4.fontFamily : theme.typography.header3.fontFamily,
    fontStyle: theme.typography.header4.fontStyle,
    fontSize: isMobile ? theme.typography.header4.fontSize : theme.typography.header3.fontSize,
    lineHeight: isMobile ? theme.typography.header4.lineHeight : theme.typography.header3.lineHeight,
  
    fontWeight: 700,
    color: theme.colors.clearMain,
  },
  imagesBlock: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
    
    width: isMobile ? 304 : 716,
    height: isMobile ? 290 : 620,
  },
  ornament: {
    width: isMobile ? 53 : 112,
    height: isMobile ? 63 : 134,
  
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
  },
  informationSentImage: {
    width: isMobile ? 196 : 315,
    height: isMobile ? 270 : 433,
    
    marginBottom: isMobile ? 0 : 80,
  },
  brandingCircle: {
    height: isMobile ? 48 : 60,
    width: isMobile ? 48 : 60,
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  imageCircle: {
    height: isMobile ? 48 : 60,
    width: isMobile ? 48 : 60,
    borderRadius: "50%",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    boxShadow: "0px 3px 20px rgba(229, 231, 234, 0.56)",
  },
}));

export default useStyles;
