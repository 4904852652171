import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      ...theme.typography.buttons,
      fontWeight: 700,
      color: theme.colors.clearMain,
      '&:disabled': {
        color: theme.colors.clearMain,
      }
    },
    goBackIconContainer: {
      marginRight: 10
    },
    buttonContainer: {
      alignSelf: "flex-start",
      marginLeft: 12,
      marginTop: 10,
      [theme.breakpoints.down("sm")]: {
        marginBottom: 10,
        marginLeft: 37
      }
    },
    closeConfirmationModal: {
      height: "234px + !important",
      width: 660,
      [theme.breakpoints.down("sm")]: {
        width: "87%!important",
      }
    },
    closeConfirmationModalContent: {
      ...theme.typography.header4,
      fontWeight: 700,
      textAlign: "center"
    },
  })
);

export default useStyles;
