import React, { useEffect, useRef } from 'react';

import { useTranslation } from "react-i18next";

import CanvasDraw from "react-canvas-draw";

import { Button } from "@material-ui/core";

import { PatientSignatureProps } from "./props";
import useStyles from "./css";
import useControlLabel from "../../../hooks/useControlLabel";


export default function PatientSignature(props: PatientSignatureProps) {
  
  const { signature, setSignature, isMobile } = props;
  
  const { t } = useTranslation("patient-form");
  
  const classes = useStyles(isMobile)();
  
  const canvas = useRef<CanvasDraw | null>(null);
  const brushColor = "#000000";
  const brushRadius = 2;
  const lazyRadius = 3;
  
  useEffect(() => {
    if (signature) {
      canvas.current?.loadSaveData(signature)
    }
  }, [])
  
  function handleDeleteSignatureClick() {
    canvas.current?.clear();
    setSignature(null)
  }
  
  function isDeleteSignatureButtonDisabled() {
    return signature === null;
  }
  
  function handleCanvasOnChange(canvas: CanvasDraw) {
    setSignature(canvas.getSaveData())
  }
  
  const PatientSignatureLabel = useControlLabel({ label: t("Patient's signature"), required: true })
  
  return (
    <div className={classes.root}>
      <div className={classes.signatureLabel}>
        <PatientSignatureLabel />
      </div>
      <CanvasDraw
        ref={canvasDraw => (canvas.current = canvasDraw)}
        className={classes.signatureBlock}
        canvasWidth={isMobile ? 288 : 668}
        canvasHeight={262}
        brushRadius={brushRadius}
        brushColor={brushColor}
        lazyRadius={lazyRadius}
        onChange={handleCanvasOnChange}
        clampLinesToDocument
        hideInterface
        hideGrid
      />
      <div className={classes.deleteSignatureButtonBlock}>
        <Button
          className={classes.deleteSignatureButton}
          onClick={handleDeleteSignatureClick}
          disabled={isDeleteSignatureButtonDisabled()}
        >
          {t("Delete signature")}
        </Button>
      </div>
    </div>
  );
}