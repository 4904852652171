import React from "react";
import { SnackbarProvider } from "notistack";
import Collapse from "@material-ui/core/Collapse";

import { AlertProviderProps } from "./props";

const AlertProvider = (props: AlertProviderProps) => {
  const { children } = props;
  return (
    <SnackbarProvider
      maxSnack={4}
      autoHideDuration={6000}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      TransitionComponent={Collapse}
    >
      {children}
    </SnackbarProvider>
  );
};

export default AlertProvider;
