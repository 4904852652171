import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const CountryDEUIcon = (props: any) => {
    return (
        <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
            <path d="M11.9626 0.75C7.0501 0.75 2.8876 3.9 1.3501 8.25H22.5751C21.0376 3.9 16.8751 0.75 11.9626 0.75Z" fill="#3E4347"/>
            <path d="M11.9626 23.25C16.8751 23.25 21.0376 20.1375 22.5751 15.75H1.3501C2.8876 20.1375 7.0501 23.25 11.9626 23.25Z" fill="#FFE62E"/>
            <path d="M1.3499 8.25C0.937402 9.4125 0.712402 10.6875 0.712402 12C0.712402 13.3125 0.937402 14.5875 1.3499 15.75H22.5749C22.9874 14.5875 23.2124 13.3125 23.2124 12C23.2124 10.6875 22.9874 9.4125 22.5749 8.25H1.3499Z" fill="#ED4C5C"/>
        </SvgIcon>
    )
};

export default CountryDEUIcon;
