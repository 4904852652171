import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { conformToMask } from "react-text-mask";

import { Button } from "@material-ui/core";

import useStyles from "./css";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { schedulerActions } from "../../redux/scheduler-slice";

import CountryFlagIcon from "../../assets/flags";
import { EnterpriseService } from "../../api/enterprise-service";
import { GetMobileMaskByTemp } from "../../constants/masks";
import { EstablishmentInfo } from "../../models/establishment/establishmentInfo";
import EstablishmentLogo from "../../components/common/EstablishmentLogo";

export default function SelectEstablishment() {
  
  const { t } = useTranslation(["general"]);
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const mobileTemplate: string = "000-000-0000";
  
  const account = useAppSelector((state) => state.scheduler.account);
  
  const { setShowLoader } = schedulerActions;
  
  const [establishmentList, setEstablishmentList] = useState<EstablishmentInfo[]>([]);
  
  useEffect(() => {
    if (account.id) {
      loadServices();
    }
  }, [account.id]);
  
  async function loadServices() {
    dispatch(setShowLoader(true));
    const servicesResponse = await EnterpriseService.getListEstablishments(account.id || "");
    dispatch(setShowLoader(false));
    setEstablishmentList(servicesResponse);
  }
  
  function onSeeServicesClick(businessNameUrl: string) {
    history.push('/' + businessNameUrl + "/services");
  }
  function onScheduleAppointmentClick(businessNameUrl: string) {
    history.push('/' + businessNameUrl + "/appointment");
  }
  
  function renderEstablishmentItem(establishment: EstablishmentInfo, index: any) {
    return (
      <div className={classes.selectEstablishmentWindow} key={index} style={{ height: 242 }}>
        <EstablishmentLogo
          logo={establishment.logo}
          onClick={() => onScheduleAppointmentClick(establishment.businessNameUrl)}
        />
        <div className={classes.descriptionContainer}>
          
          <div>
            <div className={classes.selectEstablishmentName}>
              {`${establishment.name}`}
            </div>
          </div>
          
          <div className={classes.selectEstablishmentDescription}>
            {establishment.address}
          </div>
          <div className={classes.whatsAppNumberContainer}>
            <div className={classes.divFlag}>
              <CountryFlagIcon iso={establishment?.mobileCountry?.iso3 || "MEX"}/>
            </div>
            <div className={classes.divCode}>
              {establishment?.mobileCountry?.dialCode !== undefined ? `+${establishment?.mobileCountry.dialCode}` : "+52"}
            </div>
            <div className={classes.divMobile}>
              {establishment?.phoneNumber ? conformToMask(establishment?.phoneNumber, GetMobileMaskByTemp(establishment?.mobileCountry.dialTemplate || mobileTemplate)).conformedValue : ""}
            </div>
          </div>
          
          <div className={classes.buttonContainer}>
            <Button
              className={`${classes.selectEstablishmentButton}`}
              onClick={() => onSeeServicesClick(establishment.businessNameUrl)}
              disabled={false}
              style={{ width: 163 }}
            >
              <div>
                {t("See services")}
              </div>
            </Button>
            
            <Button
              className={`${classes.scheduleButton}`}
              onClick={() => onScheduleAppointmentClick(establishment.businessNameUrl)}
              disabled={false}
              style={{ width: 163 }}
            >
              <div>
                {t("Schedule appt")}
              </div>
            </Button>
          </div>
        </div>
      
      </div>
    );
  }
  
  return (
    <div className={classes.selectEstablishmentRoot}>
      <div className={classes.selectEstablishmentFlexContainer} style={{ paddingBottom: 52 }}>
        <div className={classes.selectEstablishmentTitle}>
          {`${t("Select an establishment")}:`}
        </div>
        <div className={classes.selectEstablishmentContainer}>
          {establishmentList.map((establishment, index) => renderEstablishmentItem(establishment, index))}
        </div>
      </div>
    </div>
  );
};