import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import Button from "@material-ui/core/Button";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";

import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { schedulerActions } from "../../../redux/scheduler-slice";
import { useHistory } from "react-router-dom";

import AppointmentCancelModal from "./../AppointmentCancelModal";
import { AppointmentService } from "../../../api";
import { ScheduleAppointment } from "../../../models/schedule";
import { CancelAppointmentButtonProps } from "./props";
import { ServiceUserModel } from "../../../models/service/service-user.model";



const CancelAppointmentButton = (props: CancelAppointmentButtonProps) => {
  const { classes } = props;
  const { t } = useTranslation(["general"]);
  const theme = useTheme();
  const isMdMedia = useMediaQuery(theme.breakpoints.up("md"));
  const serviceUser = useAppSelector((state) => state.scheduler.serviceUser);
  const currentDate = useAppSelector((state) => state.scheduler.currentDate);
  const scheduleTime = useAppSelector((state) => state.scheduler.schedule);
  const oldScheduleTime = useAppSelector((state) => state.scheduler.oldSchedule);
  const account = useAppSelector((state) => state.scheduler.account);
  const appointmentId = useAppSelector((state) => state.scheduler.appointmentId);
  const isEditable = useAppSelector((state) => state.scheduler.isEditable);
  const localeApp = useAppSelector(state => state.scheduler.locale);
  const isServicesPerformedSimultaneously = useAppSelector((state) => state.scheduler.isServicesPerformedSimultaneously);
  const [cancelAppointmentModal, setCancelAppointmentModal] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const {
    setIsOpenCustomerModal,
    setIsOpenScheduleBusyModal,
    setSchedule,
    setOldSchedule,
    setServiceUser,
    setCurrentDate,
    setCustomerValid,
    setScheduleAppointment,
    setIsUpdate
  } = schedulerActions;
  const history = useHistory();

  const handleCancelAppointmentModal = () => {
    setCancelAppointmentModal(true);
  }

  const handleCancelAppointment = (allRecurringAppointments: boolean) => {
    if (appointmentId) {
      AppointmentService.removeAppointment(appointmentId as string, allRecurringAppointments).then(() => {
        dispatch(setIsOpenCustomerModal(false));
        dispatch(setIsOpenScheduleBusyModal(false));
        dispatch(setSchedule(undefined));
        dispatch(setOldSchedule(null));
        dispatch(setServiceUser([new ServiceUserModel()]));
        dispatch(setCurrentDate(new Date()));
        dispatch(setCustomerValid(undefined));
        dispatch(setScheduleAppointment(new ScheduleAppointment()));
        dispatch(setIsUpdate(false));
        history.push({pathname: `/${account.businessNameUrl}/canceled-confirmation`})
      });
    }
  };

  function countAppointmentDuration(services: ServiceUserModel[]) {
    if (isServicesPerformedSimultaneously) {
      return Math.max(...services.map(service => service?.service?.duration || 0))
    }
    return services.reduce((previousValue, currentValue) => previousValue + (currentValue?.service?.duration ? currentValue.service.duration : 0), 0);
  }
  
  const appointmentDuration = countAppointmentDuration(serviceUser);

  return (
    <>
    {appointmentId && currentDate && (scheduleTime || (oldScheduleTime !== null)) && appointmentDuration > 0 &&
      <>
        <Button className={classes.cancelButton}
                disabled={!isEditable}
                onClick={handleCancelAppointmentModal}>
          {localeApp !== 'es' && !isMdMedia ? t("Cancel appt") : t("Cancel appointment")}
        </Button>
        <AppointmentCancelModal
            open={cancelAppointmentModal}
            appointmentId={appointmentId}
            startDate={currentDate}
            scheduleTime={scheduleTime || oldScheduleTime!}
            duration={appointmentDuration}
            onClose={() => {
              setCancelAppointmentModal(false);
            }}
            onCancelAppointment={() => handleCancelAppointment(false)}
        />
      </>}
    </>
  );
};

export default CancelAppointmentButton;
