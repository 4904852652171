import { makeStyles } from "@material-ui/core/styles";

const useStyles = (isMobile: boolean) => makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    
    width: isMobile ? 304 : 716,
    height: isMobile ? 389 : 427,
    
    paddingTop: 24,
    paddingRight: isMobile ? 8 : 24,
    paddingBottom: 24,
    paddingLeft: isMobile ? 8 : 24,
    
    backgroundColor: theme.colors.white
  },
  signatureLabel: {
    alignSelf: "flex-start"
  },
  signatureBlock: {
    marginTop: 16,
    marginBottom: 16,
  
    border: "solid",
    borderColor: theme.colors.grey2,
    borderRadius: 10,
    borderWidth: 1,
  },
  deleteSignatureButtonBlock: {
    alignSelf: isMobile ? "center" : "flex-end",
    
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  
    width: 140,
    height: 32,
  },
  deleteSignatureButton: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  
    width: 140,
    height: 32,
  
    backgroundColor: theme.colors.white,
  
    border: "solid",
    borderWidth: 1,
    borderRadius: 51,
    borderColor: theme.colors.grey4,
  
    ...theme.typography.buttons,
    color: theme.colors.grey4,
    fontWeight: 700,
  
    "&:disabled": {
      opacity: 0.3,
      color: theme.colors.grey4,
    },
    "&:hover": {
      backgroundColor: theme.colors.white + "!important",
      color: theme.colors.grey4 + "!important",
    }
  },
}));

export default useStyles;
