import React, { useEffect, useState, useRef } from "react";

import { useTranslation } from "react-i18next";

import Modal from "@material-ui/core/Modal";
import { InputLabel, Tooltip } from "@material-ui/core";
import { ClickAwayListener } from "@material-ui/core";

import { ArrowLineDown, SearchIcon } from "../../../assets/icons";
import Input from "../Input";
import useDebounce from "../../../hooks/useDebounce";

import { SelectSearchItem, SelectSearchProps } from "./props";
import useStyles from "./css";
import SpecialistPhoto from "../../appointment/SpecialistPhoto";

const SelectSearch = (props: SelectSearchProps) => {

    const { t } = useTranslation(["general"]);
    const classes = useStyles();
    const {
        items
        , itemId
        , placeholderSelect
        , placeholderInput
        , label
        , iconSelect
        , disabled
        , widthSelect
        , widthList
        , isOptional
        , onSelect
        , disableScrollLock
        , isValid
        , displayPhoto
    } = props;

    const [list, setList] = useState<SelectSearchItem[]>(items);
    const [itemSelected, setItemSelected] = useState<SelectSearchItem>();
    const [search, setSearch] = useState<string>("");
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [showTooltip, setShowTooltip] = useState<boolean>(false);
    const debouncedSearchValue = useDebounce(search, 400);

    const elementsRefs = useRef<(HTMLDivElement | null)[]>([]);

    const handleItemSelected = (i: SelectSearchItem) => {
        if (i.isGroup) return;
        setItemSelected(i);
        onSelect(i);
        setOpenModal(false);
        setSearch("");
    }

    useEffect(() => {
        if (items.length > 0) {
            setList(items);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [items]);

    useEffect(() => {
        if (items.length > 0 && itemId) {
            const item = items.filter(x => x.key === itemId);
            if (item.length > 0)
                setItemSelected(item[0]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [itemId]);

    useEffect(() => {

        if (debouncedSearchValue) {
          const searchValue = removeAccents(debouncedSearchValue).toLowerCase();
          const newList = items.filter((x) => {
            const itemValue = removeAccents(x.value).toLowerCase();
            return !x.isGroup && itemValue.includes(searchValue);
          });
          setList(newList);
        } else {
            setList(items);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedSearchValue]);

    const handleSearch = (event: React.ChangeEvent<{ name: string, value: string }>) => {
        let value = event.target.value;
        setSearch(value);
    }

    const handleOpenModal = () => {
        if (disabled) return;
        setSearch("");
        setOpenModal(true);
    }

    const handleCloseModal = () => {
        setSearch("");
        setOpenModal(false);
    }

    const onMouseEnterItem = (index: number) => {
        let offsetWidth = elementsRefs.current[index]?.offsetWidth ?? 0;
        let scrollWidth = elementsRefs.current[index]?.scrollWidth ?? 0;

        if (offsetWidth < scrollWidth) {
            setShowTooltip(true);
        }
    }
    const onMouseLeaveItem = () => {
        setShowTooltip(false);
    }

    function getInitials(fullName: string) {
      if (!fullName) return '';
      const splitFullName = fullName.split(' ');
      return `${splitFullName[0][0]}${splitFullName[1][0]}`;
    }

    function removeAccents(value: string) {
      return value.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    }

    return (<div className={classes.component}>
        <div className={classes.divDropdown} style={{ width: widthSelect || 350 }}>
            <Tooltip
                placement="bottom"
                classes={{ popper: classes.popper }}
                title={itemSelected?.value?.startsWith("selectSearch_") ? t(itemSelected?.value) : itemSelected?.value || ""}
                style={{maxHeight: 40}}>
                <div>
                    <div>
                        {label && <>
                            <InputLabel className={`${classes.label}`}>
                                {label + " "}
                                {
                                    (isOptional ?? true) ?
                                        <span className={classes.optional}>({t("Optional")})</span>
                                        :
                                        <span className={classes.required}>*</span>
                                }
                            </InputLabel>
                        </>}
                        
                        <div
                            onClick={handleOpenModal}
                            style={{ cursor: disabled ? "cursor" : "pointer" }}>

                            <Input
                                id={"itemSelect"}
                                name={"itemSelected"}
                                value={itemSelected?.value?.startsWith("selectSearch_") ? t(itemSelected?.value) : itemSelected?.value || ""}
                                placeholder={placeholderSelect}
                                width={widthSelect || 350}
                                inputClass={classes.inputSelectBtn}
                                style={{ cursor: disabled ? "cursor" : "pointer" }}
                                isValid={() =>
                                    typeof isValid !== 'undefined'
                                        ? isValid
                                        : isOptional ? isOptional : itemSelected?.value !== ""}
                            />

                        </div>
                    </div>

                    <div className={classes.iconDropdown} onClick={handleOpenModal} style={{ cursor: disabled ? "cursor" : "pointer" }}>
                        {iconSelect ?? <ArrowLineDown style={{ color: "#6462F3" }} />}
                    </div>
                </div>
            </Tooltip>

        </div>
        <ClickAwayListener mouseEvent="onMouseDown" onClickAway={handleCloseModal}>
            <Modal
                open={openModal}
                onClose={handleCloseModal}
                disableScrollLock={disableScrollLock}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                BackdropProps={{ style: { backgroundColor: "transparent" } }}
                disablePortal={true}
                style={{ position: "relative", top: 4 }}>

                <div className={classes.divModalSelect} style={{ width: widthList || 350 }}>

                    <div className={classes.divSearch}>

                        <div className={classes.btnInputNew}>
                            <div>
                                <Input
                                    id={"searchInput"}
                                    name={"searchInput"}
                                    value={search}
                                    width={"92%"}
                                    maxLength={50}
                                    placeholder={placeholderInput}
                                    inputClass={classes.input}
                                    autoFocus={true}
                                    onChange={handleSearch}
                                />
                            </div>
                            <button className={classes.iconSearch}>
                                <SearchIcon style={{ color: "#DDD", height: 15, width: 15 }} />
                            </button>
                        </div>
                    </div>

                    <div className={classes.listItemScroll}>
                        {items && list.map((i: SelectSearchItem, index) => {
                            return <span key={`option-${i.key}-${index}`}>
                                <div key={i.key} className={classes.listItem} onMouseEnter={() => onMouseEnterItem(index)} onMouseLeave={onMouseLeaveItem} >
                                    <Tooltip
                                        placement="bottom"
                                        classes={{ popper: classes.popper }}
                                        title={showTooltip
                                            ? <> {i.value?.startsWith("selectSearch_")
                                                ? t(i.value)
                                                : i.value}  </>
                                            : ""}>
                                        <div className={`${classes.elementList} ${itemId === i.key
                                            ? classes.itemSelected
                                            : classes.itemNoSelected} ${i.isGroup
                                                ? classes.classRowDisabled
                                                : classes.classRow}`}>
                                            {
                                              displayPhoto && i.key != 'All' && 
                                                <SpecialistPhoto
                                                  initials={getInitials(i.value)}
                                                  photoURL={i.photoURL}
                                                /> 
                                            }
                                            <div className={classes.itemValue}
                                                onClick={() => handleItemSelected(i)}
                                                ref={(ref) => elementsRefs.current[index] = ref}
                                                key={index}>
                                                {i.value?.startsWith("selectSearch_") ? t(i.value) : i.value}
                                            </div>
                                        </div>
                                    </Tooltip>
                                </div>
                            </span>
                        })}
                    </div>

                </div>
            </Modal >
        </ClickAwayListener >
    </div >);
};

export default SelectSearch;
