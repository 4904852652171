import React, { useEffect, useState } from 'react';

import { Redirect } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import { useAppSelector } from "../../../redux/hooks";

import InterweaveComponent from "../../common/InterweaveComponent";
import { PatientFormService } from "../../../api/patient-form-service";
import { Url } from "../../../constants";
import AccountInfoProvider from "../../account/AccountInfoProvider/account-info-provider";


export default function PrivacyNoticePage() {
  
  const [privacyNoticeEnabled, setPrivacyNoticeEnabled] = useState<boolean>(true);
  const [privacyNotice, setPrivacyNotice] = useState<string>("");
  
  const account = useAppSelector((state) => state.scheduler.account);
  
  useEffect(() => {
    (async () => loadPrivacyNotice()
    )();
  }, [account.id])
  
  async function loadPrivacyNotice() {
    if (account && account.id) {
      const response = await PatientFormService.getPrivacyNotice(account.id);
      setPrivacyNotice(response.privacyNotice);
      setPrivacyNoticeEnabled(response.privacyNoticeEnabled);
    }
  }
  
  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <AccountInfoProvider />
      {
        privacyNoticeEnabled
        ? 
          <InterweaveComponent
            text={privacyNotice}
          /> 
        :
          <Redirect to={Url.NotFound} />
      }
    </>
  );
}