import React from "react";

import SvgIcon from "@material-ui/core/SvgIcon";


const CountryFRAIcon = (props: any) => {
    return (
        <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props} >
            <path d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z" fill="white" />
            <path d="M7.99875 23.3171C3.33863 21.6694 0 17.2247 0 12.0003C0 6.77592 3.33863 2.33129 7.99875 0.683594V23.3171Z" fill="#002654" />
            <path d="M24 11.973V12.0277C23.9883 17.2403 20.6532 21.6723 16.0012 23.3171V0.683594C20.6532 2.32841 23.9883 6.76041 24 11.973Z" fill="#CE1126" />
        </SvgIcon>
    )
};

export default CountryFRAIcon;
