import * as React from "react";

import Fade from "@material-ui/core/Fade";

import { useAppSelector } from "../../redux/hooks";

import NavBar from "../navbar/NavBar";
import LoaderLine from "../common/LoaderLine";
import useStyles from "./css";
import AccountInfoProvider from "../account/AccountInfoProvider/account-info-provider";

export default function Layout(props: any) {
  const showLoader = useAppSelector((state) => state.scheduler.showLoader);
  const classes = useStyles();

  return (
    <div>
      <AccountInfoProvider/>
      <NavBar/>
      <Fade
        in={showLoader ?? false}
        style={{
          transitionDelay: '800ms',
          zIndex: 999,
          position: "absolute",
          width: "100%",
          top: 64
        }}
      >
        <span>
          <LoaderLine />
        </span>
      </Fade>
      <div className={classes.root}>
        <div>{props.children}</div>
      </div>
    </div>
  );
}
