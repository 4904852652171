import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const CountryNORIcon = (props: any) => {
    return (
        <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props} >
            <g clip-path="url(#clip0_27973_194973)">
                <rect x="-3.44531" y="1" width="31.5" height="23.5" rx="1.75" fill="white" stroke="#F5F5F5" stroke-width="0.5" />
                <mask id="mask0_27973_194973" style={{maskType: "luminance"}} maskUnits="userSpaceOnUse" x="-4" y="0" width="33" height="25">
                    <rect x="-3.44531" y="1" width="31.5" height="23.5" rx="1.75" fill="white" stroke="white" stroke-width="0.5" />
                </mask>
                <g mask="url(#mask0_27973_194973)">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.44755 0.75H-3.69531V10.35H5.44755V0.75ZM10.019 0.75V10.35H28.3047V0.75H10.019ZM28.3047 15.15H10.019V24.75H28.3047V15.15ZM5.44755 24.75V15.15H-3.69531V24.75H5.44755Z" fill="#ED4C5C" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M-3.69531 13.55H6.97135V24.75H8.49516V13.55H28.3047V11.95H8.49516V0.75H6.97135V11.95H-3.69531V13.55Z" fill="#0A3A85" />
                </g>
            </g>
            <defs>
                <clipPath id="clip0_27973_194973">
                    <rect x="0.304688" y="0.75" width="24" height="24" rx="12" fill="white" />
                </clipPath>
            </defs>
        </SvgIcon>
    )
};

export default CountryNORIcon;