import { makeStyles } from "@material-ui/core/styles";

const useStyles = (isMobile: boolean) => makeStyles((theme) => ({
  root: {
    
  },
  sectionHeader: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    
    width: isMobile ? 304 : 716,
    height: 48,
    
    paddingLeft: isMobile ? 8 : 28,
    
    backgroundColor: theme.colors.backgroundGrey1,
  
    fontFamily: isMobile ? theme.typography.txtBody1.fontFamily : theme.typography.header4.fontFamily,
    fontStyle: isMobile ? theme.typography.txtBody1.fontStyle : theme.typography.header4.fontStyle,
    fontSize: isMobile ? theme.typography.txtBody1.fontSize : theme.typography.header4.fontSize,
    lineHeight: isMobile ? theme.typography.txtBody1.lineHeight : theme.typography.header4.lineHeight,
    
    fontWeight: 700,
    color: theme.colors.grey5
  }
}));

export default useStyles;
