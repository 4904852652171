import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const ArrowIcon = (props: any) => {
  const { style, viewBox, ...otherProps } = props;
  return (
    <SvgIcon
      {...otherProps}
      style={{ width: 10, height: 17, color: "#919CA5", ...style }}
      viewBox={viewBox || "0 0 10 17"}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.784811 9.19089L7.79328 16.2118C7.98273 16.3966 8.23717 16.5 8.50215 16.5C8.76712 16.5 9.02156 16.3966 9.21101 16.2118C9.39584 16.0229 9.4993 15.7695 9.4993 15.5056C9.4993 15.2417 9.39584 14.9882 9.21101 14.7993L2.89709 8.49965L9.21101 2.2014C9.39627 2.01246 9.5 1.75871 9.5 1.49445C9.5 1.2302 9.39627 0.976443 9.21101 0.787507C9.02163 0.60318 8.76749 0.5 8.50286 0.5C8.23824 0.5 7.9841 0.60318 7.79471 0.787507L0.784811 7.78556C0.602118 7.9741 0.5 8.22604 0.5 8.48823C0.5 8.75041 0.602118 9.00235 0.784811 9.19089Z"
      />
    </SvgIcon>
  );
};

export default ArrowIcon;
