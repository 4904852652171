import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { Typography, useMediaQuery, useTheme } from '@material-ui/core';

import useStyles from './css';
import { SuggestedServicesProps } from './props';
import SuggestedServiceCard from '../SuggestedServiceCard';
import ArrowNavigationIcon from '../../../assets/icons/ArrowNavigationIcon';


export default function SuggestedServices(props: SuggestedServicesProps) {

    const { t } = useTranslation(["general"]);

    const { services, handleAddService, showPrices } = props;

    const classes = useStyles();
    const theme = useTheme();
    const isMdMedia = useMediaQuery(theme.breakpoints.up("md"));

    const itemsPerPage = isMdMedia ? 4 : 1;
    const [currentIndex, setCurrentIndex] = useState(0);
    const [displayedItems, setDisplayedItems] = useState(services.slice(0, itemsPerPage));
    const isEndOfCarousel = currentIndex + itemsPerPage >= services.length;
    function handlePrevClick() {
        setCurrentIndex(currentIndex => Math.max(currentIndex - itemsPerPage, 0));
    }

    function handleNextClick() {
        setCurrentIndex(currentIndex => {
            const newIndex = currentIndex + itemsPerPage;
            return newIndex >= services.length ? currentIndex : newIndex;
        });
    }

    useEffect(() => {
        setDisplayedItems(services.slice(currentIndex, currentIndex + itemsPerPage));
    }, [currentIndex, services, itemsPerPage]);

    useEffect(() => {
        setCurrentIndex(0);
    }, [services]);

    useEffect(() => {
        function handleKeyDown(event: KeyboardEvent) {
            if (event.key === 'ArrowLeft') {
                handlePrevClick();
            } else if (event.key === 'ArrowRight') {
                handleNextClick();
            }
        };

        window.addEventListener('keydown', handleKeyDown);
        return () => window.removeEventListener('keydown', handleKeyDown);
    }, []);

    return (
        <div className={classes.root}>
            <div className={classes.headerContainer}>
                <Typography className={classes.header}>
                    {t("Suggested services")}
                </Typography>
                {isMdMedia && (
                    <Typography className={classes.header}>
                        {`${services.length} ${t("suggested services")}`}
                    </Typography>
                )}
            </div>
            <div className={classes.navigationContainer}>
                <div className={classes.arrow} onClick={handlePrevClick}>
                    <ArrowNavigationIcon opacity={currentIndex > 0 ? '1' : '0.3'} />
                </div>
                <div className={classes.servicesContainer}>
                    {displayedItems.map((service, index) => (
                        <SuggestedServiceCard
                            key={service.id}
                            service={service}
                            handleAddService={handleAddService}
                            classCard={(isMdMedia && !isEndOfCarousel && index === displayedItems.length - 1)
                                ? classes.partialCard
                                : ''}
                            showPrices={showPrices}
                        />
                    ))}
                </div>
                <div className={classes.arrow} onClick={handleNextClick}>
                    <ArrowNavigationIcon
                        opacity={isEndOfCarousel ? '0.3' : '1'}
                        style={{ transform: 'rotate(180deg)' }} />
                </div>
            </div>
        </div>
    );
};
